import React, { useEffect, useState } from "react";
import Home from "../Home";
import { Tooltip } from "react-tooltip";
import { getCapitalized } from "../../utils/Helper";
import { Controller, set, useForm } from "react-hook-form";
import Error from "../notification/Error";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  createApplication,
  getPreferencePicklist,
  getugApplicationDetail,
} from "../../redux/actions/Api";
import Success from "../notification/Success";
import { NavLink, useNavigate } from "react-router-dom";
import { HELP_TEXT, PATHS } from "../../utils";
import Loader from "../Loader";
import { resetForms, unsavedchanges } from "../../redux/actions";
import { Notes } from "../common/Notes";
import {
  AppCommon,
  ApplicationViewPage,
  PGApplicationDetails,
  UGApplicationDetails,
} from "../constantLabelFile";

let DraftBtn = "";
let addedLang = ["No", "No", "No"];

const ApplicationDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    createapplicationres,
    picklistdata,
    ugappdata,
    error,
    dashboardresponse,
    preferencepicklistvalue,
  } = useSelector((state) => state.collections);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onTouched" });

  const [year, setYear] = useState("");
  const [lang, setLang] = useState("");
  const [loader, setLoader] = useState(true);
  const [pickListVal, setPickListVal] = useState("");
  const [nation, setNation] = useState("");
  const [programApplied, setProgramApplied] = useState("");
  const [secondprogramApplied, setSecondProgramApplied] = useState("");
  const [campusPref, setCampusPref] = useState("");
  const [campusSeco, setCampusSeco] = useState("");
  const [preTestCenter, setPreTestCenter] = useState("");
  const [preInterviewCenter, setPreInterviewCenter] = useState("");
  const [applicationData, setApplicationData] = useState("");
  const [preferencePick, setPreferencePick] = useState("");
  const [campPrefe, setCampPrefe] = useState("");
  const [secondCampPrefe, setsecondCampPrefe] = useState("");
  const [readOnly, setReadOnly] = useState(false);
  const [showMsg, setShowMsg] = useState("");
  const [secondProgram, setSecondProgram] = useState("");
  const [firstProgram, setFirstProgram] = useState("");
  const [consider, setConsider] = useState("");

  useEffect(() => {
    if (dashboardresponse) {
      let formdata = {
        applicationCycleId:
          dashboardresponse?.data?.allDataOnDashboard[0]?.appCycleId,
      };
      if (formdata && dashboardresponse) {
        dispatch(getPreferencePicklist(formdata));
      }
    }
  }, [dashboardresponse]);

  useEffect(() => {
    if (ugappdata == undefined) {
      setLoader(true);
    } else {
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  }, [ugappdata]);

  useEffect(() => {
    if (error) {
      setLoader(false);
      toast.error(<Error error={error} />, {
        position: "top-center",
        autoClose: 5000,
        className: "Toastify__toast-theme--colored.Toastify__toast--warning",
      });
      setTimeout(() => {
        dispatch(resetForms());
      }, 5000);
    }
  }, [error]);

  useEffect(() => {
    if (ugappdata) {
      setApplicationData(ugappdata?.data?.records[0]);
    }
  }, [ugappdata]);

  useEffect(() => {
    let formData = {
      contactId: localStorage.getItem("contactId"),
      applicationId: localStorage.getItem("applicationId"),
    };
    if (formData) {
      dispatch(getugApplicationDetail(formData));
    }
  }, []);

  useEffect(() => {
    setPickListVal(picklistdata?.data);
    setPreferencePick(preferencepicklistvalue?.data);
  }, [picklistdata, preferencepicklistvalue]);

  let draftMsg = {
    message: "Details Saved Successfully.",
  };

  useEffect(() => {
    if (createapplicationres?.success) {
      dispatch(unsavedchanges());
      toast.success(
        <Success response={!DraftBtn ? createapplicationres : draftMsg} />,
        {
          position: "top-center",
          autoClose: 2000,
        }
      );

      setLoader(false);
      if (!DraftBtn) {
        setTimeout(() => {
          navigate(
            PATHS.EDUCATIONDETAILUG_STR +
              dashboardresponse?.data?.allDataOnDashboard[0]?.appId
          );
        }, 500);
      } else if (DraftBtn) {
        setTimeout(() => {
          draftMsg.message = "";
        }, 500);
      }
    }
  }, [createapplicationres]);

  useEffect(() => {
    if (
      applicationData.Domicile__c !== null ||
      applicationData.Domicile__c !== ""
    ) {
      setValue("nationality", applicationData?.hed__Country_of_Origin__c);
      setValue("domicile", applicationData?.Domicile__c);
      setValue("gender", applicationData?.hed__Gender__c);
      setYear(
        applicationData?.hed__Applications_Applicant__r?.records[0]
          ?.Applied_for_earlier_cycles__c
      );
      setValue(
        "prevApplied",
        applicationData?.hed__Applications_Applicant__r?.records[0]
          ?.Applied_for_earlier_cycles__c
      );
      setValue(
        "yearApplied",
        applicationData?.hed__Applications_Applicant__r?.records[0]
          ?.Year_Applied_In__c
      );
      setLang(
        applicationData?.hed__Contact_Languages__r?.records[0]?.hed__Language__c
      );
      setValue(
        "language",
        applicationData?.hed__Contact_Languages__r?.records[0]?.hed__Language__c
      );
      setValue(
        "spoken",
        applicationData?.hed__Contact_Languages__r?.records[0]?.Speaking__c
      );
      addedLang[0] =
        applicationData?.hed__Contact_Languages__r?.records[0]?.Speaking__c;
      setValue(
        "writing",
        applicationData?.hed__Contact_Languages__r?.records[0]?.Writing__c
      );
      addedLang[1] =
        applicationData?.hed__Contact_Languages__r?.records[0]?.Writing__c;
      setValue(
        "reading",
        applicationData?.hed__Contact_Languages__r?.records[0]?.Reading__c
      );
      addedLang[2] =
        applicationData?.hed__Contact_Languages__r?.records[0]?.Reading__c;
      setProgramApplied(
        applicationData?.hed__Applications_Applicant__r?.records[0]
          ?.First_Preference__c
      );
      setValue(
        "programApplying",
        applicationData?.hed__Applications_Applicant__r?.records[0]
          ?.First_Preference__c
      );
      setCampusPref(
        applicationData?.hed__Applications_Applicant__r?.records[0]
          ?.Campus_Preferences_1__c
      );
      setValue(
        "campus1",
        applicationData?.hed__Applications_Applicant__r?.records[0]
          ?.Campus_Preferences_1__c
      );

      setSecondProgramApplied(
        applicationData?.hed__Applications_Applicant__r?.records[0]
          ?.Second_Preference__c
      );
      setValue(
        "programPrefer",
        applicationData?.hed__Applications_Applicant__r?.records[0]
          ?.Second_Preference__c
      );
      setCampusSeco(
        applicationData?.hed__Applications_Applicant__r?.records[0]
          ?.Campus_Preferences_2__c
      );
      setValue(
        "campus2",
        applicationData?.hed__Applications_Applicant__r?.records[0]
          ?.Campus_Preferences_2__c
      );
      setPreTestCenter(
        applicationData?.hed__Applications_Applicant__r?.records[0]
          ?.First_Test_Center_Prefrence__c
      );
      setValue(
        "testCenter",
        applicationData?.hed__Applications_Applicant__r?.records[0]
          ?.First_Test_Center_Prefrence__c
      );
      setPreInterviewCenter(
        applicationData?.hed__Applications_Applicant__r?.records[0]
          ?.Preferred_Interview_center__c
      );
      setValue(
        "interviewCenter",
        applicationData?.hed__Applications_Applicant__r?.records[0]
          ?.Preferred_Interview_center__c
      );
      setValue("considerAdmissionInAnyCampus", applicationData?.hed__Applications_Applicant__r?.records[0]?.APU_consider_admission_in_any_campus__c)
    setConsider(applicationData?.hed__Applications_Applicant__r?.records[0]?.APU_consider_admission_in_any_campus__c)
  
    }
  }, [applicationData, preferencePick]);

  let langMsg = {
    message:
      "Please select atleast one language proficiency as Yes on selected languages",
  };

  const _onSubmit = (values) => {
    if (addedLang.includes("Yes")) {
      let formData = {
        userId: localStorage.getItem("userId"),
        contactId: localStorage.getItem("contactId"),
        applicationId: localStorage.getItem("applicationId"),
        nationality: values.nationality,
        domicile: values.domicile,
        gender: values.gender,
        prevApplied: values.prevApplied,
        yearApplied: values.yearApplied,
        language: values.language,
        spoken: values.spoken,
        writing: values.writing,
        reading: values.reading,
        programApplying: values.programApplying,
        campus1: values.campus1,
        programPrefer: values.programPrefer,
        // campus2: campusSeco ? campusSeco : values.campus2,
        campus2: values.campus2,
        testCenter: values.testCenter,
        interviewCenter: values.interviewCenter,
        considerAdmissionInAnyCampus: values.considerAdmissionInAnyCampus
      };

      if (formData) {
        setLoader(true);
        dispatch(createApplication(formData));
      }
    } else {
      toast.error(<Error error={langMsg} />, {
        position: "top-center",
        autoClose: 1500,
        className: "Toastify__toast-theme--colored.Toastify__toast--warning",
      });
    }
  };

  useEffect(() => {
    if (applicationData.Domicile__c !== null) {
      preferencePick?.coursePreference?.map((cam) => {
        if (
          cam.Id ===
          applicationData?.hed__Applications_Applicant__r?.records[0]
            ?.First_Preference__c
        ) {
          const data = preferencePick?.campusPreference[cam.value];
          setCampPrefe(data);
          setFirstProgram(cam?.notes);
        }
      });
    }
  }, [applicationData, preferencepicklistvalue, preferencePick]);

  useEffect(()=>{
    if(applicationData?.hed__Applications_Applicant__r?.records[0]?.Second_Preference__r?.Name && preferencePick){    
      if(preferencePick?.coursePreference[0]?.value === applicationData?.hed__Applications_Applicant__r?.records[0]?.Second_Preference__r?.Name){
        setValue("campus2", "")
        setsecondCampPrefe("")
      }
    }
  },[preferencePick, applicationData])

  const handleProgramApplied = (e) => {
    setFirstProgram("");
    setCampusPref("");
    setProgramApplied(e.target.value);
    preferencePick?.coursePreference?.map((cam) => {
      if (cam.Id === e.target.value) {
        const data = preferencePick?.campusPreference[cam.value];
        setCampPrefe(data);
        setFirstProgram(cam.notes);
      }
    });
    dispatch(unsavedchanges(e.target.value));
    clearErrors("programApplying");
  };

  useEffect(() => {
    if (applicationData.Domicile__c !== null) {
      preferencePick?.coursePreference?.map((cam) => {
        if (
          cam.Id ===
          applicationData?.hed__Applications_Applicant__r?.records[0]
            ?.Second_Preference__c
        ) {
          const data = preferencePick?.campusPreference[cam.value];
          setsecondCampPrefe(data);
          setSecondProgram(cam?.notes);
        }
      });
    }
  }, [applicationData, preferencePick]);

  const handleSecondProgramApplied = (e) => {
    setSecondProgram("");
    setCampusSeco("");
    setsecondCampPrefe("");
    setSecondProgramApplied(e.target.value);

    preferencePick?.coursePreference?.map((cam) => {
      if (cam.Id === e.target.value) {
        const data = preferencePick?.campusPreference[cam.value];
        setsecondCampPrefe(data);
        setSecondProgram(cam.notes);
        if (cam.value === "No Second Preference") {
          setCampusSeco(data[0]?.Id);
          setValue("campus2", "")
          clearErrors("campus2");
          setsecondCampPrefe("")
        }
      }
    });
    dispatch(unsavedchanges(e.target.value));
    clearErrors("programPrefer");
  };

  const currentDate = new Date();
  const year1 = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDate = `${year1}-${month}-${day}`;

  useEffect(() => {
    if (
      applicationData?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Started" ||
      applicationData?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Draft" ||
      applicationData?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Payment Failed" ||
      applicationData?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Payment Pending" ||
      applicationData?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Payment Submitted"
    ) {
      if (
        formattedDate >
        applicationData?.hed__Applications_Applicant__r?.records[0]
          ?.Admission_Cycle__r?.Application_Submission_Date__c
      ) {
        setReadOnly(true);
        setShowMsg(<Notes name="ADM_Cycle_Validation_App" />);
      } else {
        setReadOnly(false);
        setShowMsg("");
      }
    } else {
      if (
        formattedDate <
        applicationData?.hed__Applications_Applicant__r?.records[0]
          ?.Admission_Cycle__r?.Application_Submission_Date__c
      ) {
        setReadOnly(true);
        setShowMsg("");
      } else {
        setReadOnly(true);
        setShowMsg(<Notes name="ADM_Cycle_Validation_App" />);
      }
    }
  }, [applicationData]);

  return (
    <Home>
      <div class="right_panel">
        <div class="w100">
          <p className="top_head_styl">{showMsg}</p>
          <h4 className="top_head">
            {getCapitalized(UGApplicationDetails.Undergraduate_Programme)}
          </h4>
          <span className="help_link">
            <p>
              <NavLink to={PATHS.RAISEQUERY}>{HELP_TEXT}</NavLink>{" "}
              <Tooltip id="helpTooltip">
                <p>{ApplicationViewPage.Help_Tooltip_Text}</p>
              </Tooltip>
              <i
                data-tooltip-id="helpTooltip"
                class="fa-solid fa-circle-info"
              ></i>
            </p>
          </span>
        </div>

        <Loader show={loader} />

        <form onSubmit={handleSubmit(_onSubmit)} disabled={readOnly}>
          <div className="w100 scroll_styl">
            <div class="heading_styl head_font">
              <h4>
                {getCapitalized(UGApplicationDetails.Application_Details)}
              </h4>
            </div>
            <div class="form_sec form_part">
              <div class="form_styl">
                <div class="w100">
                  <a
                    href=" https://azimpremjiuniversity.edu.in/undergraduate/eligibility-and-diversity"
                    className="linkcheckegi"
                    target="_blank"
                  >
                    {" "}
                    <b style={{ cursor: "pointer" }}>
                      {UGApplicationDetails.Eligibility_Criteria_Message}
                    </b>
                  </a>
                </div>
              </div>

              <div class="heading_styl">
                <h4>{UGApplicationDetails.Background_Details}</h4>
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {UGApplicationDetails.Nationality}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <select
                    {...register("nationality", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    disabled={readOnly}
                    className={`${errors.nationality ? "error_msg" : ""}`}
                    onChange={(e) => {
                      setNation(e.target.value);
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("nationality");
                    }}
                  >
                    <option value="" disabled selected>
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.nationality.map((opt, ind) => (
                        <option key={ind} value={opt.value}>
                          {opt.value}
                        </option>
                      ))}
                  </select>
                  {errors.nationality && (
                    <div className="error_shown">
                      {errors.nationality?.message}
                    </div>
                  )}
                </div>
                <div class="inner_part">
                  <label>
                    {UGApplicationDetails.Gender}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <select
                    {...register("gender", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    onChange={(e) => {
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("gender");
                    }}
                    className={`${errors.gender ? "error_msg" : ""}`}
                    disabled={readOnly}
                  >
                    <option value="" disabled selected>
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.gender?.map((opt, ind) => (
                        <option key={ind} value={opt.value}>
                          {opt.value}
                        </option>
                      ))}
                  </select>
                  {errors.gender && (
                    <div className="error_shown">{errors.gender?.message}</div>
                  )}
                </div>
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {UGApplicationDetails.Applied_Before}{" "}
                    <span class="redstyl">*</span>
                  </label>
                  <select
                    {...register("prevApplied", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    disabled={readOnly}
                    value={year}
                    className={`${errors.prevApplied ? "error_msg" : ""}`}
                    onChange={(e) => {
                      setYear(e.target.value);
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("prevApplied");
                    }}
                  >
                    <option value="" disabled selected>
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.appliedEarlier.map((app, ind) => (
                        <option value={app.value}>{app.value}</option>
                      ))}
                  </select>
                  {errors.prevApplied && (
                    <div className="error_shown">
                      {errors.prevApplied?.message}
                    </div>
                  )}
                </div>
                {year === "Yes" && (
                  <div class="inner_part">
                    <label>                     
                      {UGApplicationDetails.Applied_Year}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input
                      {...register("yearApplied", {
                        required: {
                          value: true,
                          message: "Please enter value",
                        },                        
                      })}
                      autoComplete="off"
                      className={`${errors.yearApplied ? "error_msg" : ""}`}
                      maxLength="255"
                      onChange={(e) => {
                        dispatch(unsavedchanges(e.target.value));
                        clearErrors("yearApplied");
                      }}
                      disabled={readOnly}
                    />
                    {errors.yearApplied && (
                      <div className="error_shown">
                        {errors.yearApplied?.message}
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {UGApplicationDetails.Domicile}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <select
                    {...register("domicile", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    onChange={(e) => {
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("domicile");
                    }}
                    disabled={readOnly}
                    className={`${errors.domicile ? "error_msg" : ""}`}
                  >
                    <option value="" disabled selected>
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.domicile?.map((opt, ind) => (
                        <option key={opt.ind} value={opt.value}>
                          {opt.value}
                        </option>
                      ))}
                  </select>
                  {errors.domicile && (
                    <div className="error_shown">
                      {errors.domicile?.message}
                    </div>
                  )}
                </div>
              </div>
              <div class="heading_styl">
                <h4>{UGApplicationDetails.Language_Details}</h4>
              </div>

              <div class="form_styl">
                <div class="w100">
                  <Notes name="Language" size={"0.72rem"} />
                </div>
              </div>
              <div class="form_styl mt-2">
                <div class="inner_part1">
                  <div class="heading_styl">
                    <h4>{UGApplicationDetails.Language}</h4>
                  </div>
                  <select
                    {...register("language", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    className={`mt-7 ${errors.language ? "error_msg" : ""}`}
                    value={lang}
                    onChange={(e) => {
                      setLang(e.target.value);
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("language");
                    }}
                    disabled={readOnly}
                  >
                    <option value="" disabled>
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.langauges?.map((opt, ind) => (
                        <option key={ind} value={opt.Id}>
                          {opt.Name}
                        </option>
                      ))}
                  </select>
                  {errors.language && (
                    <div className="error_shown">
                      {errors.language?.message}
                    </div>
                  )}
                </div>
                <div class="inner_part1">
                  <div class="heading_styl">
                    <h4>{UGApplicationDetails.Spoken}</h4>
                  </div>
                  <select
                    {...register("spoken", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    className={` mt-7 ${errors.spoken ? "error_msg" : ""}`}
                    disabled={!lang || readOnly}
                    onChange={(e) => {
                      addedLang[0] = e.target.value;
                      clearErrors("spoken");
                    }}
                  >
                    <option value="" disabled selected>
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.languageSpeaking?.map((opt, ind) => (
                        <option key={ind} value={opt.value}>
                          {opt.value}
                        </option>
                      ))}
                  </select>
                  {errors.spoken && (
                    <div className="error_shown">{errors.spoken?.message}</div>
                  )}
                </div>
                <div class="inner_part1">
                  <div class="heading_styl">
                    <h4>{UGApplicationDetails.Writing}</h4>
                  </div>
                  <select
                    {...register("writing", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    className={` mt-7 ${errors.writing ? "error_msg" : ""}`}
                    disabled={!lang || readOnly}
                    onChange={(e) => {
                      addedLang[1] = e.target.value;
                      clearErrors("writing");
                    }}
                  >
                    <option value="" disabled selected>
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.languageWriting?.map((opt, ind) => (
                        <option key={ind} value={opt.value}>
                          {opt.value}
                        </option>
                      ))}
                  </select>
                  {errors.writing && (
                    <div className="error_shown">{errors.writing?.message}</div>
                  )}
                </div>
                <div class="inner_part1">
                  <div class="heading_styl">
                    <h4>{UGApplicationDetails.Reading}</h4>
                  </div>
                  <select
                    {...register("reading", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    className={` mt-7 ${errors.reading ? "error_msg" : ""}`}
                    disabled={!lang || readOnly}
                    onChange={(e) => {
                      addedLang[2] = e.target.value;
                      clearErrors("reading");
                    }}
                  >
                    <option value="" disabled selected>
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.languageReading?.map((opt, ind) => (
                        <option key={ind} value={opt.value}>
                          {opt.value}
                        </option>
                      ))}
                  </select>
                  {errors.reading && (
                    <div className="error_shown">{errors.reading?.message}</div>
                  )}
                </div>
              </div>
              {dashboardresponse?.data?.allDataOnDashboard[0]?.appCycleCUET && (
                <div class="form_styl" disabled={readOnly}>
                  <div class="w100">
                    <Notes
                      size={"0.72rem"}
                      name="APU_Programme_preference_UG_CUET"
                    />
                  </div>
                </div>
              )}

              <div class="heading_styl">
                <h4>{UGApplicationDetails.Programme_Applying}</h4>
              </div>
              <div class="form_styl" disabled={readOnly}>
                <div class="inner_part">
                  <label>
                    {UGApplicationDetails.Programme_Preference_1}{" "}
                    <span class="redstyl">* </span>
                  </label>

                  <select
                    {...register("programApplying", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    className={`${errors.programApplying ? "error_msg" : ""}`}
                    value={programApplied}
                    onChange={handleProgramApplied}
                    disabled={
                      formattedDate >
                        applicationData?.hed__Applications_Applicant__r
                          ?.records[0]?.Admission_Cycle__r
                          ?.Preference_End_Date__c && readOnly
                    }
                  >
                    <option value="" selected>
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {preferencePick &&
                      preferencePick?.coursePreference
                        ?.filter((val) => {
                          return !(
                            val.value ===
                              "No Second Preference"
                          );
                        })
                        ?.map((opt, ind) => (
                          <option
                          key={ind}
                          value={opt.Id}
                          disabled={
                            opt.value === "No Second Preference" ||
                            opt.Id === secondprogramApplied
                          }
                        >
                          {opt.value}
                        </option>
                        ))}                   
                  </select>
                  {errors.programApplying && (
                    <div className="error_shown">
                      {errors.programApplying?.message}
                    </div>
                  )}
                  {dashboardresponse?.data?.allDataOnDashboard[0]
                    ?.appCycleCUET &&
                    firstProgram &&
                    firstProgram}
                </div>
                <div class="inner_part">
                  <label>
                    {UGApplicationDetails.Campus}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <select
                    {...register("campus1", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    className={`${errors.campus1 ? "error_msg" : ""}`}
                    value={campusPref}
                    onChange={(e) => {
                      setCampusPref(e.target.value);
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("campus1");
                    }}
                    disabled={
                      !programApplied ||
                      (formattedDate >
                        applicationData?.hed__Applications_Applicant__r
                          ?.records[0]?.Admission_Cycle__r
                          ?.Preference_End_Date__c &&
                        readOnly)
                    }
                  >
                    <option value="" disabled selected>
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {campPrefe &&
                      campPrefe.map((opt, ind) => (
                        <option key={ind} value={opt.Id}>
                          {opt.value}
                        </option>
                      ))}
                  </select>

                  {errors.campus1 && (
                    <div className="error_shown">{errors.campus1?.message}</div>
                  )}
                </div>
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {UGApplicationDetails.Programme_Preference_2}{" "}
                    <span class="redstyl">* </span>
                    <Tooltip id="progPreference">
                      <p>
                        {
                          UGApplicationDetails.Programme_Preference_2_Tooltip_Message
                        }
                      </p>
                    </Tooltip>
                    <i
                      data-tooltip-id="progPreference"
                      class="fa-solid fa-circle-info"
                    ></i>
                  </label>

                  <select
                    {...register("programPrefer", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    className={`${errors.programPrefer ? "error_msg" : ""}`}
                    value={secondprogramApplied}
                    onChange={handleSecondProgramApplied}
                    disabled={
                      formattedDate >
                        applicationData?.hed__Applications_Applicant__r
                          ?.records[0]?.Admission_Cycle__r
                          ?.Preference_End_Date__c && readOnly
                    }
                  >
                    <option value="" selected>
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {preferencePick &&
                      preferencePick?.coursePreference?.map((opt, ind) => (
                        <option
                          key={ind}
                          disabled={opt.Id == programApplied}
                          value={opt.Id}
                        >
                          {opt.value}
                        </option>
                      ))}
                  </select>
                  {errors.programPrefer && (
                    <div className="error_shown">
                      {errors.programPrefer?.message}
                    </div>
                  )}
                  {dashboardresponse?.data?.allDataOnDashboard[0]
                    ?.appCycleCUET &&
                    secondProgram &&
                    secondProgram}
                </div>

                <div class="inner_part">
                  <label>
                    {/* Campus <span class="redstyl">* </span> */}
                    {UGApplicationDetails.Campus}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <select
                    {...register("campus2", {
                      required: {
                        value: !campusSeco ? true : false,
                        message: "Please select value",
                      },
                    })}
                    className={`${
                      !campusSeco && errors.campus2 ? "error_msg" : ""
                    }`}
                    // value={campusSeco}
                    onChange={(e) => {
                      setCampusSeco(e.target.value);
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("campus2");
                    }}
                    disabled={
                      !secondprogramApplied ||
                      secondprogramApplied === "0019D00000M5lAHQAZ" ||
                      (formattedDate >
                        applicationData?.hed__Applications_Applicant__r
                          ?.records[0]?.Admission_Cycle__r
                          ?.Preference_End_Date__c &&
                        readOnly)
                    }
                  >
                    <option value="" disabled selected>
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {secondCampPrefe &&
                      secondCampPrefe?.map((opt, ind) => (
                        <option key={ind} value={opt.Id}>
                          {opt.value}
                        </option>
                      ))}
                  </select>
                  {!campusSeco && errors.campus2 && (
                    <div className="error_shown">{errors.campus2?.message}</div>
                  )}
                </div>
              </div>
              <div class="form_styl">
              <div class="inner_part">
                      <label>
                        {PGApplicationDetails.Preference_Question}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <select
                        {...register("considerAdmissionInAnyCampus", {
                          required: {
                            value: true,
                            message: "Please select value",
                          },
                        })}
                        className={`${
                          errors.considerAdmissionInAnyCampus ? "error_msg" : ""
                        }`}     
                        value={consider}                  
                        disabled={
                          readOnly &&
                          formattedDate >
                            applicationData?.hed__Applications_Applicant__r
                              ?.records[0]?.Admission_Cycle__r
                              ?.Preference_End_Date__c
                        }
                        onChange={(e) => {  
                          setConsider(e.target.value)                      
                          dispatch(unsavedchanges(e.target.value));
                          clearErrors("considerAdmissionInAnyCampus");
                        }}
                      >
                        <option value="" selected>
                          {AppCommon.Dropdown_Default_Option}
                        </option>
                        {pickListVal &&
                          pickListVal?.considerAdmissionInAnyCampus?.map(
                            (opt, ind) => (
                              <option
                                key={ind}
                                value={opt.value}                               
                              >
                                {opt.value}
                              </option>
                            )
                          )}
                      </select>
                      {errors.considerAdmissionInAnyCampus && (
                        <div>{errors.considerAdmissionInAnyCampus.message}</div>
                      )}
                    </div>
              </div>
              <div class="form_styl">
                <div class="inner_part w100">
                  <Notes name="Consider_any_campus"/>
                </div>
              </div>
              <div class="heading_styl">
                <h4>{UGApplicationDetails.Center_Preference}</h4>
              </div>
              <div class="form_styl">
                <div class="inner_part w100">
                  <Notes name="Center_Preference_Help" />
                </div>
              </div>
              
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {UGApplicationDetails.Preferred_Test_Center}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <select
                    {...register("testCenter", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    className={`${errors.testCenter ? "error_msg" : ""}`}
                    value={preTestCenter}
                    onChange={(e) => {
                      setPreTestCenter(e.target.value);
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("testCenter");
                    }}
                    disabled={
                      formattedDate >
                        applicationData?.hed__Applications_Applicant__r
                          ?.records[0]?.Admission_Cycle__r
                          ?.Preference_End_Date__c && readOnly
                    }
                  >
                    <option value="" disabled selected>
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {preferencePick &&
                      preferencePick?.testCenter?.map((opt, ind) => (
                        <option key={ind} value={opt.Id}>
                          {opt.value}
                        </option>
                      ))}
                  </select>
                  {errors.testCenter && (
                    <div className="error_shown">
                      {errors.testCenter?.message}
                    </div>
                  )}
                </div>
                <div class="inner_part">
                  <label>
                    {UGApplicationDetails.Preferred_Interview_Center}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <Controller
                    {...register("interviewCenter", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    className={`${errors.interviewCenter ? "error_msg" : ""}`}
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      onChange = (event) => {
                        setValue("interviewCenter", event.target.value);
                        setPreInterviewCenter(event.target.value);
                        clearErrors("interviewCenter");
                        dispatch(unsavedchanges(event.target.value));
                        clearErrors("interviewCenter");
                      };
                      return (
                        <select
                          className={`${
                            errors.interviewCenter ? "error_msg" : ""
                          }`}
                          value={preInterviewCenter}
                          onChange={onChange}
                          disabled={
                            formattedDate >
                              applicationData?.hed__Applications_Applicant__r
                                ?.records[0]?.Admission_Cycle__r
                                ?.Preference_End_Date__c && readOnly
                          }
                        >
                          <option value="" disabled selected>
                            {AppCommon.Dropdown_Default_Option}
                          </option>
                          {preferencePick &&
                            preferencePick?.interviewCenter?.map((opt, ind) => (
                              <option key={ind} value={opt.Id}>
                                {opt.value}
                              </option>
                            ))}
                        </select>
                      );
                    }}
                  />
                  {errors.interviewCenter && (
                    <div className="error_shown">
                      {errors.interviewCenter?.message}
                    </div>
                  )}
                </div>
              </div>

              <div class="form_styl">
                <div class="w100">
                  <button
                    onClick={(e) => (DraftBtn = e.target.value)}
                    value="draft"
                    type="submit"
                    class={
                      readOnly &&
                      formattedDate >
                        applicationData?.hed__Applications_Applicant__r
                          ?.records[0]?.Admission_Cycle__r
                          ?.Preference_End_Date__c
                        ? "submit ml_5 disabled"
                        : "submit ml_5"
                    }
                    disabled={
                      formattedDate >
                        applicationData?.hed__Applications_Applicant__r
                          ?.records[0]?.Admission_Cycle__r
                          ?.Preference_End_Date__c && readOnly
                    }
                  >
                    {ApplicationViewPage.Draft_Button_Message}
                  </button>
                  <button
                    onClick={(e) => (DraftBtn = e.target.value)}
                    value=""
                    type="submit"
                    class={
                      readOnly &&
                      formattedDate >
                        applicationData?.hed__Applications_Applicant__r
                          ?.records[0]?.Admission_Cycle__r
                          ?.Preference_End_Date__c
                        ? "submit ml_5 disabled"
                        : "submit ml_5"
                    }
                    disabled={
                      formattedDate >
                        applicationData?.hed__Applications_Applicant__r
                          ?.records[0]?.Admission_Cycle__r
                          ?.Preference_End_Date__c && readOnly
                    }
                  >
                    {ApplicationViewPage.Save_And_Proceed_Button_Message}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Home>
  );
};
export default ApplicationDetail;
