import React, { useEffect, useState } from "react";
import * as images from "../../resources/images";
import { NavLink, useNavigate } from "react-router-dom";
import { PATHS } from "../../utils";
import { useForm } from "react-hook-form";
import Notification from "../footer/Notification";
import { useDispatch, useSelector } from "react-redux";
import { resetLoginResponseFailure, setSurveyAss } from "../../redux/actions";
import { getNotesData, userSignin } from "../../redux/actions/Api";
import Loader from "../Loader";
import $ from 'jquery';

import { StorageService } from "../../services/StorageService";

const Signin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm();

  const { signinresponse, loginResponseError, dashboardresponse, signupresponse } = useSelector(
    (state) => state.collections
  );
  const [cacheData, setCacheData] = useState();

  // Function to get all cache data

  useEffect(() => {
    function DisableBackButton() {
      window.history.forward()
    }
    DisableBackButton();
    window.onload = DisableBackButton;
    window.onpageshow = function (evt) { if (evt.persisted) DisableBackButton() }
    window.onload = function () { void (0) }
  }, [])

  //  useEffect(()=>{
  //   if(localStorage.getItem("access_token")){
  //   window.history.go(-1)
  //   // navigate(PATHS.DASHBOARD)
  //   }
  //  },[])

  useEffect(() => {
    if (loginResponseError) {
      setTimeout(() => {
        setLoader(false);
      }, 200);
    }
  }, [loginResponseError]);

  const _onSubmit = (values) => {
    if (loginResponseError) {
      dispatch(resetLoginResponseFailure());
    }
    let formData = {
      email: values.registerEmail,
      password: values.password,
    };
    if (formData) {
      setLoader(true);
      dispatch(userSignin(formData));
    }
  };

  useEffect(() => {
    if (signinresponse?.data?.selectionStatus === 'selected') {
      dispatch(getNotesData());
      navigate(PATHS.SIGNINREDIRECT, { state: signinresponse?.data });
    }
    else {
      if (signinresponse?.status === 200 && dashboardresponse) {
        dispatch(getNotesData());
        setTimeout(() => {
          setLoader(false);
          if (dashboardresponse?.data?.allDataOnDashboard?.length > 0) {
            window.location.assign(PATHS.DASHBOARD);
          } else {
            navigate(PATHS.REGISTRATION);
          }
        }, 1500);

      } else {
      }
    }

  }, [signinresponse, dashboardresponse]);


  const handlePasswordRemove = () => {
    clearErrors("password");
    dispatch(resetLoginResponseFailure());
  };

  const handleChangeEmail = () => {
    clearErrors("registerEmail");
    dispatch(resetLoginResponseFailure());
  };

  return (
    <div
      className="main_container login_main_container"
      style={{
        backgroundImage: `url("https://apf-uat--uat.sandbox.my.site.com/admissions/file-asset/univ_building_8_reduced_image?v=1")`,
        width: "100%",
        height: "100%",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div class="container_box">
        <div class="header">
          <img src={images.apuLogo1} />
        </div>
        <div className="signin_head">

          <h4>APPLICANT PORTAL</h4>
          <p>This portal is for candidates who wish to apply to Azim Premji University programmes.<br />
            Sign In to submit your application form.</p>

        </div>
        <div>
          <Loader show={loader} />


          <form
            name="signin"
            className="content"
            onSubmit={handleSubmit(_onSubmit)}
            onFocus={(e) => e.target.setAttribute("autoComplete", "none")}>
            <div class="login_box">
              {/* <> */}
              {errors?.registerEmail?.message ? (
                <p>{errors?.registerEmail?.message}</p>
              ) : errors?.password?.message ? (
                <p>{errors?.password?.message}</p>
              ) : loginResponseError ? <p>{loginResponseError}</p> : ""
              }

              <div class="input_box">
                <label>
                  <i class="fa-solid fa-user"></i>
                </label>
                <input
                  type="text"
                  placeholder="Registered Email"
                  id="regisemail"
                  {...register("registerEmail", {
                    onChange: (e) => handleChangeEmail(e),
                    required: {
                      value: true,
                      message: "Please enter your registered email id",
                    },
                  })}
                />
              </div>
              <div class="input_box">
                <label>
                  <i class="fa-solid fa-unlock"></i>
                </label>
                <input
                  type="password"
                  placeholder="Password"
                  id={"password"}
                  name="password"
                  {...register("password", {
                    onChange: (e) => handlePasswordRemove(e),
                    required: {
                      value: true,
                      message: "Please enter your Password",
                    },
                  })}
                />
              </div>
              <div class="input_box">
                <button type="submit" class="button_styl">
                  Sign In
                </button>
              </div>

              <div class="input_box">
                <NavLink to={PATHS.FORGETPASSWORD}>
                  Forgot Password?
                </NavLink>

                <NavLink to={PATHS.USERSIGNUP}> Sign up</NavLink>
              </div>
            </div>
          </form>
        </div>
        <Notification />
      </div>

    </div>
  );
};

export default Signin;
