import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import moment, { fn } from "moment";
import FaqSideView from "./FaqSideView";
import Loader from "../Loader";

const Faqs = () => {
    const { state } = useLocation();
    const [loader, setLoader] = useState(false);

    const { faqData, } = state || []; // <-- access state.jobs
    const { getFAQAllData } = useSelector((state) => state.collections);
   
    useEffect(() => {
        window.history.forward();
    }, [])


    return (
        <div>
            <Header />
            <div class="main_part">
                <Loader show={loader} />
                <div class="container_body flex">
                    <div className="w66 faq">
                        <div class="form_styl">
                            <div class="inner_part w100">
                                <h5>{faqData?.Title}</h5>
                                <p><i class="fa-regular fa-clock"></i> {moment(faqData?.LastPublishedDate).format('MMM Do, YYYY')} • Article</p>
                            </div>
                        </div>
                        <div class="form_styl">
                            <div class="inner_part">
                                <label>
                                    Title <span className="redstyl"> *</span>
                                </label>
                                <div className="faq_text" disabled >
                                    <p disabled dangerouslySetInnerHTML={{
                                        __html: faqData?.Title,
                                    }} />
                                </div>

                            </div>
                        </div>
                        <div class="form_styl">
                            <div class="inner_part">
                                <label>
                                    Description <span className="redstyl"> *</span>
                                </label>
                                <div className="faq_text">
                                    <p disabled dangerouslySetInnerHTML={{
                                        __html: faqData?.Answer__c,
                                    }} />
                                </div>

                            </div>
                        </div>
                        <div class="form_styl">
                            <div class="inner_part">
                                <label>
                                    Article Created Date <span className="redstyl"> *</span>
                                </label>
                                <div className="faq_text" disabled >
                                    <p disabled dangerouslySetInnerHTML={{
                                        __html: moment(faqData?.CreatedDate).format('MM/DD/YYYY, hh:mm a'),
                                    }} />
                                </div>
                            </div>
                            <div class="inner_part">
                                <label>
                                    URL Name <span className="redstyl"> *</span>
                                </label>
                                <div className="faq_text" disabled >
                                    <p disabled dangerouslySetInnerHTML={{
                                        __html: faqData?.UrlName,
                                    }} />
                                </div>
                            </div>
                        </div>
                        <div class="form_styl">
                            <div class="inner_part">
                                <label>
                                    Created By <span className="redstyl"> *</span>
                                </label>
                                <div className="faq_text" disabled >
                                    <p disabled dangerouslySetInnerHTML={{
                                        __html: faqData?.CreatedBy?.Name,
                                    }} />
                                </div>
                            </div>
                            <div class="inner_part"> 
                                <label>
                                    Last Modified By <span className="redstyl"> *</span>
                                </label>
                                <div className="faq_text" disabled >
                                    <p disabled dangerouslySetInnerHTML={{
                                        __html: faqData?.LastModifiedDate,
                                    }} />
                                </div>
                            </div>
                        </div>
                        <div class="form_styl">
                            <div class="inner_part">
                                <label>
                                    Last Published Date <span className="redstyl"> *</span>
                                </label>
                                <div className="faq_text" disabled >
                                    <p disabled dangerouslySetInnerHTML={{
                                        __html: moment(faqData?.LastPublishedDate).format('MM/DD/YYYY, hh:mm a'),
                                    }} />
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="w30">
                        <FaqSideView />

                    </div>

                </div>
            </div>

        </div>
    )
}

export default Faqs;