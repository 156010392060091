import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './Auth';

const PrivateRoute = ({component: component, ...rest}) => {

    const token = localStorage.getItem("access_token");
    return token ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoute;