import React, { useContext, useEffect, useRef } from "react";
import Header from "../header/Header";
import { Tooltip } from "react-tooltip";
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import moment, { fn } from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Error from "../notification/Error";
import ReactHtmlParser from "react-html-parser";
import $ from "jquery";
import "jquery-ui/ui/widgets/datepicker";
import "jquery-ui/themes/base/all.css";
import { useDispatch, useSelector } from "react-redux";
import {
  createRegistration,
  getPicklistdetails,
  getRegistrationData,
  logoutAction,
} from "../../redux/actions/Api";
import {
  CHAR_REGEX,
  EMAIL_REGEX,
  L_CHAR_REGEX,
  LNAME_CHAR_REGEX,
  MOBILE_REGEX,
  PIN_REGEX,
  WHITE_SPACE,
  WHT,
} from "../../utils/Regex";
import {
  useLocation,
  useNavigate,
  UNSAFE_NavigationContext,
} from "react-router-dom";
import { PATHS } from "../../utils";
import Loader from "../Loader";
import Success from "../notification/Success";
import { resetForms } from "../../redux/actions";
import { Notes } from "../common/Notes";
import { HAVEYOUTAKENMOREYEARS } from "../../utils/DataObjects";
import { StorageService } from "../../services/StorageService";
import SessionModal from "../common/SessionModal";
import { AppCommon, RegistrationPage } from "../constantLabelFile";

let filteredAdmission = "";

const RegistrationForm = () => {
  const dispatch = useDispatch();
  const navigation = useContext(UNSAFE_NavigationContext).navigator;
  const navigate = useNavigate();
  // const inputRef = useRef(null);
  const { state, location } = useLocation();
  const { programName } = state || [];
  const moreYearsTocompleteTwelth = HAVEYOUTAKENMOREYEARS;

  const { registrationresponse, picklistdata, error, registrationDetail } =
    useSelector((state) => state.collections);

  const [program, setProgram] = useState("");
  const [admission, setAdmission] = useState("");
  const [admData, setAdmData] = useState("");
  const [checkBox, setCheckBox] = useState(false);
  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [district, setDistrict] = useState([]);
  const [selectedState1, setSelectedState1] = useState("");
  const [selectedDistrict1, setSelectedDistrict1] = useState("");
  const [district1, setDistrict1] = useState([]);
  const [knowapu, setKnowapu] = useState("");
  const [aboutApu, setAboutApu] = useState(false);
  const [countrydata, setCountryData] = useState("");
  const [countrydata1, setCountryData1] = useState("");
  const [schoolingYes, setSchoolingYes] = useState(false);
  const [loader, setLoader] = useState(false);
  const [dateValid, setDateValid] = useState("");
  const [pickListVal, setPickListVal] = useState("");
  const [programDip, setProgramDip] = useState([]);
  const [dobErrorMsg, setDobErrorMsg] = useState("");
  const [dipNotes, setDipNotes] = useState("");
  const [regVal, setRegVal] = useState("");

  const [sopDOB, setSOPDOB] = useState("");
  const [dateArray, setdateArray] = useState("");
  const [sopFormatDate, setSopFormatDate] = useState("");
  const [showSopForm, setShowSopForm] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      relationship: "",
    },
  });

  const nameInputRef = useRef(null);

  useEffect(() => {
    const handlePopState = (e) => {
      window.history.pushState(null, "", window.location.href);
    };

    // Add event listener on mount
    window.addEventListener("popstate", handlePopState);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    let formData = {
      contactId: localStorage.getItem("contactId"),
    };
    if (formData) {
      dispatch(getRegistrationData(formData));
    }
  }, []);

  useEffect(() => {
    setRegVal(registrationDetail?.data?.records[0]);
  }, [registrationDetail]);

  useEffect(() => {
    setValue(
      "programGroup",
      regVal?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Type__c
    );
    setValue(
      "admissionCycle",
      regVal?.hed__Applications_Applicant__r?.records[0]?.Admission_Cycle__r
        ?.Admission_Cycle__c
    );
    setValue("firstName", regVal?.FirstName);
    setValue("middleName", regVal?.MiddleName);
    setValue("lastName", regVal?.LastName);
    setValue("dateOfBirth", regVal?.Birthdate);

    setValue("mobile", regVal?.MobilePhone);
    setValue(
      "relationship",
      regVal?.hed__Relationships__r?.records[0]?.hed__Type__c
    );
    setValue(
      "relationName",
      regVal?.hed__Relationships__r?.records[0]?.hed__RelatedContact__r?.Name
    );
    setValue(
      "relationMobno",
      regVal?.hed__Relationships__r?.records[0]?.hed__RelatedContact__r
        ?.MobilePhone
    );
    setValue(
      "relationEmail",
      regVal?.hed__Relationships__r?.records[0]?.hed__RelatedContact__r?.Email
    );
    setValue(
      "houseNo",
      regVal?.hed__Addresses__r?.records[0]?.hed__MailingStreet__c
    );
    setValue(
      "area",
      regVal?.hed__Addresses__r?.records[0]?.hed__MailingStreet2__c
    );
    setValue(
      "country",
      regVal?.hed__Addresses__r?.records[0]?.hed__MailingCountry__c
    );
    setCountryData(
      regVal?.hed__Addresses__r?.records[0]?.hed__MailingCountry__c
    );
    setValue(
      "state",
      regVal?.hed__Addresses__r?.records[0]?.hed__MailingState__c
    );
    setValue(
      "district",
      regVal?.hed__Addresses__r?.records[0]?.hed__MailingCounty__c
    );
    setSelectedState(
      regVal?.hed__Addresses__r?.records[0]?.hed__MailingState__c
    );

    if (
      pickListVal &&
      regVal?.hed__Addresses__r?.records[0]?.hed__MailingCountry__c === "India"
    ) {
      const stateObject =
        pickListVal?.districts?.India[
          regVal?.hed__Addresses__r?.records[0]?.hed__MailingState__c
        ];
      const dis = Object?.values(stateObject);
      setDistrict(dis);
    }
    if (
      pickListVal &&
      regVal?.hed__Addresses__r?.records[1]?.hed__MailingCountry__c === "India"
    ) {
      const stateObject1 =
        pickListVal?.districts?.India[
          regVal?.hed__Addresses__r?.records[1]?.hed__MailingState__c
        ];
      const dis1 = Object?.values(stateObject1);
      setDistrict1(dis1);
    }

    setSelectedDistrict(
      regVal?.hed__Addresses__r?.records[0]?.hed__MailingCounty__c
    );
    setValue(
      "city",
      regVal?.hed__Addresses__r?.records[0]?.hed__MailingCity__c
    );
    setValue(
      "pin",
      regVal?.hed__Addresses__r?.records[0]?.hed__MailingPostalCode__c
    );
    setCheckBox(regVal?.Check_if_same_as_Communication_Address__c);
    setValue(
      "building",
      regVal?.hed__Addresses__r?.records[1]?.hed__MailingStreet__c
    );
    setValue(
      "road",
      regVal?.hed__Addresses__r?.records[1]?.hed__MailingStreet2__c
    );
    setValue(
      "pCountry",
      regVal?.hed__Addresses__r?.records[1]?.hed__MailingCountry__c
    );
    setCountryData1(
      regVal?.hed__Addresses__r?.records[1]?.hed__MailingCountry__c
    );
    setValue(
      "pState",
      regVal?.hed__Addresses__r?.records[1]?.hed__MailingState__c
    );
    setValue(
      "pDistrict",
      regVal?.hed__Addresses__r?.records[1]?.hed__MailingCounty__c
    );
    setSelectedState1(
      regVal?.hed__Addresses__r?.records[1]?.hed__MailingState__c
    );
    setSelectedDistrict1(
      regVal?.hed__Addresses__r?.records[1]?.hed__MailingCounty__c
    );
    setValue(
      "pCity",
      regVal?.hed__Addresses__r?.records[1]?.hed__MailingCity__c
    );
    setValue(
      "pPin",
      regVal?.hed__Addresses__r?.records[1]?.hed__MailingPostalCode__c
    );
    setValue("know", regVal?.How_did_you_get_to_know_about_APU__c);
    setKnowapu(regVal?.How_did_you_get_to_know_about_APU__c);
    setValue("specify", regVal?.Specific_Answer__c);
  }, [registrationDetail, regVal, pickListVal]);

  useEffect(() => {
    // if (localStorage.getItem("access_token")) {
    dispatch(getPicklistdetails());

    // }
  }, []);

  useEffect(() => {
    setPickListVal(picklistdata?.data);
    if (programName === "Diploma" || programName === "Postgraduate Diploma") {
      if (picklistdata?.data) {
        const filt = picklistdata?.data.applicationType.filter((item) =>
          item.value.includes("Diploma")
        );
        setProgramDip(filt);
      }
    }
  }, [picklistdata]);

  let newErrormsg = {
    message: "Registration for the selected admission cycle is already done.",
  };

  useEffect(() => {
    if (
      error?.message === "Unauthorized: Invalid or expired token." ||
      error?.validToken === false
    ) {
      setLoader(false);
    }
  }, [error]);

  useEffect(() => {
    if (error) {
      setLoader(false);
      toast.error(
        <Error
          error={
            error?.message?.includes("Please choose different Admission Cycle")
              ? newErrormsg
              : error
          }
        />,
        {
          position: "top-center",
          autoClose: 5000,
          className: "Toastify__toast-theme--colored.Toastify__toast--warning",
        }
      );
      setTimeout(() => {
        dispatch(resetForms());
      }, 5000);
    }
  }, [error]);

  let newErrormessages = {
    message:
      "Registration for the signed in user is already completed. Please login again to check the registered application.",
  };

  useEffect(() => {
    if (registrationresponse?.data?.length > 0) {
      localStorage.setItem("applicationNumber", registrationresponse?.data[0]);
      localStorage.setItem("applicationId", registrationresponse?.data[1]);
      localStorage.setItem("applicant_name", registrationresponse?.data[2]);
      localStorage.setItem("applicationYear", registrationresponse?.data[3]);
      localStorage.setItem("application_email", registrationresponse?.data[4]);
      localStorage.setItem("application_mobile", registrationresponse?.data[5]);
      localStorage.setItem("application_type", registrationresponse?.data[6]);
      localStorage.setItem("display_name", registrationresponse?.data[7]);

      toast.success(<Success response={registrationresponse} />, {
        position: "top-center",
        autoClose: 2000,
      });
      setTimeout(() => {
        setLoader(false);
        navigate(PATHS.REGISTRATIONCOMPLETE);
      }, 1500);
    }
  }, [registrationresponse]);

  useEffect(() => {
    if (pickListVal && regVal?.MobilePhone == null) {
      setValue("country", pickListVal?.registrationCountry[0].Id);
      setCountryData(pickListVal?.registrationCountry[0].Id);
      setValue("pCountry", pickListVal?.registrationCountry[0].Id);
      setCountryData1(pickListVal?.registrationCountry[0].Id);
    }
  }, [pickListVal, regVal]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "short", day: "2-digit" };
    return date.toLocaleDateString("en-US", options);
  };

  useEffect(() => {
    let dateVal = formatDate(getValues("dateOfBirth"));
    setDateValid(dateVal);
  }, [dateValid, getValues("dateOfBirth")]);


  useEffect(() => {
    $(function () {
      if(admData){
      if (program === "Undergraduate Program") {
        // setDobErrorMsg("YOUR AGE MUST BE UNDER 100 YEARS")
        $("#datepicker").datepicker("destroy");
        initializeUGDatePicker();
      } else if (program === "Postgraduate Program")  {
        setDobErrorMsg("YOUR AGE MUST BE UNDER 100 YEARS");
        $("#datepicker").datepicker("destroy");
        setSOPDOB("")
        initializePGDatePicker();        
      } else {
        // setDobErrorMsg("YOUR AGE MUST BE UNDER 100 YEARS")
        $("#datepicker").datepicker("destroy");
        setSOPDOB("")
        initializePGDatePicker();
      }
    }
    });
  }, [program, admData]);
 
  const dobMin = JSON.parse(localStorage.getItem("minimumDOB"))
  const dobMax = JSON.parse(localStorage.getItem("maximumDOB"))

  const actualDobMin = parseInt(dobMin?.Value)
  const actualDobMax = parseInt(dobMax?.Value)

  function initializeUGDatePicker() {
    // var currentDate = new Date();
    // var currentYearrr = currentDate.getFullYear();
    var currentYear = arrDate[0];
    var minDate = new Date(currentYear - actualDobMin, 7, 1); // August 1st of the current year 15
    var maxDate = new Date(currentYear - actualDobMax, 6, 31); //21
    $("#datepicker").datepicker({
      changeMonth: true,
      changeYear: true,
      showButtonPanel: false,
      minDate: minDate,
      maxDate: maxDate,
      ignoreReadonly: true,
      dateFormat: "M dd,yy",
      onSelect: (e) => {
        setValue("dateOfBirth", e);
        clearErrors("dateOfBirth");
        setSOPDOB(e);
        // alert(e);
      },
    });
  }

  function initializePGDatePicker() {
    setSOPDOB("")
    var currentDate = new Date();
    var pastDate = new Date();
    pastDate.setFullYear(currentDate.getFullYear() - 100);

    $("#datepicker").datepicker({
      changeMonth: true,
      changeYear: true,
      showButtonPanel: false,
      minDate: pastDate,
      maxDate: currentDate,
      dateFormat: "M dd,yy",
      stepYears: 10,
      yearRange: pastDate.getFullYear() + ":" + currentDate.getFullYear(),
      onSelect: (e) => {
        setValue("dateOfBirth", e);
        clearErrors("dateOfBirth");
      },
    });
    $("#icon").click(function () {
      $("#datepicker").datepicker("show");
    });
  }

  let incomplete = {
    message: "Please complete required form entries and try again",
  };

  const onError = () => {
    toast.error(<Error error={incomplete} />, {
      position: "top-center",
      autoClose: 5000,
      className: "Toastify__toast-theme--colored.Toastify__toast--warning",
    });
  };

  const handleProgram = (e) => {
    setProgram(e.target.value);
    const pgData = e.target.value;
    setAdmission("");
    setAdmData("");

    const pgValue = pickListVal?.admissionCycle?.filter(
      (admission) => admission.Programme_Name === e.target.value
    );
    filteredAdmission = pgValue.length ? pgValue : pickListVal?.admissionCycle;
    clearErrors("programGroup");

    // if (
    //   e.target.value === "Undergraduate Program" ||
    //   e.target.value === "Postgraduate Program"
    // ) {
    //   setModalOpen(true);
    // }
  };

  const handleAdmission = (e) => {
    setAdmData(JSON.parse(e.target.value));
    setAdmission(e.target.value);
    const CUETTrue = JSON.parse(e.target.value);
    setDipNotes(CUETTrue?.APU_Display_Note__c);
    StorageService.setAPUCUETEXAM(CUETTrue.APU_CUET_Exam);
    StorageService.setAPUSATEXAM(CUETTrue.APU_SAT_Exam);
    // setAdmission(e.target.value);
    clearErrors("admissionCycle");
  };

  let str = admData && admData?.End_Date;
  let arrDate = str?.split('-');

  const handleCountry = (e) => {
    setCountryData(e.target.value);
    clearErrors("country");
  };

  useEffect(() => {
    if (countrydata !== "India") {
      setSelectedState("");
      setSelectedDistrict("");
    }
  }, [countrydata]);

  useEffect(() => {
    if (countrydata1 !== "India") {
      setSelectedState1("");
      setSelectedDistrict1("");
    }
  }, [countrydata1]);

  const handlePerCountry = (e) => {
    setCountryData1(e.target.value);
    clearErrors("pCountry");
  };

  const handleCheckbox = (e) => {
    setCheckBox(e.target.checked);
    clearErrors([
      "building",
      "road",
      "pCountry",
      "pState",
      "pDistrict",
      "pCity",
      "pPin",
    ]);

    if (e.target.checked) {
      const addressValue = getValues([
        "houseNo",
        "area",
        "country",
        "state",
        "district",
        "city",
        "pin",
      ]);

      setValue("building", addressValue[0]);
      setValue("road", addressValue[1]);
      setValue("pCountry", addressValue[2]);
      setValue("pState", addressValue[3]);
      setValue("pDistrict", addressValue[4]);
      setValue("pCity", addressValue[5]);
      setValue("pPin", addressValue[6]);
      setCountryData1(countrydata);
      setDistrict1(district);
      setSelectedState1(selectedState);
      setSelectedDistrict1(selectedDistrict);
    } else {
      // setValue("building", "");
      // setValue("road", "");
      // setValue("pCountry", "");
      // setValue("pState", "");
      // setValue("pDistrict", "");
      // setValue("pCity", "");
      // setValue("pPin", "");
      // setSelectedState1("");
      // setSelectedDistrict1("");
    }
  };

  useEffect(() => {
    if (selectedState) {
      const stateObject = pickListVal?.districts?.India[selectedState];
      const dis = Object?.values(stateObject);
      setDistrict(dis);
      // setSelectedDistrict("");
    }

    if (selectedState1) {
      const stateObject = pickListVal?.districts?.India[selectedState1];
      const dis = Object?.values(stateObject);
      setDistrict1(dis);
      // setSelectedDistrict1("");
    }
  }, [pickListVal]);

  const handleState = (e) => {
    setSelectedState(e.target.value);
    const stateObject = pickListVal?.districts?.India[e.target.value];
    const dis = Object?.values(stateObject);
    setDistrict(dis);
    setSelectedDistrict("");
    clearErrors("state");
  };

  const handlePermState = (e) => {
    setSelectedState1(e.target.value);
    const stateObject = pickListVal?.districts?.India[e.target.value];
    const dis = Object?.values(stateObject);
    setDistrict1(dis);
    setSelectedDistrict1("");
    clearErrors("pState");
  };

  const handleDistrict = (e) => {
    setSelectedDistrict(e.target.value);
    clearErrors("district");
  };

  const handlePerdistrict = (e) => {
    setSelectedDistrict1(e.target.value);
    clearErrors("pDistrict");
  };

  const handleknow = (e) => {
    setKnowapu(e.target.value);
    clearErrors("know");
  };

  useEffect(() => {
    if (
      knowapu === "Azim Premji Foundation/ University Members" ||
      knowapu === "Azim Premji University Students" ||
      knowapu === "Alumni Azim Premji University"
    ) {
      setAboutApu(false);
    } else {
      setAboutApu(true);
    }
  }, [knowapu, aboutApu]);

  const filteredRelations =
    program === "Undergraduate Program"
      ? pickListVal?.relationshipWithStudent?.filter(
          (relation) => relation.value !== "Spouse"
        )
      : pickListVal?.relationshipWithStudent;

  useEffect(() => {
    setValue("firstName", localStorage.getItem("first_name"));
    setValue("lastName", localStorage.getItem("last_name"));
  }, []);

  const _onSubmit = (values) => {
    let programs;
    if (values.programGroup.includes("Program")) {
      programs = values.programGroup.split(" ")[0];
    }

    let formData = {
      userId: localStorage.getItem("userId"),
      programGroup: programs ? programs : values.programGroup,
      admissionCycle: JSON.parse(values.admissionCycle).Id,
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      dateOfBirth: moment(values.dateOfBirth).format("YYYY-MM-DD"),
      mobile: values.mobile,
      relationship: values.relationship,
      relationName: values.relationName,
      relationMobno: values.relationMobno,
      relationEmail: values.relationEmail,
      houseNo: values.houseNo,
      area: values.area,
      country: countrydata,
      state: countrydata !== "India" ? "" : values.state,
      district: countrydata !== "India" ? "" : values.district,
      city: values.city,
      pin: values.pin,
      building: values.building,
      road: values.road,
      pCountry: countrydata1,
      pState: countrydata1 !== "India" ? "" : values.pState,
      pDistrict: countrydata1 !== "India" ? "" : values.pDistrict,
      pCity: values.pCity,
      pPin: values.pPin,
      sameAsCommAddress: checkBox,
      know: values.know,
      specify: values.specify,
      whatChallengesDoYouThink: values.whatChallengesDoYouThink,
      haveYouCompleted12: values.haveYouCompleted12,
      haveYouTakeMoreYears:
        values.haveYouTakeMoreYears === "true" ? true : false,
      pleaseTellWhy:
        values.haveYouTakeMoreYears === "true" ? values.pleaseTellWhy : "",
    };

    if (formData) {
      setLoader(true);
      dispatch(createRegistration(formData));
    }
  };

  useEffect(() => {
    if (schoolingYes === "false") {
      setValue("pleaseTellWhy", "");
    }
  }, [schoolingYes]);


  function notesVisible() {
    if (admData) {
      if (admData.APU_CUET_Exam && program === "Undergraduate Program") {
        return (
          <div className="w100">
            <span className="earlyadmin">
              <Notes name="CUET_UG" /> <Notes name="Cuet_UG_Note_3_URL"></Notes>
              <Notes name="Cuet_Program_Note_2" />
            </span>{" "}
            <br />
            <br />
            {admData.APU_SAT_Exam && (
              <span className="earlyadmin">
                <Notes name="APU_SAT_NOTE" />{" "}
              </span>
            )}
          </div>
        );
      } else if (admData.APU_SAT_Exam && program === "Undergraduate Program") {
        return (
          <div className="w100">
            <span className="earlyadmin">
              <Notes name="APU_SAT_NOTE" />{" "}
            </span>
          </div>
        );
      } else if (
        admData.APU_CUET_Exam &&
        admData.Programme_Name === "Postgraduate Program"
      ) {
        return (
          <div className="w100">
            <span className="earlyadmin">
              <Notes name="Cuet_Program_Note" />
              <Notes name="Cuet_Program_Note_3_URL"></Notes>
              <Notes name="APU_CUET_UG_NOTE" />
            </span>{" "}
            <br />
            <br />
            {admData.APU_SAT_Exam && (
              <span className="earlyadmin">
                <Notes name="APU_SAT_NOTE" />{" "}
              </span>
            )}
          </div>
        );
      } else if (
        admData.APU_SAT_Exam &&
        admData.Programme_Name === "Postgraduate Program"
      ) {
        return (
          <div className="w100">
            <span className="earlyadmin">
              <Notes name="APU_SAT_NOTE" />{" "}
            </span>
          </div>
        );
      }
    } else if (admData.APU_SAT_Exam) {
      return (
        <div className="w100">
          <span className="earlyadmin">
            <Notes name="APU_SAT_NOTE" />{" "}
          </span>
        </div>
      );
    }
  }

  function diplomaNotesVisible() {
    if (program) {
      const filt =
        pickListVal &&
        pickListVal?.notes?.filter((item) => item?.programmeName === program);
      {
        if (program === "Diploma" || program === "Postgraduate Diploma") {
          return <p>{ReactHtmlParser(filt[0]?.note)}</p>;
        } else {
          return false;
        }
      }
    }
  }

  function admisionCycleNoteDiploma() {
    if (program === "Diploma" || program === "Postgraduate Diploma") {
      {
        if (admission) {
          return ReactHtmlParser(dipNotes);
          //<Notes name='Diploma_Admission_Cycle_Note' />
          //ReactHtmlParser(dipNotes)
        }
      }
    }
  }

  function getOptionValue() {
    if (programDip.length > 0) {
      return (
        <select
          {...register("programGroup", {
            required: {
              value: true,
              message: "Please select value",
            },
          })}
          value={program}
          onChange={handleProgram}
          className={`${errors.programGroup ? "error_msg" : ""}`}
        >
          <option value="" disabled>
            {/* --Select Options-- */}
            {AppCommon.Dropdown_Default_Option}
          </option>
          {programDip?.map((opt, ind) => (
            <option key={ind} value={opt.value}>
              {/* {opt.value} */}
              {opt.displayName}
            </option>
          ))}
        </select>
      );
    } else if (pickListVal) {
      return (
        <select
          {...register("programGroup", {
            required: {
              value: true,
              message: "Please select value",
            },
          })}
          value={program}
          onChange={handleProgram}
          className={`${errors.programGroup ? "error_msg" : ""}`}
        >
          <option value="" disabled>
            {/* --Select Options-- */}
            {AppCommon.Dropdown_Default_Option}
          </option>
          {pickListVal?.applicationType.map((opt, ind) => (
            <option key={ind} value={opt.value}>
              {/* {opt.value} */}
              {opt.displayName}
            </option>
          ))}
        </select>
      );
    }
  }

  useEffect(() => {
    if (sopDOB) {
      var edate = sopDOB;
      var myDate = new Date(edate);
      var d = String(myDate.getDate()).padStart(2, "0");
      var m = String(myDate.getMonth() + 1).padStart(2, "0");
      // m += 1;
      var y = myDate.getFullYear();

      var newdate = y + "-" + m + "-" + d;
      setdateArray(m);
      setSopFormatDate(newdate);
    }
  }, [sopDOB]);

  useEffect(() => {
    // var currentDate = new Date();
    // var currentYear = currentDate.getFullYear();
    if(admData?.Programme_Name === "Undergraduate Program"){
    var currentYear = arrDate[0];
   
    var minDate = new Date(currentYear - actualDobMin, 7, 1); // August 1st of the current year
    var maxDate = new Date(currentYear - (actualDobMin - 2), 7, 1);

    const currentDate1 = minDate;
    const year1 = currentDate1.getFullYear();
    const month = String(currentDate1.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate1.getDate()).padStart(2, "0");
    const formattedDate = `${year1}-${month}-${day}`;

    const currentDate2 = maxDate;
    const year2 = currentDate2.getFullYear();
    const month2 = String(currentDate2.getMonth() + 1).padStart(2, "0");
    const day2 = String(currentDate2.getDate()).padStart(2, "0");
    const formattedDate2 = `${year2}-${month2}-${day2}`;

    if (sopFormatDate >= formattedDate && sopFormatDate < formattedDate2) {
      setShowSopForm(true);
    } else {
      setShowSopForm(false);
    }
  } else{
    setSOPDOB("")
  }
  
  }, [sopFormatDate, admData]);

  return (
    <div>
      <Header />
      <div class="main_part">
        {/* {isModalOpen && (
          <SessionModal onClose={() => setModalOpen(false)}>           
            <h2>{RegistrationPage.Alert_Header}</h2>            
            <p>{RegistrationPage.Alert_Message}</p>
            <button className="submit" onClick={() => dispatch(logoutAction())}>            
              {RegistrationPage.Alert_Button}
            </button>
          </SessionModal>
        )} */}
        <div class="container_body">
          <div class="notes">
            <Notes name="Application_Registration" />
          </div>

          <div class="heading_styl head_font">
            {/* <h4>Application Registration</h4> */}
            <h4>{RegistrationPage.Application_Registration}</h4>
          </div>

          <div class="form_part">
            <Loader show={loader} />

            {/* <Notes name="Application_Programme" /> */}
            <a href="" target="_blank">
              {" "}
              <Notes name="Application_Programme" />
            </a>
            <form
              name="registration"
              onSubmit={handleSubmit(_onSubmit, onError)}
              onFocus={(e) => e.target.setAttribute("autoComplete", "none")}
            >
              <div class="form_sec">
                <div class="form_styl">
                  <div class="inner_part">
                    <label>
                      {/* Program Group <span className="redstyl">* </span> */}
                      {RegistrationPage.Program_Group}{" "}
                      <span className="redstyl">* </span>
                      <Tooltip id="programTool">
                        <Notes name="Program_Group" />
                      </Tooltip>
                      <i
                        data-tooltip-id="programTool"
                        class="fa-solid fa-circle-info"
                      ></i>
                    </label>
                    {getOptionValue()}
                    {errors.programGroup && (
                      <div className="error_shown">
                        {errors.programGroup?.message}
                      </div>
                    )}
                    {program && diplomaNotesVisible()}
                  </div>

                  <div class="inner_part">
                    <label>
                      {/* Admission Cycle <span className="redstyl">* </span> */}
                      {RegistrationPage.Admission_Cycle}{" "}
                      <span className="redstyl">* </span>
                    </label>
                    <select
                      {...register("admissionCycle", {
                        required: {
                          value: true,
                          message: "Please select value",
                        },
                      })}
                      value={admission}
                      onChange={handleAdmission}
                      disabled={!program}
                      className={`${errors.admissionCycle ? "error_msg" : ""}`}
                    >
                      <option value="" disabled>
                        {/* --Select Options-- */}
                        {AppCommon.Dropdown_Default_Option}
                      </option>

                      {filteredAdmission?.length > 0 &&
                        filteredAdmission?.map((opt, ind) => (
                          <option key={ind} value={JSON.stringify(opt)}>
                            {opt.Admission_Cycle}
                          </option>
                        ))}
                    </select>
                    {errors.admissionCycle && (
                      <div className="error_shown">
                        {errors.admissionCycle?.message}
                      </div>
                    )}
                    {admisionCycleNoteDiploma()}
                  </div>
                </div>

                {notesVisible()}

                <div class="heading_styl">
                  {/* <h4>Personal Details</h4> */}
                  <h4>{RegistrationPage.Personal_Details}</h4>
                </div>
                <div class="form_styl">
                  <div class="inner_part">
                    <label>
                      {/* First Name <span className="redstyl">* </span> */}
                      {RegistrationPage.First_Name}{" "}
                      <span className="redstyl">* </span>
                    </label>
                    <input
                      type="text"
                      className={`${errors.firstName ? "error_msg" : ""}`}
                      {...register("firstName", {
                        required: {
                          value: true,
                          message: "Please enter value",
                        },
                        pattern: {
                          value: WHITE_SPACE,
                          message: "Please enter alphabetic characters only",
                        },
                      })}
                      id={"fName"}
                    />
                    <div className="error_shown">
                      {errors.firstName?.message}
                    </div>
                  </div>
                  <div class="inner_part">
                    {/* <label>Middle Name</label> */}
                    <label>{RegistrationPage.Middle_Name}</label>
                    <input
                      type="text"
                      className={`${errors.middleName ? "error_msg" : ""}`}
                      {...register("middleName", {
                        pattern: {
                          value: CHAR_REGEX,
                          message: "Please enter alphabetic characters only",
                        },
                      })}
                    />
                    <div className="error_shown">
                      {errors.middleName?.message}
                    </div>
                  </div>
                </div>
                <div class="form_styl" disabled={program ? false : true}>
                  <div class="inner_part">
                    <label>
                      {/* Last Name <span className="redstyl">* </span> */}
                      {RegistrationPage.Last_Name}{" "}
                      <span className="redstyl">* </span>
                    </label>
                    <input
                      type="text"
                      className={`${errors.lastName ? "error_msg" : ""}`}
                      autocomplete="off"
                      {...register("lastName", {
                        required: {
                          value: true,
                          message: "Please enter value",
                        },
                        pattern: {
                          value: L_CHAR_REGEX,
                          message: "Please enter alphabetic characters only",
                        },
                      })}
                    />
                    <div className="error_shown">
                      {errors.lastName?.message}
                    </div>
                  </div>
                  <div class="inner_part">
                    <label>
                      {/* Date of Birth <span className="redstyl">* </span> */}
                      {RegistrationPage.DOB} <span className="redstyl">* </span>
                      <Tooltip id="dob">
                        {program === "Postgraduate Program" ||
                        program === "Postgraduate Diploma" ||
                        program === "Diploma" ? (
                          <Notes name="DOB_PG_Helptext" />
                        ) : (
                          <Notes name="DateofBirth" />
                        )}
                      </Tooltip>
                      <i
                        data-tooltip-id="dob"
                        class="fa-solid fa-circle-info"
                      ></i>
                    </label>

                    <span className="flex clender_styl">
                      {/* <Controller
                        {...register("dateOfBirth", {
                          required: {
                            value: true,
                            message: "Please select value",
                          },
                        })}
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          onChange = (event) => {                          
                            clearErrors("dateOfBirth")
                            setValue("dateOfBirth", event);                           
                          };
                          return (
                            <span className="w100">
                              <input {...register("dateOfBirth", {
                          required: {
                            value: true,
                            message: "Please select value",
                          },
                        })}                        
                        id="datepicker" value={value}
                              // ref={nameInputRef} 
                                className={`${errors.dateOfBirth ? "error_msg" : ""}`}
                              ></input>
                              <i class="fa-regular fa-calendar" id="icon"></i>
                            
                            </span>

                          );
                        }}
                      /> */}
                      <span className="w100">
                        <input
                          {...register("dateOfBirth", {
                            required: {
                              value: true,
                              message: "Please select value",
                            },
                          })}
                          id="datepicker"
                          disabled={!admData}
                          // value={value}
                          // ref={nameInputRef}
                          // onChange={handleDateofbirth()}
                          className={`${errors.dateOfBirth ? "error_msg" : ""}`}
                          required
                          readonly="readonly"
                        ></input>
                        <i class="fa-regular fa-calendar" id="icon"></i>
                      </span>
                      {errors.dateOfBirth && (
                        <div className="error_shown">
                          {errors?.dateOfBirth?.message}
                        </div>
                      )}
                    </span>
                  </div>
                </div>
                <div class="form_styl">
                  <div class="inner_part">
                    <label>
                      {/* Mobile Number(for SMS notifications):{" "}
                      <span className="redstyl">* </span> */}
                      {RegistrationPage.Mobile_Number_SMS}{" "}
                      <span className="redstyl">* </span>
                    </label>
                    <input
                      type="text"
                      className={`${errors.mobile ? "error_msg" : ""}`}
                      autocomplete="off"
                      {...register("mobile", {
                        required: {
                          value: true,
                          message: "Please enter value",
                        },
                        pattern: {
                          value: MOBILE_REGEX,
                          message: "Please enter valid 10 digit mobile number.",
                        },
                      })}
                      maxLength={"10"}
                      minLength={"10"}
                    />
                    <div className="error_shown">{errors.mobile?.message}</div>
                  </div>
                </div>
                <div class="heading_styl">
                  {/* <h4>Alternative Contact</h4> */}
                  <h4>{RegistrationPage.Alternative_Contact}</h4>
                </div>
                <p
                  style={{
                    color: "#00115e",
                    fontWeight: 600,
                    paddingTop: "8px",
                    paddingBottom: "8px",
                  }}
                >
                  {/* Please make sure the email and mobile entered here is
                  regularly checked for updates. */}
                  {RegistrationPage.Alternate_Message}
                </p>

                <div class="form_styl">
                  <div class="inner_part1 b_none">
                    <label>
                      {/* Relationship <span className="redstyl">* </span> */}
                      {RegistrationPage.Relationship}{" "}
                      <span className="redstyl">* </span>
                    </label>
                    <select
                      {...register("relationship", {
                        required: {
                          value: true,
                          message: "Please select value",
                        },
                      })}
                      disabled={!program}
                      className={`${errors.relationship ? "error_msg" : ""}`}
                    >
                      <option value="" disabled>
                        {/* --Select Options-- */}
                        {AppCommon.Dropdown_Default_Option}
                      </option>
                      {filteredRelations?.map((data, ind) => (
                        <option key={ind} value={data.value}>
                          {data.value}
                        </option>
                      ))}
                    </select>
                    {errors.relationship && (
                      <div className="error_shown">
                        {errors.relationship?.message}
                      </div>
                    )}
                  </div>
                  <div class="inner_part1 b_none">
                    <label>
                      {/* Name <span className="redstyl">* </span> */}
                      {RegistrationPage.Name}{" "}
                      <span className="redstyl">* </span>
                    </label>
                    <input
                      type="text"
                      className={`${errors.relationName ? "error_msg" : ""}`}
                      autocomplete="off"
                      {...register("relationName", {
                        required: {
                          value: true,
                          message: "Please enter value",
                        },
                        pattern: {
                          value: WHITE_SPACE,
                          message: "Please enter alphabetic characters only",
                        },
                        validate: (val) => {
                          if (
                            document
                              .getElementById("fName")
                              ?.value.toLowerCase() === val.toLowerCase()
                          ) {
                            return "Please enter a different name.";
                          }
                        },
                      })}
                    />
                    <div className="error_shown">
                      {errors.relationName?.message}
                    </div>
                  </div>
                  <div class="inner_part1 b_none">
                    <label>
                      {/* Mobile No. <span className="redstyl">* </span> */}
                      {RegistrationPage.Mobile_No}{" "}
                      <span className="redstyl">* </span>
                    </label>
                    <input
                      type="text"
                      className={`${errors.relationMobno ? "error_msg" : ""}`}
                      autocomplete="off"
                      {...register("relationMobno", {
                        required: {
                          value: true,
                          message: "Please enter value",
                        },
                        pattern: {
                          value: MOBILE_REGEX,
                          message: "Please enter valid 10 digit mobile number.",
                        },
                      })}
                      maxLength={"10"}
                      minLength={"10"}
                    />
                    <div className="error_shown">
                      {errors.relationMobno?.message}
                    </div>
                  </div>
                  <div class="inner_part1 b_none">
                    <label>
                      {/* Email <span className="redstyl">* </span> */}
                      {RegistrationPage.Email}{" "}
                      <span className="redstyl">* </span>
                    </label>
                    <input
                      type="text"
                      className={`${errors.relationEmail ? "error_msg" : ""}`}
                      autocomplete="off"
                      {...register("relationEmail", {
                        required: {
                          value: true,
                          message: "Please enter value",
                        },
                        pattern: {
                          value: EMAIL_REGEX,
                          message: "Please enter valid email",
                        },
                      })}
                    />
                    <div className="error_shown">
                      {errors.relationEmail?.message}
                    </div>
                  </div>
                </div>
                <div class="form_styl">
                  <div class="form_sec_inner">
                    <div class="heading_styl">
                      {/* <h4>Communication Address</h4> */}
                      <h4>{RegistrationPage.Communication_Address}</h4>
                    </div>
                    <div class="w100 ">
                      <div class="inner_part2 mt-3">
                        <label>
                          {/* House No/Building: <span className="redstyl">* </span> */}
                          {RegistrationPage.House_No}{" "}
                          <span className="redstyl">* </span>
                        </label>
                        <input
                          type="text"
                          className={`${errors.houseNo ? "error_msg" : ""}`}
                          autocomplete="off"
                          {...register("houseNo", {
                            required: {
                              value: true,
                              message: "Please enter value",
                            },
                          })}
                        />
                        <div className="error_shown">
                          {errors.houseNo?.message}
                        </div>
                      </div>
                      <div class="inner_part2">
                        <label>
                          {/* Area/Road/Street/P.O:{" "}
                          <span className="redstyl">* </span> */}
                          {RegistrationPage.Area_Road}{" "}
                          <span className="redstyl">* </span>
                        </label>
                        <input
                          type="text"
                          className={`${errors.area ? "error_msg" : ""}`}
                          autocomplete="off"
                          {...register("area", {
                            required: {
                              value: true,
                              message: "Please enter value",
                            },
                          })}
                        />
                        <div className="error_shown">
                          {errors.area?.message}
                        </div>
                      </div>
                    </div>
                    <div class="w100 form_styl ">
                      <div class="inner_part">
                        <label>
                          {/* Country : <span className="redstyl">* </span> */}
                          {RegistrationPage.Country}{" "}
                          <span className="redstyl">* </span>
                        </label>
                        <select
                          {...register("country", {
                            required: {
                              value: true,
                              message: "Please select value",
                            },
                          })}
                          className={`${errors.country ? "error_msg" : ""}`}
                          value={countrydata}
                          onChange={handleCountry}
                        >
                          <option disabled value="">
                            {/* --Select Options-- */}
                            {AppCommon.Dropdown_Default_Option}
                          </option>
                          {pickListVal &&
                            pickListVal?.registrationCountry?.map(
                              (con, ind) => (
                                <option key={ind} value={con.Id}>
                                  {con.value}
                                </option>
                              )
                            )}
                        </select>
                        {errors.country && (
                          <div className="error_shown">
                            {errors.country?.message}
                          </div>
                        )}
                      </div>

                      <div class="inner_part">
                        <label>
                          {/* State/Union Territory :{" "}
                          <span className="redstyl">* </span> */}
                          {RegistrationPage.State_Union}{" "}
                          <span className="redstyl">* </span>
                        </label>
                        <select
                          {...register("state", {
                            required: {
                              value: countrydata === "India" ? true : false,
                              message: "Please select value",
                            },
                          })}
                          value={selectedState}
                          onChange={handleState}
                          className={`${errors.state ? "error_msg" : ""}`}
                          disabled={countrydata !== "India"}
                        >
                          <option disabled value="">
                            {/* --Select Options-- */}
                            {AppCommon.Dropdown_Default_Option}
                          </option>
                          {pickListVal?.states?.map((stateobj, ind) => (
                            <option value={stateobj.Id} key={ind}>
                              {stateobj.value}
                            </option>
                          ))}
                        </select>
                        {errors.state && (
                          <div className="error_shown">
                            {errors.state?.message}
                          </div>
                        )}
                      </div>
                    </div>

                    <div class="w100 ">
                      <div class="inner_part2">
                        <label>
                          {/* District: <span className="redstyl">* </span> */}
                          {RegistrationPage.District}{" "}
                          <span className="redstyl">* </span>
                        </label>
                        <select
                          {...register("district", {
                            required: {
                              value: countrydata === "India" ? true : false,
                              message: "Please select value",
                            },
                          })}
                          value={selectedDistrict}
                          onChange={handleDistrict}
                          className={`${errors.district ? "error_msg" : ""}`}
                          disabled={!selectedState || countrydata !== "India"}
                        >
                          <option disabled value="">
                            {/* --Select Options-- */}
                            {AppCommon.Dropdown_Default_Option}
                          </option>
                          {district &&
                            district?.map((distObj, ind) => (
                              <option key={ind} value={distObj.Name}>
                                {distObj.Name}
                              </option>
                            ))}
                        </select>
                        {errors.district && (
                          <div className="error_shown">
                            {errors.district?.message}
                          </div>
                        )}
                      </div>

                      <div class="inner_part2">
                        <label>
                          {/* City: <span className="redstyl">* </span> */}
                          {RegistrationPage.City}{" "}
                          <span className="redstyl">* </span>
                        </label>
                        <input
                          type="text"
                          className={`${errors.city ? "error_msg" : ""}`}
                          autocomplete="off"
                          {...register("city", {
                            required: {
                              value: true,
                              message: "Please enter value",
                            },
                            pattern: {
                              value: WHT,
                              message: "Please enter value",
                            },
                          })}
                        />
                        <div className="error_shown">
                          {errors.city?.message}
                        </div>
                      </div>
                      <div class="inner_part2">
                        <label>
                          {/* PIN: <span className="redstyl">* </span> */}
                          {RegistrationPage.Pin}{" "}
                          <span className="redstyl">* </span>
                        </label>
                        <input
                          type="text"
                          maxLength={6}
                          className={`${errors.pin ? "error_msg" : ""}`}
                          autocomplete="off"
                          {...register("pin", {
                            required: {
                              value: true,
                              message: "Please enter value",
                            },
                            pattern: {
                              value: PIN_REGEX,
                              message: "Please enter valid 6 digit Pin code.",
                            },
                            maxLength: {
                              value: 6,
                              message: "Please enter valid 6 digit Pin code.",
                            },
                            minLength: {
                              value: 6,
                              message: "Please enter valid 6 digit Pin code.",
                            },
                          })}
                        />
                        <div className="error_shown">{errors.pin?.message}</div>
                      </div>
                    </div>
                  </div>

                  <div class="form_sec_inner">
                    <div class="heading_styl">
                      {/* <h4>Permanent Address</h4> */}
                      <h4>{RegistrationPage.Permanent_Address}</h4>
                    </div>
                    <div class="w100 ">
                      <div class="flex checkbox_styl">
                        <input
                          type="checkbox"
                          checked={checkBox}
                          onChange={handleCheckbox}
                        />
                        <label class="f_size">
                          {/* Check if same as Communication Address */}
                          {RegistrationPage.Permanent_Address_Same_Message}
                        </label>
                      </div>
                      <div class="inner_part2">
                        <label>
                          {/* House No/Building: <span className="redstyl">* </span> */}
                          {RegistrationPage.House_No}{" "}
                          <span className="redstyl">* </span>
                        </label>
                        <input
                          type="text"
                          className={`${errors.building ? "error_msg" : ""}`}
                          autocomplete="off"
                          {...register("building", {
                            required: {
                              value: true,
                              message: "Please enter value",
                            },
                          })}
                          disabled={getValues("building") && checkBox}
                        />
                        <div className="error_shown">
                          {errors.building?.message}
                        </div>
                      </div>
                      <div class="inner_part2">
                        <label>
                          {/* Area/Road/Street/P.O:{" "}
                          <span className="redstyl">* </span> */}
                          {RegistrationPage.Area_Road}{" "}
                          <span className="redstyl">* </span>
                        </label>
                        <input
                          type="text"
                          className={`${errors.road ? "error_msg" : ""}`}
                          autocomplete="off"
                          {...register("road", {
                            required: {
                              value: true,
                              message: "Please enter value",
                            },
                          })}
                          disabled={getValues("road") && checkBox}
                        />
                        <div className="error_shown">
                          {errors.road?.message}
                        </div>
                      </div>
                    </div>

                    <div class="w100 form_styl ">
                      <div class="inner_part">
                        <label>
                          {/* Country : <span className="redstyl">* </span> */}
                          {RegistrationPage.Country}{" "}
                          <span className="redstyl">* </span>
                        </label>
                        <select
                          {...register("pCountry", {
                            required: {
                              value: true,
                              message: "Please select value",
                            },
                          })}
                          className={`${errors.pCountry ? "error_msg" : ""}`}
                          value={countrydata1}
                          onChange={handlePerCountry}
                          disabled={getValues("pCountry") && checkBox}
                        >
                          <option value="" disabled>
                            {/* --Select Options--... */}
                            {AppCommon.Dropdown_Default_Option}
                          </option>

                          {pickListVal &&
                            pickListVal?.registrationCountry?.map(
                              (con, ind) => (
                                <option key={ind} value={con.Id}>
                                  {con.value}
                                </option>
                              )
                            )}
                        </select>
                        {errors.pCountry && (
                          <div className="error_shown">
                            {errors.pCountry?.message}
                          </div>
                        )}
                      </div>
                      <div class="inner_part">
                        <label>
                          {/* State/Union Territory :{" "}
                          <span className="redstyl">* </span> */}
                          {RegistrationPage.State_Union}{" "}
                          <span className="redstyl">* </span>
                        </label>
                        <select
                          {...register("pState", {
                            required: {
                              value: countrydata1 === "India" ? true : false,
                              message: "Please select value",
                            },
                          })}
                          value={selectedState1}
                          onChange={handlePermState}
                          className={`${errors.pState ? "error_msg" : ""}`}
                          disabled={
                            (getValues("pState") && checkBox) ||
                            countrydata1 !== "India"
                          }
                        >
                          <option value="" disabled>
                            {/* --Select Options-- */}
                            {AppCommon.Dropdown_Default_Option}
                          </option>
                          {pickListVal?.states?.map((stateobj, ind) => (
                            <option value={stateobj.Id} key={ind}>
                              {stateobj.value}
                            </option>
                          ))}
                        </select>
                        {errors.pState && (
                          <div className="error_shown">
                            {errors.pState?.message}
                          </div>
                        )}
                      </div>
                    </div>

                    <div class="w100 ">
                      <div class="inner_part2">
                        <label>
                          {/* District: <span>*</span> */}
                          {RegistrationPage.District} <span>*</span>
                        </label>
                        <select
                          {...register("pDistrict", {
                            required: {
                              value: countrydata1 === "India" ? true : false,
                              message: "Please select value",
                            },
                          })}
                          value={selectedDistrict1}
                          onChange={handlePerdistrict}
                          className={`${errors.pDistrict ? "error_msg" : ""}`}
                          disabled={
                            !selectedState1 ||
                            checkBox ||
                            countrydata1 !== "India"
                          }
                        >
                          <option disabled value="">
                            {/* --Select Options-- */}
                            {AppCommon.Dropdown_Default_Option}
                          </option>
                          {district1 &&
                            district1?.map((distObj, ind) => (
                              <option key={ind} value={distObj.Name}>
                                {distObj.Name}
                              </option>
                            ))}
                        </select>
                        {errors.pDistrict && (
                          <div className="error_shown">
                            {errors.pDistrict?.message}
                          </div>
                        )}
                      </div>
                      <div class="inner_part2">
                        <label>
                          {/* City: <span className="redstyl">* </span> */}
                          {RegistrationPage.City}{" "}
                          <span className="redstyl">* </span>
                        </label>
                        <input
                          type="text"
                          className={`${errors.pCity ? "error_msg" : ""}`}
                          autocomplete="off"
                          {...register("pCity", {
                            required: {
                              value: true,
                              message: "Please enter value",
                            },
                            pattern: {
                              value: WHT,
                              message: "Please enter value",
                            },
                          })}
                          disabled={getValues("pCity") && checkBox}
                        />
                        <div className="error_shown">
                          {errors.pCity?.message}
                        </div>
                      </div>
                      <div class="inner_part2">
                        <label>
                          {/* PIN: <span className="redstyl">* </span> */}
                          {RegistrationPage.Pin}{" "}
                          <span className="redstyl">* </span>
                        </label>
                        <input
                          maxLength={6}
                          {...register("pPin", {
                            required: {
                              value: true,
                              message: "Please enter value",
                            },
                            pattern: {
                              value: PIN_REGEX,
                              message: "Please enter valid 6 digit Pin code.",
                            },
                            maxLength: {
                              value: 6,
                              message: "Please enter valid 6 digit Pin code.",
                            },
                          })}
                          type="text"
                          className={`${errors.pPin ? "error_msg" : ""}`}
                          autocomplete="off"
                          disabled={getValues("pPin") && checkBox}
                        />
                        <div className="error_shown">
                          {errors.pPin?.message}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {showSopForm && (
                  <>
                    <div class="heading_styl">
                      {/* <h4>Statement of Purpose (SOP)</h4> */}
                      <h4>{RegistrationPage.SOP}</h4>
                    </div>
                    <div class="form_styl">
                      <div class="inner_part">
                        <label>
                          {/* What is your date of birth?{" "}
                          <span className="redstyl">* </span> */}
                          {RegistrationPage.DOB_Message}{" "}
                          <span className="redstyl">* </span>
                        </label>
                        <input
                          type="text"
                          className={`${errors.yourDOB ? "error_msg" : ""}`}
                          // autocomplete="off"
                          // {...register("yourDOB", {
                          //   required: {
                          //     value: true,
                          //     message: "Please enter value",
                          //   },
                          // })}
                          value={moment(getValues("dateOfBirth")).format(
                            "YYYY-MM-DD"
                          )}
                          disabled={getValues("dateOfBirth")}
                        />
                        {/* <div className="error_shown">
                            {errors.yourDOB?.message}
                          </div> */}
                      </div>

                      <div class="inner_part">
                        <label>
                          {/* What challenges do you think you will face, being
                          older than other students in your class? */}
                          {RegistrationPage.Challenge_Message}
                        </label>
                        <textarea
                          {...register("whatChallengesDoYouThink", {
                            required: {
                              value: true,
                              message: "Please enter value",
                            },
                          })}
                          rows="3"
                          cols="40"
                          className={`${
                            errors.whatChallengesDoYouThink ? "error_msg" : ""
                          }`}
                        ></textarea>
                        {errors.whatChallengesDoYouThink && (
                          <div className="error_shown">
                            {errors.whatChallengesDoYouThink?.message}
                          </div>
                        )}
                      </div>
                    </div>

                    <div class="form_styl">
                      <div class="inner_part">
                        <label>
                          {/* If you completed your 12th grade in 2023 or earlier,
                          please tell us what you have been doing in the
                          interim? */}
                          {RegistrationPage.Interim_Message}
                        </label>
                        <textarea
                          {...register("haveYouCompleted12", {
                            required: {
                              value: true,
                              message: "Please enter value",
                            },
                          })}
                          className={`${
                            errors.haveYouCompleted12 ? "error_msg" : ""
                          }`}
                          rows="3"
                          cols="40"
                        ></textarea>
                        {errors.haveYouCompleted12 && (
                          <div className="error_shown">
                            {errors.haveYouCompleted12?.message}
                          </div>
                        )}
                      </div>
                      <div class="inner_part">
                        <label>
                          {/* Have you taken more than twelve years to finish your
                          schooling? <span className="redstyl">* </span> */}
                          {RegistrationPage.Years_Schooling_Message}{" "}
                          <span className="redstyl">* </span>
                        </label>
                        <span class="w100">
                          {moreYearsTocompleteTwelth.map((opt, ind) => (
                            <span class="w100 flex">
                              <input
                                type="radio"
                                {...register("haveYouTakeMoreYears", {
                                  required: {
                                    value: true,
                                    message: "Complete this field.",
                                  },
                                })}
                                className={`${
                                  errors.haveYouTakeMoreYears ? "error_msg" : ""
                                }`}
                                value={opt.Id}
                                onChange={(e) => {
                                  setSchoolingYes(e.target.value);
                                  clearErrors("haveYouTakeMoreYears");
                                }}
                              />
                              <label>{opt.value}</label>
                            </span>
                          ))}
                          {errors.haveYouTakeMoreYears && (
                            <div className="error_shown">
                              {errors.haveYouTakeMoreYears?.message}
                            </div>
                          )}
                        </span>
                      </div>
                    </div>

                    {schoolingYes === "true" && (
                      <div class="form_styl">
                        <div class="inner_part"></div>
                        <div class="inner_part">
                          {/* <label>If yes, please tell us why?</label> */}
                          <label>
                            {RegistrationPage.Years_Schooling_Response_Message}
                          </label>
                          <textarea
                            {...register("pleaseTellWhy", {
                              required: {
                                value: true,
                                message: "Please enter value",
                              },
                            })}
                            className={`${
                              errors.pleaseTellWhy ? "error_msg" : ""
                            }`}
                            rows="3"
                            cols="40"
                            autocomplete="off"
                          ></textarea>
                          {errors.pleaseTellWhy && (
                            <div className="error_shown">
                              {errors.pleaseTellWhy?.message}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}
                <div class="form_styl1 form_styl ">
                  <div class="inner_part">
                    <label>
                      {/* How did you get to know about Azim Premji University? :{" "}
                      <span className="redstyl">* </span> */}
                      {RegistrationPage.Hear_About_APU}{" "}
                      <span className="redstyl">* </span>
                    </label>
                    <select
                      {...register("know", {
                        required: {
                          value: true,
                          message: "Please select value",
                        },
                      })}
                      className={`${errors.know ? "error_msg" : ""}`}
                      onChange={handleknow}
                    >
                      <option value="" disabled selected>
                        {/* --Select Options-- */}
                        {AppCommon.Dropdown_Default_Option}
                      </option>
                      {pickListVal &&
                        pickListVal?.howDidYouGetToKnowAboutAPU?.map(
                          (data, ind) => (
                            <option key={ind} value={data.value}>
                              {data.value}
                            </option>
                          )
                        )}
                    </select>
                    {errors.know && (
                      <div className="error_shown">{errors.know?.message}</div>
                    )}

                    {knowapu && aboutApu && (
                      <div class="inner_part w66 m_auto">
                        <label>
                          {/* Please Specify: <span className="redstyl">* </span> */}
                          {RegistrationPage.Please_Specify}{" "}
                          <span className="redstyl">* </span>
                        </label>
                        <input
                          type="text"
                          className={`${errors.specify ? "error_msg" : ""}`}
                          {...register("specify", {
                            required: {
                              value: true,
                              message: "Please enter value",
                            },
                          })}
                          maxLength={"255"}
                          autocomplete="off"
                        />
                        {errors.specify && (
                          <div className="error_shown">
                            {errors.specify?.message}
                          </div>
                        )}
                      </div>
                    )}

                    <button type="submit" class="submit">
                      {/* Submit */}
                      {RegistrationPage.Submit}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationForm;
