import React, { useCallback, useEffect, useState } from "react";
import Notification from "../footer/Notification";
import Header from "../header/Header";
import { PATHS } from "../../utils";
import * as images from "../../resources/images";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";

import {
  getdashboardData,
  getPreferencePicklist,
  getugApplicationDetail,
} from "../../redux/actions/Api";
import Loader from "../Loader";
import { Notes } from "../common/Notes";
import { PREFERENCE_PICKLIST_VAL } from "../../redux/actions/Types";
import { HomePageDashboard } from "../constantLabelFile";

let clsName;
let actionName;
let clsName_1;
let actionName_1;
let iconCls;
const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { dashboardresponse, preferencepicklistvalue, allNotes, error } =
    useSelector((state) => state.collections);

  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (
      error?.message === "Unauthorized: Invalid or expired token." ||
      error?.validToken === false
    ) {
      setLoader(false);
    }
  }, [error]);

  useEffect(() => {
    dispatch(getPreferencePicklist());
    dispatch(getugApplicationDetail());
  }, []);

  useEffect(() => {
    const handlePopState = (e) => {
      // window.history.go(1);
      window.history.pushState(null, "", window.location.href);
    };

    // Add event listener on mount
    window.addEventListener("popstate", handlePopState);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    if (dashboardresponse == undefined) {
      setLoader(true);
    } else {
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  }, [dashboardresponse]);

  useEffect(() => {
    let id = {
      contactId: localStorage.getItem("contactId"),
    };
    dispatch(getdashboardData(id));
  }, []);

  useEffect(() => {
    if (dashboardresponse?.data?.allDataOnDashboard?.length <= 0) {
      navigate(PATHS.REGISTRATION);
    }
  }, [dashboardresponse]);

  const takeHome = dashboardresponse?.data?.allDataOnDashboard?.filter(
    (filterData) => filterData?.takeHomeAssSent === true
  );

  function pendingMsg() {
    if (dashboardresponse?.data?.pendingPaymentMsg) {
      return <>{dashboardresponse?.data?.pendingPaymentMsg}</>;
    }
  }

  function handleActionRequired() {
    const dD1 = dashboardresponse?.data?.allDataOnDashboard.map((data, ind) => {
      return (
        <>
          {data?.takeHomeAssSent && (
            <a href={data?.takeHomeAsssignmentLink} target="_blank">
              Take Home Assignment- {data?.appNumber}
            </a>
          )}
        </>
      );
    });
    return dD1;
  }

  function diplomaRedirect() {
    if (
      dashboardresponse?.data?.allDataOnDashboard[0]?.programName ===
        "Diploma" ||
      dashboardresponse?.data?.allDataOnDashboard[0]?.programName ===
        "Postgraduate Diploma"
    ) {
      return (
        <>
          <input
            type="submit"
            className="submit"
            value="Register Again"
            onClick={() => {
              navigate(PATHS.REGISTRATION, {
                state: {
                  programName:
                    dashboardresponse?.data?.allDataOnDashboard[0]?.programName,
                },
              });
            }}
          />
          <br />
          <div>
            <Notes name="Diploma_Register_Again" />
          </div>
        </>
      );
    } else {
      return null;
    }
  }

  const admitCard = dashboardresponse?.data?.allDataOnDashboard?.filter(
    (filterAdmitCardData) =>
      filterAdmitCardData?.appSubmitted === true ||
      filterAdmitCardData?.admitCardSent === true
  );
  
  function downLoadComponent() {
    const dD = dashboardresponse?.data?.allDataOnDashboard.map((item, ind) => {
      return (
        (item?.appSubmitted || item?.admitCardSent) && (
          <>
            {item?.appSubmitted && (
              <a
                href={item?.downloadAppLink}
                target="_blank"
                style={{ cursor: "pointer" }}
              >
                <i className="fa-solid fa-download"></i> Application -{" "}
                {item?.appNumber}
              </a>
            )}
            {item?.admitCardSent && (
              <a
                href={item?.downloadAdmitCardLink}
                target="_blank"
                style={{ cursor: "pointer" }}
              >
                <i className="fa-solid fa-download"></i> Admit Card -{" "}
                {item?.appNumber}
              </a>
            )}
          </>
        )
      );
    });
    return dD;
  }

  useEffect(() => {
    localStorage.removeItem("userData");
  }, []);

  const redirectUser = (data, event) => {
    localStorage.setItem("userData", JSON.stringify(data));
    localStorage.setItem(
      "application_type",
      dashboardresponse?.data?.allDataOnDashboard[0]?.programName
    );
    if (data?.appChecklistItems !== null) {
      let completedCheckList = data?.appChecklistItems?.split(";");
      const lastIndex = completedCheckList[completedCheckList.length - 1];

      if (lastIndex === "Application Details") {
        {
          localStorage.setItem("applicationId", data?.appId);
        }
        if (data?.programName === "Diploma") {
          if (event.ctrlKey) {
            window.open(PATHS.EDUCATIONDIPLOMA_STR + data?.appId, "_blank");
          } else {
            navigate(PATHS.EDUCATIONDIPLOMA_STR + data?.appId);
          }
        } else if (data?.programName === "Postgraduate Diploma") {
          if (event.ctrlKey) {
            window.open(PATHS.EDUCATIONDIPLOMA_STR + data?.appId, "_blank");
          } else {
            navigate(PATHS.EDUCATIONDIPLOMA_STR + data?.appId);
          }
        } else if (data?.programName === "Postgraduate Program") {
          if (event.ctrlKey) {
            window.open(PATHS.EDUCATIONDETAILPG_STR + data?.appId, "_blank");
          } else {
            navigate(PATHS.EDUCATIONDETAILPG_STR + data?.appId);
          }
        } else if (data?.programName === "Undergraduate Program") {
          if (event.ctrlKey) {
            window.open(PATHS.EDUCATIONDETAILUG_STR + data?.appId, "_blank");
          } else {
            navigate(PATHS.EDUCATIONDETAILUG_STR + data?.appId);
          }
        }
      } else if (lastIndex === "Education Details") {
        {
          localStorage.setItem("applicationId", data?.appId);
        }
        if (data?.programName === "Diploma") {
          if (event.ctrlKey) {
            window.open(PATHS.SOCIODIPLOMA_STR + data?.appId, "_blank");
          } else {
            navigate(PATHS.SOCIODIPLOMA_STR + data?.appId);
          }
        } else if (data?.programName === "Postgraduate Diploma") {
          if (event.ctrlKey) {
            window.open(PATHS.SOCIODIPLOMA_STR + data?.appId, "_blank");
          } else {
            navigate(PATHS.SOCIODIPLOMA_STR + data?.appId);
          }
        } else if (data?.programName === "Postgraduate Program") {
          if (event.ctrlKey) {
            window.open(PATHS.SOCIALECOPG_STR + data?.appId, "_blank");
          } else {
            navigate(PATHS.SOCIALECOPG_STR + data?.appId);
          }
        } else if (data?.programName === "Undergraduate Program") {
          if (event.ctrlKey) {
            window.open(PATHS.SOCIALECOUG_STR + data?.appId, "_blank");
          } else {
            navigate(PATHS.SOCIALECOUG_STR + data?.appId);
          }
        }
      } else if (lastIndex === "Socio-Economic Background") {
        {
          localStorage.setItem("applicationId", data?.appId);
        }
        if (data?.programName === "Diploma") {
          if (event.ctrlKey) {
            window.open(PATHS.UPLOADPHOTODIPLOMA_STR + data?.appId, "_blank");
          } else {
            navigate(PATHS.UPLOADPHOTODIPLOMA_STR + data?.appId);
          }
        } else if (data?.programName === "Postgraduate Diploma") {
          if (event.ctrlKey) {
            window.open(PATHS.UPLOADPHOTODIPLOMA_STR + data?.appId, "_blank");
          } else {
            navigate(PATHS.UPLOADPHOTODIPLOMA_STR + data?.appId);
          }
        } else if (data?.programName === "Postgraduate Program") {
          if (event.ctrlKey) {
            window.open(PATHS.UPLOADPHOTOPG_STR + data?.appId, "_blank");
          } else {
            navigate(PATHS.UPLOADPHOTOPG_STR + data?.appId);
          }
        } else if (data?.programName === "Undergraduate Program") {
          if (event.ctrlKey) {
            window.open(PATHS.UPLOADPHOTOUG_STR + data?.appId, "_blank");
          } else {
            navigate(PATHS.UPLOADPHOTOUG_STR + data?.appId);
          }
        }
      } else if (lastIndex === "Upload Photograph") {
        {
          localStorage.setItem("applicationId", data?.appId);
        }
        if (data?.programName === "Diploma") {
          if (event.ctrlKey) {
            window.open(PATHS.PAYMENTDETAILSUG_STR + data?.appId, "_blank");
          } else {
            navigate(PATHS.PAYMENTDETAILSUG_STR + data?.appId);
          }
        } else if (data?.programName === "Postgraduate Diploma") {
          if (event.ctrlKey) {
            window.open(PATHS.PAYMENTDETAILSUG_STR + data?.appId, "_blank");
          } else {
            navigate(PATHS.PAYMENTDETAILSUG_STR + data?.appId);
          }
        } else if (data?.programName === "Postgraduate Program") {
          if (event.ctrlKey) {
            window.open(PATHS.PAYMENTDETAILSUG_STR + data?.appId, "_blank");
          } else {
            navigate(PATHS.PAYMENTDETAILSUG_STR + data?.appId);
          }
        } else if (data?.programName === "Undergraduate Program") {
          if (event.ctrlKey) {
            window.open(PATHS.PAYMENTDETAILSUG_STR + data?.appId, "_blank");
          } else {
            navigate(PATHS.PAYMENTDETAILSUG_STR + data?.appId);
          }
        }
      } else {
        localStorage.setItem("applicationId", data?.appId);
        if (data?.programName === "Diploma") {
          if (event.ctrlKey) {
            window.open(
              PATHS.APPLICATIONDETAILDIPLOMA_STR + data?.appId,
              "_blank"
            );
          } else {
            navigate(PATHS.APPLICATIONDETAILDIPLOMA_STR + data?.appId);
          }
        } else if (data?.programName === "Postgraduate Diploma") {
          if (event.ctrlKey) {
            window.open(
              PATHS.APPLICATIONDETAILDIPLOMA_STR + data?.appId,
              "_blank"
            );
          } else {
            navigate(PATHS.APPLICATIONDETAILDIPLOMA_STR + data?.appId);
          }
        } else if (data?.programName === "Postgraduate Program") {
          if (event.ctrlKey) {
            window.open(PATHS.APPLICATIONDETAILPG_STR + data?.appId, "_blank");
          } else {
            navigate(PATHS.APPLICATIONDETAILPG_STR + data?.appId);
          }
        } else if (data?.programName === "Undergraduate Program") {
          if (event.ctrlKey) {
            window.open(PATHS.APPLICATIONDETAILUG_STR + data?.appId, "_blank");
          } else {
            navigate(PATHS.APPLICATIONDETAILUG_STR + data?.appId);
          }
        }
      }
    } else {
      localStorage.setItem("applicationId", data?.appId);
      if (data?.programName === "Diploma") {
        if (event.ctrlKey) {
          window.open(
            PATHS.APPLICATIONDETAILDIPLOMA_STR + data?.appId,
            "_blank"
          );
        } else {
          navigate(PATHS.APPLICATIONDETAILDIPLOMA_STR + data?.appId);
        }
      } else if (data?.programName === "Postgraduate Diploma") {
        if (event.ctrlKey) {
          window.open(
            PATHS.APPLICATIONDETAILDIPLOMA_STR + data?.appId,
            "_blank"
          );
        } else {
          navigate(PATHS.APPLICATIONDETAILDIPLOMA_STR + data?.appId);
        }
      } else if (data?.programName === "Postgraduate Program") {
        if (event.ctrlKey) {
          window.open(PATHS.APPLICATIONDETAILPG_STR + data?.appId, "_blank");
        } else {
          navigate(PATHS.APPLICATIONDETAILPG_STR + data?.appId);
        }
      } else if (data?.programName === "Undergraduate Program") {
        if (event.ctrlKey) {
          window.open(PATHS.APPLICATIONDETAILUG_STR + data?.appId, "_blank");
        } else {
          navigate(PATHS.APPLICATIONDETAILUG_STR + data?.appId);
        }
      }
    }
  };

  function showMessage() {
    const dD = dashboardresponse?.data?.allDataOnDashboard.map((item, ind) => {
      return (
        item?.messageOnDashboard && (
          <>
            <div className="dashboard_note">
              {/* <p>{item?.messageOnDashboard}</p> */}
              <p>{ReactHtmlParser(item?.messageOnDashboard)}</p>
            </div>
            <br />
          </>
        )
      );
    });
    return dD;
  }

  function actionMessage() {
    {
      if (dashboardresponse?.data?.actionRequiredMsg) {
        return (
          <>
            <div className="dashboard_note">
              {ReactHtmlParser(dashboardresponse?.data?.actionRequiredMsg)}
            </div>
            <br />
          </>
        );
      }
    }
  }

  return (
    <div>
      <Header />
      <div className="main_part">
        <div className="banner_styl">
          <img src={images.apubanner} alt="Banner" />
        </div>

        {showMessage()}
        {actionMessage()}

        {/* )) */}

        <div className="container_body">
          <Loader show={loader} />
          {admitCard && admitCard?.length > 0 && (
            <div className="home_sec">
              <div className="form_styl">
                <div className="inner_part">
                  <h4>Downloads</h4>
                  {downLoadComponent()}
                </div>
              </div>
            </div>
          )}
          <br />

          {dashboardresponse?.data?.pendingPaymentMsg ||
          (takeHome && takeHome?.length > 0) ? (
            <div className="home_sec">
              <div className="w100 actionred">
                <h4>{"Action Required"}</h4>
                <div className="inner_part">
                  <div className="actionrequired">{handleActionRequired()}</div>
                </div>
                {dashboardresponse?.data?.pendingPaymentMsg && (
                  <div className="">
                    <p>{dashboardresponse?.data?.pendingPaymentMsg}</p>
                  </div>
                )}
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="home_app">
            <div className="form_styl1">
              <div className="w100">
                <h4 className="m-0">APPLICATIONS</h4>
                <table width="100%">
                  <thead>
                    <tr>
                      {/* <th>Application Name</th>
                      <th>Application Number</th>
                      <th>Form Completion</th>
                      <th>Application Status</th> */}
                      <th>{HomePageDashboard.Application_Name}</th>
                      <th>{HomePageDashboard.Application_Number}</th>
                      <th>{HomePageDashboard.Form_Completion}</th>
                      <th>{HomePageDashboard.Application_Status}</th>
                    </tr>
                  </thead>

                  {dashboardresponse?.data?.allDataOnDashboard.map(
                    (data, ind) => (
                      <tbody>
                        <tr>
                          {data?.programName === "Postgraduate Program"}
                          <td>
                            <NavLink onClick={(e) => redirectUser(data, e)}>
                              {data?.appName}
                            </NavLink>
                          </td>
                          <td>{data?.appNumber}</td>
                          <td>{data?.appChecklistPercentageCompletion}%</td>
                          <td>{data?.appApplication_Status}</td>
                        </tr>
                      </tbody>
                    )
                  )}
                </table>
                {diplomaRedirect()}
              </div>
            </div>
          </div>
        </div>

        <div className="main_container">
          <Notification />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
