import React, { useEffect } from "react";
import Header from "../header/Header";


const Thankyou = () => { 

  return (
    <div>
      <Header />
      <div class="main_part">
        <div class="container_body">
          <div class="notes">
            <p class="pb1">
              Thank You for Registration...!
            </p>
            <p>
              Welcome to Azim Premji University's Applicant Portal.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Thankyou;
