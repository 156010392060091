import React, { useEffect, useState, useCallback } from "react";
import Home from "../Home";
import { getCapitalized } from "../../utils/Helper";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import useRazorpay from "react-razorpay";
import { createOrder, getdashboardData, getPaymentsDetails, sendPaymentData } from "../../redux/actions/Api";
import payment from '../../assets/payment.png';
import { toast } from "react-toastify";
import Success from "../notification/Success";
import { PAYMENT_BASE } from "../../utils";
import { CREATE_ORDER, PAYMENT_DETAIL, SEND_PAYMENT_DETAIL } from "../../redux/actions/Types";
import payImage from '../../assets/payment.png'
import { Notes } from "./Notes";
let feeAmount;
const PaymentDetailsUG = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);

  const [checkBox, setCheckedBox] = useState(false);
  const { dashboardresponse, getPaymentData, getPaymentOrder, sendPaymentDetailsSFDC } = useSelector((state) => state.collections);

  const [payementStatus, setPaymentStatus] = useState(false);

  const [Razorpay, isLoaded] = useRazorpay();

  const [orderData, setOrderData] = useState('');
  const [appIds, setAppID] = useState("");
  const [readOnly, setReadOnly] = useState(false);
  const [showMsg, setShowMsg] = useState("");

  useEffect(() => {
    if (getPaymentData === undefined) {
      setLoader(true);
    } else {
      setTimeout(() => {
        setLoader(false);
      }, 2000);
    }
  }, [getPaymentData])

  useEffect(() => {
    const appID = localStorage.getItem("userData");
    const pasrseData = JSON.parse(appID)
    setAppID(pasrseData?.appId)
  }, [])


  const loc = localStorage.getItem("userData");
  const locData = JSON.parse(loc)
  const filteredData = dashboardresponse?.data?.allDataOnDashboard?.filter((application) => application?.appId == locData?.appId)

  useEffect(() => {
    if (filteredData) {
      feeAmount = filteredData[0]?.feeAmount;
    }
  }, [filteredData])

  useEffect(() => {
    if (appIds) {
      getPaymentStatus();
    }
  }, [appIds]);

  const getPaymentStatus = () => {
    let formData = {
      applicationId: appIds,
    };
    if (formData) {
      dispatch(getPaymentsDetails(formData));
    }
  }

  useEffect(() => {
    try {
      if (getPaymentOrder?.response) {

        setLoader(false);

        displayRazorpay(getPaymentOrder?.response);
        dispatch({ type: CREATE_ORDER, payload: undefined });

      }
    } catch (error) {

    }

  }, [getPaymentOrder])

  useEffect(() => {
    if (sendPaymentDetailsSFDC?.status === 200) {
      getPaymentStatus();

      let id = {
        contactId: localStorage.getItem("contactId")
      }
      dispatch(getdashboardData(id));
      dispatch({ type: SEND_PAYMENT_DETAIL, payload: undefined });
      if (getPaymentData?.response?.records[0]?.hed__Application_Status__c !== "Payment Failed") {
        toast.success(
          <Success response={sendPaymentDetailsSFDC} />,
          {
            position: "top-center",
            autoClose: 2000,
          }
        );
      }
      setLoader(false)
    } else {
      // setPaymentStatus(false);
    }

  }, [sendPaymentDetailsSFDC])

  /** payment method */
  const createPaymentOrder = async () => {
    setLoader(true)
    setCheckedBox(true);
    try {
      let data = {
        "applicationId": appIds,
        "contactId": localStorage.getItem("contactId"),
        "amount": feeAmount
      }
      dispatch(createOrder(data));
    }
    catch (err) {

    }
  }

  function loadScript(src) {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = (error) => {

        reject(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay(order) {
    try {
      const res = await loadScript(
        PAYMENT_BASE
      );

      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }

      const options = {
        key: order?.apiKey, // Enter the Key ID generated from the Dashboard
        amount: order?.amount,
        currency: "INR",
        name: "Azim Premji University",
        description: "Total Payable Amount",
        image: "",
        order_id: order?.orderId,
        handler: async function (response) {
          const data = {
            applicationId: order?.applicationId,
            paymentId: response?.razorpay_payment_id,
            orderId: response?.razorpay_order_id,
            status: "captured",
            captured: true,
            failedReason: "",
            event: "payment.captured"
          };
          setLoader(true)
          dispatch(sendPaymentData(data));

        },
        prefill: {
          name: order.contactName,
          email: order.contactEmail,
          contact: order.contactMobile,
        },
        notes: {
          address: "Azim Premji University",
        },
        "modal": {
        "ondismiss": function(){
        getPaymentStatus();
        }
    },
        theme: {
          color: "#61dafb",
          hide_topbar: false

        },
      };

      const paymentObject = new window.Razorpay(options);

      // You can handle payment failure by listening to the failure event.
      paymentObject.on("payment.failed", async (response) => {
        const data = {
          applicationId: order?.applicationId,
          paymentId: response?.razorpay_payment_id,
          orderId: response?.razorpay_order_id,
          status: "",
          captured: "",
          failedReason: response.error.description,
          event: "payment.failed",


        };
        dispatch(sendPaymentData(data));

        alert(response.error.description);
      });

      paymentObject.open();
    } catch (error) {
    }
  }

  const currentDate = new Date();
  const year1 = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDate = `${year1}-${month}-${day}`;

  useEffect(() => { 
      if (
        formattedDate > getPaymentData?.response?.records[0]?.Admission_Cycle__r?.Application_Submission_Date__c 
      ) {
        setReadOnly(true);
        setShowMsg(<Notes name="ADM_Cycle_Validation_App" />);
      } else {
        setReadOnly(false);
        setShowMsg("");
      }
  }, [getPaymentData]);

  function displayPayment() {
    if(showMsg){
      return(
        <div>{showMsg}</div>
      )
    }
    else if (getPaymentData?.response?.records[0]?.hed__Application_Status__c) {
      if (getPaymentData?.response?.records[0]?.hed__Application_Status__c === "Started" ||
        getPaymentData?.response?.records[0]?.hed__Application_Status__c === "Draft" ||
        getPaymentData?.response?.records[0]?.hed__Application_Status__c === "Payment Failed" ||
        (getPaymentData?.response?.records[0]?.hed__Application_Status__c === "Payment Pending" && 
        getPaymentData?.response?.records[0]?.Transactions__r === null &&
        !getPaymentData?.response?.records[0]?.API_Loggers__r?.records[0]?.Awaiting_Payment_Response__c)
      ) {
        return (
          <><div class="form_styl">
            <div class="inner_part w100 paymentsyl">
              <p>Application fee of <strong>Rs.{feeAmount}/-</strong> to be remitted to Azim Premji University. You can pay your application fee through Razorpay using Debit card/Credit card/Net banking.
              </p>
              <p><strong className="red">The payment status will get reflected on your application immediately after the payment.</strong><br />
                Once the payment is made and verified, the payment status will change to "Completed".
              </p>
              <p>
                Application fees once paid will not be refunded.
              </p>
              <ul>
                <li>
                  <input type="checkbox" checked={checkBox} onChange={() => setCheckedBox(!checkBox)} />{" "}

                  I hereby certify that all the information provided in the application is true to my knowledge and beliefs.
                </li>
                <li>
                  {" "} I agree to abide by the rules and regulation of admission to the Programme as stipulated by the institute and also to frame rules from time to time.
                </li>
                <li>
                  {" "} I also agree, in the event that any information in my application being found to be incorrect or false any time after joining the course, my admission may be cancelled.
                </li>
              </ul>
            </div>
          </div>
          {feeAmount && 
            <div class="form_styl">
              <div class="w100">
                <button
                  value=""
                  type="submit"
                  class="submit ml_5"
                  onClick={() => createPaymentOrder()}
                >
                  Pay and Submit
                </button>
              </div>
            </div>
          }
            </>
        )

      } else if (getPaymentData?.response?.records[0]?.hed__Application_Status__c === "Payment Pending" && 
        getPaymentData?.response?.records[0]?.API_Loggers__r !== null &&
        getPaymentData?.response?.records[0]?.Transactions__r === null &&
        getPaymentData?.response?.records[0]?.API_Loggers__r?.records[0]?.Awaiting_Payment_Response__c
      ) {
        return <diV>
          <Notes name='Payment_Pending_Message' hours="Payment_awaiting_Response_Duration" />
        </diV>
      } else {
        return <diV>
          <p>You have paid the application fee of Rs.{feeAmount}/- and successfully submitted the application. You will receive an email and SMS at your registered
            email ID and mobile number confirming the payment</p>
        </diV>
      }

      // displayPaymentNotes()
      // showSuccessMessage()

    }
  }

  // getPaymentData?.response?.records[0]?.API_Loggers__r.records[0]?.Awaiting_Payment_Response__c

  /**old code */

  function showSuccessMessage() {
    if (getPaymentData?.response?.records[0]?.Checklist_Completion__c === '100') {
      return (

        <diV>
          <p>You have paid the application fee of Rs.{feeAmount}/- and successfully submitted the application. You will receive an email and SMS at your registered
            email ID and mobile number confirming the payment</p>
        </diV>
      )
    } else {
      return null
    }
  }
  /** end old code */

  return (
    <Home>
      <Loader show={loader} />
      <div class="right_panel">
        <div class="w100 dflex">
        </div>
        <div className="w100 scroll_styl">
          <div class="heading_styl head_font">
            <h4>{getCapitalized("Payment Details")}</h4>
          </div>

          <div class="form_sec form_part">
            {displayPayment()}
          </div>
        </div>



      </div>
    </Home>
  );
};
export default PaymentDetailsUG;
