import React, { useEffect, useRef } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Signup from './components/base/Signup';
import Signin from './components/base/Signin';
import ChangePassword from './components/base/ChangePassword';
import { PATHS } from './utils';
import RegistrationForm from './components/forms/RegistrationForm';
import Dashboard from './components/home/Dashboard';
import ApplicationDetail from './components/ugForms/ApplicationDetail';
import EducationDetail from './components/pgForms/EducationDetail';
import SocialEco from './components/pgForms/SocialEco';
import UploadPhoto from './components/ugForms/UploadPhoto';
import ApplicationDetailPG from './components/pgForms/ApplicationDetailPG';
import EducationDetailUG from './components/ugForms/EducationDetailUG';
import UploadPhotoPG from './components/pgForms/UploadPhotoPG';
import SocialEcoUG from './components/ugForms/SocialEcoUG';
import Preview from './components/pgForms/Preview';
import PreviewUG from './components/ugForms/PreviewUG';
import RaiseQuery from './components/home/RaiseQuery';
import RegistrationComplete from './components/home/RegistrationComplete';
import ForgetPassword from './components/base/ForgetPassword';
import Faqs from './components/home/Faqs';
import Share from './components/home/share';
import PreviewDiploma from './components/diploma/Preview'
import ApplicationDetailDiploma from './components/diploma/ApplicationDetailDiploma';
import EducationDiploma from './components/diploma/EducationDetail';
import SocioDiploma from './components/diploma/SocialEco';
import PrivateRoute from './utils/PrivateRoute';
import ResetEmail from './components/base/ResetEmail';
import UploadPhotoDiploma from './components/diploma/UploadPhotoDiploma';
import NotFound from './components/base/NotFound';
import Thankyou from './components/base/Thankyou';
import PaymentDetailsUG from './components/common/PaymentDetails';
import SigninRedirect from './components/base/SigninRedirect';
import { getUrlSegment } from './utils/Helper';
import SessionTimeout from './utils/SessionTimeout';
import ListFaqs from './components/home/ListFaqs';
import jQuery from "jquery";
import $ from "jquery";

const router = createBrowserRouter([
  {
    path: PATHS.USERSIGNUP,
    element: <Signup />,
  },
  {
    path: PATHS.USERSIGNIN,
    element: <Signin />
  },
  {
    path: PATHS.FORGETPASSWORD,
    element: <ForgetPassword />
  },
  {
    path: PATHS.RESETEMAIL,
    element: <ResetEmail />
  },
  {
    path: PATHS.CHANGEPASSWORD,
    element: <ChangePassword />
  },

  {
    element: <PrivateRoute />,
    children: [
      {
        path: PATHS.REGISTRATION,
        element: <RegistrationForm />
      },
      {
        path: PATHS.DASHBOARD,
        element: <Dashboard />
      },
      {
        path: PATHS.APPLICATIONDETAILUG,
        element: <ApplicationDetail />
      },
      {
        path: PATHS.APPLICATIONDETAILPG,
        element: <ApplicationDetailPG />
      },
      {
        path: PATHS.EDUCATIONDETAILPG,
        element: <EducationDetail />
      },
      {
        path: PATHS.SOCIALECOPG,
        element: <SocialEco />
      },
      {
        path: PATHS.UPLOADPHOTOUG,
        element: <UploadPhoto />
      },
      {
        path: PATHS.EDUCATIONDETAILUG,
        element: <EducationDetailUG />
      },
      {
        path: PATHS.UPLOADPHOTOPG,
        element: <UploadPhotoPG />
      },
      {
        path: PATHS.SOCIALECOUG,
        element: <SocialEcoUG />
      },
      {
        path: PATHS.PREVIEWPG,
        element: <Preview />
      },
      {
        path: PATHS.PREVIEWUG,
        element: <PreviewUG />
      },
      {
        path: PATHS.PREVIEWDIPLOMA,
        element: <PreviewDiploma />
      },
      {
        path: PATHS.RAISEQUERY,
        element: <RaiseQuery />
      },
      {
        path: PATHS.REGISTRATIONCOMPLETE,
        element: <RegistrationComplete />
      },
      {
        path: PATHS.APPLICATIONDETAILDIPLOMA,
        element: <ApplicationDetailDiploma />
      }
      ,
      {
        path: PATHS.EDUCATIONDIPLOMA,
        element: <EducationDiploma />
      },
      {
        path: PATHS.SOCIODIPLOMA,
        element: <SocioDiploma />
      },
      {
        path: PATHS.FAQS,
        element: <Faqs />
      },
      {
        path: PATHS.SHARE,
        element: <Share />
      },
      {
        path: PATHS.UPLOADPHOTODIPLOMA,
        element: <UploadPhotoDiploma />
      },
      {
        path: PATHS.THANKYOU,
        element: <Thankyou />
      },
      {
        path: PATHS.PAYMENTDETAILSUG,
        element: <PaymentDetailsUG />
      },
      {
        path: PATHS.SIGNINREDIRECT,
        element: <SigninRedirect />
      },
      {
        path: PATHS.LISTFAQS,
        element: <ListFaqs />
      },
      {
        path: "*",
        element: <NotFound />,
      },

    ]
  }
]);

function App() {
    $(document).bind("contextmenu",function(e) {
      e.preventDefault();
  });

  useEffect(() => {
    const loggedInUser = localStorage.getItem("access_token");
   
    if (loggedInUser && getUrlSegment()[0] == undefined) {
      window.location.assign(PATHS.DASHBOARD)
    }
  }, [localStorage.getItem("access_token"), getUrlSegment]);

  return (
    <div >
      <ToastContainer
        position="top-center"
        autoClose="5000"
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <RouterProvider router={router} >
      </RouterProvider>
      {localStorage.getItem("access_token") && <SessionTimeout />}
    </div>
  );
}

export default App;





