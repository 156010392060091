import axios from "axios";
import { API_PATHS } from ".";

export const getUnauthedAxios = () =>
    axios.create({
        headers: {
            "Content-Type": "application/json",
        },
    })
export const getAxios = () => {
    const token = getToken();
    const headers = {
        "content-Type": "application/json",
        Authorization: `Bearer ${token}`,
    }
    return axios.create({ headers });
}

export const getToken = () => {
    let accessToken = "";
    let token = localStorage.getItem("access_token");
    if (token) {
        let accessTokenObj = token.split("##");
        accessToken = accessTokenObj[0];
    }
    return accessToken;
};

export function getUrlSegment() {
    let url = window.location.pathname
    var segment = url.split("/");
    var filteredSegment = segment.filter(function (el) {
        return el != "";
    })
    return filteredSegment;
}

export function getCapitalized(string) {
    if (string) {
        let str = string.toUpperCase();
        return str;
    }
}

export const getCapital = (string) => {
    let str = "";

    if (string) {
        str = string.replace(
            /(^\w|\s\w)(\S*)/g,
            (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
        );
    }
    return str;
};

export const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
            resolve(
                (fileReader.result).replace(/^data:image\/\w+;base64,/, "")
            );
        };
        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};

export const convertPDFBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
            resolve(
                (fileReader.result)
            );
        };
        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};
export async function upLoadDocuments(data) {
    try {
        const result = await axios.post(`${API_PATHS.UPLOAD_API + "/" + 'token', data}`,)
        Promise.resolve(result)
    } catch (error) {
        Promise.reject(error)
    }
}

export function numberToWords(num) {
    const a = [
        '', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine',
        'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'
    ];
    const b = [
        '', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'
    ];
    const c = [
        '', 'thousand', 'lakh', 'crore'
    ];

    if (num === 0) return 'zero';

    let words = '';

    function getBelowHundred(n) {
        if (n < 20) {
            return a[n];
        } else {
            return b[Math.floor(n / 10)] + (n % 10 !== 0 ? ' ' + a[n % 10] : '');
        }
    }

    function getBelowThousand(n) {
        if (n < 100) {
            return getBelowHundred(n);
        } else {
            return a[Math.floor(n / 100)] + ' hundred' + (n % 100 !== 0 ? ' and ' + getBelowHundred(n % 100) : '');
        }
    }

    function getWordForSegment(n, index) {
        if (n === 0) return '';
        return getBelowThousand(n) + ' ' + c[index] + ' ';
    }

    let segments = [];
    let i = 0;

    // Segmenting number according to the Indian numbering system
    while (num > 0) {
        if (i === 1) { // For thousands place
            segments.push(num % 100);
            num = Math.floor(num / 100);
        } else {
            segments.push(num % 1000);
            num = Math.floor(num / 1000);
        }
        i++;
    }

    for (let j = segments.length - 1; j >= 0; j--) {
        if (segments[j] > 0) {
            words += getWordForSegment(segments[j], j);
        }
    }

    return words.trim();
}

