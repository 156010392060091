import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { PATHS } from "../../utils";
import { getUrlSegment } from "../../utils/Helper";
import { useDispatch, useSelector } from "react-redux";
import ProgressBar from "@ramonak/react-progress-bar";

import {
  createApplDiploma,
  createApplication,
  createEducation,
  createEducationpg,
  createSocioeco,
  getdashboardData,
  getPaymentsDetails,
} from "../../redux/actions/Api";
import { unsavedchanges } from "../../redux/actions";
import { StorageService } from "../../services/StorageService";
let completedItems = [true, false, false, false, false, false];

let appStatus;
let newStatus;

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("");
  const [dashdata, setDashdata] = useState("");
  const [checkItem, setCheckItem] = useState("")
  const { dashboardresponse, unsavedValues, deleteExpRes } = useSelector((state) => state.collections);

  useEffect(() => {
    let formData = {
      contactId: localStorage.getItem("contactId"),
    };
    dispatch(getdashboardData(formData));
  }, []);



  useEffect(() => {
    if (localStorage.getItem("userData")) {
      const loc = localStorage.getItem("userData");
      const locData = JSON.parse(loc)
      const filteredData = dashboardresponse?.data?.allDataOnDashboard?.filter((application) => application.appId == locData.appId)

      if (filteredData) {
        newStatus = filteredData[0];
      }
    }
    else if (dashboardresponse?.data?.allDataOnDashboard?.length == 1) {
      newStatus = dashboardresponse?.data?.allDataOnDashboard[0]
    }
  }, [dashboardresponse, newStatus])

  useEffect(() => {
    if (dashboardresponse?.data) {
      localStorage.setItem("application_type", dashboardresponse?.data?.allDataOnDashboard[0]?.programName)
    }
  }, [dashboardresponse])

  const activeLink = () => {
    let res;
    if (getUrlSegment()[1] === "application-detail-form") {
      res = "Application Details";
    }
    if (getUrlSegment()[1] === "education-detail-form") {
      res = "Education Details";
    }
    if (getUrlSegment()[1] === "social-economic-background") {
      res = "social-economic-background";
    }
    if (getUrlSegment()[1] === "upload-photo") {
      res = "upload-photo";
    }
    if (getUrlSegment()[1] === "preview") {
      res = "preview";
    }
    if (getUrlSegment()[1] === "payment-details") {
      res = "Payment Details";
    }
    return res;
  };

  useEffect(() => {
    if (dashboardresponse?.data.allDataOnDashboard.length) {
      setDashdata(dashboardresponse?.data?.allDataOnDashboard[0]);
    }
  }, [dashboardresponse]);

  useEffect(() => {
    setActiveTab(activeLink());
  }, [getUrlSegment()[1]]);

  const handleClick = () => {
    dispatch(createEducation());
    dispatch(createSocioeco());
    dispatch(createApplication());
    dispatch(createApplDiploma());
    dispatch(createEducationpg());
    dispatch(unsavedchanges());
    dispatch(getPaymentsDetails());
  };


  useEffect(() => {

    if (newStatus?.appChecklistItems) {

      let completedCheckList = newStatus?.appChecklistItems?.split(";");
      setCheckItem(completedCheckList)
    }
  }, [newStatus])

  function disabledItem(item, index) {
    if (newStatus?.appChecklistItems) {
      let completedCheckList = newStatus?.appChecklistItems?.split(";");

      if (completedCheckList) {
        let ind = completedCheckList.indexOf(item);

        if (ind !== -1) {
          completedItems[ind] = true;
          if (ind + 1 < completedItems.length) {
            completedItems[ind + 1] = true;
          }
        }


        // completedItems[completedCheckList.length - 1] = true;
        // completedItems[completedCheckList.length] = true;


        if (completedItems[index]) {
          return { pointerEvents: "" };
        } else {
          return { pointerEvents: "none" };
        }
      }
    } else {
      if (item != "Application Details") {
        return { pointerEvents: "none" };
      }
    }
  }

  function isPreviewVisible(item) {
    if (newStatus?.appChecklistItems) {
      let completedCheckList =
        newStatus?.appChecklistItems?.split(";");
      if (completedCheckList) {
        if (completedCheckList.includes(item)) {
          return true;
        } else {
          return false;
        }
      }
    }
    return false;
  }

  function getActiveTab(item1, item2) {

    if (newStatus?.appChecklistItems !== null) {
      let completedCheckList = newStatus?.appChecklistItems?.split(";");

      if (completedCheckList) {
        return completedCheckList.includes(item2) ? (
          <span>
            <i class="fa-solid fa-check"></i>
          </span>
        ) : activeTab == item1 ? (
          <span className="pink">
            <i class="fa-regular fa-clock"></i>
          </span>
        ) : (
          <span>
            <i class="fa-solid fa-list-check"></i>
          </span>
        );
      } else {
        return <span>
          <i class="fa-solid fa-check"></i>
        </span>;
      }
    }
    else {
      return activeTab == item1 ? (
        <span className="pink">
          <i class="fa-regular fa-clock"></i>
        </span>
      ) : (
        <span>
          <i class="fa-solid fa-list-check"></i>
        </span>
      );
    }
  }

  return (
    <div class="sidebar">
      <h4 class="panelhead">
        Checklist Progress{" "}
        <strong>{newStatus?.appChecklistPercentageCompletion}% Complete</strong>
      </h4>

      <ProgressBar
        bgColor={"green"}
        height={"16px"}
        labelSize={"12px"}
        className="progressbar"
        completed={newStatus?.appChecklistPercentageCompletion}
      />

      {unsavedValues && <p>You have unsaved changes</p>}

      <ul>
        <li
          className={`${activeTab == "Application Details" ? "active" : ""}`}
          onClick={handleClick}
        >
          <NavLink
            style={disabledItem("Application Details", 0)}
            to={
              dashdata?.programName === "Undergraduate Program"
                ? PATHS.APPLICATIONDETAILUG_STR + newStatus?.appId
                : dashdata?.programName === "Postgraduate Program"
                  ? PATHS.APPLICATIONDETAILPG_STR + newStatus?.appId
                  : PATHS.APPLICATIONDETAILDIPLOMA_STR + newStatus?.appId
            }
          >
            {getActiveTab("Application Details", "Application Details")}
            Application Details
          </NavLink>
        </li>
        <li
          className={`${activeTab == "Education Details" ? "active" : ""}`}
          onClick={handleClick}
        >
          <NavLink
            style={disabledItem("Education Details", 1)}
            to={
              dashdata?.programName === "Undergraduate Program"
                ? PATHS.EDUCATIONDETAILUG_STR + newStatus?.appId
                : dashdata?.programName === "Postgraduate Program"
                  ? PATHS.EDUCATIONDETAILPG_STR + newStatus?.appId
                  : PATHS.EDUCATIONDIPLOMA_STR + newStatus?.appId
            }
          >
            {getActiveTab(
              // "education-detail-form", 
              "Education Details",
              "Education Details")}{" "}
            Education Details
          </NavLink>
        </li>
        <li
          className={`${activeTab == "social-economic-background" ? "active" : ""
            }`}
          onClick={handleClick}
        >
          <NavLink
            style={disabledItem("Socio-Economic Background", 2)}
            to={
              dashdata?.programName === "Undergraduate Program"
                ? PATHS.SOCIALECOUG_STR + newStatus?.appId
                : dashdata?.programName === "Postgraduate Program"
                  ? PATHS.SOCIALECOPG_STR + newStatus?.appId
                  : PATHS.SOCIODIPLOMA_STR + newStatus?.appId
            }
          >
            {getActiveTab(
              "social-economic-background",
              "Socio-Economic Background"
            )}{" "}
            Socio-Economic Background
          </NavLink>
        </li>
        <li
          className={`${activeTab == "upload-photo" ? "active" : ""}`}
          onClick={handleClick}
        >
          <NavLink
            style={disabledItem("Upload Photograph", 3)}
            to={
              dashdata?.programName === "Undergraduate Program"
                ? PATHS.UPLOADPHOTOUG_STR + newStatus?.appId
                : dashdata?.programName === "Postgraduate Program"
                  ? PATHS.UPLOADPHOTOPG_STR + newStatus?.appId
                  : PATHS.UPLOADPHOTODIPLOMA_STR + newStatus?.appId
            }
          >
            {getActiveTab("upload-photo", "Upload Photograph")}{" "}
            Upload Photograph and Documents
          </NavLink>
        </li>

        {isPreviewVisible("Upload Photograph") && (
          <li
            className={`${activeTab == "preview" ? "pink" : ""}`}
            onClick={handleClick}
          >
            <NavLink
              // style={disabledItem('Preview',4)}
              to={
                dashdata?.programName === "Undergraduate Program"
                  ? PATHS.PREVIEWUG_STR + newStatus?.appId
                  : dashdata?.programName === "Postgraduate Program"
                    ? PATHS.PREVIEWPG_STR + newStatus?.appId
                    : PATHS.PREVIEWDIPLOMA_STR + newStatus?.appId
              }
            >
              <span><i class="fa-solid fa-check"></i>Preview</span>
            </NavLink>
          </li>
        )}
        <li
          className={`${activeTab == "Payment Details" ? "active" : ""}`}
          onClick={handleClick}
        >
          <NavLink
            style={{
              pointerEvents: checkItem[4] !== "Payment Details" ? "none" : ""
            }}
            to={
              dashdata?.programName === "Undergraduate Program"
                ? PATHS.PAYMENTDETAILSUG
                : dashdata?.programName === "Postgraduate Program"
                  ? PATHS.PAYMENTDETAILSUG
                  : PATHS.PAYMENTDETAILSUG_STR + newStatus?.appId
            } >
            {getActiveTab("Payment Details", "Payment Details")}
            Payment Details
          </NavLink>
        </li>
      </ul>
    </div>


  );
};

export default Sidebar;
