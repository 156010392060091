import React, { useState, useEffect } from "react";
import Home from "../Home";
import { Tooltip } from "react-tooltip";
import { getCapital, getCapitalized, numberToWords } from "../../utils/Helper";
import { HELP_TEXT, MA_EDUCATION, PATHS } from "../../utils";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { getUgPreview } from "../../redux/actions/Api";
import moment from "moment";
import { Notes } from "../common/Notes";
import Loader from "../Loader";
import {
  ApplicationViewPage,
  PGApplicationDetails,
  PGEducationDetails,
  PGSocialEcoDetails,
  PGUploadDocuments,
  RegistrationPage,
  UGApplicationDetails,
} from "../constantLabelFile";
let numToWrd = "";
let setCuetImage
let setDisabilityImage;
let setDisabilityLink;
let setCuetLink;

let setImageShow;
const Preview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { previewugresponse, dashboardresponse, error } = useSelector(
    (state) => state.collections
  );

  const { register, setValue, control } = useForm();
  const imageRef = React.useRef(null);
  const [regVal, setRegVal] = useState("");
  const [appVal, setAppVal] = useState("");
  const [eduVal, setEduVal] = useState("");
  const [socio, setSocio] = useState("");
  const [doc, setDoc] = useState("");
  const [programType, setProgramType] = useState("");
  const [cuetExam, setCuetExam] = useState();
  const [satExam, setSatExam] = useState();


  // const [cuetImage, setCuetImage] = useState();
  const [apuCuetYear, setCUETYear] = useState();
  const [disability, setDisability] = useState();

  const [pleaseSpecify, setpleaseSpecify] = useState("");
  const [readOnly, setReadOnly] = useState(false);
  const [showMsg, setShowMsg] = useState("");
  const [loader, setLoader] = useState(true);
  const [lang, setLang] = useState("");
  const [skills, setSkills] = useState([]);
  const [needSupport, setNeedSupport] = useState("");


  const [photoGraphData, setPhotoGraphData] = useState('');
  const [cuetData, setCuetData] = useState('');
  const [disabilityData, setDisabilityData] = useState('');
  useEffect(() => {
    if (
      error?.message === "Unauthorized: Invalid or expired token." ||
      error?.validToken === false
    ) {
      setLoader(false);
    }
  }, [error]);

  useEffect(() => {
    if (previewugresponse === undefined) {
      setLoader(true);
    } else {
      setTimeout(() => {
        setLoader(false);
      }, 2000);
    }
  }, [previewugresponse]);

  useEffect(() => {
    if (dashboardresponse) {
      setProgramType(
        dashboardresponse?.data?.allDataOnDashboard[0]?.programName
      );
    }
  }, [dashboardresponse]);

  useEffect(() => {
    let formData = {
      contactId: localStorage.getItem("contactId"),
      applicationId: localStorage.getItem("applicationId"),
      programType: dashboardresponse?.data?.allDataOnDashboard[0]?.programName,
    };
    // if (programType && formData) {
    dispatch(getUgPreview(formData));
    // }
  }, [programType]);

  useEffect(() => {
    if (previewugresponse) {
      setRegVal(previewugresponse?.data?.registrationDetails);
      setAppVal(previewugresponse?.data?.applicationDetails);
      setEduVal(previewugresponse?.data?.educationDetailsPG);
      setSocio(previewugresponse?.data?.socioEconomicDetails);
      setDoc(previewugresponse?.data?.photoDocDetails);
      setCUETYear(previewugresponse?.data?.photoDocDetails?.APU_CUET_Year);
      setDisability(previewugresponse?.data?.photoDocDetails?.IsDisable);

      setPhotoGraphData(previewugresponse?.data?.photoDocDetails?.photoGraphFile)
      setCuetData(previewugresponse?.data?.photoDocDetails?.cuetFile)
      setDisabilityData(previewugresponse?.data?.photoDocDetails?.disabiltyFile)
    }
  }, [previewugresponse]);

  useEffect(() => {
    if (doc) {
      if (previewugresponse?.data?.photoDocDetails) {
        setImageShow = photoGraphData?.VersionData;
        setCuetImage =cuetData?.Title;
        setDisabilityImage = disabilityData?.Title;
        setDisabilityLink = disabilityData?.link
        setCuetLink = cuetData?.link
      }
    }
  }, [doc, previewugresponse?.data?.photoDocDetails]);

  useEffect(() => {
    setValue(
      "programGroup",
      regVal?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Type__c
    );
    setValue(
      "admissionCycle",
      regVal?.hed__Applications_Applicant__r?.records[0]?.Admission_Cycle__r
        ?.hed__Description__c
    );
    setCuetExam(
      regVal?.hed__Applications_Applicant__r?.records[0]?.Admission_Cycle__r
        ?.APU_CUET_Exam__c
    );
    setSatExam(
      regVal?.hed__Applications_Applicant__r?.records[0]?.Admission_Cycle__r
        ?.SAT__c
    );
    setValue("firstName", regVal?.FirstName);
    setValue("middleName", regVal?.MiddleName);
    setValue("lastName", regVal?.LastName);
    setValue("dateOfBirth", moment(regVal?.Birthdate).format("DD MMM, YYYY"));
    setValue("mobile", regVal?.MobilePhone);
    setValue(
      "relationship",
      regVal?.hed__Relationships__r?.records[0]?.hed__Type__c
    );
    setValue(
      "relationName",
      regVal?.hed__Relationships__r?.records[0]?.hed__RelatedContact__r?.Name
    );
    setValue(
      "relationMobno",
      regVal?.hed__Relationships__r?.records[0]?.hed__RelatedContact__r
        ?.MobilePhone
    );
    setValue(
      "relationEmail",
      regVal?.hed__Relationships__r?.records[0]?.hed__RelatedContact__r?.Email
    );
    setValue(
      "houseNo",
      regVal?.hed__Addresses__r?.records[0]?.hed__MailingStreet__c
    );
    setValue(
      "area",
      regVal?.hed__Addresses__r?.records[0]?.hed__MailingStreet2__c
    );
    setValue(
      "country",
      regVal?.hed__Addresses__r?.records[0]?.hed__MailingCountry__c
    );
    setValue(
      "state",
      regVal?.hed__Addresses__r?.records[0]?.hed__MailingState__c
    );
    setValue(
      "district",
      regVal?.hed__Addresses__r?.records[0]?.hed__MailingCounty__c
    );
    setValue(
      "city",
      regVal?.hed__Addresses__r?.records[0]?.hed__MailingCity__c
    );
    setValue(
      "pin",
      regVal?.hed__Addresses__r?.records[0]?.hed__MailingPostalCode__c
    );
    setValue(
      "building",
      regVal?.hed__Addresses__r?.records[1]?.hed__MailingStreet__c
    );
    setValue(
      "road",
      regVal?.hed__Addresses__r?.records[1]?.hed__MailingStreet2__c
    );
    setValue(
      "pCountry",
      regVal?.hed__Addresses__r?.records[1]?.hed__MailingCountry__c
    );
    setValue(
      "pState",
      regVal?.hed__Addresses__r?.records[1]?.hed__MailingState__c
    );
    setValue(
      "pDistrict",
      regVal?.hed__Addresses__r?.records[1]?.hed__MailingCounty__c
    );
    setValue(
      "pCity",
      regVal?.hed__Addresses__r?.records[1]?.hed__MailingCity__c
    );
    setValue(
      "pPin",
      regVal?.hed__Addresses__r?.records[1]?.hed__MailingPostalCode__c
    );

    setValue("know", regVal?.How_did_you_get_to_know_about_APU__c);
    setValue("specify", regVal?.Specific_Answer__c);
    setpleaseSpecify(regVal?.Specific_Answer__c);

    // setValue("", regVal?.How_did_you_get_to_know_about_APU__c);
    // application detail
    setValue("nationality", appVal?.hed__Country_of_Origin__c);
    setValue("domicile", appVal?.Domicile__c);
    setValue("gender", appVal?.hed__Gender__c);
    setValue(
      "prevApplied",
      appVal?.hed__Applications_Applicant__r?.records[0]
        ?.Applied_for_earlier_cycles__c
    );
    setValue(
      "yearApplied",
      appVal?.hed__Applications_Applicant__r?.records[0]?.Year_Applied_In__c
    );
    setValue(
      "language",
      appVal?.hed__Contact_Languages__r?.records[0]?.hed__Language__r?.Name
    );
    setValue(
      "spoken",
      appVal?.hed__Contact_Languages__r?.records[0]?.Speaking__c
    );
    setValue(
      "writing",
      appVal?.hed__Contact_Languages__r?.records[0]?.Writing__c
    );
    setValue(
      "reading",
      appVal?.hed__Contact_Languages__r?.records[0]?.Reading__c
    );
    setValue(
      "programApplying",
      appVal?.hed__Applications_Applicant__r?.records[0]?.First_Preference__r
        ?.Name
    );
    setValue(
      "campus1",
      appVal?.hed__Applications_Applicant__r?.records[0]
        ?.Campus_Preferences_1__r?.Name
    );
    setValue(
      "programPrefer",
      appVal?.hed__Applications_Applicant__r?.records[0]?.Second_Preference__r?.Name
    );
    setValue(
      "campus2",
      appVal?.hed__Applications_Applicant__r?.records[0]
        ?.Campus_Preferences_2__r?.Name
    );
    setValue("considerAdmissionInAnyCampus", appVal?.hed__Applications_Applicant__r?.records[0]?.APU_consider_admission_in_any_campus__c)
    
    setValue(
      "testCenter",
      appVal?.hed__Applications_Applicant__r?.records[0]
        ?.First_Test_Center_Prefrence__r?.Name
    );
    setValue(
      "interviewCenter",
      appVal?.hed__Applications_Applicant__r?.records[0]
        ?.Preferred_Interview_center__r?.Name
    );
    setValue(
      "subjectAreaPreference_1",
      appVal?.hed__Applications_Applicant__r?.records[0]
        ?.Subject_Area_preference_1__c
    );
    setValue(
      "subjectAreaPreference_2",
      appVal?.hed__Applications_Applicant__r?.records[0]
        ?.Subject_Area_preference_2__c
    );

    // education detail
    setValue(
      "bachelorsCompletedByAug",
      eduVal?.hed__Education_History__r?.records[1]
        ?.Bachelors_complete_by_Aug1_NextYear__c
    );
    setValue(
      "qualificationsObtained",
      eduVal?.hed__Education_History__r?.records[1]?.Qualification_obtained__c
    );
    setValue(
      "completionYear",
      eduVal?.hed__Education_History__r?.records[1]?.Year_of_completion__c
    );
    setValue(
      "HaveUGEconomicsPassed",
      eduVal?.hed__Education_History__r?.records[1]
        ?.Have_you_passed_your_UG_Economics__c
    );
    setValue(
      "durationUndergraduateProg",
      eduVal?.hed__Education_History__r?.records[1]?.APU_Duration_of_your_UG__c
    );
    setValue(
      "townCategoryTenth",
      eduVal?.hed__Education_History__r?.records[0]
        ?.Category_of_city_town_where_studied__c
    );
    setValue(
      "countryTenth",
      eduVal?.hed__Education_History__r?.records[0]?.Country__c
    );
    setValue(
      "otherCountrySpecifyTenth",
      eduVal?.hed__Education_History__r?.records[0]?.Please_Specify_Country__c
    );
    setValue(
      "mediumInstTenth",
      eduVal?.hed__Education_History__r?.records[0]?.Medium_of_instruction__c
    );
    setValue(
      "specifyMediumTenth",
      eduVal?.hed__Education_History__r?.records[0]
        ?.Please_Specify_Medium_of_Instruction__c
    );
    setValue(
      "stateTenth",
      eduVal?.hed__Education_History__r?.records[0]?.State__c
    );
    setValue(
      "districtTenth",
      eduVal?.hed__Education_History__r?.records[0]?.District_Name__c
    );
    setValue(
      "townCategoryGrad",
      eduVal?.hed__Education_History__r?.records[1]
        ?.Category_of_city_town_where_studied__c
    );
    setValue(
      "gradMode",
      eduVal?.hed__Education_History__r?.records[1]?.Mode_of_graduation__c
    );
    setValue(
      "streamSpecialization",
      eduVal?.hed__Education_History__r?.records[1]?.Stream_Specialization__c
    );
    setValue(
      "universityName",
      eduVal?.hed__Education_History__r?.records[1]?.Name_of_the_University__c
    );
    setValue(
      "schoolNameGrad",
      eduVal?.hed__Education_History__r?.records[1]
        ?.hed__Educational_Institution_Name__c
    );
    setValue(
      "countryGrad",
      eduVal?.hed__Education_History__r?.records[1]?.Country__c
    );
    setValue(
      "otherCountrySpecifyGrad",
      eduVal?.hed__Education_History__r?.records[1]?.Please_Specify_Country__c
    );
    setValue(
      "stateGrad",
      eduVal?.hed__Education_History__r?.records[1]?.State__c
    );
    setValue(
      "districtGrad",
      eduVal?.hed__Education_History__r?.records[1]?.District_Name__c
    );
    setValue(
      "mediumInstGrad",
      eduVal?.hed__Education_History__r?.records[1]?.Medium_of_instruction__c
    );
    setValue(
      "specifyMediumGrad",
      eduVal?.hed__Education_History__r?.records[1]
        ?.Please_Specify_Medium_of_Instruction__c
    );
    setValue(
      "havePGDegree",
      eduVal?.hed__Education_History__r?.records[1]
        ?.Do_you_have_any_Postgraduate_degree__c
    );
    setValue(
      "qualificationName",
      eduVal?.hed__Education_History__r?.records[1]?.Name_on_Qualification__c
    );

    setValue("workExperience", eduVal?.Work_experience_after_gr__c);
    setValue("workExperienceInMonths", eduVal?.Work_experience_in_months__c);
    setValue("AreCurrentlyEmployed", eduVal?.Are_you_currently_employed__c);
    setValue("organization", eduVal?.Organization_where_you_are__c);
    setValue("typeOfOrganization", eduVal?.Type_of_organization__c);
    setValue("monthlyGrossSalary", eduVal?.Monthly_gross_salary_Rs__c);
    setValue("annualGrossSalary", eduVal?.Annual_gross_salary_Rs__c);
    // socio eco detail
    setValue("fatherEdu", socio?.Father_Education_Details__c);
    setValue("motherEdu", socio?.Mother_Education_Details__c);
    setValue("caste", socio?.Caste__c);
    setValue("religion", socio?.hed__Religion__c);
    setValue("famlOccupation", socio?.Main_Occupation_of_family__c);
    setValue("famlIncome", socio?.Family_income_per_year__c);
    setValue("annualIncome", socio?.Exact_Annual_income__c);
    numToWrd = socio?.Exact_Annual_income__c;

    setValue("financialAssis", socio?.Do_you_need_Financial_Assistance__c);
    setValue("disability", socio?.Are_you_a_person_with_disability__c);
    setValue("disabilityNature", socio?.Nature_of_Disability__c);
    setValue("specifyNature", socio?.Please_Specify_Disability__c);
    // setValue("scribe", socio?.Is_scribe_needed_for_the_writing_test__c);
    // setValue("specificSupport", socio?.What_kind_of_support_u_r_expecting__c);
    setValue(
      "needSpecificSupport",
      socio?.APU_need_any_specific_support__c
        ? socio?.APU_need_any_specific_support__c.split(";")
        : ""
    );
    setSkills(socio?.APU_need_any_specific_support__c);
    setValue("needSupportWrite", socio?.APU_need_any_support_to_write_test__c);
    setNeedSupport(socio?.APU_need_any_support_to_write_test__c);
    setValue("supportExpecting", socio?.What_kind_of_support_u_r_expecting__c);
    setValue("moreDetails", socio?.Do_you_need_any_help_from_Admission_Team__c);
    setValue("APU_CUET_Year", doc?.APU_CUET_Year);
    setValue(
      "APU_CUET_Language",
      doc?.Language_in_which_the_CUET_test_is_taken
    );
    setLang(doc?.Language_in_which_the_CUET_test_is_taken);
  }, [previewugresponse, regVal, appVal, eduVal, socio]);

  const handleClick = () => {
    navigate(
      PATHS.PAYMENTDETAILSUG_STR +
        dashboardresponse?.data?.allDataOnDashboard[0]?.appId
    );
  };

  const currentDate = new Date();
  const year1 = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDate = `${year1}-${month}-${day}`;

  useEffect(() => {
    if (
      regVal?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Started" ||
      regVal?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Draft" ||
      regVal?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Payment Failed" ||
      regVal?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Payment Pending" ||
      regVal?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Payment Submitted"
    ) {
      if (
        formattedDate >
        regVal?.hed__Applications_Applicant__r?.records[0]?.Admission_Cycle__r
          ?.Application_Submission_Date__c
      ) {
        setReadOnly(true);
        setShowMsg(<Notes name="ADM_Cycle_Validation_App" />);
      } else {
        setReadOnly(false);
        setShowMsg("");
      }
    } else {
      if (
        formattedDate <
        regVal?.hed__Applications_Applicant__r?.records[0]?.Admission_Cycle__r
          ?.Application_Submission_Date__c
      ) {
        setReadOnly(true);
        setShowMsg("");
      } else {
        setReadOnly(true);
        setShowMsg(<Notes name="ADM_Cycle_Validation_App" />);
      }
    }
  }, [regVal]);

  function notesVisible() {
    if (
      regVal?.hed__Applications_Applicant__r?.records[0]?.Admission_Cycle__r
        ?.APU_CUET_Exam__c &&
      regVal?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Type__c === "Postgraduate"
    ) {
      return (
        <div className="w100">
          <span className="earlyadmin">
            <Notes name="Cuet_Program_Note" />
            <Notes name="Cuet_Program_Note_3_URL"></Notes>
            <Notes name="APU_CUET_UG_NOTE" />
          </span>{" "}
          <br />
          <br />
          {regVal?.hed__Applications_Applicant__r?.records[0]
            ?.Admission_Cycle__r?.SAT__c && (
            <span className="earlyadmin">
              <Notes name="APU_SAT_NOTE" />{" "}
            </span>
          )}
        </div>
      );
    } else if (
      regVal?.hed__Applications_Applicant__r?.records[0]?.Admission_Cycle__r
        ?.SAT__c &&
      regVal?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Type__c === "Postgraduate"
    ) {
      return (
        <div className="w100">
          <span className="earlyadmin">
            <Notes name="APU_SAT_NOTE" />{" "}
          </span>
        </div>
      );
    } else if (
      regVal?.hed__Applications_Applicant__r?.records[0]?.Admission_Cycle__r
        ?.SAT__c
    ) {
      return (
        <div className="w100">
          <span className="earlyadmin">
            <Notes name="APU_SAT_NOTE" />{" "}
          </span>
        </div>
      );
    }
  }

  return (
    <Home>
      <div class="right_panel">
        <div class="w100 dflex">
          {/* <h4>{getCapitalized("Postgraduate programme")}</h4> */}
          <h4>{getCapitalized(PGApplicationDetails.Postgraduate_Programme)}</h4>
          <span className="help_link">
            <p>
              {/* <NavLink to={PATHS.RAISEQUERY}>Help for this page</NavLink>{" "} */}
              <NavLink to={PATHS.RAISEQUERY}>{HELP_TEXT}</NavLink>{" "}
              <Tooltip id="educationhelp">
                {/* <p>Click to Raise Query</p> */}
                <p>{ApplicationViewPage.Help_Tooltip_Text}</p>
              </Tooltip>
              <i
                data-tooltip-id="educationhelp"
                class="fa-solid fa-circle-info"
              ></i>
            </p>
          </span>
        </div>

        <div className="w100 scroll_styl">
          <div class="heading_styl head_font">
            {/* <h4>{getCapitalized("Preview")}</h4> */}
            <h4>{getCapitalized(ApplicationViewPage.Preview)}</h4>
          </div>
          <Loader show={loader} />
          <div class="form_styl">
            <div class="w100">
              {/* <p>
                Please review the information thoroughly before submitting the form. Please note that no changes will be allowed after submission except programme and center changes, which can be updated till a cut-off date after submission.
              </p> */}
              <Notes name="PreviewOnTopMessage" />
            </div>
          </div>
          <div class="heading_styl">
            {/* <h4>Application Registration</h4> */}
            <h4>{RegistrationPage.Application_Registration}</h4>
          </div>

          <div class="form_part">
            <a href="" target="_blank">
              <Notes name="Application_Programme" size={"0.65rem"} />
            </a>

            <div class="form_sec">
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {/* Program Group <span className="redstyl">*</span> */}
                    {RegistrationPage.Program_Group}{" "}
                    <span className="redstyl">*</span>
                    <Tooltip id="programTool">
                      <Notes name="Program_Group" />
                    </Tooltip>
                    <i
                      data-tooltip-id="programTool"
                      class="fa-solid fa-circle-info"
                    ></i>
                  </label>
                  <input {...register("programGroup")} disabled={true} />
                </div>
                <div class="inner_part">
                  <label>
                    {/* Admission Cycle <span className="redstyl">*</span> */}
                    {RegistrationPage.Admission_Cycle}{" "}
                    <span className="redstyl">*</span>
                  </label>
                  <input {...register("admissionCycle")} disabled={true} />
                </div>
              </div>

              {notesVisible()}

              {/* <p><strong>Note:</strong> Click the link to know the programs offered in Bangalore and
                Bhopal campus. </p> */}
              <div class="heading_styl">
                {/* <h4>Personal Details</h4> */}
                <h4>{RegistrationPage.Personal_Details}</h4>
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {/* First Name <span className="redstyl">*</span> */}
                    {RegistrationPage.First_Name}{" "}
                    <span className="redstyl">*</span>
                  </label>
                  <input {...register("firstName")} disabled={true} />
                </div>
                <div class="inner_part">
                  {/* <label>Middle Name </label> */}
                  <label>{RegistrationPage.Middle_Name}</label>
                  <input {...register("middleName")} disabled={true} />
                </div>
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {/* Last Name <span class="redstyl">* </span> */}
                    {RegistrationPage.Last_Name} <span class="redstyl">* </span>
                  </label>
                  <input {...register("lastName")} disabled={true} />
                </div>
                <div class="inner_part">
                  <label>
                    {/* Date of Birth <span class="redstyl">* </span> */}
                    {RegistrationPage.DOB} <span class="redstyl">* </span>
                  </label>
                  <input {...register("dateOfBirth")} disabled={true} />
                </div>
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {/* Mobile Number(for SMS notifiications):{" "}
                    <span class="redstyl">* </span> */}
                    {RegistrationPage.Mobile_Number_SMS}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <input {...register("mobile")} disabled={true} />
                </div>
              </div>
              <div class="heading_styl">
                {/* <h4>Alternative Contact</h4> */}
                <h4>{RegistrationPage.Alternative_Contact}</h4>
              </div>
              <p
                style={{
                  color: "#00115e",
                  fontWeight: 600,
                  paddingTop: "8px",
                  paddingBottom: "8px",
                }}
              >
                Please make sure the email and mobile entered here is regularly
                checked for updates.
              </p>

              <div class="form_styl">
                <div class="inner_part1 b_none">
                  <label>
                    {/* Relationship <span class="redstyl">* </span> */}
                    {RegistrationPage.Relationship}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <input {...register("relationship")} disabled={true} />
                </div>
                <div class="inner_part1 b_none">
                  <label>
                    {/* Name <span class="redstyl">* </span> */}
                    {RegistrationPage.Name} <span class="redstyl">* </span>
                  </label>
                  <input {...register("relationName")} disabled={true} />
                </div>
                <div class="inner_part1 b_none">
                  <label>
                    {/* Mobile No. <span class="redstyl">* </span> */}
                    {RegistrationPage.Mobile_No} <span class="redstyl">* </span>
                  </label>
                  <input {...register("relationMobno")} disabled={true} />
                </div>
                <div class="inner_part1 b_none">
                  <label>
                    {/* Email <span class="redstyl">* </span> */}
                    {RegistrationPage.Email} <span class="redstyl">* </span>
                  </label>
                  <input {...register("relationEmail")} disabled={true} />
                </div>
              </div>

              <div class="form_styl">
                <div class="form_sec_inner">
                  <div class="heading_styl mb-2">
                    {/* <h4>Communication Address</h4> */}
                    <h4>{RegistrationPage.Communication_Address}</h4>
                  </div>
                  <div class="w100 ">
                    <div class="inner_part2 mt-3">
                      <label>
                        {/* House No/Building: <span class="redstyl">* </span> */}
                        {RegistrationPage.House_No}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <input {...register("houseNo")} disabled={true} />
                    </div>
                    <div class="inner_part2">
                      <label>
                        {/* Area/Road/Street/P.O: <span className="redstyl">*</span> */}
                        {RegistrationPage.Area_Road}{" "}
                        <span className="redstyl">*</span>
                      </label>
                      <input {...register("area")} disabled={true} />
                    </div>
                  </div>
                  <div class="w100 form_styl ">
                    <div class="inner_part">
                      <label>
                        {/* Country : <span className="redstyl">*</span> */}
                        {RegistrationPage.Country}{" "}
                        <span className="redstyl">*</span>
                      </label>
                      <input {...register("country")} disabled={true} />
                    </div>
                    <div class="inner_part">
                      <label>
                        {/* State/Union Territory : <span class="redstyl">*</span> */}
                        {RegistrationPage.State_Union}{" "}
                        <span class="redstyl">*</span>
                      </label>
                      <input {...register("state")} disabled={true} />
                    </div>
                  </div>
                  <div class="w100 ">
                    <div class="inner_part2">
                      <label>
                        {/* District: <span class="redstyl">* </span> */}
                        {RegistrationPage.District}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <input {...register("district")} disabled={true} />
                    </div>

                    <div class="inner_part2">
                      <label>
                        {/* City: <span class="redstyl">* </span> */}
                        {RegistrationPage.City} <span class="redstyl">* </span>
                      </label>
                      <input {...register("city")} disabled={true} />
                    </div>
                    <div class="inner_part2">
                      <label>
                        {/* Pin: <span class="redstyl">* </span> */}
                        {RegistrationPage.Pin} <span class="redstyl">* </span>
                      </label>
                      <input {...register("pin")} disabled={true} />
                    </div>
                  </div>
                </div>
                <div class="form_sec_inner">
                  <div class="heading_styl">
                    {/* <h4>Permanent Address</h4> */}
                    <h4>{RegistrationPage.Permanent_Address}</h4>
                  </div>
                  <div class="w100 ">
                    <div class="flex">
                      <input
                        type="checkbox"
                        disabled
                        checked={
                          regVal?.Check_if_same_as_Communication_Address__c
                        }
                        placeholder="Enter name..."
                      />{" "}
                      <label class="f_size">
                        {/* Check if same as Communication Address */}
                        {RegistrationPage.Permanent_Address_Same_Message}
                      </label>
                    </div>
                    <div class="inner_part2">
                      <label>
                        {/* House No/Building: <span class="redstyl">* </span> */}
                        {RegistrationPage.House_No}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <input {...register("building")} disabled={true} />
                    </div>
                    <div class="inner_part2">
                      <label>
                        {/* Area/Road/Street/P.O: <span class="redstyl">* </span> */}
                        {RegistrationPage.Area_Road}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <input {...register("road")} disabled={true} />
                    </div>
                  </div>
                  <div class="w100 form_styl ">
                    <div class="inner_part">
                      <label>
                        {/* Country : <span class="redstyl">* </span> */}
                        {RegistrationPage.Country}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <input {...register("pCountry")} disabled={true} />
                    </div>
                    <div class="inner_part">
                      <label>
                        {/* State/Union Territory : <span class="redstyl">* </span> */}
                        {RegistrationPage.State_Union}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <input {...register("pState")} disabled={true} />
                    </div>
                  </div>
                  <div class="w100 ">
                    <div class="inner_part2">
                      <label>
                        {/* District: <span class="redstyl">* </span> */}
                        {RegistrationPage.District}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <input {...register("pDistrict")} disabled={true} />
                    </div>
                    <div class="inner_part2">
                      <label>
                        {/* City: <span class="redstyl">* </span> */}
                        {RegistrationPage.City} <span class="redstyl">* </span>
                      </label>
                      <input {...register("pCity")} disabled={true} />
                    </div>
                    <div class="inner_part2">
                      <label>
                        {/* Pin: <span class="redstyl">* </span> */}
                        {RegistrationPage.Pin} <span class="redstyl">* </span>
                      </label>
                      <input {...register("pPin")} disabled={true} />
                    </div>
                  </div>
                </div>
              </div>

              <div class="form_styl1">
                <div class="inner_part">
                  <label>
                    {/* How did you get to know about Azim Premji University? :{" "}
                    <span className="redstyl">* </span> */}
                    {RegistrationPage.Hear_About_APU}{" "}
                    <span className="redstyl">* </span>
                  </label>
                  <input {...register("know")} disabled />
                  {pleaseSpecify && (
                    <div class="inner_part w66 m_auto">
                      <label>
                        {/* Please Specify: <span className="redstyl">* </span> */}
                        {RegistrationPage.Please_Specify}{" "}
                        <span className="redstyl">* </span>
                      </label>
                      <input {...register("specify")} disabled />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div class="form_sec">
              <div class="heading_styl">
                {/* <h4>APPLICATION DETAILS</h4> */}
                <h4>{PGApplicationDetails.Application_Details}</h4>
              </div>
              <div class="w100">
                {/* <p>
                    Click here to check the eligibility criteria for
                    Postgraduate Programmes at Azim Premji University.
                  </p> */}
                <a
                  href="https://azimpremjiuniversity.edu.in/postgraduate/application-process-and-timeline"
                  className="linkcheckegi"
                  target="_blank"
                >
                  {/* Click here to check the eligibility criteria for Postgraduate
                  Programmes at Azim Premji University */}
                  {PGApplicationDetails.Eligibility_Criteria_Message}
                </a>{" "}
              </div>
              <div class="heading_styl">
                {/* <h4>Background Details</h4> */}
                <h4>{PGApplicationDetails.Background_Details}</h4>
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {/* Nationality <span class="redstyl">* </span> */}
                    {PGApplicationDetails.Nationality}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <input {...register("nationality")} disabled={true} />
                </div>
                <div class="inner_part">
                  <label>
                    {/* Domicile <span class="redstyl">* </span> */}
                    {PGApplicationDetails.Domicile}{" "}
                    <span class="redstyl">* </span>
                    {/* <Tooltip id="domicileTooltip">                      
                      <Notes name='Domicile_Text' />
                    </Tooltip>
                    <i
                      data-tooltip-id="domicileTooltip"
                      class="fa-solid fa-circle-info"
                      style={{ color: "gray" }}
                    ></i> */}
                  </label>
                  <input {...register("domicile")} disabled={true} />
                </div>
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {/* Gender <span class="redstyl">* </span> */}
                    {PGApplicationDetails.Gender}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <input {...register("gender")} disabled={true} />
                </div>
                <div class="inner_part">
                  <label>
                    {/* Have you applied to us previously?{" "}
                    <span class="redstyl">* </span> */}
                    {PGApplicationDetails.Applied_Before}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <input {...register("prevApplied")} disabled={true} />
                </div>
              </div>
              {appVal?.hed__Applications_Applicant__r?.records[0]
                ?.Applied_for_earlier_cycles__c === "Yes" && (
                <div className="form_styl">
                  <div className="inner_part">
                    <label>
                      {/* Applied Year <span>*</span> */}
                      {PGApplicationDetails.Applied_Year} <span>*</span>
                    </label>
                    <input {...register("yearApplied")} disabled={true} />
                  </div>
                </div>
              )}

              <div class="heading_styl">
                {/* <h4>Language Details</h4> */}
                <h4>{PGApplicationDetails.Language_Details}</h4>
              </div>

              <div class="form_styl">
                <div class="w100">
                  {/* <p>
                    Note: Any regional language that you are most comfortable with.
                    (Mention any language other than English)
                  </p> */}
                  <Notes name="Language" />
                </div>
              </div>
              <div class="form_styl mt-3">
                <div class="inner_part1">
                  <div class="heading_styl">
                    {/* <h4>Language</h4> */}
                    <h4>{PGApplicationDetails.Language}</h4>
                  </div>
                  <input
                    {...register("language")}
                    disabled={true}
                    className="mt-7"
                  />
                </div>
                <div class="inner_part1">
                  <div class="heading_styl">
                    {/* <h4>Spoken</h4> */}
                    <h4>{PGApplicationDetails.Spoken}</h4>
                  </div>
                  <input
                    {...register("spoken")}
                    disabled={true}
                    className="mt-7"
                  />
                </div>
                <div class="inner_part1">
                  <div class="heading_styl">
                    {/* <h4>Writing</h4> */}
                    <h4>{PGApplicationDetails.Writing}</h4>
                  </div>
                  <input
                    {...register("writing")}
                    disabled={true}
                    className="mt-7"
                  />
                </div>
                <div class="inner_part1">
                  <div class="heading_styl">
                    {/* <h4>Reading</h4> */}
                    <h4>{PGApplicationDetails.Reading}</h4>
                  </div>
                  <input
                    {...register("reading")}
                    disabled={true}
                    className="mt-7"
                  />
                </div>
              </div>

              <div class="heading_styl">
                {/* <h4>Program Preferences</h4> */}
                <h4>{PGApplicationDetails.Programme_Applying}</h4>
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {/* Programme applying for <span class="redstyl">* </span> */}
                    {UGApplicationDetails.Programme_Preference_1}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <input
                    {...register("programApplying")}
                    disabled={true}
                    className="mt-7"
                  />
                </div>
                <div class="inner_part">
                  <label>
                    {/* Campus <span class="redstyl">* </span> */}
                    {PGApplicationDetails.Campus}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <input
                    {...register("campus1")}
                    disabled={true}
                    className="mt-7"
                  />
                </div>
              </div>
              {appVal?.hed__Applications_Applicant__r?.records[0]
                ?.First_Preference__r?.Name === "M.A. Education" && (
                <>
                  <div class="form_styl">
                    <div class="inner_part">
                      <label>
                        {/* Subject Area Preference1<span class="redstyl">* </span> */}
                        {PGApplicationDetails.Subject_Area_Preference_1}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <input
                        {...register("subjectAreaPreference_1")}
                        disabled={true}
                        className="mt-7"
                      />
                    </div>
                  </div>
                  <div class="form_styl">
                    <div class="inner_part">
                      <label>
                        {/* Subject Area Preference2<span class="redstyl">* </span> */}
                        {PGApplicationDetails.Subject_Area_Preference_2}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <input
                        {...register("subjectAreaPreference_2")}
                        disabled={true}
                        className="mt-7"
                      />
                    </div>
                  </div>
                  <div class="form_styl">
                    <div class="inner_part">
                      <Notes name="Subject_Area_Note" />
                    </div>
                  </div>
                </>
              )}
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {/* Programme applying for <span class="redstyl">* </span> */}
                    {UGApplicationDetails.Programme_Preference_2}{" "}
                    <span class="redstyl">* </span>
                    <Tooltip id="progPreference">
                      <p>{ PGApplicationDetails.Programme_Preference_2_Tooltip_Message } </p>                                         
                    </Tooltip>
                    <i
                      data-tooltip-id="progPreference"
                      class="fa-solid fa-circle-info"
                    ></i>
                  </label>
                  <input
                    {...register("programPrefer")}
                    disabled={true}
                    className="mt-7"
                  />
                </div>
                <div class="inner_part">
                  <label>
                    {/* Campus <span class="redstyl">* </span> */}
                    {UGApplicationDetails.Campus}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <input
                    {...register("campus2")}
                    disabled={true}
                    className="mt-7"
                  />
                </div>
              </div>

              {appVal?.hed__Applications_Applicant__r?.records[0]?.Second_Preference__r?.Name === "M.A. Education" && (
                <>
                  <div class="form_styl">
                    <div class="inner_part">
                      <label>
                        {/* Subject Area Preference1<span class="redstyl">* </span> */}
                        {PGApplicationDetails.Subject_Area_Preference_1}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <input
                        {...register("subjectAreaPreference_1")}
                        disabled={true}
                        className="mt-7"
                      />
                    </div>
                  </div>
                  <div class="form_styl">
                    <div class="inner_part">
                      <label>
                        {/* Subject Area Preference2<span class="redstyl">* </span> */}
                        {PGApplicationDetails.Subject_Area_Preference_2}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <input
                        {...register("subjectAreaPreference_2")}
                        disabled={true}
                        className="mt-7"
                      />
                    </div>
                  </div>
                  <div class="form_styl">
                    <div class="inner_part">
                      <Notes name="Subject_Area_Note" />
                    </div>
                  </div>
                </>
              )}

<div class="form_styl">
              <div class="inner_part">
                      <label>
                        {PGApplicationDetails.Preference_Question}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <input
                        {...register("considerAdmissionInAnyCampus")} 
                        disabled={true}
                        />                      
                    </div>
              </div>
              <div class="form_styl">
                <div class="inner_part w100">
                  <Notes name="Consider_any_campus"/>
                </div>
              </div>

              <div class="heading_styl">
                {/* <h4>Center Preference</h4> */}
                <h4>{PGApplicationDetails.Center_Preference}</h4>
              </div>
              <div class="form_styl">
                <div class="inner_part w100">
                  <Notes name="Center_Preference_Help" />
                </div>
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {/* Preferred Test Center <span class="redstyl">* </span> */}
                    {PGApplicationDetails.Preferred_Test_Center}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <input
                    {...register("testCenter")}
                    disabled={true}
                    className="mt-7"
                  />
                </div>
                <div class="inner_part">
                  <label>
                    {/* Preferred Interview Center <span class="redstyl">* </span> */}
                    {PGApplicationDetails.Preferred_Interview_Center}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <input
                    {...register("interviewCenter")}
                    disabled={true}
                    className="mt-7"
                  />
                </div>
              </div>
            </div>

            <div class="form_sec">
              <div class="heading_styl">
                {/* <h4>{getCapitalized("Education Details")}</h4> */}
                <h4>{getCapitalized(PGEducationDetails.Education_Details)}</h4>
              </div>
              <div class="form_sec form_part">
                {/* <div class="heading_styl">                 
                  <h4>{PGEducationDetails.Bachelor_Details}</h4>
                </div> */}
                <div class="heading_styl">
                  {/* <h4>Class 10 Details</h4> */}
                  <h4>{PGEducationDetails.Class_10_Details}</h4>
                </div>

                <div class="form_styl">
                  <div class="w100 blc_txt">
                    <p>
                      Category A:{" "}
                      <i>
                        Bangalore, Chennai, Delhi, Kolkata, Mumbai, Pune,
                        Hyderabad and Chandigarh;
                      </i>
                    </p>
                    <p>
                      Category B:{" "}
                      <i>
                        Other state capital cities and cities with population
                        greater than 10 lakhs;
                      </i>
                    </p>
                    <p>
                      Category C:{" "}
                      <i>Cities with population less than 10 Lakhs</i>
                    </p>
                    <p>
                      Category D: <i>Rural</i>
                    </p>
                  </div>
                </div>
                <div class="form_styl">
                  <div class="inner_part">
                    <label>
                      {/* Category of city/town(10th){" "}
                      <span class="redstyl">* </span> */}
                      {PGEducationDetails.Category_Of_City_10}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input {...register("townCategoryTenth")} disabled />
                  </div>
                  <div class="inner_part">
                    <label>
                      {/* Country(10th) <span class="redstyl">* </span> */}
                      {PGEducationDetails.Country_10}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input {...register("countryTenth")} disabled />
                  </div>
                  {eduVal?.hed__Education_History__r?.records[0]?.Country__c ===
                    "Others" && (
                    <div class="inner_part">
                      <label>
                        {/* Please Specify <span>*</span> */}
                        {PGEducationDetails.Please_Specify} <span>*</span>
                      </label>
                      <input
                        {...register("otherCountrySpecifyTenth")}
                        disabled
                      />
                    </div>
                  )}
                </div>
                <div class="form_styl">
                  <div class="inner_part">
                    <label>
                      {/* Medium of Instruction(10th){" "}
                      <span class="redstyl">* </span> */}
                      {PGEducationDetails.Medium_Of_Instruction_10}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input {...register("mediumInstTenth")} disabled />
                  </div>
                  {eduVal?.hed__Education_History__r?.records[0]
                    ?.Medium_of_instruction__c === "Other" && (
                    <div class="inner_part">
                      <label>
                        {/* Please Specify <span className="redstyl">*</span> */}
                        {PGEducationDetails.Please_Specify}{" "}
                        <span className="redstyl">*</span>
                      </label>
                      <input {...register("specifyMediumTenth")} disabled />
                    </div>
                  )}
                  <div class="inner_part">
                    <label>
                      {/* State/Union Territory of Residence(10th){" "}
                      <span class="redstyl">* </span> */}
                      {PGEducationDetails.State_Union_10}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input {...register("stateTenth")} disabled />
                  </div>
                </div>
                <div class="form_styl">
                  <div class="inner_part">
                    <label>
                      {/* District / City(10th) <span class="redstyl">* </span> */}
                      {PGEducationDetails.District_10}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input {...register("districtTenth")} disabled />
                  </div>
                </div>

                <div class="heading_styl">
                  {/* <h4>Undergraduate Details</h4> */}
                  <h4>{PGEducationDetails.Undergraduate_Details}</h4>
                </div>
                <div class="form_styl">
                  <div class="inner_part">
                    <label>
                      {PGEducationDetails.Bachelor_Degree_Completion_Status}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input {...register("bachelorsCompletedByAug")} disabled />
                  </div>
                  {eduVal?.hed__Applications_Applicant__r?.records[0]
                    ?.First_Preference_Name__c ===
                    "Master of Public Health" && (
                    <div class="inner_part notes_class preview_note">
                      <Notes name="Master_of_Public_Health" />
                    </div>
                  )}
                </div>
                <div class="form_styl">
                  <div class="inner_part">
                    <label>
                      {PGEducationDetails.Qualification_Obtained}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input {...register("qualificationsObtained")} disabled />
                  </div>
                  <div class="inner_part">
                    <label>
                      {/* Year of completion <span class="redstyl">* </span> */}
                      {PGEducationDetails.Year_Of_Completion}{" "}
                      <span class="redstyl">* </span>
                      <i
                        data-tooltip-id="helpTooltip"
                        class="fa-solid fa-circle-info"
                      ></i>
                      <Tooltip id="helpTooltip">
                        <p>
                          {/* Please mention the passing year in 4 digits and Do not
                          enter any special character. */}
                          {
                            PGEducationDetails.Year_Of_Completion_Tooltip_Message
                          }
                        </p>
                      </Tooltip>
                    </label>
                    <input {...register("completionYear")} disabled />
                  </div>
                </div>
                {eduVal?.hed__Applications_Applicant__r?.records[0]
                  ?.First_Preference_Name__c == "M.A. Economics" && (
                  <div class="form_styl">
                    <div class="inner_part">
                      <label>
                        {/* Have you passed UG Degree in Economics?{" "}
                        <span class="redstyl">* </span> */}
                        {PGEducationDetails.UG_Economics_Passed_Question}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <input {...register("HaveUGEconomicsPassed")} disabled />
                    </div>
                  </div>
                )}
                <div class="form_styl">
                  <div class="inner_part">
                    <label>
                      {PGEducationDetails.Duration_of_Undergraduate_Programme}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input
                      {...register("durationUndergraduateProg")}
                      disabled
                    />
                  </div>
                  <div class="inner_part"></div>
                </div>
                <br />
                <div class="form_styl">
                  <div class="w100 blc_txt">
                    <p>
                      Category A:{" "}
                      <i>
                        Bangalore, Chennai, Delhi, Kolkata, Mumbai, Pune,
                        Hyderabad and Chandigarh;
                      </i>
                    </p>
                    <p>
                      Category B:{" "}
                      <i>
                        Other state capital cities and cities with population
                        greater than 10 lakhs;
                      </i>
                    </p>
                    <p>
                      Category C:{" "}
                      <i>Cities with population less than 10 Lakhs</i>
                    </p>
                    <p>
                      Category D: <i>Rural</i>
                    </p>
                  </div>
                </div>
                <div class="form_styl">
                  <div class="inner_part">
                    <label>
                      {/* Category of city/town where studied{" "}
                      <span class="redstyl">* </span> */}
                      {PGEducationDetails.Category_Of_City_UG}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input {...register("townCategoryGrad")} disabled />
                  </div>
                  <div class="inner_part">
                    <label>
                      {/* Mode of graduation <span class="redstyl">* </span> */}
                      {PGEducationDetails.Mode_Of_Graduation}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input {...register("gradMode")} disabled />
                  </div>
                </div>
                <div class="form_styl">
                  <div class="inner_part">
                    <label>
                      {/* Stream/Specialization <span class="redstyl">* </span> */}
                      {PGEducationDetails.Stream_UG}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input {...register("streamSpecialization")} disabled />
                  </div>
                  <div class="inner_part">
                    <label>
                      {/* Name of the University <span class="redstyl">* </span> */}
                      {PGEducationDetails.University_Name_UG}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input {...register("universityName")} disabled />
                  </div>
                </div>
                <div class="form_styl flex-wrap">
                  <div class="inner_part">
                    <label>
                      {/* Name of the College <span class="redstyl">* </span> */}
                      {PGEducationDetails.College_Name_UG}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input {...register("schoolNameGrad")} disabled />
                  </div>
                  <div class="inner_part">
                    <label>
                      {/* Country <span class="redstyl">* </span> */}
                      {PGEducationDetails.Country_UG}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input {...register("countryGrad")} disabled />
                  </div>
                  {eduVal?.hed__Education_History__r?.records[1]?.Country__c ===
                    "Others" && (
                    <div class="inner_part">
                      <label>
                        {/* Country <span class="redstyl">* </span> */}
                        {PGEducationDetails.Please_Specify}{" "}
                        <span className="redstyl">*</span>
                      </label>
                      <input
                        {...register("otherCountrySpecifyGrad")}
                        disabled
                      />
                    </div>
                  )}
                </div>
                <div class="form_styl">
                  <div class="inner_part">
                    <label>
                      {/* State/Union Territory of Residence{" "}
                      <span class="redstyl">* </span> */}
                      {PGEducationDetails.State_Union_Residence_UG}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input {...register("stateGrad")} disabled />
                  </div>
                  <div class="inner_part">
                    <label>
                      {/* District / City <span class="redstyl">* </span> */}
                      {PGEducationDetails.District_UG}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input {...register("districtGrad")} disabled />
                  </div>
                </div>
                <div class="form_styl">
                  <div class="inner_part">
                    <label>
                      {/* Medium of Instruction <span class="redstyl">* </span> */}
                      {PGEducationDetails.Medium_Of_Instruction_UG}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input {...register("mediumInstGrad")} disabled />
                  </div>
                  {eduVal?.hed__Education_History__r?.records[1]
                    ?.Medium_of_instruction__c === "Other" && (
                    <div class="inner_part">
                      <label>
                        {/* Please Specify <span className="redstyl">*</span> */}
                        {PGEducationDetails.Please_Specify}{" "}
                        <span className="redstyl">*</span>
                      </label>
                      <input {...register("specifyMediumGrad")} disabled />
                    </div>
                  )}
                  <div class="inner_part">
                    <label>
                      {/* Do you have any Postgraduate degree?{" "}
                      <span class="redstyl">* </span> */}
                      {PGEducationDetails.PG_Degree_Question}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input {...register("havePGDegree")} disabled />
                  </div>
                </div>

                {eduVal?.hed__Education_History__r?.records[1]
                  ?.Do_you_have_any_Postgraduate_degree__c == "Yes" && (
                  <div class="form_styl">
                    <div class="inner_part">
                      <label>
                        {/* Name of the Postgraduate degree{" "}
                        <span class="redstyl">* </span> */}
                        {PGEducationDetails.PG_Degree_Name}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <input {...register("qualificationName")} disabled />
                    </div>
                  </div>
                )}

                <div class="heading_styl">
                  {/* <h4>Work Experience</h4> */}
                  <h4>{PGEducationDetails.Work_Experience}</h4>
                </div>
                <div class="form_styl">
                  <div class="inner_part">
                    <label>
                      {/* Work experience after graduation?{" "}
                      <span class="redstyl">* </span> */}
                      {PGEducationDetails.Experience_After_Graduation_Question}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input {...register("workExperience")} disabled />
                  </div>

                  {eduVal?.Work_experience_after_gr__c === "Yes" && (
                    <div class="inner_part">
                      <label>
                        {/* Are you currently employed?
                        <span class="redstyl">*</span> */}
                        {PGEducationDetails.Currently_Employed_Question}{" "}
                        <span class="redstyl">*</span>
                      </label>
                      <input {...register("AreCurrentlyEmployed")} disabled />
                    </div>
                  )}
                </div>
                {eduVal?.Work_experience_after_gr__c === "Yes" && (
                  <>
                    <div class="form_styl">
                      <div class="inner_part">
                        <label>
                          {/* Work experience in months as on{" "} */}
                          {PGEducationDetails.Experience_As_On}{" "}
                          {moment(
                            eduVal?.hed__Applications_Applicant__r.records[0]
                              ?.Admission_Cycle__r?.Work_experience_in_months__c
                          ).format("DD MMMM YYYY")}{" "}
                          <span className="redstyl">*</span>
                        </label>
                        <input
                          {...register("workExperienceInMonths")}
                          disabled
                        />
                      </div>
                      <div class="inner_part">
                        <label>
                          {/* Name of organization working/last worked
                          <span class="redstyl">*</span> */}
                          {PGEducationDetails.Last_Organisation_Name}{" "}
                          <span class="redstyl">*</span>
                        </label>
                        <input {...register("organization")} disabled />
                      </div>
                    </div>
                    <div class="form_styl">
                      <div class="inner_part">
                        <label>
                          {/* Type of organization <span class="redstyl">*</span> */}
                          {PGEducationDetails.Last_Organisation_Type}{" "}
                          <span class="redstyl">*</span>
                        </label>
                        <input {...register("typeOfOrganization")} disabled />
                      </div>
                      <div class="inner_part">
                        <label>
                          {/* Annualy gross salary (Rs)
                          <span class="redstyl">*</span> */}
                          {PGEducationDetails.Last_Gross_Annual_Salary}{" "}
                          <span class="redstyl">*</span>
                        </label>
                        <input {...register("annualGrossSalary")} disabled />
                        {/* <label style={{ color: 'gray' }}>{getCapital(numberToWords(numToWrd))}</label> */}
                      </div>
                    </div>
                    {/* <div class="form_styl">
                      <div class="inner_part">
                        <label>
                          Annualy gross salary (Rs) <span class="redstyl">*</span>
                        </label>
                        <input {...register("annualGrossSalary")} disabled />
                        <label style={{ color: 'gray' }}>{getCapital(numberToWords(numToWrd))}</label>
                       
                      </div>
                    </div> */}
                  </>
                )}
              </div>
            </div>

            <div className="form_sec">
              <div class="heading_styl">
                {/* <h4>Socio-Economic Background</h4> */}
                <h4>{PGSocialEcoDetails.Socio_Economic_Background}</h4>
              </div>
              <div class="form_sec form_part">
                <div class="form_styl">
                  <div class="w100">
                    <p>
                      <Notes name="SED" />
                    </p>
                  </div>
                </div>
                <div class="form_styl mt-3">
                  <div class="inner_part">
                    <label>
                      {/* Father's Education Details{" "}
                      <span className="redstyl">* </span> */}
                      {PGSocialEcoDetails.Education_Details_Father}{" "}
                      <span className="redstyl">* </span>
                      <Tooltip id="fedu">
                        {/* <p>Please enter your Father's education details</p> */}
                        <p>
                          {
                            PGSocialEcoDetails.Education_Details_Father_Tooltip_Message
                          }
                        </p>
                      </Tooltip>
                      <i
                        data-tooltip-id="fedu"
                        class="fa-solid fa-circle-info"
                      ></i>
                    </label>
                    <input {...register("fatherEdu")} disabled={true} />
                  </div>
                  <div class="inner_part">
                    <label>
                      {/* Mother's Education Details{" "}
                      <span className="redstyl">* </span> */}
                      {PGSocialEcoDetails.Education_Details_Mother}{" "}
                      <span className="redstyl">* </span>
                      <Tooltip id="medu">
                        {/* <p>Please enter your Mother's education details</p> */}
                        <p>
                          {
                            PGSocialEcoDetails.Education_Details_Mother_Tooltip_Message
                          }
                        </p>
                      </Tooltip>
                      <i
                        data-tooltip-id="medu"
                        class="fa-solid fa-circle-info"
                      ></i>
                    </label>
                    <input {...register("motherEdu")} disabled={true} />
                  </div>
                </div>
                <div class="form_styl">
                  <div class="inner_part">
                    <label>
                      {/* Caste <span class="redstyl">* </span> */}
                      {PGSocialEcoDetails.Caste} <span class="redstyl">* </span>
                    </label>
                    <input {...register("caste")} disabled={true} />
                  </div>
                  <div class="inner_part">
                    <label>
                      {/* Religion <span class="redstyl">* </span> */}
                      {PGSocialEcoDetails.Religion}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input {...register("religion")} disabled={true} />
                  </div>
                </div>

                <div class="form_styl">
                  <div class="inner_part">
                    <label>
                      {/* Main Occupation of family <span class="redstyl">* </span> */}
                      {PGSocialEcoDetails.Family_Occupation_Main}{" "}
                      <span class="redstyl">* </span>
                      <Tooltip id="famocc">
                        <p>
                          {/* (Please go over the options carefully and pick the
                          closest applicable to your family/household) */}
                          {
                            PGSocialEcoDetails.Family_Occupation_Main_Tooltip_Message
                          }
                        </p>
                      </Tooltip>
                      <i
                        data-tooltip-id="famocc"
                        class="fa-solid fa-circle-info"
                      ></i>
                    </label>
                    <input {...register("famlOccupation")} disabled={true} />
                  </div>
                </div>
                <div class="form_styl align_end">
                  <div class="inner_part">
                    <label>
                      {" "}
                      {/* Annual Family income excluding yours{" "}
                      <span class="redstyl">* </span> */}
                      {PGSocialEcoDetails.Family_Income_Excluding_Self}{" "}
                      <span class="redstyl">* </span>
                      <Tooltip id="famin">
                        {/* <p>Excluding your income(Per Year)</p> */}
                        <p>
                          {
                            PGSocialEcoDetails.Family_Income_Excluding_Self_Tooltip_Message
                          }
                        </p>
                      </Tooltip>
                      <i
                        data-tooltip-id="famin"
                        class="fa-solid fa-circle-info"
                      ></i>
                    </label>
                    <input {...register("famlIncome")} disabled={true} />
                  </div>
                  <div class="inner_part">
                    <p className="mt-3">
                      {/* Please exercise caution while entering the income level as
                      you will be asked to furnish supporting documents later in
                      the admission process. */}
                      <Notes
                        name="Family_Income_Per_Year_Help"
                        size={"0.73rem"}
                      />
                    </p>
                  </div>
                </div>
                <div class="form_styl">
                  <div class="inner_part">
                    <label>
                      {/* Annual Income (Rs.) <span class="redstyl">* </span> */}
                      {PGSocialEcoDetails.Annual_Income}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input {...register("annualIncome")} disabled={true} />
                    <label style={{ color: "gray" }}>
                      {getCapital(numberToWords(numToWrd))}
                    </label>
                  </div>
                </div>

                <div class="form_styl mt-3">
                  <div class="inner_part">
                    <label className="display_inblock">
                      {/* Do you wish to avail of financial assistance for your
                      studies at Azim Premji University?{" "}
                      <span className="redstyl">*</span> */}
                      {PGSocialEcoDetails.Financial_Assistance_Question}{" "}
                      <span className="redstyl">*</span>
                    </label>
                    <span class="flex flex-wrap">
                      <span class="flex w100">
                        <input
                          type="radio"
                          checked={
                            socio?.Do_you_need_Financial_Assistance__c === "Yes"
                          }
                          disabled
                        />

                        <label type="text">Yes</label>
                      </span>
                      <span class="flex">
                        <input
                          type="radio"
                          checked={
                            socio?.Do_you_need_Financial_Assistance__c === "No"
                          }
                          disabled
                        />

                        <label type="text">No</label>
                      </span>
                      {/* <span class="flex">
                        <input type="radio" value="No" />
                        <label for="No">No</label>
                      </span> */}
                    </span>
                  </div>
                  <div class="inner_part">
                    {/* <p>
                      1.Need based scholarship - Apply if your annual family income is less than
                      7 lakhs.</p> */}
                    <Notes name="Financial_Assistance_PG1" />
                    {/* <p>
                      2.Experience based waiver - Apply for 50% tuition fee waiver, if your annual
                      family income is higher than 7 lakhs and you have minimum 3 years work
                      experience after your graduation. This is applicable only for the 2 year
                      PG programmes.
                    </p> */}
                    <Notes name="Financial_Assistance_PG" />
                  </div>
                </div>
                <div class="form_styl mt-3">
                  <div class="inner_part">
                    <label>
                      {/* Are You a Person With Disability?{" "}
                      <span class="redstyl">* </span> */}
                      {PGSocialEcoDetails.Disability_Question}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input {...register("disability")} disabled={true} />
                    {socio?.Are_you_a_person_with_disability__c === "Yes" && (
                      <>
                        <label>
                          {/* Nature of Disability <span class="redstyl">*</span> */}
                          {PGSocialEcoDetails.Disability_Nature}{" "}
                          <span class="redstyl">*</span>
                        </label>
                        <input
                          {...register("disabilityNature")}
                          disabled={true}
                        />
                      </>
                    )}
                    {socio?.Nature_of_Disability__c === "Others" && (
                      <div class="inner_part w100">
                        <label>
                          {/* Please Specify<span class="redstyl">* </span> */}
                          {PGSocialEcoDetails.Please_Specify}{" "}
                          <span class="redstyl">* </span>
                        </label>
                        <input {...register("specifyNature")} disabled />
                      </div>
                    )}

                    {socio?.Are_you_a_person_with_disability__c === "Yes" && (
                      <>
                        <div class="form_styl">
                          <div class="inner_part w100">
                            <label>
                              {PGSocialEcoDetails.Need_Support_Question}{" "}
                              <span class="redstyl">* </span>
                            </label>
                            <input {...register("needSupportWrite")} disabled />
                          </div>
                        </div>

                        {needSupport === "Yes" && (
                          <div class="inner_part w100">
                            <br />
                            <div>
                              <Notes name="Disability_specific_support" />
                            </div>
                          </div>
                        )}

                        {needSupport === "Yes" && (
                          <div class="inner_part w100">
                            <label>
                              {PGSocialEcoDetails.Test_Support_Question}{" "}
                              <span class="redstyl">* </span>
                            </label>

                            <textarea
                              {...register("needSpecificSupport")}
                              disabled
                            />

                            <label>
                              {PGSocialEcoDetails.Describe_Support_Question}
                            </label>
                            <input {...register("supportExpecting")} disabled />
                          </div>
                        )}
                        {/* <div class="inner_part w100">
                            <label>
                              {PGSocialEcoDetails.Scribe_Requirement_Question}{" "}
                              <span className="redstyl">*</span>
                            </label>
                            <input {...register("scribe")} disabled={true} />
                          </div> */}
                        {/* </div>                        */}
                        <div class="form_styl">
                          <div class="inner_part w100">
                            <label className="display_inblock">
                              {PGSocialEcoDetails.Disability_Details}{" "}
                              <span className="redstyl">*</span>
                            </label>
                            <input
                              {...register("moreDetails")}
                              disabled={true}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div class="inner_part">
                    <Notes name="Disability" />
                    <Notes name="Disability_privacy" />
                  </div>
                </div>
              </div>
            </div>

            <div className="form_sec">
              <div class="heading_styl">
                {/* <h4>{getCapitalized("upload photograph")}</h4> */}
                <h4>{getCapitalized(PGUploadDocuments.Upload_Photograph)}</h4>
              </div>
              <div class="form_part">
                <div class="form_styl">
                  <table>
                    <tr>
                      <td>
                        {/* Upload Photograph : <span class="redstyl">* </span>{" "} */}
                        {PGUploadDocuments.Upload_Photograph}{" "}
                        <span class="redstyl">* </span>{" "}
                      </td>

                      <td colSpan={"2"} className="previewPhotoupload">
                        {" "}
                        <img
                          ref={imageRef}
                          src={`data:image/jpeg;base64,${setImageShow}`}
                          alt=""
                          className="myImage"
                          disabled
                        />
                        {/* )} */}
                      </td>
                    </tr>

                    {apuCuetYear && (
                      <>
                        <tr>
                          <td>
                            {/* Upload your CUET/SAT score card: <span>* </span> */}
                            {PGUploadDocuments.Upload_Exam_Scorecard}{" "}
                            <span class="redstyl">* </span>
                          </td>
                          <td>
                            {/* <p disabled={true}>{setCuetImage}</p> */}
                            <a 
                              href={setCuetLink}
                              target="_blank" style={{ cursor: "pointer" }}>
                                {'Disability- '}{setCuetImage}
                            </a>
                            {/* <Link to={''} width="100%"  disabled={true}>{cuetImage}</Link> */}
                          </td>
                          <td>&nbsp; </td>
                        </tr>
                        <tr>
                          <td>
                            {/* CUET/SAT Year <span>* </span> */}
                            {PGUploadDocuments.Exam_Year} <span>* </span>
                          </td>
                          <td>
                            <input
                              {...register("APU_CUET_Year")}
                              disabled={true}
                            />
                          </td>
                          <td>&nbsp; </td>
                        </tr>

                        <tr>
                          <td>
                            {/* Language in which the CUET test is taken?{" "}
                            <span>* </span> */}
                            {PGUploadDocuments.CUET_Language_Question}{" "}
                            <span>* </span>
                          </td>
                          <td>
                            <input
                              {...register("APU_CUET_Language")}
                              disabled={true}
                            />
                          </td>
                          <td>&nbsp; </td>
                        </tr>
                      </>
                    )}

                    {disability && (
                      <tr>
                        <td>
                          {/* Upload your CUET/SAT score card: <span>* </span> */}
                          {PGUploadDocuments.Upload_DISABILITY}
                          {""}
                          <span class="redstyl">* </span>
                        </td>
                        <td>
                          {/* <p disabled={true}>{setDisabilityImage}</p> */}
                          <a 
                              href={setDisabilityLink}
                              target="_blank" style={{ cursor: "pointer" }}>
                                {'Disability- '}{setDisabilityImage}
                            </a>
                        </td>
                        <td>&nbsp; </td>
                      </tr>
                    )}
                  </table>
                </div>
                <span className="redstyl">
                  {/* If you would like to edit the details, please go to the
                  respective sections and edit */}
                  {ApplicationViewPage.Preview_Edit_Message}
                </span>
                <div class="form_styl">
                  <div class="w100">
                    <input
                      type="button"
                      // value="Proceed "
                      value={ApplicationViewPage.Proceed_Button_Message}
                      class="submit"
                      onClick={handleClick}
                      disabled={readOnly}
                    ></input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Home>
  );
};

export default Preview;
