import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactHtmlParser from 'react-html-parser';
import './Notes.css';
import { bachelorsDegree } from "../../redux/actions";
export const Notes = (props) => {
    const dispatch = useDispatch()
    const { allNotes, dashboardresponse } = useSelector((state) => state.collections);
    const noteData = allNotes?.data?.find((item) => item.Name === props.name)
    const noteData1 = allNotes?.data?.find((item) => item.Name === props.hours)
    const minDob = allNotes?.data?.find((item) => item.Name === "Min_year_DOB")
    const maxDob = allNotes?.data?.find((item) => item.Name === "Max_year_DOB")
    const bachelorsComp = allNotes?.data?.find((item) => item.Name === "Bachelor_s_degree_completion_status")
    var isHTML = /(<([^>]+)>)/i.test(noteData?.Value);
    const bachelorsStatusYear = dashboardresponse?.data?.allDataOnDashboard[0]?.appCycleEndDateYear;

    useEffect(()=>{
        dispatch(bachelorsDegree(bachelorsComp))
    }, [bachelorsComp])

    useEffect(()=>{
        if(minDob){
            localStorage.setItem("minimumDOB", JSON.stringify(minDob))
        }
        if(maxDob){
            localStorage.setItem("maximumDOB", JSON.stringify(maxDob))
        }

    },[minDob, maxDob])

    function isValidWebUrl(url) {
        let regEx = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/gm;
        return regEx.test(url);
    }

    function getNotesDetails() {
        if (isValidWebUrl(noteData?.Value)) {
            return (
                <a href={noteData?.Value} target="_blank">Click here {""}</a>
            )
        }
        return (
            <p>{noteData?.Value.includes("[XX]") ? noteData?.Value.replace('[XX]', noteData1?.Value) : noteData?.Value}</p>
        )
    }

    return (
        <>
            {isHTML && noteData?.Name === "Master_of_Public_Health" ? 
                ReactHtmlParser(noteData?.Value + " " + bachelorsStatusYear) : isHTML ? (
                ReactHtmlParser(noteData?.Value)
            ) : getNotesDetails()}

        </>
    )
}