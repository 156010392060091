import React, { useState, useEffect } from "react";
import * as images from "../../resources/images"
import { useActionData } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getNotifications } from "../../redux/actions/Api";
import moment from "moment";

const Notification = () => {
  const dispatch = useDispatch();

  const { dashboardresponse, notificationrespone } = useSelector((state) => state.collections)

  const [notifyData, setNotifyData] = useState("")

  useEffect(() => {
    dispatch(getNotifications())
  }, [])

  useEffect(()=>{
    if(notificationrespone){
      setNotifyData(notificationrespone?.data?.records)
    }
  },[notificationrespone])

  useEffect(() => {
    if (dashboardresponse) {
      setNotifyData(dashboardresponse?.data.notifications
      )
    }
  }, [dashboardresponse])

  return (
    <div class="footer_box">
      <h4><img src={images.notificationicon} /> Notifications</h4>
      {notifyData && notifyData?.map((notify, ind)=> (          
      <div class="notify">
        <p>
          <i class="fa-solid fa-bookmark"></i>
          <strong> {notify?.Subject__c}</strong> - {notify?.Description__c}
          <strong> Note :</strong> {notify?.note} |  <a href={notify?.URL__c} style={{ color: "black", fontStyle: "italic" }} target="_blank"><strong>{notify?.Link_Title__c}
          </strong></a>
        </p>
           
      </div>
      ))}
    </div>
  )
}

export default Notification;