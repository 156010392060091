/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState, useRef } from "react";
import Home from "../Home";
import {
  convertBase64,
  convertPDFBase64,
  getCapitalized,
} from "../../utils/Helper";
import { getDocData, uploadDocument } from "../../redux/actions/Api";
import { useDispatch, useSelector } from "react-redux";
import { HELP_TEXT, PATHS } from "../../utils";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { toast } from "react-toastify";
import Success from "../notification/Success";
import Loader from "../Loader";
import { UPLOAD_DOC_UG } from "../../redux/actions/Types";
import { unsavedchanges } from "../../redux/actions";
import {
  AppCommon,
  ApplicationViewPage,
  PGUploadDocuments,
} from "../constantLabelFile";
import { Notes } from "../common/Notes";

let doc1;
let doc2;
let doc3;
let imagePath;
let filterPDF;
let pdfPath;
let appNo;
let setImage ;
let setCuetImage;
let setDisabilityImage;
let setFileName;
let setPdfFileName;
let setPdfFileNameDisability;
let pdfLink;
let pdfDisability;
const UploadPhoto = () => {
  const fileInputRef = useRef(null);
  const filePDFInputRef = useRef(null);
  const fileDisabilityInputRef = useRef(null);
  const navigate = useNavigate();
 
  const imageRef = React.useRef(null);
  const [rslt, setResult] = React.useState("");
  const [loader, setLoader] = useState(true);
  const [cuetYear, setCuetYear] = useState("");
  const [cuetLang, setCuetLang] = useState("");
  const [doc, setDocData] = React.useState("");
  const [readOnly, setReadOnly] = useState(false);
  const [showMsg, setShowMsg] = useState("");
 
  const [appIds, setAppID] = useState("");
  const [prgLabelname, setPrglabelname] = useState();
  const [appNumber, setAppNumber] = useState("");
  const [disability, setDisability] = useState();
  
  const [photoGraphData, setPhotoGraphData] = useState('');
  const [cuetData, setCuetData] = useState('');
  const [disabilityData, setDisabilityData] = useState('');

  const dispatch = useDispatch();
  const { picklistdata, uploaddocres, docData, dashboardresponse } = useSelector((state) => state.collections);
    
  useEffect(() => {
    if (docData == undefined) {
      setLoader(true);
    } else {
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  }, [docData]);

  useEffect(() => {
    if (docData?.data) {
      setPhotoGraphData(docData?.data?.photoGraphFile)
      setCuetData(docData?.data?.cuetFile)
      setDisabilityData(docData?.data?.disabiltyFile)
      setDisability(docData?.data?.IsDisable);
     
      doc1 = docData?.data?.photoGraphFile;
      doc2 = docData?.data?.cuetFile;
      doc3 = docData?.data?.disabiltyFile;
    }
  }, [docData?.data]);


useEffect(()=>{
  if(photoGraphData){
    setImage = photoGraphData?.VersionData;
    setFileName =photoGraphData?.PathOnClient;
   
  }
},[photoGraphData])

useEffect(()=>{
  if(cuetData){
    setCuetImage = cuetData?.VersionData;
    setPdfFileName =cuetData?.PathOnClient;
    pdfLink = cuetData?.link;
  }
},[cuetData])
  
useEffect(()=>{
  if(disabilityData){
    setDisabilityImage = disabilityData?.VersionData;
    setPdfFileNameDisability = disabilityData?.PathOnClient ;
    pdfDisability = disabilityData?.link;
  }
},[disabilityData])

  useEffect(() => {
    if (docData?.data?.APU_CUET_Year) {
      setCuetYear(docData?.data?.APU_CUET_Year);
      setCuetLang(docData?.data?.Language_in_which_the_CUET_test_is_taken);
    }
  }, [docData?.data]);

  useEffect(() => {
    if (uploaddocres?.success) {
      dispatch(unsavedchanges());
      setLoader(false);
      toast.success(<Success response={uploaddocres} />, {
        position: "top-center",
        autoClose: 2000,
      });
      setTimeout(() => {
        setLoader(false);
        dispatch({ type: UPLOAD_DOC_UG, payload: undefined });
        navigate(
          PATHS.PREVIEWUG_STR +
            dashboardresponse?.data?.allDataOnDashboard[0]?.appId
        );
      }, 1500);
    }
  }, [uploaddocres]);

  useEffect(() => {
    const appID = localStorage.getItem("userData");
    const pasrseData = JSON.parse(appID);
    setAppNumber(pasrseData?.appNumber);
    setAppID(pasrseData?.appId);
    setPrglabelname(pasrseData);
  }, [localStorage.getItem("userData")]);

  useEffect(() => {
    if (appIds) {
      let id = {
        applicationId: appIds,
      };
      dispatch(getDocData(id));
    }
  }, [appIds]);

  const uploadPhoto = async (e) => {
    const isValid = validateImageType(e.target.files[0]?.name);
    const fileSize = Math.round(e.target.files[0]?.size / 1024);
    if (isValid) {
      if (fileSize > 50 && fileSize < 100) {
        doc1 = e.target.files[0];
        setImage = await convertBase64(doc1);
        imagePath = e.target.files[0].name;
        setFileName = e.target.files[0].name;
        uploader(e);
      } else {
        alert(
          "Please upload the photo, ensuring size should be greater than 50kb and less than 100kb"
        );
      }
    }
  };

  const uploadDoc = async (e) => {
    dispatch(unsavedchanges(e.target.value));
    const isValid = validatePDFType(e.target.files[0]?.name);
    const fileSize = Math.round(e.target.files[0]?.size / 1024);
    if (isValid) {
      doc2 = e.target.files[0];
      filterPDF = await convertPDFBase64(doc2);
      pdfPath = e.target.files[0].name;
      setPdfFileName = e.target.files[0].name;
      setCuetImage = filterPDF.split("data:application/pdf;base64")[1];
      uploader(e);
    }
  };

  /** upload disdability */
  const uploadDisability = async (e) => {
    dispatch(unsavedchanges(e.target.value));
    const isValid = validatePDFDisability(e.target.files[0]?.name);
    const fileSize = Math.round(e.target.files[0]?.size / 1024);
    if (isValid) {
      doc3 = e.target.files[0];
      filterPDF = await convertPDFBase64(doc3);
      pdfPath = e.target.files[0].name;
      setPdfFileNameDisability = e.target.files[0].name;
      setDisabilityImage = filterPDF.split("data:application/pdf;base64")[1];
      const url = URL.createObjectURL(doc3);
      // setPdfDisability(url);
      uploader(e);
    }
  };
  function validateImageType(fileName) {
    if (fileName) {
      const fileExtension = fileName.split(".").at(-1);
      const allowedFileTypes = ["jpg", "jpeg"];
      if (!allowedFileTypes.includes(fileExtension)) {
        window.alert(
          `File does not support. Files type must be ${allowedFileTypes.join(
            ", "
          )}`
        );
        return false;
      } else {
        return true;
      }
    }
  }

  function validatePDFType(pdfFileName) {
    if (pdfFileName) {
      const fileExtension = pdfFileName.split(".").at(-1);
      const allowedFileTypes = ["pdf"];
      if (!allowedFileTypes.includes(fileExtension)) {
        window.alert(
          `File does not support. Files type must be ${allowedFileTypes.join(
            ", "
          )}`
        );
        return false;
      } else {
        return true;
      }
    }
  }
  function validatePDFDisability(pdfFileNameDisability) {
    if (pdfFileNameDisability) {
      const fileExtension = pdfFileNameDisability.split(".").at(-1);
      const allowedFileTypes = ["pdf"];
      if (!allowedFileTypes.includes(fileExtension)) {
        window.alert(
          `File does not support. Files type must be ${allowedFileTypes.join(
            ", "
          )}`
        );
        return false;
      } else {
        return true;
      }
    }
  }
  const saveData = async () => {
    
    if (doc1) {
      const data = {
        userId: localStorage.getItem("userId"),
        contactId: localStorage.getItem("contactId"),
        applicationId: localStorage.getItem("applicationId"),
        cuetYear: cuetYear,
        cuetLang: dashboardresponse?.data?.allDataOnDashboard[0]?.appCycleSAT
          ? ""
          : cuetLang,
        records: [
          {
            // Title: doc1?.name,
            // PathOnClient: "/" + doc1?.name,
            Title: doc1?.name ? doc1?.name : doc1?.Title,
            PathOnClient: setFileName ? "/" + setFileName : "/" + doc1?.Title,
            VersionData: checkBase64(doc1?.VersionData)
              ? doc1?.VersionData
              : await convertBase64(doc1),
            FirstPublishLocationId: appIds,
            Description: "Photograph upload",
            IsMajorVersion: true,
          },
        ],
      };
       if (docData?.data?.IsDisable) {
      
        if (!doc3) {
        alert("Please upload Disability");
        return false;
        }
        else {
          {
            data.Title = doc3?.name ? doc3?.name : doc3?.Title;
            data.PathOnClient = setPdfFileNameDisability
              ? "/" + setPdfFileNameDisability
              : "/" + doc3?.Title;
            // data.VersionData = filterPDF?.split('data:application/pdf;base64,')[1];
            data.VersionData = checkBase64(doc3?.VersionData)
              ? doc3?.VersionData
              : filterPDF?.split("data:application/pdf;base64,")[1];
            data.FirstPublishLocationId = appIds;
            data.Description = "Disablity upload";
            data.IsMajorVersion = true;

          
          }

          data.records?.push({
            Title: data.Title,
            PathOnClient: data.PathOnClient,
            VersionData: data.VersionData,
            FirstPublishLocationId: data.FirstPublishLocationId,
            Description: data.Description,
            IsMajorVersion: data.IsMajorVersion,
          });
        }
      }
      if (dashboardresponse?.data?.allDataOnDashboard[0]?.appCycleCUET || dashboardresponse?.data?.allDataOnDashboard[0]?.appCycleSAT) {
        if (!doc2) {
          alert("Please upload PDF");
          return false;
        } else if (!cuetYear) {
          alert("Please choose CUET/SAT Score");
          return false;
        } else if (!cuetLang ) {
          alert("Language in which the CUET test is taken");
          return false;
        } else {
          {
            data.Title = doc2?.name ? doc2?.name : doc2?.Title;
            data.PathOnClient = setPdfFileName
              ? "/" + setPdfFileName
              : "/" + doc2?.Title;
            // data.VersionData = filterPDF?.split('data:application/pdf;base64,')[1];
            data.VersionData = checkBase64(doc2?.VersionData)
              ? doc2?.VersionData
              : filterPDF?.split("data:application/pdf;base64,")[1];
            data.FirstPublishLocationId = appIds;
            data.Description = "CUET/SAT upload";
            data.IsMajorVersion = true;
          }

          data.records?.push({
            Title: data.Title,
            PathOnClient: data.PathOnClient,
            VersionData: data.VersionData,
            FirstPublishLocationId: data.FirstPublishLocationId,
            Description: data.Description,
            IsMajorVersion: data.IsMajorVersion,
          });
        }
      } 
      // else if (dashboardresponse?.data?.allDataOnDashboard[0]?.appCycleSAT) {
      //   if (!doc2) {
      //     alert("Please upload PDF");
      //     return false;
      //   } else if (!cuetYear) {
      //     alert("Please choose CUET/SAT Score");
      //     return false;
      //   } else {
      //     {
      //       data.Title = doc2?.name ? doc2?.name : doc2?.Title;
      //       data.PathOnClient = pdfFileName
      //         ? "/" + pdfFileName
      //         : "/" + doc2?.Title;
      //       // data.VersionData = filterPDF?.split('data:application/pdf;base64,')[1];
      //       data.VersionData = checkBase64(doc2?.VersionData)
      //         ? doc2?.VersionData
      //         : filterPDF?.split("data:application/pdf;base64,")[1];
      //       data.FirstPublishLocationId = appIds;
      //       data.Description = "CUET/SAT upload";
      //       data.IsMajorVersion = true;
      //     }

      //     data.records?.push({
      //       Title: data.Title,
      //       PathOnClient: data.PathOnClient,
      //       VersionData: data.VersionData,
      //       FirstPublishLocationId: data.FirstPublishLocationId,
      //       Description: data.Description,
      //       IsMajorVersion: data.IsMajorVersion,
      //     });
      //   }
      // } 
      setLoader(true);
      dispatch(uploadDocument(data));
    } 
   
  };

  function useDisplayImage() {
    async function uploader(e) {
      const imageFile = e.target.files[0];
      const reader = new FileReader();
      reader.addEventListener("load", (e) => {
        setResult(e.target.result);
      });
      reader.readAsDataURL(imageFile);
    }
    return { rslt, uploader };
  }

  const checkBase64 = (data) => {
    const base64regex =
      /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    return base64regex.test(data);
  };

  const { result, uploader } = useDisplayImage();
  const currentDate = new Date();
  const year1 = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDate = `${year1}-${month}-${day}`;

  useEffect(() => {
    if (
      docData?.data?.applicationStatus === "Started" ||
      docData?.data?.applicationStatus === "Draft" ||
      docData?.data?.applicationStatus === "Payment Failed" ||
      docData?.data?.applicationStatus === "Payment Pending" ||
      docData?.data?.applicationStatus === "Payment Submitted"
    ) {
      if (formattedDate > docData?.data?.applicationCycleEndDate) {
        setReadOnly(true);
        setShowMsg(
          "We regret to inform you that the Application Submission for the current Admission cycle has been closed"
        );
      } else {
        setReadOnly(false);
        setShowMsg("");
      }
    } else {
      if (formattedDate < docData?.data?.applicationCycleEndDate) {
        setReadOnly(true);
        setShowMsg("");
      } else {
        setReadOnly(true);
        setShowMsg(
          "We regret to inform you that the Application Submission for the current Admission cycle has been closed"
        );
      }
    }
  }, [docData]);

  const handleButtonClick = () => {
    fileInputRef?.current?.click();
  };
  const handlePDFButtonClick = () => {
    filePDFInputRef?.current?.click();
  };
  const handleDisabilityClick = () => {
    fileDisabilityInputRef?.current.click();
  };
  function displayLanguage() {
    if (dashboardresponse?.data?.allDataOnDashboard[0]?.appCycleCUET) {
      return (
        <tr>
          <td>
            {PGUploadDocuments.CUET_Language_Question}{" "}
            <span class="redstyl">* </span>{" "}
          </td>
          <td>
            <select
              value={cuetLang}
              onChange={(e) => {
                setCuetLang(e.target.value);
                dispatch(unsavedchanges(e.target.value));
              }}
              disabled={readOnly}
            >
              <option value="" disabled selected>
                {/* --Select Options-- */}
                {AppCommon.Dropdown_Default_Option}
              </option>
              {picklistdata?.data?.apuCuetLanguages.map((item, index) => (
                <option key={index} value={item.Id}>
                  {item.value}
                </option>
              ))}
            </select>
          </td>
          <td>&nbsp; </td>
        </tr>
      );
    } else {
      return false;
    }
  }

  function displayCUET() {
    {
      if (
        dashboardresponse?.data?.allDataOnDashboard[0]?.appCycleCUET ||
        dashboardresponse?.data?.allDataOnDashboard[0]?.appCycleSAT
      ) {
        return (
          <>
            <tr>
              <td>
                {PGUploadDocuments.Upload_Exam_Scorecard}{" "}
                <span class="redstyl">* </span>{" "}
              </td>

              <td>
                <div className="flex uploadpic">
                  <span
                    onClick={handlePDFButtonClick}
                    className="uploadfile_styl"
                  >
                    {/* Upload Doc File{" "} */}
                    {PGUploadDocuments.Upload_Doc_File}{" "}
                  </span>
                  <span>{setPdfFileName ? setPdfFileName : "No file choosen"}</span>
                </div>
                <input
                  ref={filePDFInputRef}
                  type="file"
                  id="myfile"
                  name="myfile"
                  accept="application/pdf"
                  onChange={uploadDoc}
                  disabled={readOnly}
                  style={{ display: "none" }}
                />{" "}
              </td>
              <td>
                {setCuetImage && (
                  // <Link to={pdfLink} width="100%" target="_blank">{appNumber}</Link>
                  <a
                    href={pdfLink}
                    target="_blank"
                    style={{ cursor: "pointer" }}
                  >
                   {appNumber}
                  </a>
                )}
              </td>
            </tr>

            <tr>
              <td>
                {/* CUET/SAT/JEE Main Year <span class="redstyl">* </span>{" "} */}
                {PGUploadDocuments.Exam_Year} <span class="redstyl">* </span>{" "}
              </td>
              <td>
                <select
                  value={cuetYear}
                  disabled={readOnly}
                  onChange={(e) => {
                    setCuetYear(e.target.value);
                    dispatch(unsavedchanges(e.target.value));
                  }}
                >
                  <option value="" disabled selected>
                    {/* --Select Options-- */}
                    {AppCommon.Dropdown_Default_Option}
                  </option>
                  {picklistdata?.data?.apuCuetYear.map((item, index) => (
                    <option key={index} value={item.Id}>
                      {item.value}
                    </option>
                  ))}
                </select>
              </td>
              <td>&nbsp; </td>
            </tr>
            {displayLanguage()}
          </>
        );
      } else {
        return false;
      }
    }
  }

  function displayDisability() {
    if (docData?.data?.IsDisable) {
    return (
          <tr>
            <td>
              {PGUploadDocuments.Upload_DISABILITY}{" "}
              <span class="redstyl">* </span>{" "}
            </td>

            <td>
              <div className="flex uploadpic">
                <span
                  onClick={handleDisabilityClick}
                  className="uploadfile_styl"
                >
                  {PGUploadDocuments.Upload_Doc_File}{" "}
                </span>
                <span>{setPdfFileNameDisability ? setPdfFileNameDisability : "No file choosen"}</span>
              </div>
              <input
                ref={fileDisabilityInputRef}
                type="file"
                id="myfile"
                name="myfile"
                accept="application/pdf"
                onChange={uploadDisability}
                disabled={readOnly}
                style={{ display: "none" }}
              />{" "}
            </td>
            <td>
              {setDisabilityImage && (
                <a 
                href={pdfDisability}
                target="_blank" style={{ cursor: "pointer" }}>
                   {'Disability- '}{appNumber}
                </a>
              )}
            </td>
          </tr>

    );
  }else{
    return false;
  }
  }
  return (
    <Home>
      <Loader show={loader}></Loader>
      <div class="right_panel">
        <div class="w100">
          <p className="top_head_styl">{showMsg}</p>
          <h4 className="top_head">
            {getCapitalized(prgLabelname?.programName)}
          </h4>
          <span className="help_link">
            <p>             
              <NavLink to={PATHS.RAISEQUERY}>{HELP_TEXT}</NavLink>{" "}
              <Tooltip id="helpTooltip">               
                <p>{ApplicationViewPage.Help_Tooltip_Text}</p>
              </Tooltip>
              <i
                data-tooltip-id="helpTooltip"
                class="fa-solid fa-circle-info"
              ></i>
            </p>
          </span>
        </div>
        <div className="w100 scroll_styl">
          <div class="heading_styl head_font">          
            <h4>{getCapitalized(PGUploadDocuments.Upload_Photo_Documents)}</h4>
          </div>
          <div class="form_sec form_part">
            <div class="form_styl">
              <div class="w100 blctext">
                <Notes name = 'FileUploadInstructions' />
              </div>
            </div>

            <div class="form_styl">
              <table>
                <tr>
                  <td>                  
                    {PGUploadDocuments.Upload_Photograph}{" "}
                    <span class="redstyl">* </span>{" "}
                  </td>

                  <td>
                    <div className="flex uploadpic">
                      <span
                        onClick={handleButtonClick}
                        className="uploadfile_styl"
                      >                       
                        {PGUploadDocuments.Upload_File}{" "}
                      </span>
                      <span>{setFileName ? setFileName : "No file choosen"}</span>
                    </div>
                    <input
                      ref={fileInputRef}
                      type="file"
                      id="myfile"
                      name="myfile"
                      accept=".jpg,.jpeg"
                      onChange={uploadPhoto}
                      disabled={readOnly}
                      style={{ display: "none" }}
                    />{" "}
                  </td>
                  <td>
                    {" "}
                    <img
                      ref={imageRef}
                      src={`data:image/jpeg;base64,${setImage}`}
                      alt=""
                      className="myImage"
                      disabled={readOnly}
                    />
                  </td>
                </tr>

                {displayCUET()}

                {/* disability doc start */}
                {docData?.data?.IsDisable  && 
                displayDisability()}
                {/* disability doc end */}
              </table>
            </div>

            <div class="form_styl">
              <div class="w100">
                <input
                  onClick={() => {
                    saveData();
                  }}
                  type="button"                
                  value={ApplicationViewPage.Save_And_Proceed_Button_Message}
                  class="submit"
                  disabled={readOnly}
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Home>
  );
};

export default UploadPhoto;
