import React, { useEffect, useState } from "react";
import { PATHS } from "../../utils";
import { useForm } from "react-hook-form";
import { EMAIL_REGEX } from "../../utils/Regex";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../../redux/actions/Api";
import Error from "../notification/Error";
import { resetForms } from "../../redux/actions";
import { toast } from "react-toastify";
import Loader from "../Loader";

const ForgetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors }, } = useForm();

  const { forgotresponse, error } = useSelector((state) => state.collections);

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (error) {
      setLoader(false);
      toast.error(<Error error={error} />, {
        position: "top-center",
        autoClose: 1000,
        className: "Toastify__toast-theme--colored.Toastify__toast--warning",
      });
      setTimeout(() => {
        dispatch(resetForms());
      }, 1000);
    }
  }, [error]);

  useEffect(() => {
    if (forgotresponse) {
      navigate(PATHS.RESETEMAIL)
    }
  }, [forgotresponse])

  const _onSubmit = (values) => {
    setLoader(true);
    let formData = {
      email: values.email
    }
    dispatch(forgotPassword(formData));
  }
  return (
    <div
      class="main_container"
      style={{
        backgroundImage: `url("https://apf-uat--uat.sandbox.my.site.com/admissions/file-asset/univ_building_8_reduced_image?v=1")`,
        width: "100%",
        height: "100%",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Loader show={loader} />
      <div class="container_box text-center">
        <span className="reset_top">
          <h4> Password Reset</h4>
          <p className="mt-3">To reset your password, we will need your username. <br />
            We'll send password reset instructions to the email address <br /> associated with your account.</p>
        </span>
        {errors?.useremail && <div className="error_shown">{errors.useremail?.message}</div>}
        <form onSubmit={handleSubmit(_onSubmit)} onFocus={(e) => e.target.setAttribute("autoComplete", "none")}>
          <div class="change_password reset_password">
            <div class="input_box">
              <label>
                <i class="fa-solid fa-user"></i>
              </label>
              <input type="text" placeholder="Username"
                {...register("email", {
                  required: {
                    value: true,
                    message: "Email is required"
                  },
                  pattern: {
                    value: EMAIL_REGEX,
                    message: "Invalid Email Address",
                  },
                }
                )} />
            </div>
            <div class="input_box">
              <button type="submit" class="red_button_styl">Reset Password</button>
            </div>
            <div class="input_box cancel_btn">
              <NavLink to={PATHS.USERSIGNIN}>Cancel</NavLink>
            </div>
          </div>
        </form>

        <p>@2024 Azim Premji University. All rights reserved.</p>
      </div>
    </div>
  )
}

export default ForgetPassword;
