import React, { useState, useEffect } from "react";
import Home from "../Home";
import { getCapitalized } from "../../utils/Helper";
import { useForm } from "react-hook-form";
import { HELP_TEXT, PATHS } from "../../utils";
import { NavLink, useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { useDispatch, useSelector } from "react-redux";
import {
  createEducationpg,
  getpgEducationDetail,
} from "../../redux/actions/Api";
import { toast } from "react-toastify";
import Success from "../notification/Success";
import Loader from "../Loader";
import { NUM_REGEX } from "../../utils/Regex";
import { resetForms, unsavedchanges } from "../../redux/actions";
import Error from "../notification/Error";
import { Notes } from "../common/Notes";
import { CREATEEDUCATION_DETAIL_PG } from "../../redux/actions/Types";
import moment from "moment";
import {
  AppCommon,
  ApplicationViewPage,
  PGEducationDetails,
} from "../constantLabelFile";

let DraftBtn = "";
let bachelorsStatus = [];
let bachelorsStatusYear;

const EducationDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    picklistdata,
    error,
    pgedudata,
    pgEducationdetailres,
    dashboardresponse,
    bachelorsCompletionStatus
  } = useSelector((state) => state.collections);

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
  });

  const [experience, setExprience] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectednewCountry, setSelectednewCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [district, setDistrict] = useState([]);
  const [selectedState1, setSelectedState1] = useState("");
  const [selectedDistrict1, setSelectedDistrict1] = useState("");
  const [district1, setDistrict1] = useState([]);
  const [loader, setLoader] = useState(true);
  const [pickListVal, setPickListVal] = useState("");
  const [medInsti, setMedInsti] = useState("");
  const [mediumInsti, setMediumInsti] = useState("");
  const [pgDegree, setPgdegree] = useState("");
  const [allEduresponsedata, setAllEduresponsedata] = useState("");

  const [readOnly, setReadOnly] = useState(false);
  const [showMsg, setShowMsg] = useState("");

  useEffect(() => {
    if (pgEducationdetailres == undefined) {
      setLoader(true);
    } else {
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  }, [pgEducationdetailres]);

  useEffect(() => {
    setAllEduresponsedata(pgEducationdetailres?.data?.records[0]);
  }, [pgEducationdetailres]);
 
  useEffect(()=>{
    bachelorsStatus = bachelorsCompletionStatus?.Value?.split(","); 
    bachelorsStatusYear = dashboardresponse?.data?.allDataOnDashboard[0]?.appCycleEndDateYear;
    if (bachelorsStatus && bachelorsStatusYear) {
      bachelorsStatus = 
        {
          ...bachelorsStatus,
          1: `${bachelorsStatus[1]} ${bachelorsStatusYear}` 
        };
    }
  }, [bachelorsCompletionStatus, dashboardresponse]) 

  useEffect(() => {
    if (
      allEduresponsedata?.hed__Education_History__r &&
      picklistdata &&
      pickListVal
    ) {
      setValue(
        "bachelorsCompletedByAug",
        allEduresponsedata?.hed__Education_History__r?.records[1]
          ?.Bachelors_complete_by_Aug1_NextYear__c
      );          
      setValue(
        "qualificationsObtained",
        allEduresponsedata?.hed__Education_History__r?.records[1]
          ?.Qualification_obtained__c
      );
      setValue(
        "completionYear",
        allEduresponsedata?.hed__Education_History__r?.records[1]
          ?.Year_of_completion__c
      );
      setValue(
        "HaveUGEconomicsPassed",
        allEduresponsedata?.hed__Education_History__r?.records[1]
          ?.Have_you_passed_your_UG_Economics__c
      );
      setValue("durationUndergraduateProg", allEduresponsedata?.hed__Education_History__r?.records[1]?.APU_Duration_of_your_UG__c)

      setValue(
        "townCategoryTenth",
        allEduresponsedata?.hed__Education_History__r?.records[0]
          ?.Category_of_city_town_where_studied__c
      );
      setValue(
        "countryTenth",
        allEduresponsedata?.hed__Education_History__r?.records[0]?.Country__c
      );
      setSelectedCountry(
        allEduresponsedata?.hed__Education_History__r?.records[0]?.Country__c
      );
      setValue(
        "otherCountrySpecifyTenth",
        allEduresponsedata?.hed__Education_History__r?.records[0]
          ?.Please_Specify_Country__c
      );
      setValue(
        "mediumInstTenth",
        allEduresponsedata?.hed__Education_History__r?.records[0]
          ?.Medium_of_instruction__c
      );
      setMedInsti(
        allEduresponsedata?.hed__Education_History__r?.records[0]
          ?.Medium_of_instruction__c
      );
      setValue(
        "specifyMediumTenth",
        allEduresponsedata?.hed__Education_History__r?.records[0]
          ?.Please_Specify_Medium_of_Instruction__c
      );
      setValue(
        "stateTenth",
        allEduresponsedata?.hed__Education_History__r?.records[0]?.State__c
      );
      setSelectedState(
        allEduresponsedata?.hed__Education_History__r?.records[0]?.State__c
      );
      setValue(
        "districtTenth",
        allEduresponsedata?.hed__Education_History__r?.records[0]?.District__c
      );
      if (
        pickListVal &&
        allEduresponsedata?.hed__Education_History__r?.records[0]
          ?.Country__c === "India"
      ) {
        const stateObject =
          allEduresponsedata?.hed__Education_History__r?.records[0]
            ?.Country__c === "India" &&
          pickListVal?.districts?.India[
            allEduresponsedata?.hed__Education_History__r?.records[0]?.State__c
          ];
        const dis = Object?.values(stateObject);
        setDistrict(dis);
      }
      if (
        pickListVal &&
        allEduresponsedata?.hed__Education_History__r?.records[1]
          ?.Country__c === "India"
      ) {
        const stateObject1 =
          allEduresponsedata?.hed__Education_History__r?.records[1]
            ?.Country__c === "India" &&
          pickListVal?.districts?.India[
            allEduresponsedata?.hed__Education_History__r?.records[1]?.State__c
          ];
        const dis1 = Object?.values(stateObject1);
        setDistrict1(dis1);
      }

      setSelectedDistrict(
        allEduresponsedata?.hed__Education_History__r?.records[0]?.District__c
      );

      setValue(
        "townCategoryGrad",
        allEduresponsedata?.hed__Education_History__r?.records[1]
          ?.Category_of_city_town_where_studied__c
      );
      setValue(
        "gradMode",
        allEduresponsedata?.hed__Education_History__r?.records[1]
          ?.Mode_of_graduation__c
      );
      setValue(
        "streamSpecialization",
        allEduresponsedata?.hed__Education_History__r?.records[1]
          ?.Stream_Specialization__c
      );
      setValue(
        "universityName",
        allEduresponsedata?.hed__Education_History__r?.records[1]
          ?.Name_of_the_University__c
      );
      setValue(
        "schoolNameGrad",
        allEduresponsedata?.hed__Education_History__r?.records[1]
          ?.hed__Educational_Institution_Name__c
      );
      setValue(
        "countryGrad",
        allEduresponsedata?.hed__Education_History__r?.records[1]?.Country__c
      );
      setSelectednewCountry(
        allEduresponsedata?.hed__Education_History__r?.records[1]?.Country__c
      );
      setValue(
        "otherCountrySpecifyGrad",
        allEduresponsedata?.hed__Education_History__r?.records[1]
          ?.Please_Specify_Country__c
      );
      setValue(
        "stateGrad",
        allEduresponsedata?.hed__Education_History__r?.records[1]?.State__c
      );
      setSelectedState1(
        allEduresponsedata?.hed__Education_History__r?.records[1]?.State__c
      );
      setValue(
        "districtGrad",
        allEduresponsedata?.hed__Education_History__r?.records[1]?.District__c
      );

      setSelectedDistrict1(
        allEduresponsedata?.hed__Education_History__r?.records[1]?.District__c
      );

      setValue(
        "mediumInstGrad",
        allEduresponsedata?.hed__Education_History__r?.records[1]
          ?.Medium_of_instruction__c
      );
      setMediumInsti(
        allEduresponsedata?.hed__Education_History__r?.records[1]
          ?.Medium_of_instruction__c
      );
      setValue(
        "specifyMediumGrad",
        allEduresponsedata?.hed__Education_History__r?.records[1]
          ?.Please_Specify_Medium_of_Instruction__c
      );
      setValue(
        "havePGDegree",
        allEduresponsedata?.hed__Education_History__r?.records[1]
          ?.Do_you_have_any_Postgraduate_degree__c
      );
      setPgdegree(
        allEduresponsedata?.hed__Education_History__r?.records[1]
          ?.Do_you_have_any_Postgraduate_degree__c
      );
      setValue(
        "qualificationName",
        allEduresponsedata?.hed__Education_History__r?.records[1]
          ?.Name_on_Qualification__c
      );

      setValue(
        "workExperience",
        allEduresponsedata?.Work_experience_after_gr__c
      );
      setExprience(allEduresponsedata?.Work_experience_after_gr__c);
      setValue(
        "workExperienceInMonths",
        allEduresponsedata?.Work_experience_in_months__c
      );
      setValue(
        "AreCurrentlyEmployed",
        allEduresponsedata?.Are_you_currently_employed__c
      );
      setValue(
        "organization",
        allEduresponsedata?.Organization_where_you_are__c
      );
      setValue(
        "typeOfOrganization",
        allEduresponsedata?.Type_of_organization__c
      );
      setValue(
        "monthlyGrossSalary",
        allEduresponsedata?.Monthly_gross_salary_Rs__c
      );
      setValue(
        "annualGrossSalary",
        allEduresponsedata?.Annual_gross_salary_Rs__c
      );
    }
  }, [allEduresponsedata, picklistdata, pickListVal, bachelorsStatus, bachelorsStatusYear, bachelorsCompletionStatus]);

  
  useEffect(() => {
    if (error) {
      setLoader(false);
      toast.error(<Error error={error} />, {
        position: "top-center",
        autoClose: 5000,
        className: "Toastify__toast-theme--colored.Toastify__toast--warning",
      });
      setTimeout(() => {
        dispatch(resetForms());
      }, 5000);
    }
  }, [error]);

  useEffect(() => {
    let formData = {
      contactId: localStorage.getItem("contactId"),
      applicationId: localStorage.getItem("applicationId"),
    };
    if (formData) {
      dispatch(getpgEducationDetail(formData));
    }
  }, []);

  useEffect(() => {
    setPickListVal(picklistdata?.data);
  }, [picklistdata]);

  let draftMsg = {
    message: "Details Saved Successfully.",
  };

  useEffect(() => {
    if (pgedudata) {
      dispatch(unsavedchanges());
      toast.success(<Success response={!DraftBtn ? pgedudata : draftMsg} />, {
        position: "top-center",
        autoClose: 2000,
      });
      setLoader(false);
      if (!DraftBtn) {
        setTimeout(() => {
          dispatch({ type: CREATEEDUCATION_DETAIL_PG, payload: undefined });
          navigate(
            PATHS.SOCIALECOPG_STR +
              dashboardresponse?.data?.allDataOnDashboard[0]?.appId
          );
        }, 500);
      } else if (DraftBtn) {
        setTimeout(() => {
          draftMsg.message = "";
          dispatch({ type: CREATEEDUCATION_DETAIL_PG, payload: undefined });
        }, 500);
      }
    }
  }, [pgedudata]);

  const handleExprience = (e) => {
    setExprience(e.target.value);
    dispatch(unsavedchanges(e.target.value));
    clearErrors("workExperience");
  };

  const handleState = (e) => {
    setSelectedState(e.target.value);
    const stateObject = pickListVal?.districts?.India[e.target.value];
    const dis = Object?.values(stateObject);
    setDistrict(dis);
    setSelectedDistrict("");
    dispatch(unsavedchanges(e.target.value));
    clearErrors("stateTenth");
  };

  const handlenewState = (e) => {
    setSelectedState1(e.target.value);
    const stateObject = pickListVal?.districts?.India[e.target.value];
    const dis = Object?.values(stateObject);
    setDistrict1(dis);
    setSelectedDistrict1("");

    dispatch(unsavedchanges(e.target.value));
    clearErrors("stateGrad");
  };

  const _onSubmit = (values) => {
    let formData = {
      userId: localStorage.getItem("userId"),
      contactId: localStorage.getItem("contactId"),
      applicationId: localStorage.getItem("applicationId"),
      bachelorsCompletedByAug: values.bachelorsCompletedByAug,
      qualificationsObtained: values.qualificationsObtained,
      completionYear: values.completionYear,
      durationUndergraduateProg: values.durationUndergraduateProg,
      townCategoryTenth: values.townCategoryTenth,
      countryTenth: values.countryTenth ? values.countryTenth : "",
      otherCountrySpecifyTenth:
        values.countryTenth === "Others" ? values.otherCountrySpecifyTenth : "",
      mediumInstTenth: values.mediumInstTenth ? values.mediumInstTenth : "",
      specifyMediumTenth: values.specifyMediumTenth
        ? values.specifyMediumTenth
        : "",
      stateTenth:
        values.countryTenth === "India"
          ? values.stateTenth
            ? values.stateTenth
            : ""
          : "",
      districtTenth:
        values.countryTenth === "India"
          ? values.districtTenth
            ? values.districtTenth
            : null
          : null,
      townCategoryGrad: values.townCategoryGrad,
      gradMode: values.gradMode,
      streamSpecialization: values.streamSpecialization,
      universityName: values.universityName,
      schoolNameGrad: values.schoolNameGrad,
      countryGrad: values.countryGrad ? values.countryGrad : "",
      otherCountrySpecifyGrad:
        values.countryGrad === "Others" ? values.otherCountrySpecifyGrad : "",
      stateGrad:
        values.countryGrad === "India"
          ? values.stateGrad
            ? values.stateGrad
            : ""
          : "",
      districtGrad:
        values.countryGrad === "India"
          ? values.districtGrad
            ? values.districtGrad
            : null
          : null,
      mediumInstGrad: values.mediumInstGrad,
      specifyMediumGrad: values.specifyMediumGrad
        ? values.specifyMediumGrad
        : "",
      havePGDegree: values.havePGDegree,
      qualificationName:
        values.havePGDegree == "Yes" ? values.qualificationName : "",
      workExperience: values.workExperience,
      AreCurrentlyEmployed:
        values.workExperience === "Yes" ? values.AreCurrentlyEmployed : "",
      workExperienceInMonths:
        values.workExperience === "Yes"
          ? parseInt(values.workExperienceInMonths)
          : null,
      organization: values.workExperience === "Yes" ? values.organization : "",
      typeOfOrganization:
        values.workExperience === "Yes" ? values.typeOfOrganization : "",
      annualGrossSalary:
        values.workExperience === "Yes"
          ? parseInt(values.annualGrossSalary)
          : null,
      HaveUGEconomicsPassed: values.HaveUGEconomicsPassed
        ? values.HaveUGEconomicsPassed
        : "",
      allPGDetails: "",
    };

    if (formData) {
      setLoader(true);
      dispatch(createEducationpg(formData));
    }
  };

  const currentDate = new Date();
  const year1 = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDate = `${year1}-${month}-${day}`;

  useEffect(() => {
    if (
      allEduresponsedata?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Started" ||
      allEduresponsedata?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Draft" ||
      allEduresponsedata?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Payment Failed" ||
      allEduresponsedata?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Payment Pending" ||
      allEduresponsedata?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Payment Submitted"
    ) {
      if (
        formattedDate >
        allEduresponsedata?.hed__Applications_Applicant__r?.records[0]
          ?.Admission_Cycle__r?.Application_Submission_Date__c
      ) {
        setReadOnly(true);
        setShowMsg(<Notes name="ADM_Cycle_Validation_App" />);
      } else {
        setReadOnly(false);
        setShowMsg("");
      }
    } else {
      if (
        formattedDate <
        allEduresponsedata?.hed__Applications_Applicant__r?.records[0]
          ?.Admission_Cycle__r?.Application_Submission_Date__c
      ) {
        setReadOnly(true);
        setShowMsg("");
      } else {
        setReadOnly(true);
        setShowMsg(<Notes name="ADM_Cycle_Validation_App" />);}
    }
  }, [allEduresponsedata]);


  return (
    <Home>
      <div class="right_panel">
        <div class="w100">
          <p className="top_head_styl">{showMsg}</p>
          <h4 className="top_head">
            {getCapitalized(PGEducationDetails.Postgraduate_Programme)}
          </h4>
          <span className="help_link">
            <p>
              <NavLink to={PATHS.RAISEQUERY}>{HELP_TEXT}</NavLink>{" "}
              <Tooltip id="educationhelp">
                <p>{ApplicationViewPage.Help_Tooltip_Text}</p>
              </Tooltip>
              <i
                data-tooltip-id="educationhelp"
                class="fa-solid fa-circle-info"
              ></i>
            </p>
          </span>
        </div>
        <div className="w100 scroll_styl">
          <div class="heading_styl head_font">
            <h4>{getCapitalized(PGEducationDetails.Education_Details)}</h4>
          </div>
          <Loader show={loader} />
          <form
            onSubmit={handleSubmit(_onSubmit)}
            disabled={readOnly}
          >
            <div class="form_sec form_part">
              <div class="heading_styl">
                <h4>{PGEducationDetails.Class_10_Details}</h4>
              </div>

              <div class="form_styl">
                <div class="w100 blc_txt cat_pg_text">
                  <Notes name="Category_PG" />
                </div>
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {PGEducationDetails.Category_Of_City_10}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <select
                    {...register("townCategoryTenth", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    onChange={(e) => {
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("townCategoryTenth");
                    }}
                    disabled={readOnly}
                    className={`${errors.townCategoryTenth ? "error_msg" : ""}`}
                  >
                    <option disabled selected value="">
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.regionOfResidence?.map((soci, ind) => (
                        <option value={soci.key}>{soci.value}</option>
                      ))}
                  </select>
                  {errors.townCategoryTenth && (
                    <div className="error_shown">
                      {errors.townCategoryTenth?.message}
                    </div>
                  )}
                </div>
                <div class="inner_part">
                  <label>
                    {PGEducationDetails.Country_10}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <select
                    {...register("countryTenth", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    disabled={readOnly}
                    value={selectedCountry}
                    onChange={(e) => {
                      setSelectedCountry(e.target.value);
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("countryTenth");
                    }}
                    className={`${errors.countryTenth ? "error_msg" : ""}`}
                  >
                    <option disabled selected value="">
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.country?.map((soci, ind) => (
                        <option value={soci.key}>{soci.value}</option>
                      ))}
                  </select>
                  {errors.countryTenth && (
                    <div className="error_shown">
                      {errors.countryTenth?.message}
                    </div>
                  )}
                </div>
              </div>
              <div class="form_styl flex-wrap">
                {selectedCountry === "Others" && (
                  <div class="inner_part">
                    <label>
                      {PGEducationDetails.Please_Specify}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input
                      {...register("otherCountrySpecifyTenth", {
                        required: {
                          value: true,
                          message: "Please select value",
                        },
                      })}
                      autoComplete="off"
                      onChange={(e) => {
                        dispatch(unsavedchanges(e.target.value));
                        clearErrors("otherCountrySpecifyTenth");
                      }}
                      className={`${
                        errors.otherCountrySpecifyTenth ? "error_msg" : ""
                      }`}
                      disabled={readOnly}
                    />
                    {errors.otherCountrySpecifyTenth && (
                      <div className="error_shown">
                        {errors.otherCountrySpecifyTenth?.message}
                      </div>
                    )}
                  </div>
                )}
                <div class="inner_part">
                  <label>
                    {PGEducationDetails.Medium_Of_Instruction_10}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <select
                    {...register("mediumInstTenth", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    className={`${errors.mediumInstTenth ? "error_msg" : ""}`}
                    value={medInsti}
                    onChange={(e) => {
                      setMedInsti(e.target.value);
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("mediumInstTenth");
                    }}
                    disabled={readOnly}
                  >
                    <option disabled selected value="">
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.mediumOfInstruction?.map((soci, ind) => (
                        <option value={soci.key}>{soci.value}</option>
                      ))}
                  </select>
                  {errors.mediumInstTenth && (
                    <div className="error_shown">
                      {errors.mediumInstTenth?.message}
                    </div>
                  )}
                </div>
                {medInsti === "Other" && (
                  <div class="inner_part">
                    <label>
                      {PGEducationDetails.Please_Specify}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input
                      {...register("specifyMediumTenth", {
                        required: {
                          value: true,
                          message: "Please select value",
                        },
                      })}
                      autoComplete="off"
                      onChange={(e) => {
                        dispatch(unsavedchanges(e.target.value));
                        clearErrors("specifyMediumTenth");
                      }}
                      disabled={readOnly}
                      className={`${
                        errors.specifyMediumTenth ? "error_msg" : ""
                      }`}
                    />
                    {errors.specifyMediumTenth && (
                      <div className="error_shown">
                        {errors.specifyMediumTenth?.message}
                      </div>
                    )}
                  </div>
                )}
                <div class="inner_part">
                  <label>{PGEducationDetails.State_Union_10}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <select
                    {...register("stateTenth", {
                      required: {
                        value: selectedCountry === "Others" ? false : true,
                        message: "Please select value",
                      },
                    })}
                    className={`${errors.stateTenth ? "error_msg" : ""}`}
                    value={selectedState}
                    onChange={handleState}
                    disabled={
                      !selectedCountry ||
                      selectedCountry === "Others" ||
                      readOnly
                    }
                  >
                    {selectedCountry === "Others" ? (
                      <option disabled selected value="">
                        {AppCommon.Dropdown_NA_Option}
                      </option>
                    ) : (
                      <option disabled value="">
                        {AppCommon.Dropdown_Default_Option}
                      </option>
                    )}
                    {selectedCountry !== "Others" &&
                      pickListVal &&
                      pickListVal?.states?.map((soci, ind) => (
                        <option value={soci.Id}>{soci.value}</option>
                      ))}
                  </select>
                  {errors.stateTenth && (
                    <div className="error_shown">
                      {errors.stateTenth?.message}
                    </div>
                  )}
                </div>
              </div>

              <div class="form_styl">
                <div class="inner_part">
                  <label>{PGEducationDetails.District_10}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <select
                    {...register("districtTenth", {
                      required: {
                        value: selectedCountry === "Others" ? false : true,
                        message: "Please select value",
                      },
                    })}
                    className={`${errors.districtTenth ? "error_msg" : ""}`}
                    value={selectedDistrict}
                    onChange={(e) => {
                      setSelectedDistrict(e.target.value);
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("districtTenth");
                    }}
                    disabled={selectedCountry === "Others" || readOnly}
                  >
                    {selectedCountry === "Others" ? (
                      <option disabled selected value="">
                        {AppCommon.Dropdown_NA_Option}
                      </option>
                    ) : (
                      <option disabled value=""></option>
                    )}
                    {selectedCountry !== "Others" &&
                      district &&
                      district?.map((distObj, ind) => (
                        <option key={ind} value={distObj.Id}>
                          {distObj.Name}
                        </option>
                      ))}
                  </select>
                  {errors.districtTenth && (
                    <div className="error_shown">
                      {errors.districtTenth?.message}
                    </div>
                  )}
                </div>
              </div>
              <div class="heading_styl">
                <h4>{PGEducationDetails.Undergraduate_Details}</h4>
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>                   
                    {PGEducationDetails.Bachelor_Degree_Completion_Status}{" "}
                    <span class="redstyl">* </span>
                  </label>   
                        
                  <select
                    {...register("bachelorsCompletedByAug", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}                    
                    value={getValues("bachelorsCompletedByAug") || ""}
                    onChange={(e) => {                      
                      setValue("bachelorsCompletedByAug", e.target.value);                      
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("bachelorsCompletedByAug");                      
                    }}                    
                    disabled={readOnly}
                    className={`${
                      errors.bachelorsCompletedByAug ? "error_msg" : ""
                    }`}
                  >                    
                       <option disabled selected value="">
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {bachelorsStatusYear && bachelorsStatus && 
  Object.values(bachelorsStatus).map((soci, ind) => (
    <option key={ind} value={soci}>{soci}</option>
  ))
}                                     
                  </select>                                                
                  {errors.bachelorsCompletedByAug && (
                    <div className="error_shown">
                      {errors.bachelorsCompletedByAug?.message}
                    </div>
                  )}
                </div>
                <div class="inner_part notes_class">
                  {allEduresponsedata?.hed__Applications_Applicant__r
                    ?.records[0]?.First_Preference_Name__c ===
                    "Master of Public Health" && (
                    <Notes name="Master_of_Public_Health" />
                  )} 
                </div>
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {PGEducationDetails.Qualification_Obtained}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <select
                    {...register("qualificationsObtained", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    onChange={(e) => {
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("qualificationsObtained");
                    }}
                    disabled={
                      readOnly &&
                      formattedDate >
                        allEduresponsedata?.hed__Applications_Applicant__r
                          ?.records[0]?.Admission_Cycle__r
                          ?.Preference_End_Date__c
                    }
                    className={`${
                      errors.qualificationsObtained ? "error_msg" : ""
                    }`}
                  >
                    <option disabled selected value="">
                      
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.qualificationObtained?.map((soci, ind) => (
                        <option value={soci.key}>{soci.value}</option>
                      ))}
                      
                  </select>
                  {errors.qualificationsObtained && (
                    <div className="error_shown">
                      {errors.qualificationsObtained?.message}
                    </div>
                  )}
                </div>
                <div class="inner_part">
                  <label>
                    {PGEducationDetails.Year_Of_Completion}{" "}
                    <span class="redstyl">* </span>
                    <i
                      data-tooltip-id="helpTooltip"
                      class="fa-solid fa-circle-info"
                    ></i>
                    <Tooltip id="helpTooltip">
                      <p>
                        {PGEducationDetails.Year_Of_Completion_Tooltip_Message}
                      </p>
                    </Tooltip>
                  </label>
                  <input
                    {...register("completionYear", {
                      required: {
                        value: true,
                        message: "Please enter value",
                      },
                      pattern: {
                        value: NUM_REGEX,
                        message:
                          "Your entry does not match the allowed pattern.",
                      },
                      maxLength: {
                        value: 4,
                        message: "Please enter valid year",
                      },
                      minLength: {
                        value: 4,
                        message: "Please enter valid year",
                      },
                    })}
                    autoComplete="off"
                    onChange={(e) => {
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("completionYear");
                    }}
                    disabled={readOnly}
                    maxLength="4"
                    className={`${errors.completionYear ? "error_msg" : ""}`}
                  />
                  {errors.completionYear && (
                    <div className="error_shown">
                      {errors.completionYear?.message}
                    </div>
                  )}
                </div>
              </div>

              {allEduresponsedata?.hed__Applications_Applicant__r?.records[0]
                ?.First_Preference_Name__c == "M.A. Economics" && (
                <div class="form_styl">
                  <div class="inner_part">
                    <label>
                      {PGEducationDetails.UG_Economics_Passed_Question}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <select
                      {...register("HaveUGEconomicsPassed", {
                        required: {
                          value: true,
                          message: "Please select value",
                        },
                      })}
                      onChange={(e) => {
                        dispatch(unsavedchanges(e.target.value));
                        clearErrors("HaveUGEconomicsPassed");
                      }}
                      disabled={
                        readOnly &&
                        formattedDate >
                          allEduresponsedata?.hed__Applications_Applicant__r
                            ?.records[0]?.Admission_Cycle__r
                            ?.Preference_End_Date__c
                      }
                      className={`${
                        errors.HaveUGEconomicsPassed ? "error_msg" : ""
                      }`}
                    >
                      <option disabled selected value="">
                        {AppCommon.Dropdown_Default_Option}
                      </option>
                      {pickListVal &&
                        pickListVal?.haveYouPassedUGEconomics?.map(
                          (soci, ind) => (
                            <option value={soci.key}>{soci.value}</option>
                          )
                        )}
                    </select>
                    {errors.HaveUGEconomicsPassed && (
                      <div className="error_shown">
                        {errors.HaveUGEconomicsPassed?.message}
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div class="form_styl">
                <div class="inner_part">
                  <label>                    
                    {PGEducationDetails.Duration_of_Undergraduate_Programme}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <select
                    {...register("durationUndergraduateProg", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    onChange={(e) => {
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("durationUndergraduateProg");
                    }}
                    disabled={
                      readOnly &&
                      formattedDate >
                        allEduresponsedata?.hed__Applications_Applicant__r
                          ?.records[0]?.Admission_Cycle__r
                          ?.Preference_End_Date__c
                    }
                    className={`${
                      errors.durationUndergraduateProg ? "error_msg" : ""
                    }`}
                  >
                    <option disabled selected value="">
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.durationUG?.map((soci, ind) => (
                        <option value={soci.key}>{soci.value}</option>
                      ))}
                  </select>
                  {errors.durationUndergraduateProg && (
                    <div className="error_shown">
                      {errors.durationUndergraduateProg?.message}
                    </div>
                  )}
                </div>
                <div class="inner_part"></div>
                </div>
               <br />
              <div class="form_styl">
                <div class="w100 blc_txt">
                  <Notes name="Category" />
                </div>
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {PGEducationDetails.Category_Of_City_UG}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <select
                    {...register("townCategoryGrad", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    onChange={(e) => {
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("townCategoryGrad");
                    }}
                    className={`${errors.townCategoryGrad ? "error_msg" : ""}`}
                    disabled={readOnly}
                  >
                    <option disabled selected value="">
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.regionOfResidence?.map((soci, ind) => (
                        <option value={soci.Id}>{soci.value}</option>
                      ))}
                  </select>
                  {errors.townCategoryGrad && (
                    <div className="error_shown">
                      {errors.townCategoryGrad?.message}
                    </div>
                  )}
                </div>
                <div class="inner_part">
                  <label>
                    {PGEducationDetails.Mode_Of_Graduation}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <select
                    {...register("gradMode", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    onChange={(e) => {
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("gradMode");
                    }}
                    className={`${errors.gradMode ? "error_msg" : ""}`}
                    disabled={readOnly}
                  >
                    <option disabled selected value="">
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.modeOfGraduation?.map((soci, ind) => (
                        <option value={soci.Id}>{soci.value}</option>
                      ))}
                  </select>
                  {errors.gradMode && (
                    <div className="error_shown">
                      {errors.gradMode?.message}
                    </div>
                  )}
                </div>
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {PGEducationDetails.Stream_UG}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <input
                    type="text"
                    {...register("streamSpecialization", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    autoComplete="off"
                    onChange={(e) => {
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("streamSpecialization");
                    }}
                    disabled={readOnly}
                    className={`${
                      errors.streamSpecialization ? "error_msg" : ""
                    }`}
                  />
                  {errors.streamSpecialization && (
                    <div className="error_shown">
                      {errors.streamSpecialization?.message}
                    </div>
                  )}
                </div>
                <div class="inner_part">
                  <label>
                    {PGEducationDetails.University_Name_UG}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <input
                    type="text"
                    {...register("universityName", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    autoComplete="off"
                    onChange={(e) => {
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("universityName");
                    }}
                    disabled={readOnly}
                    className={`${errors.universityName ? "error_msg" : ""}`}
                  />
                  {errors.universityName && (
                    <div className="error_shown">
                      {errors.universityName?.message}
                    </div>
                  )}
                </div>
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {PGEducationDetails.College_Name_UG}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <input
                    type="text"
                    {...register("schoolNameGrad", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    autoComplete="off"
                    onChange={(e) => {
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("schoolNameGrad");
                    }}
                    disabled={readOnly}
                    className={`${errors.schoolNameGrad ? "error_msg" : ""}`}
                  />
                  {errors.schoolNameGrad && (
                    <div className="error_shown">
                      {errors.schoolNameGrad?.message}
                    </div>
                  )}
                </div>
                <div class="inner_part">
                  <label>
                    {PGEducationDetails.Country_UG}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <select
                    {...register("countryGrad", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    disabled={readOnly}
                    className={`${errors.countryGrad ? "error_msg" : ""}`}
                    value={selectednewCountry}
                    onChange={(e) => {
                      setSelectednewCountry(e.target.value);
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("countryGrad");
                    }}
                  >
                    <option disabled selected value="">
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.country?.map((soci, ind) => (
                        <option value={soci.Id}>{soci.value}</option>
                      ))}
                  </select>
                  {errors.countryGrad && (
                    <div className="error_shown">
                      {errors.countryGrad?.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="form_styl">
                {selectednewCountry === "Others" && (
                  <div class="inner_part">
                    <label>
                      {PGEducationDetails.Please_Specify}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input
                      {...register("otherCountrySpecifyGrad", {
                        required: {
                          value: true,
                          message: "Please select value",
                        },
                      })}
                      autoComplete="off"
                      onChange={(e) => {
                        dispatch(unsavedchanges(e.target.value));
                        clearErrors("otherCountrySpecifyGrad");
                      }}
                      className={`${
                        errors.otherCountrySpecifyGrad ? "error_msg" : ""
                      }`}
                      disabled={readOnly}
                    />
                    {errors.otherCountrySpecifyGrad && (
                      <div className="error_shown">
                        {errors.otherCountrySpecifyGrad?.message}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {PGEducationDetails.State_Union_Residence_UG}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <select
                    {...register("stateGrad", {
                      required: {
                        value: selectednewCountry === "Others" ? false : true,
                        message: "Please select value",
                      },
                    })}
                    className={`${errors.stateGrad ? "error_msg" : ""}`}
                    value={selectedState1}
                    onChange={handlenewState}
                    disabled={
                      !selectednewCountry ||
                      selectednewCountry === "Others" ||
                      readOnly
                    }
                  >
                    {selectednewCountry === "Others" ? (
                      <option selected disabled value="">
                        {AppCommon.Dropdown_NA_Option}
                      </option>
                    ) : (
                      <option disabled value="">
                        {AppCommon.Dropdown_Default_Option}
                      </option>
                    )}
                    {selectednewCountry !== "Others" &&
                      pickListVal?.states?.map((stateobj, ind) => (
                        <option value={stateobj.Id} key={ind}>
                          {stateobj.value}
                        </option>
                      ))}
                  </select>
                  {errors.stateGrad && (
                    <div className="error_shown">
                      {errors.stateGrad?.message}
                    </div>
                  )}
                </div>
                <div class="inner_part">
                  <label>
                    {PGEducationDetails.District_UG}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <select
                    type="text"
                    {...register("districtGrad", {
                      required: {
                        value: selectednewCountry === "Others" ? false : true,
                        message: "Please select value",
                      },
                    })}
                    disabled={selectednewCountry === "Others" || readOnly}
                    className={`${errors.districtGrad ? "error_msg" : ""}`}
                    value={selectedDistrict1}
                    onChange={(e) => {
                      setSelectedDistrict1(e.target.value);
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("districtGrad");
                    }}
                  >
                    {selectednewCountry === "Others" && (
                      <option disabled selected value="">
                        {AppCommon.Dropdown_NA_Option}
                      </option>
                    )}
                    {selectednewCountry !== "Others" && (
                      <option disabled value=""></option>
                    )}
                    {selectednewCountry !== "Others" &&
                      district1 &&
                      district1?.map((distObj, ind) => (
                        <option key={ind} value={distObj.Id}>
                          {distObj.Name}
                        </option>
                      ))}
                  </select>
                  {errors.districtGrad && (
                    <div className="error_shown">
                      {errors.districtGrad?.message}
                    </div>
                  )}
                </div>
              </div>
              <div class="form_styl flex-wrap">
                <div class="inner_part">
                  <label>
                   {PGEducationDetails.Medium_Of_Instruction_UG}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <select
                    {...register("mediumInstGrad", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    disabled={readOnly}
                    className={`${errors.mediumInstGrad ? "error_msg" : ""}`}
                    value={mediumInsti}
                    onChange={(e) => {
                      setMediumInsti(e.target.value);
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("mediumInstGrad");
                    }}
                  >
                    <option disabled selected value="">
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.mediumOfInstruction?.map((soci, ind) => (
                        <option value={soci.Id}>{soci.value}</option>
                      ))}
                  </select>
                  {errors.mediumInstGrad && (
                    <div className="error_shown">
                      {errors.mediumInstGrad?.message}
                    </div>
                  )}
                </div>
                {mediumInsti === "Other" && (
                  <div class="inner_part">
                    <label>
                      {PGEducationDetails.Please_Specify}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input
                      {...register("specifyMediumGrad", {
                        required: {
                          value: true,
                          message: "Please select value",
                        },
                      })}
                      autoComplete="off"
                      onChange={(e) => {
                        dispatch(unsavedchanges(e.target.value));
                        clearErrors("specifyMediumGrad");
                      }}
                      disabled={readOnly}
                      className={`${
                        errors.specifyMediumGrad ? "error_msg" : ""
                      }`}
                    />
                    {errors.specifyMediumGrad && (
                      <div className="error_shown">
                        {errors.specifyMediumGrad?.message}
                      </div>
                    )}
                  </div>
                )}
                <div class="inner_part">
                  <label>
                    {PGEducationDetails.PG_Degree_Question}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <select
                    {...register("havePGDegree", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    className={`${errors.havePGDegree ? "error_msg" : ""}`}
                    value={pgDegree}
                    onChange={(e) => {
                      setPgdegree(e.target.value);
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("havePGDegree");
                    }}
                    disabled={readOnly}
                  >
                    <option disabled selected value="">
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.doYouHavePostGradDegree?.map((soci, ind) => (
                        <option value={soci.Id}>{soci.value}</option>
                      ))}
                  </select>
                  {errors.havePGDegree && (
                    <div className="error_shown">
                      {errors.havePGDegree?.message}
                    </div>
                  )}
                </div>
              </div>

              {pgDegree == "Yes" && (
                <div class="form_styl">
                  <div class="inner_part">
                    <label>
                      {PGEducationDetails.PG_Degree_Name}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input
                      type="text"
                      {...register("qualificationName", {
                        required: {
                          value: true,
                          message: "Please select value",
                        },
                      })}
                      autoComplete="off"
                      onChange={(e) => {
                        dispatch(unsavedchanges(e.target.value));
                        clearErrors("qualificationName");
                      }}
                      disabled={readOnly}
                      className={`${
                        errors.qualificationName ? "error_msg" : ""
                      }`}
                    />
                    {errors.qualificationName && (
                      <div className="error_shown">
                        {errors.qualificationName?.message}
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div class="heading_styl">
                <h4>{PGEducationDetails.Work_Experience}</h4>
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {PGEducationDetails.Experience_After_Graduation_Question}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <select
                    {...register("workExperience", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    disabled={readOnly}
                    className={`${errors.workExperience ? "error_msg" : ""}`}
                    onChange={(e) => {
                      handleExprience(e);
                    }}
                  >
                    <option disabled selected value="">
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.workExpAfterGraduation?.map((soci, ind) => (
                        <option
                          value={soci.Id}
                          >
                          {soci.value}
                        </option>
                      ))}
                  </select>
                  {errors.workExperience && (
                    <div className="error_shown">
                      {errors.workExperience?.message}
                    </div>
                  )}
                </div>
                {experience === "Yes" && (
                  <div class="inner_part">
                    <label>
                      {PGEducationDetails.Currently_Employed_Question}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <select
                      {...register("AreCurrentlyEmployed", {
                        required: {
                          value: true,
                          message: "Please select value",
                        },
                      })}
                      onChange={(e) => {
                        dispatch(unsavedchanges(e.target.value));
                        clearErrors("AreCurrentlyEmployed");
                      }}
                      className={`${
                        errors.AreCurrentlyEmployed ? "error_msg" : ""
                      }`}
                      disabled={readOnly}
                    >
                      <option disabled selected value="">
                        {AppCommon.Dropdown_Default_Option}
                      </option>
                      {pickListVal &&
                        pickListVal?.areYouCurrentlyEmployeed?.map(
                          (soci, ind) => (
                            <option value={soci.Id}>{soci.value}</option>
                          )
                        )}
                    </select>
                    {errors.AreCurrentlyEmployed && (
                      <div className="error_shown">
                        {errors.AreCurrentlyEmployed?.message}
                      </div>
                    )}
                  </div>
                )}
              </div>
              {experience === "Yes" && (
                <>
                  <div class="form_styl">
                    <div class="inner_part">
                      <label>
                        {PGEducationDetails.Experience_As_On}{" "}
                        {moment(
                          allEduresponsedata?.hed__Applications_Applicant__r
                            .records[0]?.Admission_Cycle__r
                            ?.Work_experience_in_months__c
                        ).format("DD MMMM YYYY")}
                        <span class="redstyl">* </span>
                      </label>
                      <input
                        type="text"
                        {...register("workExperienceInMonths", {
                          required: {
                            value: true,
                            message: "Please select value",
                          },
                          pattern: {
                            value: NUM_REGEX,
                            message: "Work experience should be in Months.",
                          },
                          maxLength: {
                            value: 11,
                            message: "Work experience should be in Months.",
                          },
                          validate: (val) => {
                            if (
                              allEduresponsedata?.hed__Applications_Applicant__r
                                ?.records[0]?.First_Preference_Name__c ===
                                "Master of Public Health" &&
                              val < 18
                            ) {
                              return `Minimum 18 months of experience required to Apply for Master of Public Health.`;
                            }
                          },
                        })}
                        autoComplete="off"
                        maxLength="11"
                        onChange={(e) => {
                          dispatch(unsavedchanges(e.target.value));
                          clearErrors("workExperienceInMonths");
                        }}
                        disabled={readOnly}
                        className={`${
                          errors.workExperienceInMonths ? "error_msg" : ""
                        }`}
                      />
                      {errors.workExperienceInMonths && (
                        <div className="error_shown">
                          {errors.workExperienceInMonths?.message}
                        </div>
                      )}
                    </div>

                    <div class="inner_part">
                      <label>
                        {PGEducationDetails.Last_Organisation_Name}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <input
                        type="text"
                        {...register("organization", {
                          required: {
                            value: true,
                            message: "Please select value",
                          },
                        })}
                        autoComplete="off"
                        onChange={(e) => {
                          dispatch(unsavedchanges(e.target.value));
                          clearErrors("organization");
                        }}
                        disabled={readOnly}
                        className={`${errors.organization ? "error_msg" : ""}`}
                      />
                      {errors.organization && (
                        <div className="error_shown">
                          {errors.organization?.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div class="form_styl">
                    <div class="inner_part">
                      <label>
                        {PGEducationDetails.Last_Organisation_Type}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <select
                        {...register("typeOfOrganization", {
                          required: {
                            value: true,
                            message: "Please select value",
                          },
                        })}
                        onChange={(e) => {
                          dispatch(unsavedchanges(e.target.value));
                          clearErrors("typeOfOrganization");
                        }}
                        disabled={readOnly}
                        className={`${
                          errors.typeOfOrganization ? "error_msg" : ""
                        }`}
                      >
                        <option disabled selected value="">
                          {AppCommon.Dropdown_Default_Option}
                        </option>
                        {pickListVal &&
                          pickListVal?.typeOfOrganization?.map((soci, ind) => (
                            <option value={soci.Id}>{soci.value}</option>
                          ))}
                      </select>
                      {errors.typeOfOrganization && (
                        <div className="error_shown">
                          {errors.typeOfOrganization?.message}
                        </div>
                      )}
                    </div>
                    <div class="inner_part">
                      <label>
                        {PGEducationDetails.Last_Gross_Annual_Salary}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <input
                        type="text"
                        {...register("annualGrossSalary", {
                          required: {
                            value: true,
                            message: "Please select value",
                          },
                          pattern: {
                            value: NUM_REGEX,
                            message: "Please enter number only!",
                          },
                        })}
                        autoComplete="off"
                        maxLength="18"
                        onChange={(e) => {
                          dispatch(unsavedchanges(e.target.value));
                          clearErrors("annualGrossSalary");
                        }}
                        disabled={readOnly}
                        className={`${
                          errors.annualGrossSalary ? "error_msg" : ""
                        }`}
                      />
                      {errors.annualGrossSalary && (
                        <div className="error_shown">
                          {errors.annualGrossSalary?.message}
                        </div>
                      )}
                    </div>                  
                  </div>
                </>
              )}

              <div class="form_styl">
                <div class="w100">
                  <button
                    onClick={(e) => (DraftBtn = e.target.value)}
                    value="draft"
                    type="submit"
                    class={
                      readOnly &&
                      formattedDate >
                        allEduresponsedata?.hed__Applications_Applicant__r
                          ?.records[0]?.Admission_Cycle__r
                          ?.Preference_End_Date__c
                        ? "submit ml_5 disabled"
                        : "submit ml_5"
                    }
                    disabled={
                      readOnly &&
                      formattedDate >
                        allEduresponsedata?.hed__Applications_Applicant__r
                          ?.records[0]?.Admission_Cycle__r
                          ?.Preference_End_Date__c
                    }
                  >
                    {ApplicationViewPage.Draft_Button_Message}
                  </button>
                  <button
                    onClick={(e) => (DraftBtn = e.target.value)}
                    value=""
                    type="submit"
                    class={
                      readOnly &&
                      formattedDate >
                        allEduresponsedata?.hed__Applications_Applicant__r
                          ?.records[0]?.Admission_Cycle__r
                          ?.Preference_End_Date__c
                        ? "submit ml_5 disabled"
                        : "submit ml_5"
                    }
                    disabled={
                      readOnly &&
                      formattedDate >
                        allEduresponsedata?.hed__Applications_Applicant__r
                          ?.records[0]?.Admission_Cycle__r
                          ?.Preference_End_Date__c
                    }
                  >
                    {ApplicationViewPage.Save_And_Proceed_Button_Message}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Home>
  );
};

export default EducationDetail;
