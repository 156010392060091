import React, { useState, useEffect } from "react";
import * as images from "../../resources/images";
import { PATHS } from "../../utils";
import { useForm } from "react-hook-form";
import {
  GET_LOWER_CASE,
  GET_NUMBER,
  GET_UPPER_CASE,
  SPECIAL_CHAR,
} from "../../utils/Regex";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword, getLastPasswordDetails } from "../../redux/actions/Api";
import { useLocation, useNavigate, useParams, } from "react-router-dom";
import Loader from "../Loader";
import moment, { fn } from "moment";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm();

  const { setpassrespone, getPasswordChanged } = useSelector((state) => state.collections);
  const [passwordComplexity, setPasswordComplexity] = useState(false);
  const [eightState, getEightState] = useState(false);
  const [specialCharState, getSpecialCharState] = useState(false);
  const [upperCaseComplexity, getUpperCaseComplexity] = useState(false);
  const [lowerCaseComplexity, getLowerCaseComplexity] = useState(false);
  const [numberComplexity, getNumberComplexity] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [confirmSuccess, setConfirmSuccess] = useState(false);
  const [messageState, setMessageState] = useState("");
  const [loader, setLoader] = useState(false);
  const [submitMessage, setSubmitMessage] = useState("")

  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const token = query.get('token');
  const userEmail = query.get('email');


  useEffect(() => {
    dispatch(getLastPasswordDetails(token))
  }, [])

  let date;

  useEffect(() => {
    if (getPasswordChanged) {
      date = moment(getPasswordChanged?.Object?.data).format("DD-MM-YYYY")
     
    }
  }, [getPasswordChanged, date])


  useEffect(() => {
    if (setpassrespone?.success) {
      setTimeout(() => {
        setLoader(false);
        navigate(PATHS.USERSIGNIN);
      }, 1500);
    }
  }, [setpassrespone]);

  const typePassword = (e) => {
    setPasswordComplexity(false);
    let item = e.target.value;

    if (item.length == 0) {
      clearErrors(["password"]);
      setSuccess(false)
    } else if (item.length >= 8) {
      getEightState(true);
      setValue("password", item);
      //setError("password", { type: "custom", message: "Too Strong"});
      clearErrors(["password"]);
      setSuccess(true);
    } else {
      setSuccess(false)
      setMessageState("")
      getEightState(false);
      setPasswordComplexity(true);
      setError("password", { type: "custom", message: "Too weak" });
    }
    if (SPECIAL_CHAR.test(item)) {
      getSpecialCharState(true);
      setSubmitMessage("")
    } else {
      setSuccess(false)
      setMessageState("")
      setPasswordComplexity(true);
      getSpecialCharState(false);
    }

    if (GET_UPPER_CASE.test(item)) {
      // getLowerCaseComplexity(true);
      getUpperCaseComplexity(true)
      setSubmitMessage("")
    } else {
      setPasswordComplexity(true);
      setMessageState("")
      getUpperCaseComplexity(false);
      setSuccess(false);
    }
    if (GET_NUMBER.test(item)) {
      getNumberComplexity(true);
      setSubmitMessage("")
    } else {
      setSuccess(false)
      setMessageState("")
      setPasswordComplexity(true);
      getNumberComplexity(false);
    }

    if (getValues("confirmpassword") === "") {
    } else if (item !== getValues("confirmpassword")) {
      setSuccess(false)
      setError("confirmpassword", {
        type: "custom",
        message: "Password doesn't match",
      });
    } else if (
      getValues("confirmpassword") !== "" &&
      item === getValues("confirmpassword")
    ) {
      setSuccess(false)
      clearErrors("confirmpassword");
    }
  };

  const onConfirmPassword = (e) => {
    setSuccess(false);
    let item = e.target.value;
    if (item === "") {
      setSuccess(false)
      setConfirmSuccess(false);
      clearErrors(["confirmpassword"]);
    } else if ((getValues("password").length - 1) === item.length) {
      // setError("confirmpassword", {
      //   type: "custom",
      //   message: "Good so far",
      // });
      setSuccess(false);
      setConfirmSuccess(true);
      setMessageState("Good so far")
      clearErrors(["confirmpassword"]);
    }
    else if (getValues("password") === item) {
      // setError("confirmpassword", {
      //   type: "custom",
      //   message: "match",
      // });
      setSuccess(false);
      setConfirmSuccess(true);
      setMessageState("match")
      clearErrors(["confirmpassword"]);
    } else if (getValues("password") !== item) {
      setSuccess(false)
      setConfirmSuccess(false);
      setError("confirmpassword", {
        type: "custom",
        message: "Password doesn't match",
      });
    }
  };

  const _onSubmit = (values) => {
    if (!upperCaseComplexity || !numberComplexity || !eightState) {
      setSubmitMessage("Please follow the above password  rules!!!!")
      return false
    } else {
      setSubmitMessage("")
    }

    let formData = {
      password: values.password,
    };

    if (formData) {
      setLoader(true);
      dispatch(resetPassword(formData, token));
    }
  };

  return (
    <div
      class="main_container"
      style={{
        backgroundImage: `url("https://apf-uat--uat.sandbox.my.site.com/admissions/file-asset/univ_building_8_reduced_image?v=1")`,
        width: "100%",
        height: "100%",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      {getPasswordChanged?.tokenExpired ?
        <div class="container_box reset_top">
          <div className="change_password reset_password">
            <h4>Link expired</h4>
            <p className="text-center mt-2">
              Link expired. Please reset your password through reset password button.
            </p>
            <div className="input_box mt-2 pt-2">
              <button onClick={() => navigate(PATHS.FORGETPASSWORD)} className="red_button_styl">Reset Password</button>
            </div>
          </div>
        </div>
        :
        <div class="container_box">
          <div class="header">
            <img src={images.apuLogo1} />
          </div>

          <h4>Change Your Password</h4>
          <div class="change_password">
            <div class="input_box">
              <p>
                Enter a new password for {userEmail ? userEmail.replace(/ /g, '+') : ''}. Make sure to include
                atleast:
              </p>
              <span className="radio_styl">
                <input type="checkbox" checked={eightState && true} />
                <span class="checkmark"></span>
                <label className={`${eightState ? "green_txt" : ""}`}>
                  8 Characters <span class="redstyl">* </span>
                </label>
              </span>
              <span className="radio_styl">
                <input type="checkbox" checked={upperCaseComplexity && true} />
                <span class="checkmark"></span>
                <label className={`${upperCaseComplexity ? "green_txt" : ""}`}>
                  1 Letter(Upper case) <span class="redstyl">* </span>
                </label>
              </span>
              <span className="radio_styl">
                <input type="checkbox" checked={numberComplexity && true} />
                <span class="checkmark"></span>
                <label className={`${numberComplexity ? "green_txt" : ""}`}>
                  1 Number <span class="redstyl">* </span>
                </label>
              </span>
            </div>
            <Loader show={loader} />

            <form onSubmit={handleSubmit(_onSubmit)} onFocus={(e) => e.target.setAttribute("autoComplete", "none")}>
              <div class="input_box">
                <label>
                  New Password <span class="redstyl">* </span>
                </label>
                <input
                  {...register("password", {
                    required: {
                      value: true,
                      message: "",
                    },
                  })}
                  className={`${errors.password ? "error_msg" : ""}`}
                  // type={type}
                  type="password"
                  id={"getPassword"}
                  onChange={typePassword}
                  onBlur={() => setSuccess(false)}
                />
                {errors.password && (
                  <div className="error_shown error_text">
                    {errors.password?.message}
                  </div>
                )}
                {isSuccess && (
                  <div className="error_text error_green">
                    {"Strong Password"}
                  </div>
                )}
              </div>
              <div class="input_box">
                <label>
                  Confirm Password <span class="redstyl">* </span>
                </label>
                <input
                  {...register("confirmpassword", {
                    required: {
                      value: true,
                      message: "",
                    },
                    validate: (val) => {
                      if (document.getElementById("getPassword")?.value != val) {
                        return "Password doesn't match";
                      }
                    },
                  })}
                  className={`${errors.confirmpassword ? "error_msg" : ""}`}
                  type={`password`}
                  id={"confirmPassword"}
                  onChange={(e) => onConfirmPassword(e)}
                />

                {errors.confirmpassword && (
                  <div className="error_shown error_text">
                    {errors.confirmpassword?.message}
                  </div>
                )}
                {confirmSuccess && (
                  <div className="error_text error_green" >
                    {messageState}
                  </div>
                )}
              </div>


              {submitMessage && <p style={{ color: "red" }}>{submitMessage}</p>}

              <div class="input_box">
                <button
                  type="submit"
                  className={`${messageState !== "match" ? "" : "button_styl"}`}
                  disabled={
                    document.getElementById("getPassword")?.value !==
                    document.getElementById("confirmPassword")?.value ||
                    document.getElementById("getPassword")?.value == 0 ||
                    document.getElementById("confirmPassword")?.value == 0
                  }
                >
                  Change Password
                </button>
              </div>
            </form>
            <div class="input_box">
              {getPasswordChanged?.Object ?
                <p>Password was last changed on {moment(getPasswordChanged?.Object?.data).format('lll')}.</p> :
                <br />}
            </div>
          </div>

          <p>@2024 Azim Premji University. All rights reserved.</p>
        </div>
      }
    </div>
  );
};

export default ChangePassword;
