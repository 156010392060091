import React from 'react';
import './Modal.css';

const SessionModal = ({ onClose, children }) => {  
    return (       
        <div style={styles.overlay}>
          <div style={styles.modal}>
            {children}
            <button style={styles.closeButton} onClick={onClose}>
              X
            </button>
          </div>
        </div>
      );
};

const styles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex:"9"
    },
    modal: {
      backgroundColor: "white",
      padding: "20px",
      borderRadius: "8px",
      position: "relative",
      width: "80%",
      maxWidth: "500px"
    },
    closeButton: {
      position: "absolute",
      top: "10px",
      fontWeight:"600",
      right: "10px",
      background: "transparent",
      border: "none",
      fontSize: "18px",
      cursor: "pointer"
    }
  };

export default SessionModal;