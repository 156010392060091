// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Modal overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Modal content */
.modal-content {
    background: white;
    padding: 20px;
    border-radius: 4px;
    max-width: 500px;
    width: 100%;
    position: relative;
}

/* Close button */
.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/common/Modal.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,oCAAoC;IACpC,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA,kBAAkB;AAClB;IACI,iBAAiB;IACjB,aAAa;IACb,kBAAkB;IAClB,gBAAgB;IAChB,WAAW;IACX,kBAAkB;AACtB;;AAEA,iBAAiB;AACjB;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,gBAAgB;IAChB,YAAY;IACZ,iBAAiB;IACjB,eAAe;AACnB","sourcesContent":["/* Modal overlay */\r\n.modal-overlay {\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    right: 0;\r\n    bottom: 0;\r\n    background-color: rgba(0, 0, 0, 0.5);\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n}\r\n\r\n/* Modal content */\r\n.modal-content {\r\n    background: white;\r\n    padding: 20px;\r\n    border-radius: 4px;\r\n    max-width: 500px;\r\n    width: 100%;\r\n    position: relative;\r\n}\r\n\r\n/* Close button */\r\n.modal-close {\r\n    position: absolute;\r\n    top: 10px;\r\n    right: 10px;\r\n    background: none;\r\n    border: none;\r\n    font-size: 1.5rem;\r\n    cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
