import React, { useState, useEffect, useRef } from 'react';
import { TimeoutWarningModal } from '../components/TimeoutWarningModal';


const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
];
const SessionTimeout = ({ children }) => {
    let timer;
    const [isLoggedIn, setIsLoggedIn] = useState(true); // Track user login state
    const timeoutRef = useRef(null);
    // const [loading, setLoading] = useState(false);
    const [isWarningModalOpen, setWarningModalOpen] = useState(false);


    // this function sets the timer that logs out the user after 10 secs
    const handleLogoutTimer = () => {
        timer = setTimeout(() => {
            // clears any pending timer.
            resetTimer();
            // Listener clean up. Removes the existing event listener from the window
            Object.values(events).forEach((item) => {
                window.removeEventListener(item, resetTimer);
            });
            // logs out user
            // signOutAction();
            setWarningModalOpen(true);
        }, 28800000); // 10000ms = 10secs. You can change the time.
    };

    // 28800000 8 hrs
    // 43200000


    // this resets the timer if it exists.
    const resetTimer = () => {
        if (timer) clearTimeout(timer);
    };

    // when component mounts, it adds an event listeners to the window
    // each time any of the event is triggered, i.e on mouse move, click, scroll, keypress etc, the timer to logout user after 10 secs of inactivity resets.
    // However, if none of the event is triggered within 10 secs, that is app is inactive, the app automatically logs out.
    useEffect(() => {
        Object.values(events).forEach((item) => {
            window.addEventListener(item, () => {
                resetTimer();
                handleLogoutTimer();
            });
        });
    }, []);

    // logs out user by clearing out auth token in localStorage and redirecting url to /signin page.
    // const signOutAction = () => {
    //     setWarningModalOpen(true);
    //     alert("hello")
    // };
    // return children

    return(
        <>
        {isWarningModalOpen && 
            <TimeoutWarningModal isOpen={true} warningMessage={"Session Timeout!"} message={"You're being timed out due to inactivity."}
            />}
            </>
    )
}

export default SessionTimeout;
