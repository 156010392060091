import React, { useState, useEffect } from "react";
import Home from "../Home";
import { Tooltip } from "react-tooltip";
import { getCapital, getCapitalized, numberToWords } from "../../utils/Helper";
import { useDispatch, useSelector } from "react-redux";
import { getUgPreview, getdashboardData } from "../../redux/actions/Api";
import { HELP_TEXT, PATHS } from "../../utils";
import { NavLink, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import moment from "moment";
import { Notes } from "../common/Notes";
import Loader from "../Loader";
import {
  ApplicationViewPage,
  PGApplicationDetails,
  PGSocialEcoDetails,
  PGUploadDocuments,
  RegistrationPage,
  UGApplicationDetails,
  UGEducationDetails,
  UGSocialEcoDetails,
  UGUploadDocuments,
} from "../constantLabelFile";
let numToWrd = "";
let setCuetImage;
let setDisabilityImage;
let setDisabilityLink;
let setImageShow;

const PreviewUG = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { previewugresponse, dashboardresponse, error } = useSelector(
    (state) => state.collections
  );
  const imageRef = React.useRef(null);
  const { register, setValue } = useForm();
  const [regVal, setRegVal] = useState("");
  const [appVal, setAppVal] = useState("");
  const [eduVal, setEduVal] = useState("");
  const [doc, setDoc] = useState("");
  // const [imageShow, setImageShow] = useState("");
  const [programType, setProgramType] = useState("");
  const [apuCuetYear, setCUETYear] = useState();
  const [readOnly, setReadOnly] = useState(false);
  const [showMsg, setShowMsg] = useState("");
  const [sopDate, setSOPDate] = useState();
  const [loader, setLoader] = useState(true);

  const [skills, setSkills] = useState([]);
  const [needSupport, setNeedSupport] = useState("");
  const [disability, setDisability] = useState();


  const [photoGraphData, setPhotoGraphData] = useState('');
  const [cuetData, setCuetData] = useState('');
  const [disabilityData, setDisabilityData] = useState('');



  useEffect(() => {
    if (
      error?.message === "Unauthorized: Invalid or expired token." ||
      error?.validToken === false
    ) {
      setLoader(false);
    }
  }, [error]);

  useEffect(() => {
    if (previewugresponse === undefined) {
      setLoader(true);
    } else {
      setTimeout(() => {
        setLoader(false);
      }, 2000);
    }
  }, [previewugresponse]);

  useEffect(() => {
    if (!dashboardresponse) {
      dispatch(
        getdashboardData({ contactId: localStorage.getItem("contactId") })
      );
    }
  }, [dashboardresponse]);

  useEffect(() => {
    if (dashboardresponse) {
      setProgramType(
        dashboardresponse?.data?.allDataOnDashboard[0]?.programName
      );
    }
  }, [dashboardresponse]);

  useEffect(() => {
    let formData = {
      contactId: localStorage.getItem("contactId"),
      applicationId: localStorage.getItem("applicationId"),
      programType: dashboardresponse?.data?.allDataOnDashboard[0]?.programName,
    };
    dispatch(getUgPreview(formData));
  }, [programType]);

  const [socio, setSocio] = useState("");

  useEffect(() => {
    if (previewugresponse) {
      setRegVal(previewugresponse?.data?.registrationDetails);
      setAppVal(previewugresponse?.data?.applicationDetails);
      setEduVal(previewugresponse?.data?.educationDetailsUG);
      setSocio(previewugresponse?.data?.socioEconomicDetails);
      setDoc(previewugresponse?.data?.photoDocDetails);
      setCUETYear(previewugresponse?.data?.photoDocDetails?.APU_CUET_Year);
      setDisability(previewugresponse?.data?.photoDocDetails?.IsDisable);
      setPhotoGraphData(previewugresponse?.data?.photoDocDetails?.photoGraphFile)
      setCuetData(previewugresponse?.data?.photoDocDetails?.cuetFile)
      setDisabilityData(previewugresponse?.data?.photoDocDetails?.disabiltyFile)
    }
  }, [previewugresponse]);

  useEffect(() => {
    if (doc) {
      if (previewugresponse?.data?.photoDocDetails) {
        setImageShow = photoGraphData?.VersionData;
        setCuetImage =cuetData?.Title;
        setDisabilityImage = disabilityData?.Title;
        setDisabilityLink = disabilityData?.link
      }
    }
  }, [doc, previewugresponse]);

  useEffect(() => {
    setValue(
      "programGroup",
      regVal?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Type__c
    );
    setValue(
      "admissionCycle",
      regVal?.hed__Applications_Applicant__r?.records[0]?.Admission_Cycle__r
        ?.hed__Description__c
    );
    setValue("firstName", regVal?.FirstName);
    setValue("middleName", regVal?.MiddleName);
    setValue("lastName", regVal?.LastName);
    setValue("dateOfBirth", moment(regVal?.Birthdate).format("DD MMM, YYYY"));
    setSOPDate(moment(regVal?.Birthdate).format("YYYY-MM-DD"));
    setValue("mobile", regVal?.MobilePhone);
    setValue(
      "relationship",
      regVal?.hed__Relationships__r?.records[0]?.hed__Type__c
    );
    setValue(
      "relationName",
      regVal?.hed__Relationships__r?.records[0]?.hed__RelatedContact__r?.Name
    );
    setValue(
      "relationMobno",
      regVal?.hed__Relationships__r?.records[0]?.hed__RelatedContact__r
        ?.MobilePhone
    );
    setValue(
      "relationEmail",
      regVal?.hed__Relationships__r?.records[0]?.hed__RelatedContact__r?.Email
    );
    setValue(
      "houseNo",
      regVal?.hed__Addresses__r?.records[0]?.hed__MailingStreet__c
    );
    setValue(
      "area",
      regVal?.hed__Addresses__r?.records[0]?.hed__MailingStreet2__c
    );
    setValue(
      "country",
      regVal?.hed__Addresses__r?.records[0]?.hed__MailingCountry__c
    );
    setValue(
      "state",
      regVal?.hed__Addresses__r?.records[0]?.hed__MailingState__c
    );
    setValue(
      "district",
      regVal?.hed__Addresses__r?.records[0]?.hed__MailingCounty__c
    );
    setValue(
      "city",
      regVal?.hed__Addresses__r?.records[0]?.hed__MailingCity__c
    );
    setValue(
      "pin",
      regVal?.hed__Addresses__r?.records[0]?.hed__MailingPostalCode__c
    );
    setValue(
      "building",
      regVal?.hed__Addresses__r?.records[1]?.hed__MailingStreet__c
    );
    setValue(
      "road",
      regVal?.hed__Addresses__r?.records[1]?.hed__MailingStreet2__c
    );
    setValue(
      "pCountry",
      regVal?.hed__Addresses__r?.records[1]?.hed__MailingCountry__c
    );
    setValue(
      "pState",
      regVal?.hed__Addresses__r?.records[1]?.hed__MailingState__c
    );
    setValue(
      "pDistrict",
      regVal?.hed__Addresses__r?.records[1]?.hed__MailingCounty__c
    );
    setValue(
      "pCity",
      regVal?.hed__Addresses__r?.records[1]?.hed__MailingCity__c
    );
    setValue(
      "pPin",
      regVal?.hed__Addresses__r?.records[1]?.hed__MailingPostalCode__c
    );
    setValue(
      "whatChallengesDoYouThink",
      regVal?.What_challenges_do_you_think__c
    );
    setValue(
      "haveYouCompleted12",
      regVal?.If_you_have_completed_your_12th_Grade__c
    );
    setValue("haveYouTakeMoreYears", regVal?.Have_you_taken_more_than_12_years__c);
    setValue("pleaseTellWhy", regVal?.If_yes_please_tell_us_why__c);
    setValue("know", regVal?.How_did_you_get_to_know_about_APU__c);
    setValue("specify", regVal?.Specific_Answer__c);
    setValue("nationality", appVal?.hed__Country_of_Origin__c);
    setValue("domicile", appVal?.Domicile__c);
    setValue("gender", appVal?.hed__Gender__c);
    setValue(
      "prevApplied",
      appVal?.hed__Applications_Applicant__r?.records[0]
        ?.Applied_for_earlier_cycles__c
    );
    setValue(
      "yearApplied",
      appVal?.hed__Applications_Applicant__r?.records[0]?.Year_Applied_In__c
    );
    setValue(
      "language",
      appVal?.hed__Contact_Languages__r?.records[0]?.hed__Language__r?.Name
    );
    setValue(
      "spoken",
      appVal?.hed__Contact_Languages__r?.records[0]?.Speaking__c
    );
    setValue(
      "writing",
      appVal?.hed__Contact_Languages__r?.records[0]?.Writing__c
    );
    setValue(
      "reading",
      appVal?.hed__Contact_Languages__r?.records[0]?.Reading__c
    );
    setValue(
      "programApplying",
      appVal?.hed__Applications_Applicant__r?.records[0]?.First_Preference__r
        ?.Name
    );
    setValue(
      "campus1",
      appVal?.hed__Applications_Applicant__r?.records[0]
        ?.Campus_Preferences_1__r?.Name
    );
    setValue(
      "programPrefer",
      appVal?.hed__Applications_Applicant__r?.records[0]?.Second_Preference__r
        ?.Name
    );
    setValue(
      "campus2",
      appVal?.hed__Applications_Applicant__r?.records[0]
        ?.Campus_Preferences_2__r?.Name
    );
    setValue("considerAdmissionInAnyCampus", appVal?.hed__Applications_Applicant__r?.records[0]?.APU_consider_admission_in_any_campus__c)
    
    setValue(
      "testCenter",
      appVal?.hed__Applications_Applicant__r?.records[0]
        ?.First_Test_Center_Prefrence__r?.Name
    );
    setValue(
      "interviewCenter",
      appVal?.hed__Applications_Applicant__r?.records[0]
        ?.Preferred_Interview_center__r?.Name
    );
    // education detail
    setValue(
      "schoolNameTenth",
      eduVal?.hed__Education_History__r?.records[0]
        ?.hed__Educational_Institution_Name__c
    );
    setValue(
      "schoolNameTwelveth",
      eduVal?.hed__Education_History__r?.records[1]
        ?.hed__Educational_Institution_Name__c
    );
    setValue(
      "schoolAddressTenth",
      eduVal?.hed__Education_History__r?.records[0]?.Address_of_the_School__c
    );
    setValue(
      "schoolAddressTwelveth",
      eduVal?.hed__Education_History__r?.records[1]?.Address_of_the_School__c
    );
    setValue(
      "countryTenth",
      eduVal?.hed__Education_History__r?.records[0]?.Country__c
    );
    setValue(
      "countryTwelveth",
      eduVal?.hed__Education_History__r?.records[1]?.Country__c
    );
    setValue(
      "otherCountrySpecifyTenth",
      eduVal?.hed__Education_History__r?.records[0]?.Please_Specify_Country__c
    );
    setValue(
      "otherCountrySpecifyTwelveth",
      eduVal?.hed__Education_History__r?.records[1]?.Please_Specify_Country__c
    );
    setValue(
      "stateTenth",
      eduVal?.hed__Education_History__r?.records[0]?.State__c
        ? eduVal?.hed__Education_History__r?.records[0]?.State__c
        : "NA"
    );
    setValue(
      "stateTwelveth",
      eduVal?.hed__Education_History__r?.records[1]?.State__c
        ? eduVal?.hed__Education_History__r?.records[1]?.State__c
        : "NA"
    );
    setValue(
      "districtTenth",
      eduVal?.hed__Education_History__r?.records[0]?.District_Name__c
        ? eduVal?.hed__Education_History__r?.records[0]?.District_Name__c
        : "NA"
    );
    setValue(
      "districtTwelveth",
      eduVal?.hed__Education_History__r?.records[1]?.District_Name__c
        ? eduVal?.hed__Education_History__r?.records[1]?.District_Name__c
        : "NA"
    );
    setValue(
      "boardTenth",
      eduVal?.hed__Education_History__r?.records[0]?.School_College_Board__c
    );
    setValue(
      "boardTwelth",
      eduVal?.hed__Education_History__r?.records[1]?.School_College_Board__c
    );
    setValue(
      "subjectsTenth",
      eduVal?.hed__Education_History__r?.records[0]?.Major_Subjects__c
    );
    setValue(
      "subjectsTwelveth",
      eduVal?.hed__Education_History__r?.records[1]?.Major_Subjects__c
    );
    setValue(
      "gradeSystemTenth",
      eduVal?.hed__Education_History__r?.records[0]?.Percentage_Grade__c
    );
    setValue(
      "gradeSystemTwelveth",
      eduVal?.hed__Education_History__r?.records[1]?.Percentage_Grade__c
    );
    setValue(
      "percentageTenth",
      eduVal?.hed__Education_History__r?.records[0]?.Overall_Percentage_Grade__c
    );
    setValue(
      "percentageTwelveth",
      eduVal?.hed__Education_History__r?.records[1]?.Overall_Percentage_Grade__c
    );
    setValue(
      "passingYearTenth",
      eduVal?.hed__Education_History__r?.records[0]?.Year_Of_Passing__c
    );
    setValue(
      "passingYearTwelveth",
      eduVal?.hed__Education_History__r?.records[1]?.Year_Of_Passing__c
    );
    setValue(
      "schoolTypeTenth",
      eduVal?.hed__Education_History__r?.records[0]
        ?.Type_of_School_College_Board__c
    );
    setValue(
      "schoolTypeTwelveth",
      eduVal?.hed__Education_History__r?.records[1]
        ?.Type_of_School_College_Board__c
    );
    setValue(
      "mediumInstTenth",
      eduVal?.hed__Education_History__r?.records[0]?.Medium_of_instruction__c
    );
    setValue(
      "mediumInstTwelveth",
      eduVal?.hed__Education_History__r?.records[1]?.Medium_of_instruction__c
    );
    setValue(
      "specifyMediumTenth",
      eduVal?.hed__Education_History__r?.records[0]
        ?.Please_Specify_Medium_of_Instruction__c
    );
    setValue(
      "specifyMediumTwelveth",
      eduVal?.hed__Education_History__r?.records[1]
        ?.Please_Specify_Medium_of_Instruction__c
    );
    setValue(
      "residenceTenth",
      eduVal?.hed__Education_History__r?.records[0]?.Region_of_Residence__c
    );
    setValue(
      "residenceTwelveth",
      eduVal?.hed__Education_History__r?.records[1]?.Region_of_Residence__c
    );
    setValue("schoolGroupTenth", eduVal?.hed__Education_History__r?.records[0]?.Class_12_school_group__c)
    setValue(
      "schoolGroupTwelveth",
      eduVal?.hed__Education_History__r?.records[1]?.Class_12_school_group__c
    );
    // socio eco detail
    setValue("fatherEdu", socio?.Father_Education_Details__c);
    setValue("motherEdu", socio?.Mother_Education_Details__c);
    setValue("caste", socio?.Caste__c);
    setValue("religion", socio?.hed__Religion__c);
    setValue("famlOccupation", socio?.Main_Occupation_of_family__c);
    setValue("famlIncome", socio?.Family_income_per_year__c);
    setValue("annualIncome", socio?.Exact_Annual_income__c);
    numToWrd = socio?.Exact_Annual_income__c;

    setValue("financialAssis", socio?.Do_you_need_Financial_Assistance__c);
    setValue("disability", socio?.Are_you_a_person_with_disability__c);
    setValue("disabilityNature", socio?.Nature_of_Disability__c);
    setValue("specifyNature", socio?.Please_Specify_Disability__c);
    setValue(
      "needSpecificSupport",
      socio?.APU_need_any_specific_support__c
        ? socio?.APU_need_any_specific_support__c.split(";")
        : ""
    );
    setSkills(socio?.APU_need_any_specific_support__c);
    setValue("needSupportWrite", socio?.APU_need_any_support_to_write_test__c);
    setNeedSupport(socio?.APU_need_any_support_to_write_test__c);
    setValue("supportExpecting", socio?.What_kind_of_support_u_r_expecting__c);
    setValue("moreDetails", socio?.Do_you_need_any_help_from_Admission_Team__c);
    setValue("APU_CUET_Year", doc?.APU_CUET_Year);
    setValue(
      "APU_CUET_Language",
      doc?.Language_in_which_the_CUET_test_is_taken
    );
  }, [previewugresponse, regVal, appVal, eduVal, socio]);

  const handleClick = () => {
    navigate(
      PATHS.PAYMENTDETAILSUG_STR +
        dashboardresponse?.data?.allDataOnDashboard[0]?.appId
    );
  };

  const currentDate = new Date();
  const year1 = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDate = `${year1}-${month}-${day}`;

  useEffect(() => {
    if (
      regVal?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Started" ||
      regVal?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Draft" ||
      regVal?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Payment Failed" ||
      regVal?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Payment Pending" ||
      regVal?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Payment Submitted"
    ) {
      if (
        formattedDate >
        regVal?.hed__Applications_Applicant__r?.records[0]?.Admission_Cycle__r
          ?.Application_Submission_Date__c
      ) {
        setReadOnly(true);
        setShowMsg(<Notes name="ADM_Cycle_Validation_App" />);
      } else {
        setReadOnly(false);
        setShowMsg("");
      }
    } else {
      if (
        formattedDate <
        regVal?.hed__Applications_Applicant__r?.records[0]?.Admission_Cycle__r
          ?.Application_Submission_Date__c
      ) {
        setReadOnly(true);
        setShowMsg("");
      } else {
        setReadOnly(true);
        setShowMsg(<Notes name="ADM_Cycle_Validation_App" />);
      }
    }
  }, [regVal]);

  function notesVisible() {
    if (
      regVal?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Type__c === "Undergraduate" &&
      regVal?.hed__Applications_Applicant__r?.records[0]?.Admission_Cycle__r
        ?.APU_CUET_Exam__c
    ) {
      return (
        <div className="w100">
          <span className="earlyadmin">
            <Notes name="CUET_UG" /> <Notes name="Cuet_UG_Note_3_URL"></Notes>
            <Notes name="Cuet_Program_Note_2" />
          </span>{" "}
          <br />
          <br />
          {regVal?.hed__Applications_Applicant__r?.records[0]
            ?.Admission_Cycle__r?.SAT__c && (
            <span className="earlyadmin">
              <Notes name="APU_SAT_NOTE" />{" "}
            </span>
          )}
        </div>
      );
    } else if (
      regVal?.hed__Applications_Applicant__r?.records[0]?.Admission_Cycle__r
        ?.SAT__c &&
      regVal?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Type__c === "Undergraduate"
    ) {
      return (
        <div className="w100">
          <span className="earlyadmin">
            <Notes name="APU_SAT_NOTE" />{" "}
          </span>
        </div>
      );
    } else if (
      regVal?.hed__Applications_Applicant__r?.records[0]?.Admission_Cycle__r
        ?.SAT__c
    ) {
      return (
        <div className="w100">
          <span className="earlyadmin">
            <Notes name="APU_SAT_NOTE" />{" "}
          </span>
        </div>
      );
    }
  }

  return (
    <Home>
      <div class="right_panel">
        <div class="w100 dflex">
          <h4>
            {getCapitalized(UGApplicationDetails.Undergraduate_Programme)}
          </h4>
          <span className="help_link">
            <p>
              <NavLink to={PATHS.RAISEQUERY}>{HELP_TEXT}</NavLink>{" "}
              <Tooltip id="helpTooltip">
                <p>{ApplicationViewPage.Help_Tooltip_Text}</p>
              </Tooltip>
              <i
                data-tooltip-id="helpTooltip"
                class="fa-solid fa-circle-info"
              ></i>
            </p>
          </span>
        </div>

        <div className="w100 scroll_styl">
          <div class="heading_styl head_font">
            <h4>{getCapitalized(ApplicationViewPage.Preview)}</h4>
          </div>
          <Loader show={loader} />
          <div class="form_styl">
            <div class="w100">
              <Notes name="PreviewOnTopMessage" />
            </div>
          </div>
          <div class="heading_styl">
            <h4>{RegistrationPage.Application_Registration}</h4>
          </div>

          <div class="form_part">
            <a href="" target="_blank">
              <Notes name="Application_Programme" size={"0.65rem"} />
            </a>

            <div class="form_sec">
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {RegistrationPage.Program_Group}{" "}
                    <span class="redstyl">* </span>
                    <Tooltip id="programTooltip">
                      <Notes name="Program_Group" />
                    </Tooltip>
                    <i
                      data-tooltip-id="programTooltip"
                      class="fa-solid fa-circle-info"
                      style={{ color: "gray" }}
                    ></i>
                  </label>
                  <input {...register("programGroup")} disabled={true}></input>
                </div>
                <div class="inner_part">
                  <label>
                    {RegistrationPage.Admission_Cycle}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <input {...register("admissionCycle")} disabled={true} />
                </div>
              </div>
              {notesVisible()}
              <div class="heading_styl">
                <h4>{RegistrationPage.Personal_Details}</h4>
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {RegistrationPage.First_Name}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <input {...register("firstName")} disabled={true} />
                </div>
                <div class="inner_part">
                  <label>{RegistrationPage.Middle_Name}</label>
                  <input {...register("middleName")} disabled={true} />
                </div>
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {RegistrationPage.Last_Name} <span class="redstyl">* </span>
                  </label>
                  <input {...register("lastName")} disabled={true} />
                </div>
                <div class="inner_part">
                  <label>
                    {RegistrationPage.DOB} <span class="redstyl">* </span>
                  </label>
                  <input {...register("dateOfBirth")} disabled={true} />
                </div>
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {RegistrationPage.Mobile_Number_SMS}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <input {...register("mobile")} disabled={true} />
                </div>
              </div>
              <div class="heading_styl">
                <h4>{RegistrationPage.Alternative_Contact}</h4>
              </div>
              <p
                style={{
                  color: "#00115e",
                  fontWeight: 600,
                  paddingTop: "8px",
                  paddingBottom: "8px",
                }}
              >
                Please make sure the email and mobile entered here is regularly
                checked for updates.
              </p>

              <div class="form_styl">
                <div class="inner_part1 b_none">
                  <label>
                    {RegistrationPage.Relationship}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <input {...register("relationship")} disabled={true} />
                </div>
                <div class="inner_part1 b_none">
                  <label>
                    {RegistrationPage.Name} <span class="redstyl">* </span>
                  </label>
                  <input {...register("relationName")} disabled={true} />
                </div>
                <div class="inner_part1 b_none">
                  <label>
                    {RegistrationPage.Mobile_No} <span class="redstyl">* </span>
                  </label>
                  <input {...register("relationMobno")} disabled={true} />
                </div>
                <div class="inner_part1 b_none">
                  <label>
                    {RegistrationPage.Email} <span class="redstyl">* </span>
                  </label>
                  <input {...register("relationEmail")} disabled={true} />
                </div>
              </div>
              <div class="form_styl">
                <div class="form_sec_inner ">
                  <div class="heading_styl mb-2">
                    <h4>{RegistrationPage.Communication_Address}</h4>
                  </div>
                  <div class="w100 communicationadd">
                    <div class="inner_part2 mt-2">
                      <label>
                        {RegistrationPage.House_No}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <input {...register("houseNo")} disabled={true} />
                    </div>
                    <div class="inner_part2">
                      <label>
                        {RegistrationPage.Area_Road}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <input {...register("area")} disabled={true} />
                    </div>
                  </div>
                  <div class="w100 form_styl ">
                    <div class="inner_part">
                      <label>
                        {RegistrationPage.Country}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <input {...register("country")} disabled={true} />
                    </div>
                    <div class="inner_part">
                      <label>
                        {RegistrationPage.State_Union}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <input {...register("state")} disabled={true} />
                    </div>
                  </div>
                  <div class="w100 ">
                    <div class="inner_part2">
                      <label>
                        {RegistrationPage.District}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <input {...register("district")} disabled={true} />
                    </div>
                    <div class="inner_part2">
                      <label>
                        {RegistrationPage.City} <span class="redstyl">* </span>
                      </label>
                      <input {...register("city")} disabled={true} />
                    </div>
                    <div class="inner_part2">
                      <label>
                        {RegistrationPage.Pin} <span class="redstyl">* </span>
                      </label>
                      <input {...register("pin")} disabled={true} />
                    </div>
                  </div>
                </div>
                <div class="form_sec_inner">
                  <div class="heading_styl">
                    <h4>{RegistrationPage.Permanent_Address}</h4>
                  </div>
                  <div class="w100 ">
                    <div class="flex">
                      <input
                        type="checkbox"
                        disabled
                        checked={
                          regVal?.Check_if_same_as_Communication_Address__c
                        }
                        placeholder="Enter name..."
                      />{" "}
                      <label class="f_size">
                        {RegistrationPage.Permanent_Address_Same_Message}
                      </label>
                    </div>
                    <div class="inner_part2 mt-2">
                      <label>
                        {RegistrationPage.House_No}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <input {...register("building")} disabled={true} />
                    </div>
                    <div class="inner_part2">
                      <label>
                        {RegistrationPage.Area_Road}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <input {...register("road")} disabled={true} />
                    </div>
                  </div>
                  <div class="w100 form_styl ">
                    <div class="inner_part">
                      <label>
                        {RegistrationPage.Country}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <input {...register("pCountry")} disabled={true} />
                    </div>
                    <div class="inner_part">
                      <label>
                        {RegistrationPage.State_Union}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <input {...register("pState")} disabled={true} />
                    </div>
                  </div>
                  <div class="w100 ">
                    <div class="inner_part2">
                      <label>
                        {RegistrationPage.District}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <input {...register("pDistrict")} disabled={true} />
                    </div>
                    <div class="inner_part2">
                      <label>
                        {RegistrationPage.City} <span class="redstyl">* </span>
                      </label>
                      <input {...register("pCity")} disabled={true} />
                    </div>
                    <div class="inner_part2">
                      <label>
                        {RegistrationPage.Pin} <span class="redstyl">* </span>
                      </label>
                      <input {...register("pPin")} disabled={true} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {regVal?.What_challenges_do_you_think__c && (
              <div class="form_sec">
                <div class="heading_styl">
                  <h4>{RegistrationPage.SOP}</h4>
                </div>
                <div class="form_styl align_end">
                  <div class="inner_part">
                    <label>
                      {RegistrationPage.DOB_Message}{" "}
                      <span className="redstyl">* </span>
                    </label>
                    <input value={sopDate} disabled={true} />
                  </div>

                  <div class="inner_part">
                    <label>
                      {RegistrationPage.Challenge_Message}
                    </label>
                    <input
                      {...register("whatChallengesDoYouThink")}
                      disabled={true}
                    />
                  </div>
                </div>
                <div class="form_styl">
                  <div class="inner_part">
                    <label>
                      {RegistrationPage.Interim_Message}
                    </label>
                    <input
                      {...register("haveYouCompleted12")}
                      disabled={true}
                    />
                  </div>
                  <div class="inner_part">
                    <label>
                      {RegistrationPage.Years_Schooling_Message}{" "}
                      <span className="redstyl">* </span>
                    </label>
                    <span class="w100">
                      <span class="w100 flex">
                        <input
                          type="radio"
                          checked={
                            regVal?.Have_you_taken_more_than_12_years__c ===
                            true
                          }
                          disabled={true}
                        />
                        <label>yes</label>
                      </span>
                      <span class="w100 flex">
                        <input
                          type="radio"
                          checked={
                            regVal?.Have_you_taken_more_than_12_years__c ===
                            false
                          }
                          disabled={true}
                        />
                        <label>No</label>
                      </span>
                    </span>
                  </div>
                </div>

                {regVal?.Have_you_taken_more_than_12_years__c === true && (
                  <div class="form_styl">
                    <div class="inner_part"></div>
                    <div class="inner_part">
                      <label>
                        {RegistrationPage.Years_Schooling_Response_Message}
                      </label>
                      <input {...register("pleaseTellWhy")} disabled={true} />
                    </div>
                  </div>
                )}
                <div class="form_styl flex-wrap justify_center mt-3">
                  <div class="inner_part w66">
                    <label>
                      {RegistrationPage.Hear_About_APU}{" "}
                      <span className="redstyl">* </span>
                    </label>
                    <input {...register("know")} disabled={true} />
                  </div>

                  {regVal?.Specific_Answer__c && (
                    <div class="inner_part">
                      <label>
                        {RegistrationPage.Please_Specify}{" "}
                        <span className="redstyl">* </span>
                      </label>
                      <input {...register("specify")} disabled={true} />
                    </div>
                  )}
                </div>
              </div>
            )}

            <div class="form_sec">
              <div class="w100">
                <a
                  href="https://azimpremjiuniversity.edu.in/undergraduate/application-process-and-timelines"
                  className="linkcheckegi"
                  target="_blank"
                >
                  {UGApplicationDetails.Eligibility_Criteria_Message}
                </a>{" "}
              </div>
              <div class="heading_styl">
                <h4>{UGApplicationDetails.Background_Details}</h4>
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {UGApplicationDetails.Nationality}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <input {...register("nationality")} disabled={true} />
                </div>
                <div class="inner_part">
                  <label>
                    {UGApplicationDetails.Gender}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <input {...register("gender")} disabled={true} />
                </div>
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {UGApplicationDetails.Applied_Before}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <input {...register("prevApplied")} disabled={true} />
                </div>
                {appVal?.hed__Applications_Applicant__r?.records[0]
                  ?.Applied_for_earlier_cycles__c === "Yes" && (
                  <div class="inner_part">
                    <label>
                      {UGApplicationDetails.Applied_Year}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input {...register("yearApplied")} disabled={true} />
                  </div>
                )}
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {UGApplicationDetails.Domicile}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <input {...register("domicile")} disabled={true} />
                </div>
              </div>
              <div class="heading_styl">
                <h4>{UGApplicationDetails.Language_Details}</h4>
              </div>

              <div class="form_styl">
                <div class="w100">
                  <Notes name="Language" size={"0.72rem"} />
                </div>
              </div>
              <div class="form_styl mt-3 flex-wrap">
                <div class="inner_part1">
                  <div class="heading_styl">
                    <h4>{UGApplicationDetails.Language}</h4>
                  </div>
                  <input
                    {...register("language")}
                    disabled={true}
                    className="mt-7"
                  />
                </div>
                <div class="inner_part1">
                  <div class="heading_styl">
                    <h4>{UGApplicationDetails.Spoken}</h4>
                  </div>
                  <input
                    {...register("spoken")}
                    disabled={true}
                    className="mt-7"
                  />
                </div>
                <div class="inner_part1">
                  <div class="heading_styl">
                    <h4>{UGApplicationDetails.Writing}</h4>
                  </div>
                  <input
                    {...register("writing")}
                    disabled={true}
                    className="mt-7"
                  />
                </div>
                <div class="inner_part1">
                  <div class="heading_styl">
                    <h4>{UGApplicationDetails.Reading}</h4>
                  </div>
                  <input
                    {...register("reading")}
                    disabled={true}
                    className="mt-7"
                  />
                </div>
              </div>
             {apuCuetYear &&  <div class="form_styl">
                <div class="w100">
                  <Notes
                    size={"0.72rem"}
                    name="APU_Programme_preference_UG_CUET"
                  />
                </div>
              </div> }
              <div class="heading_styl">
                <h4>{UGApplicationDetails.Programme_Applying}</h4>
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {UGApplicationDetails.Programme_Preference_1}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <input
                    {...register("programApplying")}
                    disabled={true}
                    className="mt-7"
                  />                 
                  {apuCuetYear && previewugresponse?.data?.firstPerferenceNotes && (
                    <p>{previewugresponse?.data?.firstPerferenceNotes}</p>
                  )}
                </div>
                <div class="inner_part">
                  <label>
                    {UGApplicationDetails.Campus}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <input
                    {...register("campus1")}
                    disabled={true}
                    className="mt-7"
                  />
                </div>
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {UGApplicationDetails.Programme_Preference_2}{" "}
                    <span class="redstyl">* </span>
                    <Tooltip id="progPreference">
                      <p>
                        {
                          UGApplicationDetails.Programme_Preference_2_Tooltip_Message
                        }
                      </p>
                    </Tooltip>
                    <i
                      data-tooltip-id="progPreference"
                      class="fa-solid fa-circle-info"
                      style={{ color: "gray" }}
                    ></i>
                  </label>
                  <input
                    {...register("programPrefer")}
                    disabled={true}
                    className="mt-7"
                  />                 
                  {apuCuetYear && previewugresponse?.data?.secondPerferenceNotes && (
                    <p>{previewugresponse?.data?.secondPerferenceNotes}</p>
                  )}
                </div>
                <div class="inner_part">
                  <label>
                    {UGApplicationDetails.Campus}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <input
                    {...register("campus2")}
                    disabled={true}
                    className="mt-7"
                  />
                </div>
              </div>
              <div class="form_styl">
              <div class="inner_part">
                      <label>
                        {PGApplicationDetails.Preference_Question}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <input
                        {...register("considerAdmissionInAnyCampus")} 
                        disabled={true}
                        />                      
                    </div>
              </div>
              <div class="form_styl">
                <div class="inner_part w100">
                  <Notes name="Consider_any_campus"/>
                </div>
              </div>        
              <div class="heading_styl">
                <h4>{UGApplicationDetails.Center_Preference}</h4>
              </div>
              <div class="form_styl">
                <div class="inner_part w100">
                  <Notes name="Center_Preference_Help" />
                </div>
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {UGApplicationDetails.Preferred_Test_Center}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <input
                    {...register("testCenter")}
                    disabled={true}
                    className="mt-7"
                  />
                </div>
                <div class="inner_part">
                  <label>
                    {UGApplicationDetails.Preferred_Interview_Center}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <input
                    {...register("interviewCenter")}
                    disabled={true}
                    className="mt-7"
                  />
                </div>
              </div>
            </div>
            <div class="form_sec">
              <div class="heading_styl">
                <h4>{getCapitalized("Education Details")}</h4>
              </div>
              <div class="form_styl">
                <table>
                  <tr>
                    <th></th>
                    <th>{UGEducationDetails.Class_10_Details}</th>
                    <th>
                      {UGEducationDetails.Class_12_Details}
                      <Tooltip id="programTool">
                        <Notes name="X12thStandard" />
                      </Tooltip>
                      <i
                        data-tooltip-id="programTool"
                        class="fa-solid fa-circle-info"
                      ></i>
                    </th>
                  </tr>
                  <tr>
                    <td>
                      {UGEducationDetails.School_Name}{" "}
                      <span class="redstyl">* </span>{" "}
                    </td>
                    <td>
                      <input {...register("schoolNameTenth")} disabled={true} />
                    </td>
                    <td>
                      <input
                        {...register("schoolNameTwelveth")}
                        disabled={true}
                      />{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {UGEducationDetails.School_Address}{" "}
                      <span class="redstyl">* </span>
                    </td>
                    <td>
                      <input
                        {...register("schoolAddressTenth")}
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        {...register("schoolAddressTwelveth")}
                        disabled={true}
                      />{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {UGEducationDetails.School_Country}{" "}
                      <span class="redstyl">* </span>
                    </td>
                    <td>
                      <input {...register("countryTenth")} disabled={true} />
                      {eduVal?.hed__Education_History__r?.records[0]
                        ?.Country__c === "Others" && (
                        <div class="inner_part w100">
                          <label>
                            {UGEducationDetails.Please_Specify} <span>*</span>
                          </label>
                          <input
                            {...register("otherCountrySpecifyTenth")}
                            disabled
                          />
                        </div>
                      )}
                    </td>
                    <td>
                      <input {...register("countryTwelveth")} disabled={true} />
                      {eduVal?.hed__Education_History__r?.records[1]
                        ?.Country__c === "Others" && (
                        <div class="inner_part w100">
                          <label>
                            {UGEducationDetails.Please_Specify}{" "}
                            <span className="redstyl">*</span>
                          </label>
                          <input
                            {...register("otherCountrySpecifyTwelveth")}
                            disabled
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {UGEducationDetails.State_Union_Residence_Tooltip_Message}{" "}
                      <span class="redstyl">* </span>
                      <i
                        data-tooltip-id="stateTooltip"
                        class="fa-solid fa-circle-info"
                      ></i>
                      <Tooltip id="stateTooltip">
                        {UGEducationDetails.Region_Of_Residence_Tooltip_Message}
                      </Tooltip>
                    </td>
                    <td>
                      <input {...register("stateTenth")} disabled={true} />
                    </td>
                    <td>
                      <input {...register("stateTwelveth")} disabled={true} />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {UGEducationDetails.District}{" "}
                      <span class="redstyl">* </span>
                    </td>
                    <td>
                      <input {...register("districtTenth")} disabled={true} />
                    </td>
                    <td>
                      <input
                        {...register("districtTwelveth")}
                        disabled={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {UGEducationDetails.School_Board}{" "}
                      <span class="redstyl">* </span>
                    </td>
                    <td>
                      <input {...register("boardTenth")} disabled={true} />
                    </td>
                    <td>
                      <input {...register("boardTwelth")} disabled={true} />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {UGEducationDetails.Major_Subjects}{" "}
                      <span class="redstyl">* </span>
                    </td>
                    <td>
                      <input {...register("subjectsTenth")} disabled={true} />
                    </td>
                    <td>
                      <input
                        {...register("subjectsTwelveth")}
                        disabled={true}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>
                      {UGEducationDetails.Grade} <span class="redstyl">* </span>
                    </td>
                    <td>
                      <input
                        {...register("gradeSystemTenth")}
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        {...register("gradeSystemTwelveth")}
                        disabled={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {UGEducationDetails.Grade_Overall}{" "}
                      <span class="redstyl">* </span>
                    </td>
                    <td>
                      <input {...register("percentageTenth")} disabled={true} />
                    </td>
                    <td>
                      <input
                        {...register("percentageTwelveth")}
                        disabled={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {UGEducationDetails.Passing_Year}{" "}
                      <span class="redstyl">* </span>
                      <i
                        data-tooltip-id="yearTooltip"
                        class="fa-solid fa-circle-info"
                      ></i>
                      <Tooltip id="yearTooltip">
                        <p>
                          {UGEducationDetails.Passing_Year_Tooltip_Message}
                        </p>
                      </Tooltip>
                    </td>
                    <td>
                      <input
                        {...register("passingYearTenth")}
                        disabled={true}
                      />{" "}
                    </td>
                    <td>
                      <input
                        {...register("passingYearTwelveth")}
                        disabled={true}
                      />{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {UGEducationDetails.School_Type}{" "}
                      <span class="redstyl">* </span>
                    </td>
                    <td>
                      <input {...register("schoolTypeTenth")} disabled={true} />
                    </td>
                    <td>
                      <input
                        {...register("schoolTypeTwelveth")}
                        disabled={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {UGEducationDetails.Medium_Of_Instruction}{" "}
                      <span class="redstyl">* </span>
                    </td>
                    <td>
                      <input {...register("mediumInstTenth")} disabled={true} />
                      {eduVal?.hed__Education_History__r?.records[0]
                        ?.Medium_of_instruction__c === "Other" && (
                        <div class="inner_part w100">
                          <label>
                            {UGEducationDetails.Please_Specify}{" "}
                            <span className="redstyl">*</span>
                          </label>
                          <input {...register("specifyMediumTenth")} disabled />
                        </div>
                      )}
                    </td>
                    <td>
                      <input
                        {...register("mediumInstTwelveth")}
                        disabled={true}
                      />
                      {eduVal?.hed__Education_History__r?.records[1]
                        ?.Medium_of_instruction__c === "Other" && (
                        <div class="inner_part w100">
                          <label>
                            {UGEducationDetails.Please_Specify}{" "}
                            <span className="redstyl">*</span>
                          </label>
                          <input
                            {...register("specifyMediumTwelveth")}
                            disabled
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {UGEducationDetails.Region_Of_Residence}{" "}
                      <span class="redstyl">* </span>
                      <i
                        data-tooltip-id="regionhelpTooltip"
                        class="fa-solid fa-circle-info"
                      ></i>
                      <Tooltip id="regionhelpTooltip">
                        <p>
                          {
                            UGEducationDetails.Region_Of_Residence_Tooltip_Message
                          }
                        </p>
                      </Tooltip>
                    </td>
                    <td>
                      <input {...register("residenceTenth")} disabled={true} />
                    </td>
                    <td>
                      <input
                        {...register("residenceTwelveth")}
                        disabled={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {/* Class 12 school group <span class="redstyl">* </span> */}
                      {UGEducationDetails.School_Group_12}{" "}
                      <span class="redstyl">* </span>
                      <Tooltip id="programTool">
                        {/* <p>Undergraduate or Postgraduate program</p> */}
                        <Notes name="X12thStandard" />
                      </Tooltip>
                      <i
                        data-tooltip-id="programTool"
                        class="fa-solid fa-circle-info"
                      ></i>
                    </td>
                    {/* <td></td> */}
                    <td>
                      <input
                        {...register("schoolGroupTenth")}
                        disabled={true}
                      />
                    </td>
                    <td>
                      <input
                        {...register("schoolGroupTwelveth")}
                        disabled={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3" className="bottom_clr">
                      <div class="form_styl">
                        <div class="w100 blc_txt">
                          <p>
                            Category A:{" "}
                            <i>
                              Bangalore, Chennai, Delhi, Kolkata, Mumbai, Pune,
                              Hyderabad and Chandigarh;
                            </i>
                          </p>
                          <p>
                            Category B:{" "}
                            <i>
                              Other state capital cities and cities with
                              population greater than 10 lakhs;
                            </i>
                          </p>
                          <p>
                            Category C:{" "}
                            <i>Cities with population less than 10 Lakhs</i>
                          </p>
                          <p>
                            Category D: <i>Rural</i>
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>

            <div className="form_sec">
              <div class="heading_styl">
                {/* <h4>Socio-Economic Background</h4> */}
                <h4>{UGSocialEcoDetails.Socio_Economic_Background}</h4>
              </div>
              <div class="form_sec">
                <div class="form_styl">
                  <div class="w100">
                    <div
                      style={{
                        color: "#294799",
                      }}
                      class="w100"
                    >
                      <Notes name="SED" />
                    </div>
                  </div>
                </div>
                <div class="form_styl mt-3">
                  <div class="inner_part">
                    <label>
                      {/* Father's Education Details <span class="redstyl">* </span> */}
                      {UGSocialEcoDetails.Education_Details_Father}{" "}
                      <span class="redstyl">* </span>
                      <Tooltip id="fedu">
                        {/* <p>Please enter your Father's education details</p> */}
                        <p>
                          {
                            UGSocialEcoDetails.Education_Details_Father_Tooltip_Message
                          }
                        </p>
                      </Tooltip>
                      <i
                        data-tooltip-id="fedu"
                        class="fa-solid fa-circle-info"
                      ></i>
                    </label>
                    <input {...register("fatherEdu")} disabled={true} />
                  </div>
                  <div class="inner_part">
                    <label>
                      {/* Mother's Education Details <span class="redstyl">* </span> */}
                      {UGSocialEcoDetails.Education_Details_Mother}
                      <span class="redstyl">* </span>
                      <Tooltip id="medu">
                        {/* <p>Please enter your Mother's education details</p> */}
                        <p>
                          {
                            UGSocialEcoDetails.Education_Details_Mother_Tooltip_Message
                          }
                        </p>
                      </Tooltip>
                      <i
                        data-tooltip-id="medu"
                        class="fa-solid fa-circle-info"
                      ></i>
                    </label>
                    <input {...register("motherEdu")} disabled={true} />
                  </div>
                </div>
                <div class="form_styl">
                  <div class="inner_part">
                    <label>
                      {/* Caste <span class="redstyl">* </span> */}
                      {UGSocialEcoDetails.Caste} <span class="redstyl">* </span>
                    </label>
                    <input {...register("caste")} disabled={true} />
                  </div>
                  <div class="inner_part">
                    <label>
                      {/* Religion <span class="redstyl">* </span> */}
                      {UGSocialEcoDetails.Religion}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input {...register("religion")} disabled={true} />
                  </div>
                </div>

                <div class="form_styl">
                  <div class="inner_part">
                    <label>
                      {/* Main Occupation of family <span class="redstyl">* </span> */}
                      {UGSocialEcoDetails.Family_Occupation_Main}{" "}
                      <span class="redstyl">* </span>
                      <Tooltip id="famocc">
                        <p>
                          {/* (Please go over the options carefully and pick the
                          closest applicable to your family/household.) */}
                          {
                            UGSocialEcoDetails.Family_Occupation_Main_Tooltip_Message
                          }
                        </p>
                      </Tooltip>
                      <i
                        data-tooltip-id="famocc"
                        class="fa-solid fa-circle-info"
                      ></i>
                    </label>
                    <input {...register("famlOccupation")} disabled={true} />
                  </div>
                </div>
                <div class="form_styl align_end">
                  <div class="inner_part">
                    <label>
                      {/* Family income excluding your income{" "}
                      <span class="redstyl">* </span> */}
                      {UGSocialEcoDetails.Family_Income_Annual}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input {...register("famlIncome")} disabled={true} />
                  </div>
                  <div class="inner_part">
                    <p className="mt-3">
                      {/* Please exercise caution while entering the income level as
                      you will be asked to furnish supporting documents later in
                      the admission process. */}
                      <Notes name="Family_Income_Per_Year_Help" />
                    </p>
                  </div>
                </div>
                <div class="form_styl">
                  <div class="inner_part">
                    <label>
                      {/* *Exact Annual Income <span class="redstyl">* </span> */}
                      {UGSocialEcoDetails.Exact_Annual_Income}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input {...register("annualIncome")} disabled={true} />
                    <label style={{ color: "gray" }}>
                      {getCapital(numberToWords(numToWrd))}
                    </label>
                  </div>
                </div>

                <div class="form_styl mt-3">
                  <div class="inner_part">
                    <label className="display_inblock">
                      {/* Do you wish to avail of financial assistance for your
                      studies at Azim Premji University?{" "}
                      <span className="redstyl">*</span> */}
                      {UGSocialEcoDetails.Financial_Assistance_Question}{" "}
                      <span className="redstyl">*</span>
                    </label>
                    <span class="flex">
                      <span class="flex">
                        <input
                          type="radio"
                          disabled
                          checked={
                            socio?.Do_you_need_Financial_Assistance__c === "Yes"
                          }
                        />
                        {/* <input {...register("schoolGroupTwelveth")} disabled={true} />						    */}
                        <label for="yes">yes</label>
                      </span>
                      <span class="flex">
                        <input
                          type="radio"
                          disabled
                          checked={
                            socio?.Do_you_need_Financial_Assistance__c === "No"
                          }
                        />
                        {/* <input {...register("schoolGroupTwelveth")} disabled={true} />					    */}
                        <label for="No">No</label>
                      </span>
                    </span>
                  </div>
                  <div class="inner_part">
                    <p>
                      {/* You can apply for financial assistance if the annual
                      family income is less than 12 lakhs. */}
                      <Notes name="Financial_Assistance_UG" />
                    </p>
                  </div>
                </div>
                <div class="form_styl">
                  <div class="inner_part">
                    <label>
                      {/* Are You a Person With Disability?{" "}
                      <span class="redstyl">* </span> */}
                      {UGSocialEcoDetails.Disability_Question}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input {...register("disability")} disabled={true} />

                    {socio?.Are_you_a_person_with_disability__c === "Yes" && (
                      <div class="inner_part w100">
                        <label>
                          {/* Nature of Disability <span class="redstyl">* </span> */}
                          {UGSocialEcoDetails.Disability_Nature}{" "}
                          <span class="redstyl">* </span>
                        </label>
                        <input
                          {...register("disabilityNature")}
                          disabled={true}
                        />
                      </div>
                    )}

                    {socio?.Nature_of_Disability__c === "Others" && (
                      <div class="form_styl">
                        <div class="inner_part w100">
                          <label>
                            {/* Please Specify<span class="redstyl">* </span> */}
                            {UGSocialEcoDetails.Please_Specify}{" "}
                            <span class="redstyl">* </span>
                          </label>
                          <input {...register("specifyNature")} disabled />
                        </div>
                      </div>
                    )}
                    {socio?.Are_you_a_person_with_disability__c === "Yes" && (
                      <>
                        <div class="form_styl">
                          <div class="inner_part w100">
                            <label>
                              {PGSocialEcoDetails.Need_Support_Question}{" "}
                              <span class="redstyl">* </span>
                            </label>
                            <input {...register("needSupportWrite")} disabled />
                          </div>
                        </div>
                        {needSupport === "Yes" && (
                          <div class="inner_part w100">
                            <br />
                            <div>
                              <Notes name="Disability_specific_support" />
                            </div>
                          </div>
                        )}
                        {needSupport === "Yes" && (
                          <div class="inner_part w100">
                            <label>
                              {PGSocialEcoDetails.Test_Support_Question}{" "}
                              <span class="redstyl">* </span>
                            </label>

                            <input
                              {...register("needSpecificSupport")}
                              disabled
                            />

                            <label>
                              {PGSocialEcoDetails.Describe_Support_Question}
                            </label>
                            <input {...register("supportExpecting")} disabled />
                          </div>
                        )}
                        <div class="form_styl">
                          <div class="inner_part w100">
                            <label className="display_inblock">
                              {/* Mention more details about your disability and any
                              specific support when you join the programme.{" "}
                              <span className="redstyl">*</span> */}
                              {UGSocialEcoDetails.Disability_Details}{" "}
                              <span className="redstyl">*</span>
                            </label>
                            <input
                              {...register("moreDetails")}
                              disabled={true}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div class="inner_part">
                    <Notes name="Disability" />
                  </div>
                </div>
              </div>
            </div>

            <div className="form_sec">
              <div class="heading_styl">
                {/* <h4>{getCapitalized("upload photograph")}</h4> */}
                <h4>{getCapitalized(UGUploadDocuments.Upload_Photograph)}</h4>
              </div>
              <div class="form_part">
                <div class="form_styl">
                  <table>
                    <tr>
                      <td>
                        {/* Upload Photograph : <span class="redstyl">* </span>{" "} */}
                        {UGUploadDocuments.Upload_Photograph}{" "}
                        <span class="redstyl">* </span>{" "}
                      </td>

                      <td>
                        {" "}
                        <img
                          ref={imageRef}
                          src={`data:image/jpeg;base64,${setImageShow}`}
                          alt=""
                          className="myImage"
                        />
                        {/* )} */}
                      </td>
                    </tr>
                    {apuCuetYear && (
                      <>
                        <tr>
                          <td>
                            {/* Upload your CUET/SAT score card: <span>* </span> */}
                            {UGUploadDocuments.Upload_Exam_Scorecard}{" "}
                            <span>* </span>
                          </td>
                          <td>
                            {/* <input {...register("APU_CUET_Year")} disabled={true} /> */}
                            <p disabled={true}>{setCuetImage}</p>
                          </td>
                          <td>&nbsp; </td>
                        </tr>
                        <tr>
                          <td>
                            {/* CUET/SAT Year <span>* </span> */}
                            {UGUploadDocuments.Exam_Year} <span>* </span>
                          </td>
                          <td>
                            {/* <p disabled={true} >{cuetImage}</p> */}
                            <input
                              {...register("APU_CUET_Year")}
                              disabled={true}
                            />
                          </td>
                          <td>&nbsp; </td>
                        </tr>
                        <tr>
                          <td>
                            {/* Language in which the CUET test is taken?{" "}
                            <span>* </span> */}
                            {UGUploadDocuments.CUET_Language_Question}{" "}
                            <span>* </span>
                          </td>
                          <td>
                            <td>
                              <input
                                {...register("APU_CUET_Language")}
                                disabled={true}
                              />
                            </td>
                          </td>
                          <td>&nbsp; </td>
                        </tr>
                      </>
                    )}

                    {disability && (
                      <tr>
                        <td>
                          {/* Upload your CUET/SAT score card: <span>* </span> */}
                          {PGUploadDocuments.Upload_DISABILITY}
                          {""}
                          <span class="redstyl">* </span>
                        </td>
                        <td>
                          {/* <p disabled={true}>{disabilityImage}</p> */}
                          <a 
                              href={setDisabilityLink}
                              target="_blank" style={{ cursor: "pointer" }}>
                                {'Disability- '}{setDisabilityImage}
                            </a>
                       
                        </td>
                        <td>&nbsp; </td>
                      </tr>
                    )}
                  </table>
                </div>

                <span className="redstyl">
                  {/* If you would like to edit the details, please go to the
                  respective sections and edit */}
                  {ApplicationViewPage.Preview_Edit_Message}
                </span>

                <div class="form_styl">
                  <div class="w100">
                    <input
                      type="button"
                      // value="Proceed "
                      value={ApplicationViewPage.Proceed_Button_Message}
                      class="submit"
                      onClick={handleClick}
                      disabled={readOnly}
                    ></input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Home>
  );
};

export default PreviewUG;
