import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { PATHS } from "../../utils";

const ResetEmail = () => {

  return (
    <div
      class="main_container"
      style={{
        backgroundImage: `url("https://apf-uat--uat.sandbox.my.site.com/admissions/file-asset/univ_building_8_reduced_image?v=1")`,
        width: "100%",
        height: "100%",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div class="container_box ">
        <div class="reset_email  flex ">

          <h4 className="w-100">NOW, CHECK YOUR EMAIL</h4>

          <div class="input_box">
            <p>
              <span>Check the email account associated with your user name for instructions on
                resetting your password. <span className="text_red"> Remember to look in your spam folder, where automated
                  messages  sometimes filter </span>.If you still cant login, please send a mail to -
                outreach@apu.edu.in or call at <span className="text_red">8971889988 </span>
                betweeen 10AM - 4 PM
              </span>
            </p>

            <NavLink to={PATHS.USERSIGNIN}>Back to Login</NavLink>

          </div>
        </div>

      </div>
    </div>
  )
}

export default ResetEmail;