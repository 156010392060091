import { BACHELROS, CREATE_TICKET, LOGINRESPONSE_FAILURE, REGISTRATION, RESPONSE_FAILURE, SIGNUPRESPONSE_FAILURE, UNSAVED } from "./Types";

export const loginResponseFailure = (data) => async (dispatch) => {
  if (data?.response?.data?.message) {
    dispatch({
      type: LOGINRESPONSE_FAILURE,
      payload: data?.response?.data?.message,
    });
  } else {
    dispatch({ type: LOGINRESPONSE_FAILURE, payload: data?.message });
  }
};

export const signupResponseFailure = (data) => async (dispatch) => {
  if (data?.response?.data?.message) {
    dispatch({
      type: SIGNUPRESPONSE_FAILURE,
      payload: data?.response?.data?.message,
    });
  } else {
    dispatch({ type: SIGNUPRESPONSE_FAILURE, payload: data?.message });
  }
};

export const resetLoginResponseFailure = () => async (dispatch) => {
  dispatch({ type: LOGINRESPONSE_FAILURE, payload: "" });
  dispatch({ type: SIGNUPRESPONSE_FAILURE, payload: "" });
};

export const responseFailure = (data) => async (dispatch) => {
  if (data?.response?.data?.message) {
    dispatch({
      type: RESPONSE_FAILURE,
      payload: data?.response?.data,
    });
  } else {
    dispatch({ type: RESPONSE_FAILURE, payload: data });
  }
};

export const resetForms = () => async (dispatch) => {
  dispatch({ type: RESPONSE_FAILURE, payload: "" });
  dispatch({ type: REGISTRATION, payload: "" });
  dispatch({ type: CREATE_TICKET, payload: "" });
};

export const unsavedchanges = (data) => async (dispatch) => {
  dispatch({ type: UNSAVED, payload:  data});
}

export const bachelorsDegree = (data) => async (dispatch) => {
  dispatch({type: BACHELROS, payload: data})
}