import React, { useState, useEffect } from "react";
import Home from "../Home";
import { Tooltip } from "react-tooltip";
import { getCapitalized } from "../../utils/Helper";
import { useForm } from "react-hook-form";
import Error from "../notification/Error";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  createApplication,
  getPreferencePicklist,
  getugApplicationDetail,
} from "../../redux/actions/Api";
import { HELP_TEXT, PATHS } from "../../utils";
import { NavLink, useNavigate } from "react-router-dom";
import Loader from "../Loader";
import Success from "../notification/Success";
import { resetForms, unsavedchanges } from "../../redux/actions";
import { Notes } from "../common/Notes";
import {
  AppCommon,
  ApplicationViewPage,
  PGApplicationDetails,
  UGApplicationDetails,
} from "../constantLabelFile";

let DraftBtn = "";
let addedLang = ["No", "No", "No"];

const ApplicationDetailPG = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    createapplicationres,
    picklistdata,
    ugappdata,
    error,
    dashboardresponse,
    preferencepicklistvalue,
  } = useSelector((state) => state.collections);

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onTouched" });

  const [year, setYear] = useState("");
  const [lang, setLang] = useState("");
  const [loader, setLoader] = useState(true);
  const [pickListVal, setPickListVal] = useState("");
  const [nation, setNation] = useState("");
  const [programApplied, setProgramApplied] = useState("");
  const [secondprogramApplied, setSecondProgramApplied] = useState("");
  const [campusPref, setCampusPref] = useState("");
  const [campusSeco, setCampusSeco] = useState("");
  const [preTestCenter, setPreTestCenter] = useState("");
  const [preInterviewCenter, setPreInterviewCenter] = useState("");
  const [subject1, setSubject1] = useState("");
  const [subject2, setSubject2] = useState("");
  const [applicationData, setApplicationData] = useState("");
  const [campPrefe, setCampPrefe] = useState("");
  const [readOnly, setReadOnly] = useState(false);
  const [showMsg, setShowMsg] = useState("");
  const [preferencePick, setPreferencePick] = useState(""); 
  const [array, setArray] = useState([]);
  const [firstProgram, setFirstProgram] = useState("");
  const [secondCampPrefe, setsecondCampPrefe] = useState("");
  const [Sec, setSec] = useState("")
  const [subjectPre1, setSubjectPre1] = useState("");
  const [subjectPre2, setSubjectPre2] = useState("")
  const [consider, setConsider] = useState("");

  useEffect(() => {
    if (ugappdata == undefined) {
      setLoader(true);
    } else {
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  }, [ugappdata]);

  useEffect(() => {
    if (error) {
      setLoader(false);
      toast.error(<Error error={error} />, {
        position: "top-center",
        autoClose: 1000,
        className: "Toastify__toast-theme--colored.Toastify__toast--warning",
      });
      setTimeout(() => {
        dispatch(resetForms());
      }, 1000);
    }
  }, [error]);

  useEffect(() => {
    if (ugappdata) {
      setApplicationData(ugappdata?.data?.records[0]);
    }
  }, [ugappdata]);

  useEffect(() => {
    let formData = {
      contactId: localStorage.getItem("contactId"),
      applicationId: localStorage.getItem("applicationId"),
    };
    if (formData) {
      dispatch(getugApplicationDetail(formData));
    }
  }, []);

  useEffect(() => {
    setPickListVal(picklistdata?.data);
    setPreferencePick(preferencepicklistvalue?.data);
  }, [picklistdata, preferencepicklistvalue]);

  useEffect(() => {
    if (dashboardresponse) {
      let formdata = {
        applicationCycleId:
          dashboardresponse?.data?.allDataOnDashboard[0]?.appCycleId,
      };
      if (formdata && dashboardresponse) {
        dispatch(getPreferencePicklist(formdata));
      }
    }
  }, [dashboardresponse]);

  useEffect(() => {
    if (year === "No") {
      setValue("yearApplied", "");
    }
  }, [year]);

  let draftMsg = {
    message: "Details Saved Successfully.",
  };

  useEffect(() => {
    if (createapplicationres?.success) {
      dispatch(unsavedchanges());
      toast.success(
        <Success response={!DraftBtn ? createapplicationres : draftMsg} />,
        {
          position: "top-center",
          autoClose: 2000,
        }
      );

      setLoader(false);
      if (!DraftBtn) {
        setTimeout(() => {
          navigate(
            PATHS.EDUCATIONDETAILPG_STR +
              dashboardresponse?.data?.allDataOnDashboard[0]?.appId
          );
        }, 500);
      } else if (DraftBtn) {
        setTimeout(() => {
          draftMsg.message = "";
        }, 500);
      }
    }
  }, [createapplicationres]);

  useEffect(() => {
    setValue("nationality", applicationData?.hed__Country_of_Origin__c);
    setValue("domicile", applicationData?.Domicile__c);
    setValue("gender", applicationData?.hed__Gender__c);
    setValue(
      "prevApplied",
      applicationData?.hed__Applications_Applicant__r?.records[0]
        ?.Applied_for_earlier_cycles__c
    );
    setYear(
      applicationData?.hed__Applications_Applicant__r?.records[0]
        ?.Applied_for_earlier_cycles__c
    );
    setValue(
      "yearApplied",
      applicationData?.hed__Applications_Applicant__r?.records[0]
        ?.Year_Applied_In__c
    );
    setValue(
      "language",
      applicationData?.hed__Contact_Languages__r?.records[0]?.hed__Language__c
    );
    setLang(
      applicationData?.hed__Contact_Languages__r?.records[0]?.hed__Language__c
    );
    setValue(
      "spoken",
      applicationData?.hed__Contact_Languages__r?.records[0]?.Speaking__c
    );
    addedLang[0] =
      applicationData?.hed__Contact_Languages__r?.records[0]?.Speaking__c;
    setValue(
      "writing",
      applicationData?.hed__Contact_Languages__r?.records[0]?.Writing__c
    );
    addedLang[1] =
      applicationData?.hed__Contact_Languages__r?.records[0]?.Writing__c;

    setValue(
      "reading",
      applicationData?.hed__Contact_Languages__r?.records[0]?.Reading__c
    );
    addedLang[2] =
      applicationData?.hed__Contact_Languages__r?.records[0]?.Reading__c;

    setValue(
      "programApplying",
      applicationData?.hed__Applications_Applicant__r?.records[0]
        ?.First_Preference__c
    );
    setProgramApplied(
      applicationData?.hed__Applications_Applicant__r?.records[0]
        ?.First_Preference__c
    );
    setValue(
      "campus1",
      applicationData?.hed__Applications_Applicant__r?.records[0]
        ?.Campus_Preferences_1__c
    );
    setCampusPref(
      applicationData?.hed__Applications_Applicant__r?.records[0]
        ?.Campus_Preferences_1__c
    );
    setValue(
      "campus2",
      applicationData?.hed__Applications_Applicant__r?.records[0]
        ?.Campus_Preferences_2__c
    );
    setValue(
      "programPrefer",
      applicationData?.hed__Applications_Applicant__r?.records[0]
        ?.Second_Preference__c
    );
    setSecondProgramApplied(applicationData?.hed__Applications_Applicant__r?.records[0]
      ?.Second_Preference__c)
    setValue(
      "testCenter",
      applicationData?.hed__Applications_Applicant__r?.records[0]
        ?.First_Test_Center_Prefrence__c
    );
    setPreTestCenter(
      applicationData?.hed__Applications_Applicant__r?.records[0]
        ?.First_Test_Center_Prefrence__c
    );
    setValue(
      "interviewCenter",
      applicationData?.hed__Applications_Applicant__r?.records[0]
        ?.Preferred_Interview_center__c
    );
    setPreInterviewCenter(
      applicationData?.hed__Applications_Applicant__r?.records[0]
        ?.Preferred_Interview_center__c
    );   
    
    setValue(
      "subjectPreference1",
      applicationData?.hed__Applications_Applicant__r?.records[0]
        ?.Subject_Area_preference_1__c
    );
    setSubject1(
      applicationData?.hed__Applications_Applicant__r?.records[0]
        ?.Subject_Area_preference_1__c
    );
    setValue(
      "subjectPreference2",
      applicationData?.hed__Applications_Applicant__r?.records[0]
        ?.Subject_Area_preference_2__c
    );
    setSubject2(
      applicationData?.hed__Applications_Applicant__r?.records[0]
        ?.Subject_Area_preference_2__c
    );    
    setValue("considerAdmissionInAnyCampus", applicationData?.hed__Applications_Applicant__r?.records[0]?.APU_consider_admission_in_any_campus__c)
    setConsider(applicationData?.hed__Applications_Applicant__r?.records[0]?.APU_consider_admission_in_any_campus__c)
  }, [applicationData]);

  let langMsg = {
    message:
      "Please select atleast one language proficiency as Yes on selected languages",
  };

  const _onSubmit = (values) => {
    if (addedLang.includes("Yes")) {
      let formData = {
        userId: localStorage.getItem("userId"),
        contactId: localStorage.getItem("contactId"),
        applicationId: localStorage.getItem("applicationId"),
        nationality: values.nationality,
        domicile: values.domicile,
        gender: values.gender,
        prevApplied: values.prevApplied,
        yearApplied: values.yearApplied,
        language: values.language,
        spoken: values.spoken,
        writing: values.writing,
        reading: values.reading,
        programApplying: values.programApplying,
        campus1: values.campus1,
        programPrefer: values.programPrefer,
        // campus2: campusSeco ? campusSeco : values.campus2,
        campus2: Sec === "No Second Preference" ? "" : values.campus2,
        subjectPreference1:
          (firstProgram === "M.A. Education" || Sec === "M.A. Education") ? values.subjectPreference1 : "",
        subjectPreference2:
          (firstProgram === "M.A. Education" || Sec === "M.A. Education") ? values.subjectPreference2 : "",
        testCenter: values.testCenter,
        interviewCenter: values.interviewCenter,
        considerAdmissionInAnyCampus: values.considerAdmissionInAnyCampus
      };     

      if (formData) {
        setLoader(true);
        dispatch(createApplication(formData));
      }
    } else {
      toast.error(<Error error={langMsg} />, {
        position: "top-center",
        autoClose: 1500,
        className: "Toastify__toast-theme--colored.Toastify__toast--warning",
      });
      setLoader(false);
    }
  };

  useEffect(() => {
    preferencePick?.coursePreference?.map((cam) => {
      if (
        cam.Id ===
        applicationData?.hed__Applications_Applicant__r?.records[0]
          ?.First_Preference__c
      ) {
        const data = preferencePick?.campusPreference[cam.value];
        setCampPrefe(data);
        setFirstProgram(cam?.value);        
      }
      if (
        cam.Id ===
        applicationData?.hed__Applications_Applicant__r?.records[0]
          ?.Second_Preference__c
      ) {
        const data = preferencePick?.campusPreference[cam.value];
        setsecondCampPrefe(data);
        setSec(cam?.value);
        setSecondProgramApplied(cam?.Id)       
      }
    });
  }, [applicationData, preferencepicklistvalue, preferencePick]);

  useEffect(()=>{ 
      campPrefe && campPrefe?.map((cam) => {
        if(cam.Id === applicationData?.hed__Applications_Applicant__r?.records[0]
          ?.Campus_Preferences_1__c && firstProgram === "M.A. Education"){
          const data = preferencePick?.subjectAreaPreference1[cam.value];    
          setSubjectPre1(data)   
          const data1 = preferencePick?.subjectAreaPreference2[cam.value];
          setSubjectPre2(data1) 
        }
      })
      secondCampPrefe && secondCampPrefe?.map((cam) => {
        if(cam?.Id === applicationData?.hed__Applications_Applicant__r?.records[0]
          ?.Campus_Preferences_2__c && Sec === "M.A. Education"){
          const data = preferencePick?.subjectAreaPreference1[cam.value];    
          setSubjectPre1(data) 
          const data1 = preferencePick?.subjectAreaPreference2[cam.value];
          setSubjectPre2(data1)     
        }
      })
  },[applicationData, preferencePick, campPrefe, secondCampPrefe])

  useEffect(()=>{
    if(Sec === "No Second Preference"){
      setValue("campus2", "")
      clearErrors("campus2")
      setValue("programPrefer", preferencePick?.coursePreference[0]?.Id)
      setSecondProgramApplied(preferencePick?.coursePreference[0]?.Id)
      const data = preferencePick?.campusPreference[Sec]; 
      setCampusSeco(data[0]?.Id); 
      setsecondCampPrefe("")
    }
  },[Sec, preferencePick])

  const handleProgramApplied = (e) => {
    setFirstProgram("");
    setProgramApplied(e.target.value);
    setCampusPref("");

    preferencePick?.coursePreference?.map((cam) => {
      if (cam.Id === e.target.value) {
        const data = preferencePick?.campusPreference[cam.value];
        setCampPrefe(data);
        setFirstProgram(cam.value);
        setSec(preferencePick?.coursePreference[0]?.value)
        
        if(cam.value === "M.A. Economics" || cam.value === "M.Sc. Climate Change and Sustainability"){
          setValue("programPrefer", preferencePick?.coursePreference[0]?.Id)
          setValue("campus2", "")
          setSecondProgramApplied(preferencePick?.coursePreference[0]?.Id)                 
          setsecondCampPrefe(data)
          setFirstProgram("")
          setSec(preferencePick?.coursePreference[0]?.value)
        }       
        if(cam.value === "M.A. Education"){
          setValue("subjectPreference1", "")
          setValue("subjectPreference2", "")
          setSubject1("")
          setSubject2("")
        }
      }
    });

    dispatch(unsavedchanges(e.target.value));
    clearErrors("programApplying");
  };

  const handleSecondProgramApplied = (e) => {
    setCampusSeco("");
    setsecondCampPrefe("");
    setSecondProgramApplied(e.target.value);
    setValue("campus2", "")

    preferencePick?.coursePreference?.map((cam) => {
      if (cam.Id === e.target.value) {
        const data = preferencePick?.campusPreference[cam.value];
        setSec(cam.value)
        setsecondCampPrefe(data);
        if (cam.value === "No Second Preference") {        
          setCampusSeco(data[0]?.Id);         
          setValue("campus2", "")
          clearErrors("campus2");
          setsecondCampPrefe("");
          setFirstProgram("")
        }
     
        if(cam.value === "M.A. Education"){
          setValue("subjectPreference1", "")
          setValue("subjectPreference2", "")
          setSubject1("")
          setSubject2("")
        }
      }
    });
    dispatch(unsavedchanges(e.target.value));
    clearErrors("programPrefer");
  };

  const handleCampus = (e, camp) => {    
    if(camp === "camp1"){
      setCampusPref(e.target.value);   
      campPrefe?.map((cam) => {
      if(cam.Id === e.target.value && firstProgram === "M.A. Education"){
        const data = preferencePick?.subjectAreaPreference1[cam.value];    
        setSubjectPre1(data)     
        const data1 = preferencePick?.subjectAreaPreference2[cam.value];
        setSubjectPre2(data1) 
      }})
      dispatch(unsavedchanges(e.target.value));
      clearErrors("campus1");
    } else if(camp === "camp2"){
      setCampusSeco(e.target.value);      
      secondCampPrefe?.map((cam) => {
      if(cam?.Id === e.target.value && Sec === "M.A. Education"){
        const data = preferencePick?.subjectAreaPreference1[cam.value];    
        setSubjectPre1(data)  
        const data1 = preferencePick?.subjectAreaPreference2[cam.value];
        setSubjectPre2(data1)     
      }
    })
    dispatch(unsavedchanges(e.target.value));
      clearErrors("campus2");
      }
  }

  const currentDate = new Date();
  const year1 = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDate = `${year1}-${month}-${day}`;

  useEffect(() => {
    if (
      applicationData?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Started" ||
      applicationData?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Draft" ||
      applicationData?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Payment Failed" ||
      applicationData?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Payment Pending" ||
      applicationData?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Payment Submitted"
    ) {
      if (
        formattedDate >
        applicationData?.hed__Applications_Applicant__r?.records[0]
          ?.Admission_Cycle__r?.Application_Submission_Date__c
      ) {
        setReadOnly(true);
        setShowMsg(<Notes name="ADM_Cycle_Validation_App" />);
      } else {
        setReadOnly(false);
        setShowMsg("");
      }
    } else {
      if (
        formattedDate <
        applicationData?.hed__Applications_Applicant__r?.records[0]
          ?.Admission_Cycle__r?.Application_Submission_Date__c
      ) {
        setReadOnly(true);
        setShowMsg("");
      } else {
        setReadOnly(true);
        setShowMsg(<Notes name="ADM_Cycle_Validation_App" />);
      }
    }
  }, [applicationData]);

  useEffect(() => {
    let filteredArray = pickListVal?.subjectAreaPreference2?.filter(
      (val) => val.value !== "No Second Preference"
    );

    let noSecondPreference = pickListVal?.subjectAreaPreference2?.find(
      (val) => val.value === "No Second Preference"
    );

    if (noSecondPreference) {
      filteredArray.unshift(noSecondPreference);
    }

    setArray(filteredArray);
  }, [pickListVal]);

  return (
    <Home>
      <div class="right_panel">
        <div class="w100">
          <p className="top_head_styl">{showMsg}</p>
          <h4 className="top_head">           
            {getCapitalized(PGApplicationDetails.Postgraduate_Programme)}
          </h4>
          <span className="help_link">
            <p>            
              <NavLink to={PATHS.RAISEQUERY}>{HELP_TEXT}</NavLink>{" "}
              <Tooltip id="helpTooltip">             
                <p>{ApplicationViewPage.Help_Tooltip_Text}</p>
              </Tooltip>
              <i
                data-tooltip-id="helpTooltip"
                class="fa-solid fa-circle-info"
              ></i>
            </p>
          </span>
        </div>
        <Loader show={loader} />
        <form
          onSubmit={handleSubmit(_onSubmit)}        
          disabled={readOnly}
        >
          <div className="w100 scroll_styl">
            <div class="heading_styl head_font">         
              <h4>
                {getCapitalized(PGApplicationDetails.Application_Details)}
              </h4>
            </div>
            <div class="form_sec form_part">
              <div class="form_styl">
                <div class="w100">
                  <a
                    // href="https://azimpremjiuniversity.edu.in/postgraduate/application-process-and-timeline"
                    href=" https://azimpremjiuniversity.edu.in/postgraduate/eligibility-and-diversity"
                    className="linkcheckegi"
                    target="_blank"
                  >                  
                    {PGApplicationDetails.Eligibility_Criteria_Message}
                  </a>{" "}
                </div>
              </div>

              <div class="heading_styl">             
                <h4>{PGApplicationDetails.Background_Details}</h4>
              </div>

              <div class="form_styl">
                <div class="inner_part">
                  <label>                  
                    {PGApplicationDetails.Nationality}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <select
                    {...register("nationality", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    disabled={readOnly}
                    className={`${errors.nationality ? "error_msg" : ""}`}
                    onChange={(e) => {
                      setNation(e.target.value);
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("nationality");
                    }}
                  >
                    <option value="" disabled selected>                     
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.nationality.map((opt, ind) => (
                        <option key={ind} value={opt.value}>
                          {opt.value}
                        </option>
                      ))}
                  </select>
                  {errors.nationality && (
                    <div className="error_shown">
                      {errors.nationality?.message}
                    </div>
                  )}
                </div>
                <div class="inner_part">
                  <label>                   
                    {PGApplicationDetails.Gender}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <select
                    {...register("gender", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    onChange={(e) => {
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("gender");
                    }}
                    disabled={readOnly}
                    className={`${errors.gender ? "error_msg" : ""}`}
                  >
                    <option value="" disabled selected>                     
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.gender?.map((opt, ind) => (
                        <option key={ind} value={opt.value}>
                          {opt.value}
                        </option>
                      ))}
                  </select>
                  {errors.gender && (
                    <div className="error_shown">{errors.gender?.message}</div>
                  )}
                </div>
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>                   
                    {PGApplicationDetails.Applied_Before}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <select
                    {...register("prevApplied", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    disabled={readOnly}
                    className={`${errors.prevApplied ? "error_msg" : ""}`}
                    onChange={(e) => {
                      setYear(e.target.value);
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("prevApplied");
                    }}
                  >
                    <option value="" disabled selected>                    
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.appliedEarlier.map((app, ind) => (
                        <option value={app.value}>{app.value}</option>
                      ))}
                  </select>
                  {errors.prevApplied && (
                    <div className="error_shown">
                      {errors.prevApplied.message}
                    </div>
                  )}
                </div>
                {year === "Yes" && (
                  <div class="inner_part">
                    <label>                     
                      {PGApplicationDetails.Applied_Year}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input
                      {...register("yearApplied", {
                        required: {
                          value: true,
                          message: "Please enter value",
                        },                        
                      })}
                      autoComplete="off"
                      className={`${errors.yearApplied ? "error_msg" : ""}`}
                      maxLength="255"
                      onChange={(e) => {
                        dispatch(unsavedchanges(e.target.value));
                        clearErrors("yearApplied");
                      }}
                      disabled={readOnly}
                      type="text"
                    />
                    {errors.yearApplied && (
                      <div className="error_shown">
                        {errors.yearApplied?.message}
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div class="form_styl">
                <div class="inner_part">
                  <label>                
                    {PGApplicationDetails.Domicile}{" "}
                    <span class="redstyl">* </span>                    
                  </label>
                  <select
                    {...register("domicile", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    onChange={(e) => {
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("domicile");
                    }}
                    disabled={readOnly}
                    className={`${errors.domicile ? "error_msg" : ""}`}
                  >
                    <option value="" disabled selected>                     
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.domicile?.map((opt, ind) => (
                        <option key={opt.ind} value={opt.value}>
                          {opt.value}
                        </option>
                      ))}
                  </select>
                  {errors.domicile && (
                    <div className="error_shown">{errors.domicile.message}</div>
                  )}
                </div>
              </div>

              <div class="heading_styl">                
                <h4>{PGApplicationDetails.Language_Details}</h4>
              </div>

              <div class="form_styl">
                <div class="w100">
                  <Notes name="Language" />
                </div>
              </div>
              <div class="form_styl">
                <div class="inner_part1">
                  <div class="heading_styl">                   
                    <h4>{PGApplicationDetails.Language}</h4>
                  </div>
                  <select
                    {...register("language", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    className={`mt-7 ${errors.language ? "error_msg" : ""}`}
                    value={lang}
                    disabled={readOnly}
                    onChange={(e) => {
                      setLang(e.target.value);
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("language");
                    }}
                  >
                    <option value="" disabled>                     
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.langauges?.map((opt, ind) => (
                        <option key={ind} value={opt.Id}>
                          {opt.Name}
                        </option>
                      ))}
                  </select>
                  {errors.language && (
                    <div className="error_shown">
                      {errors.language?.message}
                    </div>
                  )}
                </div>
                <div class="inner_part1">
                  <div class="heading_styl">                   
                    <h4>{PGApplicationDetails.Spoken}</h4>
                  </div>
                  <select
                    {...register("spoken", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    className={` mt-7 ${errors.spoken ? "error_msg" : ""}`}
                    disabled={!lang || readOnly}
                    onChange={(e) => {
                      addedLang[0] = e.target.value;
                      clearErrors("spoken");
                    }}
                  >
                    <option value="" disabled selected>                    
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.languageSpeaking?.map((opt, ind) => (
                        <option key={ind} value={opt.value}>
                          {opt.value}
                        </option>
                      ))}
                  </select>
                  {errors.spoken && (
                    <div className="error_shown">{errors.spoken?.message}</div>
                  )}
                </div>
                <div class="inner_part1">
                  <div class="heading_styl">                    
                    <h4>{PGApplicationDetails.Writing}</h4>
                  </div>
                  <select
                    {...register("writing", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    className={` mt-7 ${errors.writing ? "error_msg" : ""}`}
                    disabled={!lang || readOnly}
                    onChange={(e) => {
                      addedLang[1] = e.target.value;
                      clearErrors("writing");
                    }}
                  >
                    <option value="" disabled selected>                    
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.languageWriting?.map((opt, ind) => (
                        <option key={ind} value={opt.value}>
                          {opt.value}
                        </option>
                      ))}
                  </select>
                  {errors.writing && (
                    <div className="error_shown">{errors.writing?.message}</div>
                  )}
                </div>
                <div class="inner_part1">
                  <div class="heading_styl">                    
                    <h4>{PGApplicationDetails.Reading}</h4>
                  </div>
                  <select
                    {...register("reading", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    className={` mt-7 ${errors.reading ? "error_msg" : ""}`}
                    disabled={!lang || readOnly}
                    onChange={(e) => {
                      addedLang[2] = e.target.value;
                      clearErrors("reading");
                    }}
                  >
                    <option value="" disabled selected>                      
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.languageReading?.map((opt, ind) => (
                        <option key={ind} value={opt.value}>
                          {opt.value}
                        </option>
                      ))}
                  </select>
                  {errors.reading && (
                    <div className="error_shown">{errors.reading?.message}</div>
                  )}
                </div>
              </div>
              <div class="heading_styl">               
                <h4>{PGApplicationDetails.Programme_Applying}</h4>                
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>                  
                    {UGApplicationDetails.Programme_Preference_1}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <select
                    {...register("programApplying", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    className={`${errors.programApplying ? "error_msg" : ""}`}
                    value={programApplied}
                    onChange={handleProgramApplied}
                    disabled={
                      readOnly &&
                      formattedDate >
                        applicationData?.hed__Applications_Applicant__r
                          ?.records[0]?.Admission_Cycle__r
                          ?.Preference_End_Date__c
                    }
                  >
                    <option value="" disabled selected>                    
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {preferencePick &&
                      preferencePick?.coursePreference
                        ?.filter((val) => {
                          return !(
                            val.value ===
                              "No Second Preference"
                          );
                        })
                        ?.map((opt, ind) => (
                          <option key={ind} value={opt.Id} disabled={ opt.value === Sec}>
                          {opt.value}
                        </option>
                        ))}
                    {/* {preferencePick &&
                      preferencePick?.coursePreference?.map((opt, ind) => (
                        <option key={ind} value={opt.Id} disabled={ opt.value === "No Second Preference" || opt.value === Sec}>
                          {opt.value}
                        </option>
                      ))} */}
                  </select>
                  {errors.programApplying && (
                    <div className="error_shown">
                      {errors.programApplying?.message}
                    </div>
                  )}
                </div>
                <div class="inner_part">
                  <label>                   
                    {PGApplicationDetails.Campus}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <select
                    {...register("campus1", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    className={`${errors.campus1 ? "error_msg" : ""}`}
                    value={campusPref}
                    onChange={(e) => {handleCampus(e, "camp1")}}
                    disabled={
                      !programApplied ||
                      (formattedDate >
                        applicationData?.hed__Applications_Applicant__r
                          ?.records[0]?.Admission_Cycle__r
                          ?.Preference_End_Date__c &&
                        readOnly)
                    }
                  >
                    <option value="" disabled selected>                    
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {campPrefe &&
                      campPrefe?.map((opt, ind) => (
                        <option key={ind} value={opt.Id} >
                          {opt.value}
                        </option>
                      ))}                    
                  </select>
                  {errors.campus1 && (
                    <div className="error_shown">{errors.campus1?.message}</div>
                  )}
                </div>
              </div>
              {firstProgram === "M.A. Education" && (
                <>
                  <div class="form_styl">
                    <div class="inner_part">
                      <label>                       
                        {PGApplicationDetails.Subject_Area_Preference_1}{" "}
                        <span class="redstyl">* </span>
                      </label>                      
                      <select
                        {...register("subjectPreference1", {
                          required: {
                            value: true,
                            message: "Please select value",
                          },
                        })}
                        className={`${
                          errors.subjectPreference1 ? "error_msg" : ""
                        }`}
                        value={subject1}
                        disabled={ 
                          readOnly &&
                          formattedDate >
                            applicationData?.hed__Applications_Applicant__r
                              ?.records[0]?.Admission_Cycle__r
                              ?.Preference_End_Date__c
                        }
                        onChange={(e) => {
                          setSubject1(e.target.value);
                          dispatch(unsavedchanges(e.target.value));
                          clearErrors("subjectPreference1");
                        }}
                      >
                        <option value="" selected>                         
                          {AppCommon.Dropdown_Default_Option}
                        </option>
                        {subjectPre1 &&
                          subjectPre1?.map(
                            (opt, ind) => (
                              <option
                                key={ind}
                                value={opt.value}
                                disabled={opt.value === "No Second Preference" || opt.value == subject2}
                              >
                                {opt.value}
                              </option>
                            )
                          )}
                      </select>
                      {errors.subjectPreference1 && (
                        <div className="error_shown">
                          {errors.subjectPreference1.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div class="form_styl">
                    <div class="inner_part">
                      <label>                      
                        {PGApplicationDetails.Subject_Area_Preference_2}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <select
                        {...register("subjectPreference2", {
                          required: {
                            value: true,
                            message: "Please select value",
                          },
                        })}
                        className={`${
                          errors.subjectPreference2 ? "error_msg" : ""
                        }`}
                        value={subject2}
                        disabled={ 
                          readOnly &&
                          formattedDate >
                            applicationData?.hed__Applications_Applicant__r
                              ?.records[0]?.Admission_Cycle__r
                              ?.Preference_End_Date__c
                        }
                        onChange={(e) => {
                          setSubject2(e.target.value);
                          dispatch(unsavedchanges(e.target.value));
                          clearErrors("subjectPreference2");
                        }}
                      >
                        <option value="" selected>                        
                          {AppCommon.Dropdown_Default_Option}
                        </option>
                        {/* {array &&
                          array?.map((opt, ind) => (
                            <option
                              key={ind}
                              value={opt.value}
                              disabled={opt.value == subject1}
                            >
                              {opt.value}
                            </option>
                          ))} */}
                          {subjectPre2 && subjectPre2.map((opt, ind)=> (
                            <option
                              key={ind}
                              value={opt.value}
                              disabled={opt.value == subject1}
                            >
                              {opt.value}
                            </option>
                          ))}
                      </select>
                      {errors.subjectPreference2 && (
                        <div>{errors.subjectPreference2.message}</div>
                      )}
                    </div>
                  </div>

                  <div class="form_styl">
                    <div class="inner_part">
                      <Notes name="Subject_Area_Note" />
                    </div>
                  </div>
                </>
              )}
              <div class="form_styl">
                <div class="inner_part">
                  <label>                   
                    {UGApplicationDetails.Programme_Preference_2}{" "}
                    <span class="redstyl">* </span>
                    <Tooltip id="progPreference">
                      <p>{ PGApplicationDetails.Programme_Preference_2_Tooltip_Message } </p>                                         
                    </Tooltip>
                    <i
                      data-tooltip-id="progPreference"
                      class="fa-solid fa-circle-info"
                    ></i>
                  </label>               
                  <select
                    {...register("programPrefer", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    className={`${errors.programPrefer ? "error_msg" : ""}`}
                    value={secondprogramApplied}
                    onChange={handleSecondProgramApplied}
                    disabled={ !firstProgram || firstProgram === "M.A. Economics" || firstProgram === "M.Sc. Climate Change and Sustainability" ||   
                      formattedDate >
                        applicationData?.hed__Applications_Applicant__r
                          ?.records[0]?.Admission_Cycle__r
                          ?.Preference_End_Date__c && readOnly
                    }
                  >
                    <option value="" selected>                     
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {/* {preferencePick &&
                      preferencePick?.coursePreference?.map((opt, ind) => (
                        <option
                          key={ind}
                          disabled={opt.Id == programApplied}
                          value={opt.Id}
                        >
                          {opt.value}
                        </option>
                      ))} */}
                      {preferencePick &&
                      preferencePick?.coursePreference
                        ?.filter((val) => {
                          return !(
                            (firstProgram && val.value === "M.A. Economics") ||
                            val.value ===
                              "M.Sc. Climate Change and Sustainability"
                          );
                        })
                        ?.map((opt, ind) => (
                          <option
                            key={ind}
                            disabled={opt.Id === programApplied}
                            value={opt.Id}
                          >
                            {opt.value}
                          </option>
                        ))}
                  </select>
                  {errors.programPrefer && (
                    <div className="error_shown">
                      {errors.programPrefer?.message}
                    </div>
                  )}                
                </div>
                <div class="inner_part">
                  <label>                   
                    {UGApplicationDetails.Campus}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <select
                    {...register("campus2", {
                      required: {
                        value: !campusSeco ? true : false,
                        message: "Please select value",
                      },
                    })}
                    className={`${!campusSeco && errors.campus2 ? "error_msg" : ""
                    }`}
                    // value={campusSeco}
                    onChange={(e) => {handleCampus(e, "camp2")}}
                    disabled={ !firstProgram || firstProgram === "M.A. Economics" || firstProgram === "M.Sc. Climate Change and Sustainability" || 
                      !secondprogramApplied ||
                      secondprogramApplied === "0019D00000M5lAHQAZ" ||
                      (formattedDate >
                        applicationData?.hed__Applications_Applicant__r
                          ?.records[0]?.Admission_Cycle__r
                          ?.Preference_End_Date__c &&
                        readOnly)
                    }
                  >
                    <option value="" disabled selected>                     
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {secondCampPrefe &&
                      secondCampPrefe?.map((opt, ind) => (
                        <option key={ind} value={opt.Id}>
                          {opt.value}
                        </option>
                      ))}
                  </select>
                 
                  {!campusSeco && errors.campus2 && (
                    <div className="error_shown">{errors.campus2?.message}</div>
                  )}
                </div>
              </div>
            { Sec === "M.A. Education" && (
                <>
                  <div class="form_styl">
                    <div class="inner_part">
                      <label>                       
                        {PGApplicationDetails.Subject_Area_Preference_1}{" "}
                        <span class="redstyl">* </span>
                      </label>                      
                      <select
                        {...register("subjectPreference1", {
                          required: {
                            value: true,
                            message: "Please select value",
                          },
                        })}
                        className={`${
                          errors.subjectPreference1 ? "error_msg" : ""
                        }`}
                        value={subject1}
                        disabled={ 
                          readOnly &&
                          formattedDate >
                            applicationData?.hed__Applications_Applicant__r
                              ?.records[0]?.Admission_Cycle__r
                              ?.Preference_End_Date__c
                        }
                        onChange={(e) => {
                          setSubject1(e.target.value);
                          dispatch(unsavedchanges(e.target.value));
                          clearErrors("subjectPreference1");
                        }}
                      >
                        <option value="" selected>                         
                          {AppCommon.Dropdown_Default_Option}
                        </option>
                        {subjectPre1 &&
                          subjectPre1?.map(
                            (opt, ind) => (
                              <option
                                key={ind}
                                value={opt.value}
                                disabled={opt.value === "No Second Preference" || opt.value == subject2}
                              >
                                {opt.value}
                              </option>
                            )
                          )}
                      </select>
                      {errors.subjectPreference1 && (
                        <div className="error_shown">
                          {errors.subjectPreference1.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div class="form_styl">
                    <div class="inner_part">
                      <label>                      
                        {PGApplicationDetails.Subject_Area_Preference_2}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <select
                        {...register("subjectPreference2", {
                          required: {
                            value: true,
                            message: "Please select value",
                          },
                        })}
                        className={`${
                          errors.subjectPreference2 ? "error_msg" : ""
                        }`}
                        value={subject2}
                        disabled={ 
                          readOnly &&
                          formattedDate >
                            applicationData?.hed__Applications_Applicant__r
                              ?.records[0]?.Admission_Cycle__r
                              ?.Preference_End_Date__c
                        }
                        onChange={(e) => {
                          setSubject2(e.target.value);
                          dispatch(unsavedchanges(e.target.value));
                          clearErrors("subjectPreference2");
                        }}
                      >
                        <option value="" selected>                        
                          {AppCommon.Dropdown_Default_Option}
                        </option>
                        {/* {array &&
                          array?.map((opt, ind) => (
                            <option
                              key={ind}
                              value={opt.value}
                              disabled={opt.value == subject1}
                            >
                              {opt.value}
                            </option>
                          ))} */}
                          {subjectPre2 && subjectPre2.map((opt, ind)=> (
                            <option
                              key={ind}
                              value={opt.value}
                              disabled={opt.value == subject1}
                            >
                              {opt.value}
                            </option>
                          ))}
                      </select>
                      {errors.subjectPreference2 && (
                        <div>{errors.subjectPreference2.message}</div>
                      )}
                    </div>
                  </div>

                  <div class="form_styl">
                    <div class="inner_part">
                      <Notes name="Subject_Area_Note" />
                    </div>
                  </div>
                </>
              )}
               <div class="form_styl">
              <div class="inner_part">
                      <label>
                        {PGApplicationDetails.Preference_Question}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <select
                        {...register("considerAdmissionInAnyCampus", {
                          required: {
                            value: true,
                            message: "Please select value",
                          },
                        })}
                        className={`${
                          errors.considerAdmissionInAnyCampus ? "error_msg" : ""
                        }`}     
                        value={consider}                  
                        disabled={
                          readOnly &&
                          formattedDate >
                            applicationData?.hed__Applications_Applicant__r
                              ?.records[0]?.Admission_Cycle__r
                              ?.Preference_End_Date__c
                        }
                        onChange={(e) => {  
                          setConsider(e.target.value)                      
                          dispatch(unsavedchanges(e.target.value));
                          clearErrors("considerAdmissionInAnyCampus");
                        }}
                      >
                        <option value="" selected>
                          {AppCommon.Dropdown_Default_Option}
                        </option>
                        {pickListVal &&
                          pickListVal?.considerAdmissionInAnyCampus?.map(
                            (opt, ind) => (
                              <option
                                key={ind}
                                value={opt.value}                               
                              >
                                {opt.value}
                              </option>
                            )
                          )}
                      </select>
                      {errors.considerAdmissionInAnyCampus && (
                        <div>{errors.considerAdmissionInAnyCampus.message}</div>
                      )}
                    </div>
              </div>
              <div class="form_styl">
                <div class="inner_part w100">
                  <Notes name="Consider_any_campus"/>
                </div>
              </div>
              <div class="heading_styl">
                <h4>{PGApplicationDetails.Center_Preference}</h4>
              </div>
              <div class="form_styl">
                <div class="inner_part w100">
                  <Notes name="Center_Preference_Help" />
                </div>
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {PGApplicationDetails.Preferred_Test_Center}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <select
                    {...register("testCenter", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    className={`${errors.testCenter ? "error_msg" : ""}`}
                    value={preTestCenter}
                    disabled={
                      readOnly &&
                      formattedDate >
                        applicationData?.hed__Applications_Applicant__r
                          ?.records[0]?.Admission_Cycle__r
                          ?.Preference_End_Date__c
                    }
                    onChange={(e) => {
                      setPreTestCenter(e.target.value);
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("testCenter");
                    }}
                  >
                    <option value="" disabled selected>
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {preferencePick &&
                      preferencePick?.testCenter?.map((opt, ind) => (
                        <option key={ind} value={opt.Id}>
                          {opt.value}
                        </option>
                      ))}
                  </select>
                  {errors.testCenter && (
                    <div className="error_shown">
                      {errors.testCenter.message}
                    </div>
                  )}
                </div>
                <div class="inner_part">
                  <label>
                    {PGApplicationDetails.Preferred_Interview_Center}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <select
                    {...register("interviewCenter", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    className={`${errors.interviewCenter ? "error_msg" : ""}`}
                    value={preInterviewCenter}
                    onChange={(e) => {
                      setPreInterviewCenter(e.target.value);
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("interviewCenter");
                    }}
                    disabled={
                      readOnly &&
                      formattedDate >
                        applicationData?.hed__Applications_Applicant__r
                          ?.records[0]?.Admission_Cycle__r
                          ?.Preference_End_Date__c
                    }
                  >
                    <option value="" disabled selected>
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {preferencePick &&
                      preferencePick?.interviewCenter?.map((opt, ind) => (
                        <option key={ind} value={opt.Id}>
                          {opt.value}
                        </option>
                      ))}
                  </select>
                  {errors.interviewCenter && (
                    <div className="error_shown">
                      {errors.interviewCenter?.message}
                    </div>
                  )}
                </div>
              </div>

              <div class="form_styl">
                <div class="w100">
                  <button
                    onClick={(e) => (DraftBtn = e.target.value)}
                    value="draft"
                    type="submit"
                    class={
                      readOnly &&
                      formattedDate >
                        applicationData?.hed__Applications_Applicant__r
                          ?.records[0]?.Admission_Cycle__r
                          ?.Preference_End_Date__c
                        ? "submit ml_5 disabled"
                        : "submit ml_5"
                    }
                    disabled={
                      readOnly &&
                      formattedDate >
                        applicationData?.hed__Applications_Applicant__r
                          ?.records[0]?.Admission_Cycle__r
                          ?.Preference_End_Date__c
                    }
                  >
                    {ApplicationViewPage.Draft_Button_Message}
                  </button>
                  <button
                    onClick={(e) => (DraftBtn = e.target.value)}
                    value=""
                    type="submit"
                    class={
                      readOnly &&
                      formattedDate >
                        applicationData?.hed__Applications_Applicant__r
                          ?.records[0]?.Admission_Cycle__r
                          ?.Preference_End_Date__c
                        ? "submit ml_5 disabled"
                        : "submit ml_5"
                    }
                    disabled={
                      readOnly &&
                      formattedDate >
                        applicationData?.hed__Applications_Applicant__r
                          ?.records[0]?.Admission_Cycle__r
                          ?.Preference_End_Date__c
                    }
                  >
                    {ApplicationViewPage.Save_And_Proceed_Button_Message}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Home>
  );
};
export default ApplicationDetailPG;



                  
