import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { PATHS } from "../../utils";
import { getCapital, getUrlSegment } from "../../utils/Helper";
import { useDispatch, useSelector } from "react-redux";
import { getNotesData, getPicklistdetails, logoutAction } from "../../redux/actions/Api";
import { toast } from "react-toastify";
import Error from "../notification/Error";
import * as images from "../../resources/images";
import { TimeoutWarningModal } from "../TimeoutWarningModal";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userName, setUsername] = useState("");
  const [openProfile, setOpenProfile] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const { dashboardresponse, signinresponse, error } = useSelector(
    (state) => state.collections
  );

  useEffect(() => {
    if (error?.message === "Unauthorized: Invalid or expired token." || error?.validToken === false) {
      setModalOpen(true)
    }
  }, [error]);

  useEffect(() => {
    setUsername(
      localStorage.getItem("first_name") +
      " " +
      localStorage.getItem("last_name")
    );
  }, []);

  useEffect(() => {
    // if (localStorage.getItem("access_token")) {
    dispatch(getPicklistdetails());
    dispatch(getNotesData())
    // }
  }, []);

  const profileHandler = (type) => {
    if (type === "open") {
      setOpenProfile(true);
    } else if (type === "close") {
      setOpenProfile(false);
    }
  };

  let homeMsg = {
    message: "Please register yourself to access home page"
  }

  const handleHome = () => {
    if (dashboardresponse?.data?.length !== 0) {
      navigate(PATHS.DASHBOARD);
    } else {
      toast.error(
        <Error error={homeMsg} />,
        {
          position: "top-center",
          autoClose: 5000,
        }
      );
    }
  };

  return (
    <div class="main_header">
      <div class="top_header">
        <div className="logostyl">
          <img src={images.apuLogo1} alt="Logo" />
        </div>
        <div>
          <div class="user_profile">
            {openProfile ? (
              <div class="profile_box" onClick={() => profileHandler("close")}>
                <span>
                  <i class="fa-solid fa-user"></i>
                </span>
                <h4>{userName}</h4>
              </div>
            ) : (
              <div class="profile_box" onClick={() => profileHandler("open")}>
                <span>
                  <i class="fa-solid fa-user"></i>
                </span>
                <h4>{userName}</h4>
              </div>
            )}
          </div>
          {openProfile && (
            <div className="dropdown">
              <ul>
                <li>
                  <i class="fa-solid fa-house"></i>
                  <NavLink to={PATHS.DASHBOARD}> Home</NavLink>
                </li>
                <li onClick={() => dispatch(logoutAction())}>
                  <i class="fa-solid fa-right-from-bracket"></i> Logout
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      {modalOpen && <TimeoutWarningModal isOpen={true} warningMessage={"Login Alert!!"} message={"You are logged into the portal in another browser and the session is still active. Please continue your actions using that browser."} />}
      <div class="main_menu">
        <div class="menu_header">
          <div class="nav_link">
            <ul>
              <li onClick={handleHome}>
                Home
              </li>
              <li>
                <NavLink to={PATHS.RAISEQUERY}>Raise a Query</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
