import React, { useState, useEffect } from "react";
import Home from "../Home";
import { Tooltip } from "react-tooltip";
import { getCapital, getCapitalized, numberToWords } from "../../utils/Helper";
import { HELP_TEXT, PATHS } from "../../utils";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { getUgPreview } from "../../redux/actions/Api";
import ReactHtmlParser from "react-html-parser";

import moment from "moment";
import { Notes } from "../common/Notes";
import Loader from "../Loader";
import {
  ApplicationViewPage,
  DiplomaApplicationDetails,
  DiplomaEducationDetails,
  DiplomaSocialEcoDetails,
  DiplomaUploadDocuments,
  PGUploadDocuments,
  RegistrationPage,
} from "../constantLabelFile";
let numToWrd = "";
let setImage;
let setDisabilityImage;
const Preview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { previewugresponse, dashboardresponse, error } = useSelector(
    (state) => state.collections
  );
  const { register, setValue, control } = useForm();
  const [year, setYear] = useState("");
  const [regVal, setRegVal] = useState("");
  const [appVal, setAppVal] = useState("");
  const [eduVal, setEduVal] = useState("");
  const [socio, setSocio] = useState("");

  const [eduQual, setEduQual] = useState("");
  const [programType, setProgramType] = useState("");
  const [photo, setPhoto] = useState();
  const [records, setRecords] = useState([]);
  const [appIds, setAppID] = useState("");
  const [admissionCNote, setAdmissionCNote] = useState("");
  const [prgCNote, setPRGCNote] = useState("");

  const [readOnly, setReadOnly] = useState(false);
  const [showMsg, setShowMsg] = useState("");
  const [dipFlex, setDipFlex] = useState("");
  const [knowapu, setKnowapu] = useState("");
  const [pleaseSpecify, setpleaseSpecify] = useState("");
  const [loader, setLoader] = useState(true);
  const [prgLabelname, setPrglabelname] = useState();
  const [disability, setDisability] = useState();
  // const [disabilityImage, setDisabilityImage] = useState();

  const [photoGraphData, setPhotoGraphData] = useState('');
  const [disabilityData, setDisabilityData] = useState('');

  useEffect(() => {
    if (
      error?.message === "Unauthorized: Invalid or expired token." ||
      error?.validToken === false
    ) {
      setLoader(false);
    }
  }, [error]);

  useEffect(() => {
    if (previewugresponse === undefined) {
      setLoader(true);
    } else {
      setTimeout(() => {
        setLoader(false);
      }, 2000);
    }
  }, [previewugresponse]);

  useEffect(() => {
    const appID = localStorage.getItem("userData");
    const pasrseData = JSON.parse(appID);
    setAppID(pasrseData?.appId);
    setPrglabelname(pasrseData);
  }, [localStorage.getItem("userData")]);

  useEffect(() => {
    if (dashboardresponse) {
      setProgramType(
        dashboardresponse?.data?.allDataOnDashboard[0]?.programName
      );
    }
  }, [dashboardresponse]);

  useEffect(() => {
    let formData = {
      contactId: localStorage.getItem("contactId"),
      applicationId: appIds,
      programType: programType,
    };
    // if (programType && formData && appIds) {
    dispatch(getUgPreview(formData));
    // }
  }, [programType, appIds]);

  useEffect(() => {
    if (previewugresponse) {
      setRegVal(previewugresponse?.data?.registrationDetails);
      setAppVal(previewugresponse?.data?.applicationDetails);
      setEduVal(previewugresponse?.data?.educationDetailsPG);
      setSocio(previewugresponse?.data?.socioEconomicDetails);
      setPhoto(previewugresponse?.data?.photoDocDetails);
      setDisability(previewugresponse?.data?.photoDocDetails?.IsDisable);
      setPhotoGraphData(previewugresponse?.data?.photoDocDetails?.photoGraphFile)
      setDisabilityData(previewugresponse?.data?.photoDocDetails?.disabiltyFile)
      // setAdmissionCNote()
    }
  }, [previewugresponse]);

  const currentDate = new Date();
  const year1 = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDate = `${year1}-${month}-${day}`;

  useEffect(() => {
    if (
      regVal?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Started" ||
      regVal?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Draft" ||
      regVal?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Payment Failed" ||
      regVal?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Payment Pending" ||
      regVal?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Payment Submitted"
    ) {
      if (
        formattedDate >
        regVal?.hed__Applications_Applicant__r?.records[0]?.Admission_Cycle__r
          ?.Application_Submission_Date__c
      ) {
        setReadOnly(true);
        setShowMsg(<Notes name="ADM_Cycle_Validation_App" />);
      } else {
        setReadOnly(false);
        setShowMsg("");
      }
    } else {
      if (
        formattedDate <
        regVal?.hed__Applications_Applicant__r?.records[0]?.Admission_Cycle__r
          ?.Application_Submission_Date__c
      ) {
        setReadOnly(true);
        setShowMsg("");
      } else {
        setReadOnly(true);
        setShowMsg(<Notes name="ADM_Cycle_Validation_App" />);
      }
    }
  }, [regVal]);

  function diplomaNotesVisible() {
    if (regVal) {
      {
        if (
          regVal?.hed__Applications_Applicant__r?.records[0]
            ?.hed__Application_Type__c === "Diploma" ||
          regVal?.hed__Applications_Applicant__r?.records[0]
            ?.hed__Application_Type__c === "Postgraduate Diploma"
        ) {
          return (
            // <p>{'The postgraduate diploma programmes and Certificate programmes are designed for working professionals in the  Education and Development sector and these programmes are conducted in a hybrid mode (a combination of online classes and in-person classes)'}</p>
            <p>
              {ReactHtmlParser(
                regVal?.hed__Applications_Applicant__r?.records[0]
                  ?.Admission_Cycle__r?.hed__Account__r?.APU_Display_Note__c
              )}
            </p>
          );
        }
      }
    }
  }

  useEffect(() => {
    setAdmissionCNote(
      regVal?.hed__Applications_Applicant__r?.records[0]?.Admission_Cycle__r
        ?.APU_Display_Note__c
    );
    setPRGCNote(
      regVal?.hed__Applications_Applicant__r?.records[0]?.Admission_Cycle__r
        ?.hed__Acount__r?.APU_Display_Note__c
    );
    setValue(
      "programGroup",
      regVal?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Type__c
    );
    setValue(
      "admissionCycle",
      regVal?.hed__Applications_Applicant__r?.records[0]?.Admission_Cycle__r
        ?.hed__Description__c
    );

    setValue("firstName", regVal?.FirstName);
    setValue("middleName", regVal?.MiddleName);
    setValue("lastName", regVal?.LastName);
    setValue("dateOfBirth", moment(regVal?.Birthdate).format("DD-MM-YYYY"));
    setValue("mobile", regVal?.MobilePhone);
    setValue(
      "relationship",
      regVal?.hed__Relationships__r?.records[0]?.hed__Type__c
    );
    setValue(
      "relationName",
      regVal?.hed__Relationships__r?.records[0]?.hed__RelatedContact__r?.Name
    );
    setValue(
      "relationMobno",
      regVal?.hed__Relationships__r?.records[0]?.hed__RelatedContact__r
        ?.MobilePhone
    );
    setValue(
      "relationEmail",
      regVal?.hed__Relationships__r?.records[0]?.hed__RelatedContact__r?.Email
    );
    setValue(
      "houseNo",
      regVal?.hed__Addresses__r?.records[0]?.hed__MailingStreet__c
    );
    setValue(
      "area",
      regVal?.hed__Addresses__r?.records[0]?.hed__MailingStreet2__c
    );
    setValue(
      "country",
      regVal?.hed__Addresses__r?.records[0]?.hed__MailingCountry__c
    );
    setValue(
      "state",
      regVal?.hed__Addresses__r?.records[0]?.hed__MailingState__c
    );
    setValue(
      "district",
      regVal?.hed__Addresses__r?.records[0]?.hed__MailingCounty__c
    );
    setValue(
      "city",
      regVal?.hed__Addresses__r?.records[0]?.hed__MailingCity__c
    );
    setValue(
      "pin",
      regVal?.hed__Addresses__r?.records[0]?.hed__MailingPostalCode__c
    );
    setValue(
      "building",
      regVal?.hed__Addresses__r?.records[1]?.hed__MailingStreet__c
    );
    setValue(
      "road",
      regVal?.hed__Addresses__r?.records[1]?.hed__MailingStreet2__c
    );
    setValue(
      "pCountry",
      regVal?.hed__Addresses__r?.records[1]?.hed__MailingCountry__c
    );
    setValue(
      "pState",
      regVal?.hed__Addresses__r?.records[1]?.hed__MailingState__c
    );
    setValue(
      "pDistrict",
      regVal?.hed__Addresses__r?.records[1]?.hed__MailingCounty__c
    );
    setValue(
      "pCity",
      regVal?.hed__Addresses__r?.records[1]?.hed__MailingCity__c
    );
    setValue(
      "pPin",
      regVal?.hed__Addresses__r?.records[1]?.hed__MailingPostalCode__c
    );
    setValue("know", regVal?.How_did_you_get_to_know_about_APU__c);
    setKnowapu(regVal?.How_did_you_get_to_know_about_APU__c);
    setValue("specify", regVal?.Specific_Answer__c);

    setpleaseSpecify(regVal?.Specific_Answer__c);

    setDipFlex(
      appVal?.hed__Applications_Applicant__r?.records[0]
        ?.APU_Diploma_Flexibility__c
    );
    setValue(
      "programApplying",
      appVal?.hed__Applications_Applicant__r?.records[0]?.First_Preference__r
        ?.Name
    );
    setValue("nationality", appVal?.hed__Country_of_Origin__c);
    setValue("domicile", appVal?.Domicile__c);
    setValue("gender", appVal?.hed__Gender__c);
    setValue(
      "haveYouAttendUniversityProgram",
      appVal?.hed__Applications_Applicant__r?.records[0]
        ?.APU_Haveyouattended_university_Program__c
    );
    setYear(
      appVal?.hed__Applications_Applicant__r?.records[0]
        ?.APU_Haveyouattended_university_Program__c
    );
    setValue(
      "apuYear",
      appVal?.hed__Applications_Applicant__r?.records[0]?.APU_Year__c
    );

    setValue(
      "apuDescribeNatureOfWork",
      appVal?.hed__Applications_Applicant__r?.records[0]
        ?.APU_Describe_nature_of_work_that_you_do__c
    );
    setValue(
      "apuWhyDoYouWantToJoinProgramme",
      appVal?.hed__Applications_Applicant__r?.records[0]
        ?.APU_Why_do_you_want_to_join_programme__c
    );
    setValue(
      "apuHowYouPlanLearningFromProgramme",
      appVal?.hed__Applications_Applicant__r?.records[0]
        ?.APU_How_you_plan_learning_from_programme__c
    );
    setValue(
      "apuRecommenderName",
      appVal?.hed__Applications_Applicant__r?.records[0]
        ?.APU_Recommender_Name__c
    );
    setValue(
      "apuRecommenderOrgName",
      appVal?.hed__Applications_Applicant__r?.records[0]
        ?.APU_Recommender_Organization_Name__c
    );
    setValue(
      "apuRecommenderJobTitle",
      appVal?.hed__Applications_Applicant__r?.records[0]
        ?.APU_Recommender_Job_Title__c
    );
    setValue(
      "apuRecommenderEmail",
      appVal?.hed__Applications_Applicant__r?.records[0]
        ?.APU_Recommender_Email__c
    );
    setValue(
      "apuRecommenderJobMobile",
      appVal?.hed__Applications_Applicant__r?.records[0]
        ?.APU_Recommender_Contact_Number__c
    );
    // education detail
    setValue(
      "qualificationsObtained",
      eduVal?.hed__Education_History__r?.records[1]?.Qualification_obtained__c
    );
    setValue(
      "completionYear",
      eduVal?.hed__Education_History__r?.records[1]?.Year_of_completion__c
    );
    setValue(
      "townCategoryTenth",
      eduVal?.hed__Education_History__r?.records[0]
        ?.Category_of_city_town_where_studied__c
    );
    setValue(
      "countryTenth",
      eduVal?.hed__Education_History__r?.records[0]?.Country__c
    );
    setValue(
      "otherCountrySpecifyTenth",
      eduVal?.hed__Education_History__r?.records[0]?.Please_Specify_Country__c
    );
    setValue(
      "mediumInstTenth",
      eduVal?.hed__Education_History__r?.records[0]?.Medium_of_instruction__c
    );
    setValue(
      "specifyMediumTenth",
      eduVal?.hed__Education_History__r?.records[0]
        ?.Please_Specify_Medium_of_Instruction__c
    );
    setValue(
      "stateTenth",
      eduVal?.hed__Education_History__r?.records[0]?.State__c
    );
    setValue(
      "districtTenth",
      eduVal?.hed__Education_History__r?.records[0]?.District_Name__c
    );
    setValue(
      "townCategoryGrad",
      eduVal?.hed__Education_History__r?.records[1]
        ?.Category_of_city_town_where_studied__c
    );
    setValue(
      "gradMode",
      eduVal?.hed__Education_History__r?.records[1]?.Mode_of_graduation__c
    );
    setValue(
      "streamSpecialization",
      eduVal?.hed__Education_History__r?.records[1]?.Stream_Specialization__c
    );
    setEduQual(
      eduVal?.hed__Education_History__r?.records[1]
        ?.APU_Have_any_qualification_certification__c
    );
    setValue(
      "universityName",
      eduVal?.hed__Education_History__r?.records[1]?.Name_of_the_University__c
    );
    setValue(
      "schoolNameGrad",
      eduVal?.hed__Education_History__r?.records[1]
        ?.hed__Educational_Institution_Name__c
    );
    setValue(
      "countryGrad",
      eduVal?.hed__Education_History__r?.records[1]?.Country__c
    );
    setValue(
      "otherCountrySpecifyGrad",
      eduVal?.hed__Education_History__r?.records[1]?.Please_Specify_Country__c
    );
    setValue(
      "stateGrad",
      eduVal?.hed__Education_History__r?.records[1]?.State__c
    );
    setValue(
      "districtGrad",
      eduVal?.hed__Education_History__r?.records[1]?.District_Name__c
    );
    setValue(
      "mediumInstGrad",
      eduVal?.hed__Education_History__r?.records[1]?.Medium_of_instruction__c
    );
    setValue(
      "specifyMediumGrad",
      eduVal?.hed__Education_History__r?.records[1]
        ?.Please_Specify_Medium_of_Instruction__c
    );
    setValue(
      "havePGDegree",
      eduVal?.hed__Education_History__r?.records[1]
        ?.Do_you_have_any_Postgraduate_degree__c
    );
    setValue(
      "qualificationName",
      eduVal?.hed__Education_History__r?.records[1]?.Name_on_Qualification__c
    );
    setValue(
      "diplomaSpecialization",
      eduVal?.hed__Education_History__r?.records[1]
        ?.APU_Diploma_Specialization__c
    );
    setValue(
      "diplomaUniversityInstitution",
      eduVal?.hed__Education_History__r?.records[1]
        ?.APU_Diploma_University_Institution__c
    );
    setValue(
      "diplomaYearOfCompletion",
      eduVal?.hed__Education_History__r?.records[1]
        ?.APU_Year_of_completion_diploma__c
    );

    setValue("workExperience", eduVal?.Work_experience_after_gr__c);
    setValue(
      "workExperienceInMonths",
      eduVal?.hed__Applications_Applicant__r?.records[0]
        ?.APU_Total_Experience_in_Months__c
    );
    setValue(
      "relevantWorkExpAfterGrad",
      eduVal?.hed__Applications_Applicant__r?.records[0]
        ?.APU_Relevant_experience_after_Graduation__c
    );
    setValue(
      "typeOfOrg",
      eduVal?.hed__Applications_Applicant__r?.records[0]
        ?.APU_Type_of_Organization__c
    );
    setValue(
      "orgAdditionalInfo",
      eduVal?.hed__Applications_Applicant__r?.records[0]
        ?.APU_Organization_Additional_info__c
    );
    setValue(
      "orgName",
      eduVal?.hed__Applications_Applicant__r?.records[0]
        ?.APU_Name_of_the_organization__c
    );
    setValue(
      "noOfPeopleReporting",
      eduVal?.hed__Applications_Applicant__r?.records[0]
        ?.APU_No_people_reporting_to_you__c
    );

    setRecords(eduVal?.Work_Experiences__r?.records);

    // socio eco detail
    setValue(
      "sponsorProgrammeFees",
      socio?.hed__Applications_Applicant__r?.records[0]
        ?.APU_organization_sponsor_programme_fee__c
    );
    setValue("caste", socio?.Caste__c);
    setValue("religion", socio?.hed__Religion__c);
    setValue("famlOccupation", socio?.Main_Occupation_of_family__c);
    setValue("famlIncome", socio?.Family_income_per_year__c);
    setValue("annualIncome", socio?.Exact_Annual_income__c);
    numToWrd = socio?.Exact_Annual_income__c;
    setValue("disability", socio?.Are_you_a_person_with_disability__c);
    setValue("disabilityNature", socio?.Nature_of_Disability__c);
    setValue("moreDetails", socio?.Do_you_need_any_help_from_Admission_Team__c);

    setImage =photoGraphData?.link;
    setDisabilityImage =disabilityData?.Title;

  }, [previewugresponse, regVal, appVal, eduVal, socio, photo]);

  const handleClick = () => {
    navigate(PATHS.PAYMENTDETAILSUG_STR + appIds);
  };

  return (
    <Home>
      <div class="right_panel">
        <div class="w100 dflex">
          <h4>{getCapitalized(prgLabelname?.programName)}</h4>
          <span className="help_link">
            <p>
              <NavLink to={PATHS.RAISEQUERY}>{HELP_TEXT}</NavLink>{" "}
              <Tooltip id="helpTooltip">
                {/* <p>Click to Raise Query</p> */}
                <p>{ApplicationViewPage.Help_Tooltip_Text}</p>
              </Tooltip>
              <i
                data-tooltip-id="helpTooltip"
                class="fa-solid fa-circle-info"
              ></i>
            </p>
          </span>
        </div>

        <div className="w100 scroll_styl">
          <div class="heading_styl head_font">
            {/* <h4>{getCapitalized("Preview")}</h4> */}
            <h4>{getCapitalized(ApplicationViewPage.Preview)}</h4>
          </div>
          <Loader show={loader} />
          <div class="form_styl">
            <div class="w100">
              <Notes name="PreviewOnTopMessage" />
            </div>
          </div>
          <div class="heading_styl">
            {/* <h4>Application Registration</h4> */}
            <h4>{RegistrationPage.Application_Registration}</h4>
          </div>

          <div class="form_part">
            {/* <p className="text-left">
              Click the link to know the programs offered in Bangalore and
              Bhopal campus. Please choose the admission cycle carefully and
              proceed with the application form.
            </p> */}
            <Notes name="Application_Programme" />
            <div class="form_sec">
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {/* Program Group <span className="redstyl">*</span> */}
                    {RegistrationPage.Program_Group}{" "}
                    <span className="redstyl">*</span>
                    <Tooltip id="programTool">
                      <Notes name="Program_Group" />
                    </Tooltip>
                    <i
                      data-tooltip-id="programTool"
                      class="fa-solid fa-circle-info"
                    ></i>
                  </label>
                  <input {...register("programGroup")} disabled />
                  {diplomaNotesVisible()}
                </div>
                <div class="inner_part">
                  <label>
                    {/* Admission Cycle <span className="redstyl">*</span> */}
                    {RegistrationPage.Admission_Cycle}{" "}
                    <span className="redstyl">*</span>
                  </label>
                  <input {...register("admissionCycle")} disabled />

                  <p>{ReactHtmlParser(admissionCNote)}</p>
                </div>
              </div>
              {/* <div><p>{admissionCNote}</p></div> */}
              <div class="heading_styl">
                {/* <h4>Personal Details</h4> */}
                <h4>{RegistrationPage.Personal_Details}</h4>
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {/* First Name <span className="redstyl">*</span> */}
                    {RegistrationPage.First_Name}{" "}
                    <span className="redstyl">*</span>
                  </label>
                  <input {...register("firstName")} disabled />
                </div>
                <div class="inner_part">
                  {/* <label>Middle Name </label> */}
                  <label>{RegistrationPage.Middle_Name}</label>
                  <input {...register("middleName")} disabled />
                </div>
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {/* Last Name <span class="redstyl">* </span> */}
                    {RegistrationPage.Last_Name} <span class="redstyl">* </span>
                  </label>
                  <input {...register("lastName")} disabled />
                </div>
                <div class="inner_part">
                  <label>
                    {/* Date of Birth <span class="redstyl">* </span> */}
                    {RegistrationPage.DOB} <span class="redstyl">* </span>
                  </label>
                  <input {...register("dateOfBirth")} disabled />
                </div>
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {/* Mobile Number(for SMS notifiications):{" "}
                    <span class="redstyl">* </span> */}
                    {RegistrationPage.Mobile_Number_SMS}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <input {...register("mobile")} disabled />
                </div>
              </div>
              <div class="heading_styl">
                {/* <h4>Alternative Contact</h4> */}
                <h4>{RegistrationPage.Alternative_Contact}</h4>
              </div>

              <div class="form_styl">
                <div class="inner_part1 b_none">
                  <label>
                    {/* Relationship <span class="redstyl">* </span> */}
                    {RegistrationPage.Relationship}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <input {...register("relationship")} disabled />
                </div>
                <div class="inner_part1 b_none">
                  <label>
                    {/* Name <span class="redstyl">* </span> */}
                    {RegistrationPage.Name} <span class="redstyl">* </span>
                  </label>
                  <input {...register("relationName")} disabled />
                </div>
                <div class="inner_part1 b_none">
                  <label>
                    {/* Mobile No. <span class="redstyl">* </span> */}
                    {RegistrationPage.Mobile_No} <span class="redstyl">* </span>
                  </label>
                  <input {...register("relationMobno")} disabled />
                </div>
                <div class="inner_part1 b_none">
                  <label>
                    {/* Email <span class="redstyl">* </span> */}
                    {RegistrationPage.Email} <span class="redstyl">* </span>
                  </label>
                  <input {...register("relationEmail")} disabled />
                </div>
              </div>
              <div class="form_styl">
                <div class="form_sec_inner">
                  <div class="heading_styl mb-2">
                    {/* <h4>Communication Address</h4> */}
                    <h4>{RegistrationPage.Communication_Address}</h4>
                  </div>
                  <div class="w100 ">
                    <div class="inner_part2 mt-3">
                      <label>
                        {/* House No/Building: <span class="redstyl">* </span> */}
                        {RegistrationPage.House_No}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <input {...register("houseNo")} disabled />
                    </div>
                    <div class="inner_part2">
                      <label>
                        {/* Area/Road/Street/P.O: <span className="redstyl">*</span> */}
                        {RegistrationPage.Area_Road}{" "}
                        <span className="redstyl">*</span>
                      </label>
                      <input {...register("area")} disabled />
                    </div>
                  </div>
                  <div class="w100 form_styl ">
                    <div class="inner_part">
                      <label>
                        {/* Country : <span className="redstyl">*</span> */}
                        {RegistrationPage.Country}{" "}
                        <span className="redstyl">*</span>
                      </label>
                      <input {...register("country")} disabled />
                    </div>
                    <div class="inner_part">
                      <label>
                        {/* State/Union Territory : <span>*</span> */}
                        {RegistrationPage.State_Union} <span>*</span>
                      </label>
                      <input {...register("state")} disabled />
                    </div>
                  </div>
                  <div class="w100 ">
                    <div class="inner_part2">
                      <label>
                        {/* District: <span class="redstyl">* </span> */}
                        {RegistrationPage.District}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <input {...register("district")} disabled />
                    </div>

                    <div class="inner_part2">
                      <label>
                        {/* City: <span class="redstyl">* </span> */}
                        {RegistrationPage.City} <span class="redstyl">* </span>
                      </label>
                      <input {...register("city")} disabled />
                    </div>
                    <div class="inner_part2">
                      <label>
                        {/* Pin: <span class="redstyl">* </span> */}
                        {RegistrationPage.Pin} <span class="redstyl">* </span>
                      </label>
                      <input {...register("pin")} disabled />
                    </div>
                  </div>
                </div>
                <div class="form_sec_inner">
                  <div class="heading_styl">
                    {/* <h4>Permanent Address</h4> */}
                    <h4>{RegistrationPage.Permanent_Address}</h4>
                  </div>
                  <div class="w100 ">
                    <div class="flex">
                      <input
                        type="checkbox"
                        disabled
                        checked={
                          regVal?.Check_if_same_as_Communication_Address__c
                        }
                        placeholder="Enter name..."
                      />{" "}
                      <label class="f_size">
                        {" "}
                        {/* Check if same as Communication Address */}
                        {RegistrationPage.Permanent_Address_Same_Message}
                      </label>
                    </div>
                    <div class="inner_part2">
                      <label>
                        {/* House No/Building: <span class="redstyl">* </span> */}
                        {RegistrationPage.House_No}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <input {...register("building")} disabled />
                    </div>
                    <div class="inner_part2">
                      <label>
                        {/* Area/Road/Street/P.O: <span class="redstyl">* </span> */}
                        {RegistrationPage.Area_Road}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <input {...register("road")} disabled />
                    </div>
                  </div>
                  <div class="w100 form_styl ">
                    <div class="inner_part">
                      <label>
                        {/* Country : <span class="redstyl">* </span> */}
                        {RegistrationPage.Country}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <input {...register("pCountry")} disabled />
                    </div>
                    <div class="inner_part">
                      <label>
                        {/* State/Union Territory : <span class="redstyl">* </span> */}
                        {RegistrationPage.State_Union}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <input {...register("pState")} disabled />
                    </div>
                  </div>
                  <div class="w100 ">
                    <div class="inner_part2">
                      <label>
                        {/* District: <span class="redstyl">* </span> */}
                        {RegistrationPage.District}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <input {...register("pDistrict")} disabled />
                    </div>
                    <div class="inner_part2">
                      <label>
                        {/* City: <span class="redstyl">* </span> */}
                        {RegistrationPage.City} <span class="redstyl">* </span>
                      </label>
                      <input {...register("pCity")} disabled />
                    </div>
                    <div class="inner_part2">
                      <label>
                        {/* Pin: <span class="redstyl">* </span> */}
                        {RegistrationPage.Pin} <span class="redstyl">* </span>
                      </label>
                      <input {...register("pPin")} disabled />
                    </div>
                  </div>
                </div>
              </div>

              <div class="form_styl1">
                <div class="inner_part">
                  <label>
                    {/* How did you get to know about Azim Premji University? :{" "}
                    <span className="redstyl">* </span> */}
                    {RegistrationPage.Hear_About_APU}{" "}
                    <span className="redstyl">* </span>
                  </label>
                  <input {...register("know")} disabled />
                  {pleaseSpecify && (
                    <div class="inner_part w66 m_auto">
                      <label>
                        {/* Please Specify: <span className="redstyl">* </span> */}
                        {RegistrationPage.Please_Specify}{" "}
                        <span className="redstyl">* </span>
                      </label>
                      <input {...register("specify")} disabled />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="form_sec">
              <div class="heading_styl">
                {/* <h4>Application Details</h4> */}
                <h4>{DiplomaApplicationDetails.Application_Details}</h4>
              </div>

              <div class="form_sec">
                <div class="form_styl">
                  {dipFlex && (
                    <div class="inner_part">
                      <label>
                        {/* Diploma Specification <span className="redstyl">*</span> */}
                        {DiplomaApplicationDetails.Diploma_Specification}{" "}
                        <span className="redstyl">*</span>
                      </label>
                      <span class="flex">
                        <span class="flex">
                          <input
                            type="radio"
                            name="apuDiplomaFlexibility"
                            checked={dipFlex === "Certificate"}
                            disabled
                          />
                          {/* <label>Certificate</label> */}
                          <label>{ApplicationViewPage.Certificate}</label>
                        </span>
                        <span class="flex">
                          <input
                            type="radio"
                            name="apuDiplomaFlexibility"
                            checked={dipFlex === "Diploma"}
                            disabled
                          />
                          {/* <label>Diploma</label> */}
                          <label>{ApplicationViewPage.Diploma}</label>
                        </span>
                      </span>
                    </div>
                  )}
                  <div class="inner_part">
                    <label>
                      {/* Programme applying for <span className="redstyl">*</span> */}
                      {DiplomaApplicationDetails.Programme_Applying}{" "}
                      <span className="redstyl">*</span>
                    </label>
                    <input {...register("programApplying")} disabled />
                    <p>
                      {ReactHtmlParser(
                        eduVal?.hed__Applications_Applicant__r?.records[0]
                          ?.First_Preference__r?.APU_Display_Note__c
                      )}
                    </p>
                  </div>
                </div>
                {appVal?.hed__Country_of_Origin__c && (
                  <>
                    <div class="heading_styl">
                      {/* <h4>Background Details</h4> */}
                      <h4>{DiplomaApplicationDetails.Background_Details}</h4>
                    </div>
                    <div class="form_styl">
                      <div class="inner_part">
                        <label>
                          {/* Nationality <span className="redstyl">*</span> */}
                          {DiplomaApplicationDetails.Nationality}{" "}
                          <span className="redstyl">*</span>
                        </label>
                        <input {...register("nationality")} disabled />
                      </div>
                      <div class="inner_part">
                        <label>
                          {/* Domicile <span className="redstyl">*</span> */}
                          {DiplomaApplicationDetails.Domicile}{" "}
                          <span className="redstyl">*</span>
                          <Tooltip id="domicileTooltip">
                            <Notes name="Eligibility_Criteria_Note" />
                          </Tooltip>
                          <i
                            data-tooltip-id="domicileTooltip"
                            class="fa-solid fa-circle-info"
                            style={{ color: "gray" }}
                          ></i>
                        </label>
                        <input {...register("domicile")} disabled />
                      </div>
                    </div>
                    <div class="form_styl">
                      <div class="inner_part">
                        <label>
                          {/* Gender <span className="redstyl">*</span> */}
                          {DiplomaApplicationDetails.Gender}{" "}
                          <span className="redstyl">*</span>
                        </label>
                        <input {...register("gender")} disabled />
                      </div>
                      <div class="inner_part">
                        <label>
                          {/* Have you applied to us previously?{" "}
                          <span className="redstyl">*</span> */}
                          {DiplomaApplicationDetails.Attended_Before}{" "}
                          <span className="redstyl">*</span>
                        </label>
                        <input
                          {...register("haveYouAttendUniversityProgram")}
                          disabled
                        />
                      </div>
                    </div>

                    {year === "Yes" && (
                      <div className="form_styl">
                        <div class="inner_part">
                          <label>
                            {/* Programs attended and Year{" "}
                            <span className="redstyl">*</span> */}
                            {DiplomaApplicationDetails.Attended_Year}{" "}
                            <span className="redstyl">*</span>
                          </label>
                          <textarea
                            disabled
                            {...register("apuYear")}
                            rows="5"
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}

                {appVal?.hed__Applications_Applicant__r?.records[0]
                  ?.APU_Describe_nature_of_work_that_you_do__c && (
                  <>
                    {" "}
                    <div class="heading_styl">
                      {/* <h4>Statement of Purpose (SOP)</h4> */}
                      <h4>{DiplomaApplicationDetails.SOP}</h4>
                    </div>
                    <div class="form_styl">
                      <div class="inner_part">
                        <label>
                          {/* Describe the nature of work that you do?{" "}
                          <span className="redstyl">*</span> */}
                          {DiplomaApplicationDetails.Nature_Of_Work}{" "}
                          <span className="redstyl">*</span>
                        </label>
                        <textarea
                          disabled
                          {...register("apuDescribeNatureOfWork")}
                          placeholder=" "
                          rows="6"
                        ></textarea>
                      </div>
                      <div class="inner_part">
                        <label>
                          {/* Why do you want to join the Programme?{" "}
                          <span className="redstyl">*</span> */}
                          {DiplomaApplicationDetails.Joining_Reason}{" "}
                          <span className="redstyl">*</span>
                        </label>
                        <textarea
                          disabled
                          {...register("apuWhyDoYouWantToJoinProgramme")}
                          placeholder=" "
                          rows="6"
                        ></textarea>
                      </div>
                    </div>
                    <div class="form_styl">
                      <div class="inner_part">
                        <label>
                          {/* How do you plan to apply the learnings from the
                          Programme? <span className="redstyl">*</span> */}
                          {DiplomaApplicationDetails.Apply_Learnings_Plan}{" "}
                          <span className="redstyl">*</span>
                        </label>
                        <textarea
                          disabled
                          {...register("apuHowYouPlanLearningFromProgramme")}
                          placeholder=" "
                          rows="6"
                        ></textarea>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            {appVal?.hed__Applications_Applicant__r?.records[0]
              ?.APU_Recommender_Name__c && (
              <>
                <div class="heading_styl">
                  {/* <h4>Recommender Details </h4> */}
                  <h4>{DiplomaApplicationDetails.Recommender_Details}</h4>
                </div>
                <div class="form_styl">
                  <div class="w100">
                    <Notes name="Diploma_Recommender" />
                  </div>
                </div>
                <div class="form_styl">
                  <div class="inner_part">
                    <label>
                      {/* Name <span className="redstyl">*</span> */}
                      {DiplomaApplicationDetails.Name}{" "}
                      <span className="redstyl">*</span>
                    </label>
                    <input disabled {...register("apuRecommenderName")} />
                  </div>
                  <div class="inner_part">
                    <label>
                      {/* Organization Name <span className="redstyl">*</span> */}
                      {DiplomaApplicationDetails.Organization_Name}{" "}
                      <span className="redstyl">*</span>
                    </label>
                    <input disabled {...register("apuRecommenderOrgName")} />
                  </div>
                </div>

                <div class="form_styl">
                  <div class="inner_part">
                    <label>
                      {/* Job Title <span className="redstyl">*</span> */}
                      {DiplomaApplicationDetails.Job_Title}{" "}
                      <span className="redstyl">*</span>
                    </label>
                    <input disabled {...register("apuRecommenderJobTitle")} />
                  </div>
                  <div class="inner_part">
                    <label>
                      {/* Email <span className="redstyl">*</span> */}
                      {DiplomaApplicationDetails.Email}{" "}
                      <span className="redstyl">*</span>
                    </label>
                    <input disabled {...register("apuRecommenderEmail")} />
                  </div>
                </div>
                <div class="form_styl">
                  <div class="inner_part">
                    <label>
                      {/* Contact Number <span className="redstyl">*</span> */}
                      {DiplomaApplicationDetails.Contact_Number}{" "}
                      <span className="redstyl">*</span>
                    </label>
                    <input disabled {...register("apuRecommenderJobMobile")} />
                  </div>
                </div>
              </>
            )}

            <div class="form_sec">
              <div class="heading_styl">
                {/* <h4>Education Details</h4> */}
                <h4>{DiplomaEducationDetails.Education_Details}</h4>
              </div>
              <div class="form_sec form_part">
                <div class="heading_styl">
                  {/* <h4>Bachelor’s Details</h4> */}
                  <h4>{DiplomaEducationDetails.Bachelor_Details}</h4>
                </div>
                <div class="form_styl">
                  <div class="inner_part">
                    <label>
                      {/* Qualification obtained <span className="redstyl">*</span> */}
                      {DiplomaEducationDetails.Qualification_Obtained}{" "}
                      <span className="redstyl">*</span>
                    </label>
                    <input {...register("qualificationsObtained")} disabled />
                  </div>
                  <div class="inner_part">
                    <label>
                      {/* Year of completion <span className="redstyl">*</span> */}
                      {DiplomaEducationDetails.Year_Of_Completion}{" "}
                      <span className="redstyl">*</span>
                      <i
                        data-tooltip-id="yearcompletion"
                        class="fa-solid fa-circle-info"
                      ></i>
                      <Tooltip id="yearcompletion">
                        {/* <p>
                          Please mention the passing year in 4 digits and Do not
                          enter any special character.{" "}
                        </p> */}
                        <Notes name="Year_of_completion_Diploma" />
                      </Tooltip>
                    </label>
                    <input {...register("completionYear")} disabled />
                  </div>
                </div>

                <div class="heading_styl">
                  {/* <h4>Class 10 Details</h4> */}
                  <h4>{DiplomaEducationDetails.Class_10_Details}</h4>
                </div>

                <div class="form_styl">
                  <div class="w100 blc_txt">
                    <p>
                      Category A:{" "}
                      <i>
                        Bangalore, Chennai, Delhi, Kolkata, Mumbai, Pune,
                        Hyderabad and Chandigarh;
                      </i>
                    </p>
                    <p>
                      Category B:{" "}
                      <i>
                        Other state capital cities and cities with population
                        greater than 10 lakhs;
                      </i>
                    </p>
                    <p>
                      Category C:{" "}
                      <i>Cities with population less than 10 Lakhs</i>
                    </p>
                    <p>
                      Category D: <i>Rural</i>
                    </p>
                  </div>
                </div>
                <div class="form_styl">
                  <div class="inner_part">
                    <label>
                      {/* Category of city/town(10th){" "}
                      <span class="redstyl">* </span> */}
                      {DiplomaEducationDetails.Category_Of_City_10}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input {...register("townCategoryTenth")} disabled />
                  </div>
                  <div class="inner_part">
                    <label>
                      {/* Country(10th) <span class="redstyl">* </span> */}
                      {DiplomaEducationDetails.Country_10}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input {...register("countryTenth")} disabled />
                  </div>
                </div>
                <div class="form_styl">
                  {eduVal?.hed__Education_History__r?.records[0]?.Country__c ===
                    "Others" && (
                    <div class="inner_part">
                      <label>
                        {/* Please Specify <span>*</span> */}
                        {DiplomaEducationDetails.Please_Specify} <span>*</span>
                      </label>
                      <input
                        {...register("otherCountrySpecifyTenth")}
                        disabled
                      />
                    </div>
                  )}
                  <div class="inner_part">
                    <label>
                      {/* Medium of Instruction(10th){" "}
                      <span class="redstyl">* </span> */}
                      {DiplomaEducationDetails.Medium_Of_Instruction_10}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input {...register("mediumInstTenth")} disabled />
                  </div>
                </div>
                <div class="form_styl">
                  {eduVal?.hed__Education_History__r?.records[0]
                    ?.Medium_of_instruction__c === "Other" && (
                    <div class="inner_part">
                      <label>
                        {/* Please Specify <span className="redstyl">*</span> */}
                        {DiplomaEducationDetails.Please_Specify}{" "}
                        <span className="redstyl">*</span>
                      </label>
                      <input {...register("specifyMediumTenth")} disabled />
                    </div>
                  )}
                  {eduVal?.hed__Education_History__r?.records[0]?.Country__c ===
                    "India" && (
                    <div class="inner_part">
                      <label>
                        {/* State/Union Territory of Residence(10th){" "}
                        <span class="redstyl">* </span> */}
                        {DiplomaEducationDetails.State_Union_10}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <input {...register("stateTenth")} disabled />
                    </div>
                  )}
                </div>
                {eduVal?.hed__Education_History__r?.records[0]?.Country__c ===
                  "India" && (
                  <div class="form_styl">
                    <div class="inner_part">
                      <label>
                        {/* District / City(10th) <span class="redstyl">* </span> */}
                        {DiplomaEducationDetails.District_10}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <input {...register("districtTenth")} disabled />
                    </div>
                  </div>
                )}

                <div class="heading_styl">
                  {/* <h4>Undergraduate Details</h4> */}
                  <h4>{DiplomaEducationDetails.Undergraduate_Details}</h4>
                </div>
                <div class="form_styl">
                  <div class="w100 blc_txt">
                    <p>
                      Category A:{" "}
                      <i>
                        Bangalore, Chennai, Delhi, Kolkata, Mumbai, Pune,
                        Hyderabad and Chandigarh;
                      </i>
                    </p>
                    <p>
                      Category B:{" "}
                      <i>
                        Other state capital cities and cities with population
                        greater than 10 lakhs;
                      </i>
                    </p>
                    <p>
                      Category C:{" "}
                      <i>Cities with population less than 10 Lakhs</i>
                    </p>
                    <p>
                      Category D: <i>Rural</i>
                    </p>
                  </div>
                </div>
                <div class="form_styl">
                  <div class="inner_part">
                    <label>
                      {/* Category of city/town where studied{" "}
                      <span className="redstyl">*</span> */}
                      {DiplomaEducationDetails.Category_Of_City_UG}{" "}
                      <span className="redstyl">*</span>
                    </label>
                    <input {...register("townCategoryGrad")} disabled />
                  </div>
                  <div class="inner_part">
                    <label>
                      {/* Mode of Graduation <span className="redstyl">*</span> */}
                      {DiplomaEducationDetails.Mode_Of_Graduation}{" "}
                      <span className="redstyl">*</span>
                    </label>
                    <input {...register("gradMode")} disabled />
                  </div>
                </div>
                <div class="form_styl">
                  <div class="inner_part">
                    <label>
                      {/* Stream/Specialization <span className="redstyl">*</span> */}
                      {DiplomaEducationDetails.Stream_UG}{" "}
                      <span className="redstyl">*</span>
                    </label>
                    <input {...register("streamSpecialization")} disabled />
                  </div>
                  <div class="inner_part">
                    <label>
                      {/* Name of the University <span className="redstyl">*</span> */}
                      {DiplomaEducationDetails.University_Name_UG}{" "}
                      <span className="redstyl">*</span>
                    </label>
                    <input {...register("universityName")} disabled />
                  </div>
                </div>
                <div class="form_styl">
                  <div class="inner_part">
                    <label>
                      {/* Name of the College <span className="redstyl">*</span> */}
                      {DiplomaEducationDetails.College_Name_UG}{" "}
                      <span className="redstyl">*</span>
                    </label>
                    <input {...register("schoolNameGrad")} disabled />
                  </div>
                  <div class="inner_part">
                    <label>
                      {/* Country <span className="redstyl">*</span> */}
                      {DiplomaEducationDetails.Country_UG}{" "}
                      <span className="redstyl">*</span>
                    </label>
                    <input {...register("countryGrad")} disabled />
                  </div>
                </div>

                <div class="form_styl">
                  {eduVal?.hed__Education_History__r?.records[1]?.Country__c ===
                    "Others" && (
                    <div class="inner_part">
                      <label>
                        {/* Please Specify <span className="redstyl">*</span> */}
                        {DiplomaEducationDetails.Please_Specify}{" "}
                        <span className="redstyl">*</span>
                      </label>
                      <input
                        {...register("otherCountrySpecifyGrad")}
                        disabled
                      />
                    </div>
                  )}
                </div>
                {eduVal?.hed__Education_History__r?.records[1]?.Country__c ===
                  "India" && (
                  <div class="form_styl">
                    <div class="inner_part">
                      <label>
                        {/* State/Union Territory of Residence{" "}
                        <span className="redstyl">*</span> */}
                        {DiplomaEducationDetails.State_Union_Residence_UG}{" "}
                        <span className="redstyl">*</span>
                      </label>
                      <input {...register("stateGrad")} disabled />
                    </div>
                    <div class="inner_part">
                      <label>
                        {/* District / City <span className="redstyl">*</span> */}
                        {DiplomaEducationDetails.District_UG}{" "}
                        <span className="redstyl">*</span>
                      </label>
                      <input {...register("districtGrad")} disabled />
                    </div>
                  </div>
                )}
                <div class="form_styl">
                  <div class="inner_part">
                    <label>
                      {/* Medium of Instruction <span className="redstyl">*</span> */}
                      {DiplomaEducationDetails.Medium_Of_Instruction_UG}{" "}
                      <span className="redstyl">*</span>
                    </label>
                    <input {...register("mediumInstGrad")} disabled />
                  </div>
                  {eduVal?.hed__Education_History__r?.records[1]
                    ?.Medium_of_instruction__c === "Other" && (
                    <div class="inner_part">
                      <label>
                        {/* Please Specify <span className="redstyl">*</span> */}
                        {DiplomaEducationDetails.Please_Specify}{" "}
                        <span className="redstyl">*</span>
                      </label>
                      <input {...register("specifyMediumGrad")} disabled />
                    </div>
                  )}
                </div>
                <div class="form_styl">
                  <div class="inner_part">
                    <label>
                      {/* Do you have any Postgraduate degree?{" "}
                      <span className="redstyl">*</span> */}
                      {DiplomaEducationDetails.PG_Degree_Question}{" "}
                      <span className="redstyl">*</span>
                    </label>
                    <input {...register("havePGDegree")} disabled />
                  </div>
                </div>
                <div class="form_styl">
                  {eduVal?.hed__Education_History__r?.records[1]
                    ?.Do_you_have_any_Postgraduate_degree__c === "Yes" && (
                    <div class="inner_part">
                      <label>
                        {/* Name of the Postgraduate degree{" "}
                        <span class="redstyl">* </span> */}
                        {DiplomaEducationDetails.PG_Degree_Name}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <input disabled {...register("qualificationName")} />
                    </div>
                  )}
                  <div class="inner_part">
                    <label>
                      {/* Do you have any other educational qualification
                      /certification? <span className="redstyl">*</span> */}
                      {
                        DiplomaEducationDetails.Any_Other_Qualifications_Question
                      }{" "}
                      <span className="redstyl">*</span>
                    </label>
                    <span class="flex">
                      <span class="flex">
                        <input
                          type="radio"
                          name="financialAssis"
                          checked={eduQual === "Yes"}
                          disabled
                        />
                        <label for="Yes">Yes</label>
                      </span>
                      <span class="flex">
                        <input
                          type="radio"
                          name="financialAssis"
                          checked={eduQual === "No"}
                          disabled
                        />
                        <label for="No">No</label>
                      </span>
                    </span>
                  </div>
                </div>
                {eduQual === "Yes" && (
                  <>
                    <div className="form_styl">
                      <div class="inner_part">
                        <label>
                          {/* Specialization <span className="redstyl">*</span> */}
                          {DiplomaEducationDetails.Specialization_Other}{" "}
                          <span className="redstyl">*</span>
                        </label>
                        <input
                          disabled
                          {...register("diplomaSpecialization")}
                        />
                      </div>
                      <div class="inner_part">
                        <label>
                          {/* University/Institution{" "}
                          <span className="redstyl">*</span> */}
                          {DiplomaEducationDetails.University_Other}{" "}
                          <span className="redstyl">*</span>
                        </label>
                        <input
                          disabled
                          {...register("diplomaUniversityInstitution")}
                        />
                      </div>
                    </div>

                    <div className="form_styl">
                      <div class="inner_part">
                        <label>
                          {/* Year of completion <span className="redstyl">*</span> */}
                          {DiplomaEducationDetails.Year_Of_Completion}{" "}
                          <span className="redstyl">*</span>
                          <i
                            data-tooltip-id="yearcompletionEdu"
                            class="fa-solid fa-circle-info"
                          ></i>
                          <Tooltip id="yearcompletionEdu">
                            {/* <p>Please enter value like 2021. </p> */}
                            <p>
                              {
                                DiplomaEducationDetails.Year_Of_Completion_Tooltip_Message
                              }
                            </p>
                          </Tooltip>
                        </label>
                        <input
                          disabled
                          {...register("diplomaYearOfCompletion")}
                        ></input>
                      </div>
                    </div>
                  </>
                )}

                <div class="heading_styl">
                  {/* <h4>Work Experience</h4> */}
                  <h4>{DiplomaEducationDetails.Work_Experience}</h4>
                </div>
                <div className="form_styl">
                  {eduVal && eduVal?.Work_experience_after_gr__c === "Yes" && (
                    <div className="inner_part" disabled>
                      <label style={{ color: "gray" }}>
                        {/* Program Commencement Month :{" "} */}
                        {
                          DiplomaEducationDetails.Program_Commencement_Month
                        }{" "}
                        {
                          eduVal?.hed__Applications_Applicant__r?.records[0]
                            ?.First_Preference__r?.APU_Commencement_Month__c
                        }
                      </label>
                    </div>
                  )}
                </div>
                <div class="form_styl">
                  <div class="inner_part">
                    <label>
                      {/* Total Experience after Graduation (in months){" "} */}
                      {
                        DiplomaEducationDetails.Total_Experience_After_Graduation
                      }{" "}
                      <span className="redstyl">*</span>
                      <>
                        <Tooltip id="exphelpTooltip">
                          {/* <p>
                            Enter the total experience in months at the time
                            of Program Commencement Month
                          </p> */}
                          <Notes name="Total_Experience_after_Graduation_Diploma" />
                        </Tooltip>
                        <i
                          data-tooltip-id="exphelpTooltip"
                          class="fa-solid fa-circle-info"
                        ></i>
                      </>
                    </label>
                    <input disabled {...register("workExperienceInMonths")} />
                  </div>
                  <div class="inner_part">
                    <label>
                      {/* Relevant work experience after Graduation (in months)
                      <span className="redstyl">*</span> */}
                      {
                        DiplomaEducationDetails.Relevant_Experience_After_Graduation
                      }{" "}
                      <span className="redstyl">*</span>
                      <>
                        <Tooltip id="relevantwork">
                          {/* <p>
                            Experience in the broad fields of Education/Development/Health etc will be considered as relevant
                          </p> */}
                          <Notes name="Relevant_work_experience_after_Graduation_Diploma" />
                        </Tooltip>
                        <i
                          data-tooltip-id="relevantwork"
                          class="fa-solid fa-circle-info"
                        ></i>
                      </>
                    </label>
                    <input {...register("relevantWorkExpAfterGrad")} disabled />
                  </div>
                </div>

                <>
                  <div class="form_styl">
                    <div class="inner_part">
                      <label>
                        {/* Type of Organization <span className="redstyl">*</span> */}
                        {
                          DiplomaEducationDetails.Type_Of_Organization_Experience
                        }{" "}
                        <span className="redstyl">*</span>
                        <>
                          <Tooltip id="typeoforg">
                            {/* <p>
                              Choose the most relevant type based on your current/latest organization
                            </p> */}
                            <Notes name="Type_of_Organization_Diploma" />
                          </Tooltip>
                          <i
                            data-tooltip-id="typeoforg"
                            class="fa-solid fa-circle-info"
                          ></i>
                        </>
                      </label>
                      <input disabled {...register("typeOfOrg")} />
                    </div>
                    <div class="inner_part">
                      <label>
                        {/* Organization Additional Info{" "}
                        <span className="redstyl">*</span> */}
                        {DiplomaEducationDetails.Organization_Additional_Info}{" "}
                        <span className="redstyl">*</span>
                        <Tooltip id="orghelpTooltip">
                          {/* <p>Select what best fits your Organization</p> */}
                          <p>
                            {
                              DiplomaEducationDetails.Organization_Additional_Info_Tooltip_Message
                            }
                          </p>
                        </Tooltip>
                        <i
                          data-tooltip-id="orghelpTooltip"
                          class="fa-solid fa-circle-info"
                        ></i>
                      </label>
                      <input {...register("orgAdditionalInfo")} disabled />
                    </div>
                  </div>

                  {/* <div class="form_styl">
                     
                      <div class="inner_part">
                        <label>
                          Name of the Organization{" "}
                          <span className="redstyl">*</span>
                        </label>
                        <input disabled {...register("orgName")} />
                      </div>
                    </div> */}
                </>

                {eduVal?.hed__Applications_Applicant__r?.records[0]
                  ?.APU_No_people_reporting_to_you__c && (
                  <div class="form_styl">
                    <div class="inner_part">
                      <label>
                        {/* No.of people reporting to you?{" "}
                        <span className="redstyl">*</span> */}
                        {DiplomaEducationDetails.No_Of_People_Reporting}{" "}
                        <span className="redstyl">*</span>
                      </label>
                      <input disabled {...register("noOfPeopleReporting")} />
                    </div>
                  </div>
                )}

                <div class="heading_styl">
                  {/* <h4>More Details on Work Experience</h4> */}
                  <h4>
                    {DiplomaEducationDetails.Work_Experience_More_Details}
                  </h4>
                </div>

                <div class="form_styl">
                  <div class="w100">
                    {/* <p>
                      Note: Enter work experience details starting with latest experience{" "}
                    </p> */}
                    <Notes name="More_Details_on_Work_Experience_Diploma" />
                  </div>
                </div>

                {records?.map((element, index) => (
                  <React.Fragment key={index}>
                    <div class="form_styl">
                      <div class="inner_part">
                        <label>
                          {/* Start Date <span class="redstyl">* </span> */}
                          {
                            DiplomaEducationDetails.Start_Date_More_Experience
                          }{" "}
                          <span class="redstyl">* </span>
                        </label>
                        <input
                          disabled
                          defaultValue={element?.APU_Start_Date__c}
                        />
                      </div>
                      <div class="inner_part">
                        {/* <label>End Date</label> */}
                        <label>
                          {DiplomaEducationDetails.End_Date_More_Experience}
                        </label>
                        <input
                          disabled
                          defaultValue={element?.APU_End_Date__c}
                        />
                      </div>
                    </div>
                    <div class="form_styl">
                      <div class="inner_part">
                        <label>
                          {/* Organization <span class="redstyl">* </span> */}
                          {
                            DiplomaEducationDetails.Organization_More_Experience
                          }{" "}
                          <span class="redstyl">* </span>
                          <Tooltip id="expoforg">
                            {/* <p>Enter Name of the organization</p> */}
                            <p>
                              {
                                DiplomaEducationDetails.Organization_More_Experience_Tooltip_Message
                              }
                            </p>
                          </Tooltip>
                          <i
                            data-tooltip-id="expoforg"
                            class="fa-solid fa-circle-info"
                          ></i>
                        </label>
                        <input
                          disabled
                          defaultValue={element?.APU_Organization__c}
                        />
                      </div>
                      <div class="inner_part">
                        <label>
                          {/* Job Title <span class="redstyl">* </span> */}
                          {
                            DiplomaEducationDetails.Job_Title_More_Experience
                          }{" "}
                          <span class="redstyl">* </span>
                        </label>
                        <input
                          disabled
                          defaultValue={element?.APU_Job_Title__c}
                        />
                      </div>
                    </div>
                    <div class="form_styl">
                      <div class="inner_part">
                        <label>
                          {/* Thematic areas of work <span class="redstyl">* </span> */}
                          {
                            DiplomaEducationDetails.Thematic_Areas_More_Experience
                          }{" "}
                          <span class="redstyl">* </span>
                          <Tooltip id="areaTooltip">
                            {/* <p>
                              Please write brief areas of your work (eg -
                              Climate change, women empowerment etc)
                            </p> */}
                            <Notes name="Diploma_Thematic_Areas" />
                          </Tooltip>
                          <i
                            data-tooltip-id="areaTooltip"
                            class="fa-solid fa-circle-info"
                          ></i>
                        </label>
                        <textarea
                          disabled
                          defaultValue={element?.APU_Thematic_areas_of_work__c}
                          rows="3"
                        ></textarea>
                      </div>
                      <div class="inner_part">
                        <label>
                          {/* Roles and Responsibility{" "}
                          <span class="redstyl">* </span> */}
                          {
                            DiplomaEducationDetails.Roles_Responsibility_More_Experience
                          }{" "}
                          <span class="redstyl">* </span>
                        </label>
                        <textarea
                          disabled
                          defaultValue={
                            element?.APU_Roles_and_Responsibilities__c
                          }
                          rows="3"
                        ></textarea>
                      </div>
                    </div>
                    <div class="form_styl">
                      <div class="inner_part">
                        <label>
                          {/* Town <span class="redstyl">* </span> */}
                          {DiplomaEducationDetails.Town_More_Experience}{" "}
                          <span class="redstyl">* </span>
                        </label>
                        <input disabled defaultValue={element?.APU_Town__c} />
                      </div>
                      <div class="inner_part">
                        <label>
                          {/* District <span class="redstyl">* </span> */}
                          {
                            DiplomaEducationDetails.District_More_Experience
                          }{" "}
                          <span class="redstyl">* </span>
                        </label>
                        <input
                          disabled
                          defaultValue={element?.APU_District__c}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>

            <div className="form_sec">
              <div class="heading_styl">
                {/* <h4>Socio-Economic Background</h4> */}
                <h4>{DiplomaSocialEcoDetails.Socio_Economic_Background}</h4>
              </div>
              <div class="form_sec form_part">
                <div class="form_styl">
                  <div class="w100">
                    <Notes name="SED_Diploma" />
                  </div>
                </div>
                <div class="form_styl">
                  <div class="inner_part">
                    <label>
                      {/* Will your organization sponsor the programme fee?{" "}
                      <span className="redstyl">*</span>{" "} */}
                      {
                        DiplomaSocialEcoDetails.Organization_Sponsor_Fee_Question
                      }{" "}
                      <span className="redstyl">*</span>{" "}
                    </label>
                    <input {...register("sponsorProgrammeFees")} disabled />
                  </div>
                </div>
                <div class="form_styl">
                  <div class="inner_part">
                    <label>
                      {/* Caste <span className="redstyl">*</span> */}
                      {DiplomaSocialEcoDetails.Caste}{" "}
                      <span className="redstyl">*</span>
                    </label>
                    <input {...register("caste")} disabled />
                  </div>
                  <div class="inner_part">
                    <label>
                      {/* Religion <span className="redstyl">*</span> */}
                      {DiplomaSocialEcoDetails.Religion}{" "}
                      <span className="redstyl">*</span>
                    </label>
                    <input {...register("religion")} disabled />
                  </div>
                </div>
                <div class="form_styl"></div>
                <div class="form_styl">
                  <div class="inner_part">
                    <label>
                      {/* Family income excluding your income{" "}
                      <span className="redstyl">*</span>{" "} */}
                      {DiplomaSocialEcoDetails.Family_Income_Excluding_Self}{" "}
                      <span className="redstyl">*</span>{" "}
                      {/* <Tooltip id="famin">
                        <Notes name='Family_Income_Per_Year_Help' />

                      </Tooltip>
                    
                      <i
                        data-tooltip-id="famin"
                        class="fa-solid fa-circle-info"
                      ></i> */}
                    </label>
                    <input {...register("famlIncome")} disabled />
                  </div>
                  <div class="inner_part">
                    {/* <p>
                      Please exercise caution while entering the income level as
                      you will be asked to furnish supporting documents later in
                      the admission process.
                    </p> */}
                    <Notes name="Family_Income_Per_Year_Help" />
                  </div>
                </div>
                <div class="form_styl">
                  <div class="inner_part">
                    <label>
                      {/* Exact Annual Income <span className="redstyl">*</span> */}
                      {DiplomaSocialEcoDetails.Exact_Annual_Income}{" "}
                      <span className="redstyl">*</span>
                    </label>
                    <input {...register("annualIncome")} disabled />
                    <label style={{ color: "gray" }}>
                      {getCapital(numberToWords(numToWrd))}
                    </label>
                  </div>
                </div>

                <div class="form_styl">
                  <div class="inner_part">
                    <label>
                      {/* Are you a person with disability?{" "}
                      <span className="redstyl">*</span> */}
                      {DiplomaSocialEcoDetails.Disability_Question}{" "}
                      <span className="redstyl">*</span>
                    </label>
                    <input {...register("disability")} disabled />

                    {socio?.Are_you_a_person_with_disability__c === "Yes" && (
                      <>
                        <label>
                          {/* Nature of Disability{" "}
                          <span className="redstyl">*</span> */}
                          {DiplomaSocialEcoDetails.Disability_Nature}{" "}
                          <span className="redstyl">*</span>
                        </label>
                        <input {...register("disabilityNature")} disabled />
                      </>
                    )}
                  </div>
                  <div class="inner_part">
                    <Notes name="Disability" />
                  </div>
                </div>

                {socio?.Are_you_a_person_with_disability__c === "Yes" && (
                  <>
                    <div class="form_styl">
                      <div class="inner_part">
                        <label>
                          {/* Mention more details about your disability and any
                          specific support when you join the programme.{" "}
                          <span className="redstyl">*</span> */}
                          {DiplomaSocialEcoDetails.Disability_Details}{" "}
                          <span className="redstyl">*</span>
                        </label>
                        <input {...register("moreDetails")} disabled />
                      </div>
                    </div>{" "}
                  </>
                )}
              </div>
            </div>

            <div className="form_sec">
              <div class="heading_styl">
                {/* <h4>Upload Photograph and Documents</h4> */}
                <h4>{DiplomaUploadDocuments.Upload_Photo_Documents}</h4>
              </div>
              <div class="form_part">
                <div class="form_styl">
                  <table>
                    <tr>
                      <td>
                        {/* Upload Photograph : <span className="redstyl">*</span>{" "} */}
                        {DiplomaUploadDocuments.Upload_Photograph}{" "}
                        <span className="redstyl">*</span>{" "}
                      </td>
                      <img src={setImage} alt="" className="myImage" disabled />
                      <td>&nbsp; </td>
                    </tr>
                    {disability && (
                      <tr>
                        <td>
                          {/* Upload your CUET/SAT score card: <span>* </span> */}
                          {PGUploadDocuments.Upload_DISABILITY}
                          {""}
                          <span class="redstyl">* </span>
                        </td>
                        <td>
                          <p disabled={true}>{setDisabilityImage}</p>
                          {/* <Link to={''} width="100%"  disabled={true}>{cuetImage}</Link> */}
                        </td>
                        <td>&nbsp; </td>
                      </tr>
                    )}
                  </table>
                </div>

                <div class="form_styl">
                  <div class="w100">
                    <span className="prvw">
                      {/* If you would like to edit the details, please go to the
                      respective sections and edit */}
                      {ApplicationViewPage.Preview_Edit_Message}
                    </span>
                  </div>
                </div>

                <div class="form_styl">
                  <div class="w100">
                    <input
                      type="button"
                      // value="Proceed "
                      value={ApplicationViewPage.Proceed_Button_Message}
                      class="submit"
                      onClick={handleClick}
                      disabled={readOnly}
                    ></input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Home>
  );
};

export default Preview;
