import { useEffect } from 'react';
import { useBeforeUnload } from 'react-router-dom';

const PreventBackNavigation = () => {
    useBeforeUnload((event) => {
        // Custom message for the confirmation dialog
       
        event.preventDefault();
        event.returnValue = 'Are you sure you want to leave this page? Changes you made may not be saved.';
    });

    return null;
};

export default PreventBackNavigation;