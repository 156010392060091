import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";



const SigninRedirect = () => {
  const location = useLocation();
  // location.state
  // useEffect(() => {
  //   // window.history.pushState(null, document.title, window.location.href);
  //   // window.addEventListener('popstate', function (event) {
  //   //   window.history.pushState(null, document.title, window.location.href);
  //   // });
  //   // window.addEventListener('popstate', function(event) {
      
  //   // });
  // }, [])

  return (
    <div>

      <div class="redirect_container">
        <div class="container_body signinredrct">
          <div class="redirectnotes">
            <h4 class="pb1">
              Welcome to Azim Premji University's Applicant Portal.
            </h4>
            {/* <Notes name='Selected_For_Interview_msg' /> */}
            <h5 class="pb1">
              {/* Congratulations! You are selected for interview for {location.state?.programGroup}. A call letter will be sent to you shortly. */}
              Please log in to the <a href="https://apf-uat--uat.sandbox.my.site.com/admissions/s/login/"> Admissions Portal</a>, to perform further actions on your application
            </h5>
          </div>
          {/* <div class="form_styl">
            <div class="w100">
              <a href="https://azimpremjifoundation--admconnect.sandbox.my.site.com/admissions/s/login/?ec=302&startURL=%2Fadmissions%2Fs%2Fregistration-form" target="_blank" className="">
               Admission Portal
              </a>

            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};



export default SigninRedirect;