import React, { useState, useEffect } from "react";
import Home from "../Home";
import { HELP_TEXT, PATHS } from "../../utils";
import { NavLink, useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { getCapital, getCapitalized, numberToWords } from "../../utils/Helper";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { PERCENT } from "../../utils/Regex";
import {
  createSocioeco,
  getugSocioEcoDetail,
} from "../../redux/actions/Api";
import Error from "../notification/Error";
import Success from "../notification/Success";
import { resetForms, unsavedchanges } from "../../redux/actions";
import { toast } from "react-toastify";
import { CREATESOCIOECO_DETAIL_PG } from "../../redux/actions/Types";
import { Notes } from "../common/Notes";
import Loader from "../Loader";
import { FAMINCOME } from "../../utils/DataObjects";
import {
  AppCommon,
  ApplicationViewPage,
  DiplomaSocialEcoDetails,
} from "../constantLabelFile";

let natureDis = [];
let DraftBtn = "";
let numToWrd = "";
const SocialEco = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
  });

  const { createsociores, picklistdata, ugsociodata, error } = useSelector(
    (state) => state.collections
  );

  const [disability, setDisability] = useState("");
  const [religionState, setReligionState] = useState("");
  const [casteState, setCasteState] = useState("");
  const [famlIncomeState, setFamlIncomeState] = useState("");
  const [disaNature, setDisaNature] = useState("");
  const [socioecoData, setSocioecoData] = useState("");
  const [loader, setLoader] = useState(true);
  const [pickListVal, setPickListVal] = useState("");
  const [sponsor, setSponsor] = useState("");
  const [readOnly, setReadOnly] = useState(false);
  const [showMsg, setShowMsg] = useState("");
  const [appIds, setAppID] = useState("");
  const [numToWords, setNumToWords] = useState("");
  const [prgLabelname, setPrglabelname] = useState();

  useEffect(() => {
    const appID = localStorage.getItem("userData");
    const pasrseData = JSON.parse(appID);
    setAppID(pasrseData?.appId);
    setPrglabelname(pasrseData);
  }, [localStorage.getItem("userData")]);

  useEffect(() => {
    if (ugsociodata == undefined) {
      setLoader(true);
    } else {
      setTimeout(() => {
        setLoader(false);
      }, 2000);
    }
  }, [ugsociodata]);

  useEffect(() => {
    if (
      error?.message === "Unauthorized: Invalid or expired token." ||
      error?.validToken === false
    ) {
      setLoader(false);
    }
  }, [error]);

  useEffect(() => {
    if (error) {
      setLoader(false);
      toast.error(<Error error={error} />, {
        position: "top-center",
        autoClose: 5000,
        className: "Toastify__toast-theme--colored.Toastify__toast--warning",
      });
      setTimeout(() => {
        dispatch(resetForms());
      }, 5000);
    }
  }, [error]);

  useEffect(() => {
    if (appIds) {
      let formData = {
        contactId: localStorage.getItem("contactId"),
        applicationId: appIds,
      };
      if (formData) {
        dispatch(getugSocioEcoDetail(formData));
      }
    }
  }, [appIds]);

  useEffect(() => {
    if (ugsociodata) {
      setSocioecoData(ugsociodata.data?.records[0]);
    }
  }, [ugsociodata]);

  const handleDisability = (e) => {
    setDisability(e.target.value);
    if (e.target.value === "No") {
      setValue("disabilityNature", "");   
      setValue("moreDetails", "");
    }
    dispatch(unsavedchanges(e.target.value));
    clearErrors("disability");
  };

  useEffect(() => {
    setPickListVal(picklistdata?.data);
  }, [picklistdata]);

  useEffect(() => {
    if (socioecoData?.Are_you_a_person_with_disability__c !== null) {
      setValue(
        "sponsorProgrammeFee",
        socioecoData?.hed__Applications_Applicant__r?.records[0]
          ?.APU_organization_sponsor_programme_fee__c
      );
      setSponsor(
        socioecoData?.hed__Applications_Applicant__r?.records[0]
          ?.APU_organization_sponsor_programme_fee__c
      );
      setValue("caste", socioecoData?.Caste__c);
      setCasteState(socioecoData?.Caste__c);
      setValue("religion", socioecoData?.hed__Religion__c);
      setReligionState(socioecoData?.hed__Religion__c);
      setValue("famlOccupation", socioecoData?.Main_Occupation_of_family__c);
      setValue("famlIncome", socioecoData?.Family_income_per_year__c);
      setFamlIncomeState(socioecoData?.Family_income_per_year__c);
      setValue("annualIncome", socioecoData?.Exact_Annual_income__c);
      setNumToWords(numberToWords(socioecoData?.Exact_Annual_income__c));
      setValue("disability", socioecoData?.Are_you_a_person_with_disability__c);
      setDisability(socioecoData?.Are_you_a_person_with_disability__c);
      setValue("disabilityNature", socioecoData?.Nature_of_Disability__c);
      setDisaNature(socioecoData?.Nature_of_Disability__c);
      setValue(
        "moreDetails",
        socioecoData?.Do_you_need_any_help_from_Admission_Team__c
      );
    }
  }, [socioecoData]);

  let draftMsg = {
    message: "Details Saved Successfully.",
  };

  useEffect(() => {
    if (createsociores) {
      dispatch(unsavedchanges());
      toast.success(
        <Success response={!DraftBtn ? createsociores : draftMsg} />,
        {
          position: "top-center",
          autoClose: 2000,
        }
      );
      setLoader(false);
      if (!DraftBtn) {
        setTimeout(() => {
          dispatch({ type: CREATESOCIOECO_DETAIL_PG, payload: undefined });
          navigate(PATHS.UPLOADPHOTODIPLOMA_STR + appIds);
        }, 1000);
      } else if (DraftBtn) {
        setTimeout(() => {
          draftMsg.message = "";
          dispatch({ type: CREATESOCIOECO_DETAIL_PG, payload: undefined });
        }, 500);
      }
    }
  }, [createsociores]);

  const _onSubmit = (values) => {
    setLoader(true);
    let formData = {
      userId: localStorage.getItem("userId"),
      contactId: localStorage.getItem("contactId"),
      applicationId: appIds,
      fatherEdu: "",
      motherEdu: "",
      caste: values.caste,
      religion: values.religion,
      famlOccupation: "",
      famlIncome: values.famlIncome,
      annualIncome: parseInt(values.annualIncome),
      financialAssis: "yes", // always yes for diploma
      disability: values.disability,
      disabilityNature: disability == "No" ? "" : values.disabilityNature,
      scribe: "",
      specificSupport: "",
      moreDetails: disability == "No" ? "" : values.moreDetails,
      sponsorProgrammeFee: values.sponsorProgrammeFee,
      finalInterviewResult: "",
    };

    if (formData) {
      dispatch(createSocioeco(formData));
    }
  };

  const currentDate = new Date();
  const year1 = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDate = `${year1}-${month}-${day}`;

  useEffect(() => {
    if (
      socioecoData?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Started" ||
      socioecoData?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Draft" ||
      socioecoData?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Payment Failed" ||
      socioecoData?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Payment Pending" ||
      socioecoData?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Payment Submitted"
    ) {
      if (
        formattedDate >
        socioecoData?.hed__Applications_Applicant__r?.records[0]
          ?.Admission_Cycle__r?.Application_Submission_Date__c
      ) {
        setReadOnly(true); setShowMsg(<Notes name="ADM_Cycle_Validation_App" />);
      } else {
        setReadOnly(false);
        setShowMsg("");
      }
    } else {
      if (
        formattedDate <
        socioecoData?.hed__Applications_Applicant__r?.records[0]
          ?.Admission_Cycle__r?.Application_Submission_Date__c
      ) {
        setReadOnly(true);
        setShowMsg("");
      } else {
        setReadOnly(true);setShowMsg(<Notes name="ADM_Cycle_Validation_App" />);
      }
    }
  }, [socioecoData]);

  const handleBlur = (nbmr) => {
    setNumToWords(numberToWords(nbmr));
  };

  useEffect(()=>{
    if(pickListVal){   
    natureDis =  pickListVal?.disabilityNature;
    natureDis?.forEach(function(v, i) {
      if (v.Id == 'Others') {
        natureDis?.push(natureDis[i]);
        natureDis?.splice(i, 1);
      }
    });
  }

  },[natureDis, pickListVal])

  return (
    <Home>
      <div class="right_panel">
        <div class="w100">
          <p className="top_head_styl">{showMsg}</p>
          <h4 className="top_head">
            {getCapitalized(prgLabelname?.programName)}
          </h4>
          <span className="help_link">
            <p>
              <NavLink to={PATHS.RAISEQUERY}>{HELP_TEXT}</NavLink>{" "}
              <Tooltip id="helpTooltip">
                <p>{ApplicationViewPage.Help_Tooltip_Text}</p>
              </Tooltip>
              <i
                data-tooltip-id="helpTooltip"
                class="fa-solid fa-circle-info"
              ></i>
            </p>
          </span>
        </div>
        <div className="w100 scroll_styl">
          <div class="heading_styl head_font">
            <h4>{DiplomaSocialEcoDetails.Socio_Economic_Background}</h4>
          </div>
          <Loader show={loader} />
          <form
            onSubmit={handleSubmit(_onSubmit)}
            onFocus={(e) => e.target.setAttribute("autoComplete", "none")}
          >
            <div class="form_sec form_part">
              <div class="form_styl">
                <div class="w100">
                  <Notes name="SED_Diploma" />
                </div>
              </div>

              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {DiplomaSocialEcoDetails.Organization_Sponsor_Fee_Question}{" "}
                    <span className="redstyl">*</span>{" "}
                  </label>
                  <select
                    className={`${
                      errors.sponsorProgrammeFee ? "error_msg" : ""
                    }`}
                    {...register("sponsorProgrammeFee", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    disabled={readOnly}
                    value={sponsor}
                    onChange={(e) => {
                      setSponsor(e.target.value);
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("sponsorProgrammeFee");
                    }}
                  >
                    <option selected value="">
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.apuOrgSponsorProgrammeFee?.map(
                        (soci, ind) => (
                          <option value={soci.Id}>{soci.value}</option>
                        )
                      )}
                  </select>
                  {errors.sponsorProgrammeFee && (
                    <div className="error_shown">
                      {errors.sponsorProgrammeFee?.message}
                    </div>
                  )}
                </div>
              </div>

              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {DiplomaSocialEcoDetails.Caste}{" "}
                    <span className="redstyl">*</span>
                  </label>
                  <select
                    {...register("caste", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    disabled={readOnly}
                    className={`${errors.caste ? "error_msg" : ""}`}
                    value={casteState}
                    onChange={(e) => {
                      setCasteState(e.target.value);
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("caste");
                    }}
                  >
                    <option selected value="">
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.caste?.map((soci, ind) => (
                        <option value={soci.value}>{soci.value}</option>
                      ))}
                  </select>
                  {errors.caste && (
                    <div className="error_shown">{errors.caste?.message}</div>
                  )}
                </div>
                <div class="inner_part">
                  <label>
                    {DiplomaSocialEcoDetails.Religion}{" "}
                    <span className="redstyl">*</span>
                  </label>
                  <select
                    {...register("religion", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    disabled={readOnly}
                    className={`${errors.religion ? "error_msg" : ""}`}
                    value={religionState}
                    onChange={(e) => {
                      setReligionState(e.target.value);
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("religion");
                    }}
                  >
                    <option selected value="">
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.religion?.map((soci, ind) => (
                        <option value={soci.value}>{soci.value}</option>
                      ))}
                  </select>
                  {errors.religion && (
                    <div className="error_shown">
                      {errors.religion?.message}
                    </div>
                  )}
                </div>
              </div>

              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {DiplomaSocialEcoDetails.Family_Income_Excluding_Self}{" "}
                    <span className="redstyl">*</span>{" "}                  
                  </label>
                  <select
                    className={`${errors.famlIncome ? "error_msg" : ""}`}
                    {...register("famlIncome", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    disabled={readOnly}
                    value={famlIncomeState}
                    onChange={(e) => {
                      setFamlIncomeState(e.target.value);
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("famlIncome");
                    }}
                  >
                    <option selected value="">
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {FAMINCOME &&
                      FAMINCOME?.map((soci, ind) => (
                        <option value={soci.value}>{soci.value}</option>
                      ))}
                  </select>
                  {errors.famlIncome && (
                    <div className="error_shown">
                      {errors.famlIncome?.message}
                    </div>
                  )}
                </div>
                <div class="inner_part">
                  <Notes name="Family_Income_Per_Year_Help" />
                </div>
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {DiplomaSocialEcoDetails.Exact_Annual_Income}{" "}
                    <span className="redstyl">*</span>
                  </label>
                  <input
                    {...register("annualIncome", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                      pattern: {
                        value: PERCENT,
                        message: "Please enter number only",
                      },
                    })}
                    onChange={(e) => {
                      clearErrors("annualIncome");
                      dispatch(unsavedchanges(e.target.value));
                      numToWrd = e.target.value;
                      handleBlur(numToWrd);
                    }}
                    disabled={readOnly}
                    className={`${errors.annualIncome ? "error_msg" : ""}`}
                    onFocus={(e) =>
                      e.target.setAttribute("autoComplete", "none")
                    }
                    maxLength={"11"}
                  />
                  {errors.annualIncome && (
                    <div className="error_shown">
                      {errors.annualIncome?.message}
                    </div>
                  )}
                  <p>{getCapital(numToWords)}</p>
                </div>
              </div>
           
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {DiplomaSocialEcoDetails.Disability_Question}{" "}
                    <span className="redstyl">*</span>
                  </label>
                  <select
                    {...register("disability", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    disabled={readOnly}
                    className={`${errors.disability ? "error_msg" : ""}`}
                    value={disability}
                    onChange={(e) => handleDisability(e)}
                  >
                    <option value="">
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.isDisable.map((soci, ind) => (
                        <option value={soci.value}>{soci.value}</option>
                      ))}
                  </select>
                  {errors.disability && (
                    <div className="error_shown">
                      {errors.disability?.message}
                    </div>
                  )}

                  {disability === "Yes" && (
                    <>
                      <label>
                        {DiplomaSocialEcoDetails.Disability_Nature}{" "}
                        <span className="redstyl">*</span>
                      </label>
                      <select
                        {...register("disabilityNature", {
                          required: {
                            value: true,
                            message: "Please select value",
                          },
                        })}
                        className={`${
                          errors.disabilityNature ? "error_msg" : ""
                        }`}
                        value={disaNature}
                        onChange={(e) => {
                          setDisaNature(e.target.value);
                          dispatch(unsavedchanges(e.target.value));
                          clearErrors("disabilityNature");
                        }}
                        disabled={readOnly}
                      >
                        <option disabled selected value="">
                          {AppCommon.Dropdown_Default_Option}
                        </option>
                        {natureDis &&
                          natureDis?.map((soci, ind) => (
                            <option value={soci.value}>{soci.value}</option>
                          ))}
                      </select>
                    </>
                  )}
                </div>
                <div class="inner_part">
                  <Notes name="Disability" /><br />
                  <Notes name="Disability_privacy" />
                </div>
              </div>

              {disability === "Yes" && (
                <div class="form_styl">
                  <div class="inner_part">
                    <label className="display_inblock">
                      {DiplomaSocialEcoDetails.Disability_Details}{" "}
                      <span className="redstyl">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder=""
                      {...register("moreDetails", {
                        required: {
                          value: true,
                          message: "Please select value",
                        },
                      })}
                      onChange={(e) => {
                        dispatch(unsavedchanges(e.target.value));
                        clearErrors("moreDetails");
                      }}
                      disabled={readOnly}
                      className={`${errors.moreDetails ? "error_msg" : ""}`}
                    />
                    {errors.moreDetails && (
                      <div className="error_shown">
                        {errors.moreDetails?.message}
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div class="form_styl">
                <div class="w100">
                  <button
                    onClick={(e) => (DraftBtn = e.target.value)}
                    value="draft"
                    type="submit"
                    class={readOnly ? "submit ml_5 disabled" : "submit ml_5"}
                    disabled={readOnly}
                  >
                    {ApplicationViewPage.Draft_Button_Message}
                  </button>
                  <button
                    onClick={(e) => (DraftBtn = e.target.value)}
                    value=""
                    type="submit"
                    class={readOnly ? "submit ml_5 disabled" : "submit ml_5"}
                    disabled={readOnly}
                  >
                    {ApplicationViewPage.Save_And_Proceed_Button_Message}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Home>
  );
};

export default SocialEco;
