export class StorageService {

    static APU_CUET_EXAM = 'APU CUET EXAM';
    static USER_DETAIL = 'user detail';
    static APPLICATION_ID = 'application-id';
    static APP_USERDATA = '';

    static setAPUCUETEXAM(val) {
        localStorage.setItem(StorageService.APU_CUET_EXAM, val);
    }
    static setAPUSATEXAM(val) {
        localStorage.setItem(StorageService.APU_SAT_Exam, val);
    }

    static setApplicationId(val) {
        localStorage.setItem(StorageService.APPLICATION_ID, val);
    }

    static getApplicationId() {
        return localStorage.getItem(StorageService.APPLICATION_ID);
    }

    static getAPUCUETEXAM() {
        return localStorage.getItem(StorageService.APU_CUET_EXAM);
    }
    static getAPUSATTEXAM() {
        return localStorage.getItem(StorageService.APU_SAT_Exam);
    }

    static setUserDetail(val) {

        localStorage.setItem(StorageService.USER_DETAIL, JSON.stringify(val));
    }
    static getUserDetail() {
        return JSON.parse(localStorage.getItem(StorageService.USER_DETAIL))
    }
    static getUserId() {
        const userDetail = JSON.parse(localStorage.getItem(StorageService.USER_DETAIL));
        if (userDetail) {
            return userDetail.data.user_id
        }
        else {
            return null
        }

    }
    static getContactId() {
        const userDetail = JSON.parse(localStorage.getItem(StorageService.USER_DETAIL));
        if (userDetail) {
            return userDetail.data.contact_id
        }
        else {
            return null
        }

    }
    static getToken() {
        const userDetail = JSON.parse(localStorage.getItem(StorageService.USER_DETAIL));
        if (userDetail) {
            return userDetail.token
        }
        else {
            return null
        }

    }

}