import React from "react";

const Error = ({error}) => {
    
    const eMessage = error.message.split("\n");
    const errMessage = eMessage[0].split(":");
        
    return(
        <div>
            <p>{errMessage[1] ? errMessage[1] : error?.message}</p>
        </div>
    )
}

export default Error;