import { type } from "@testing-library/user-event/dist/type";

export const SIGNINCONTENT = "SIGNINCONTENT";
export const USERSIGNIN = "USERSIGNIN";
export const USERSIGNUP = "USERSIGNUP";
export const LOGINRESPONSE_FAILURE = "LOGINRESPONSE_FAILURE";
export const SIGNUPRESPONSE_FAILURE = "SIGNUPRESPONSE_FAILURE";
export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_STATE = "GET_STATE";
export const GET_DISTRICT = "GET_DISTRICT";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const REGISTRATION = "REGISTRATION";
export const REGISTRATION_DETAIL = "REGISTRATION_DETAIL";
export const THANK_YOU = "THANK_YOU";
// ug forms
export const APPLICATION_DETAIL_UG = "APPLICATION_DETAIL_UG";
export const CREATEAPPLICATION_DETAIL_UG = "CREATEAPPLICATION_DETAIL_UG";
export const EDUCATION_DETAIL_UG = "EDUCATION_DETAIL_UG";
export const CREATEEDUCATION_DETAIL_UG = "CREATEEDUCATION_DETAIL_UG";
export const SOCIECO_DETAIL_UG = "SOCIECO_DETAIL_UG";
export const CREATESOCIOECO_DETAIL_UG = "CREATESOCIOECO_DETAIL_UG";
export const UPLOAD_DOC_UG = "UPLOAD_DOC_UG";

export const UGAPPLICATION_DETAIL = "UGAPPLICATION_DETAIL";
export const UGEDUCATION_DETAIL = "UGEDUCATION_DETAIL";
export const UGSOCIECO_DETAIL = "UGSOCIECO_DETAIL"
export const UGUPLOAD_DOC = "UGUPLOAD_DOC";
// pg forms
export const APPLICATION_DETAIL_PG = "APPLICATION_DETAIL_PG";
export const CREATEAPPLICATION_DETAIL_PG = "CREATEAPPLICATION_DETAIL_PG";
export const EDUCATION_DETAIL_PG = "EDUCATION_DETAIL_PG";
export const CREATEEDUCATION_DETAIL_PG = "CREATEEDUCATION_DETAIL_PG";
export const SOCIECO_DETAIL_PG = "SOCIECO_DETAIL_PG";
export const CREATESOCIOECO_DETAIL_PG = "CREATESOCIOECO_DETAIL_PG";
export const UPLOAD_DOC_PG = "UPLOAD_DOC_PG";
// all types
export const DASHBOARD_DETAIL = "DASHBOARD_DETAIL";
export const ALL_NOTIFICATION = "ALL_NOTIFICATION";
export const PREVIEW_UG = "PREVIEW_UG";
export const LOGOUT = "LOGOUT";

export const PICKLIST_DETAILS = "PICKLIST_DETAILS";
export const RESPONSE_FAILURE = "RESPONSE_FAILURE";
export const TICKET_DETAILS = "TICKET_DETAILS";
export const GET_POST_CASE = "GET_POST_CASE";
export const POST_TICKET = "POST_TICKET";
export const POST_EXISTING_TICKET = "POST_EXISTING_TICKET";

export const PREFERENCE_PICKLIST_VAL = "PREFERENCE_PICKLIST_VAL";
export const CREATE_TICKET = "CREATE_TICKET";
export const ALL_NOTES = "ALL_NOTES";
export const CREATE_DIPLOMA = "CREATE_DIPLOMA";
export const DOC_DATA = "DOC_DATA";
export const FAQ_DATA = "FAQ_DATA";
export const CREATE_ORDER = "CREATE_ORDER"

export const CREATE_DIPLOMA_EDUCATION = "CREATE_DIPLOMA_EDUCATION";
export const PAYMENT_DETAIL = "PAYMENT_DETAIL"
export const SEND_PAYMENT_DETAIL = "SEND_PAYMENT_DETAIL"

export const UNSAVED = "UNSAVED"
export const PASSWORDCHANGED = "PASSWORDCHANGED"
export const REGISTRATION_DATA = "REGISTRATION_DATA";
export const DELETE_EXPERIENCE = "DELETE_EXPERIENCE";

export const BACHELROS = "BACHELROS";

