export const API_BASE_PATH = process.env.REACT_APP_API_BASE_PATH;

export const PAYMENT_BASE = process.env.REACT_APP_API_BASE_PATH_PAYMENT;

export const HELP_TEXT = 'Help for this page';

export const PATHS = {
  USERSIGNIN: "/",
  USERSIGNUP: "/signup",
  REGISTRATION: "/registration-form",
  DASHBOARD: "/dashboard",
  // HOME: "/home",
  APPLICATIONDETAILUG: "/admissions/application-detail-form/ug/:id",
  APPLICATIONDETAILUG_STR: "/admissions/application-detail-form/ug/",
  APPLICATIONDETAILPG: "/admissions/application-detail-form/pg/:id",
  APPLICATIONDETAILPG_STR: "/admissions/application-detail-form/pg/",
  EDUCATIONDETAILUG: "/admissions/education-detail-form/ug/:id",
  EDUCATIONDETAILUG_STR: "/admissions/education-detail-form/ug/",
  EDUCATIONDETAILPG: "/admissions/education-detail-form/pg/:id",
  EDUCATIONDETAILPG_STR: "/admissions/education-detail-form/pg/",
  SOCIALECOUG: "/admissions/social-economic-background/ug/:id",
  SOCIALECOUG_STR: "/admissions/social-economic-background/ug/",
  SOCIALECOPG: "/admissions/social-economic-background/pg/:id",
  SOCIALECOPG_STR: "/admissions/social-economic-background/pg/",
  UPLOADPHOTOUG: "/admissions/upload-photo/ug/:id",
  UPLOADPHOTOUG_STR: "/admissions/upload-photo/ug/",
  UPLOADPHOTOPG: "/admissions/upload-photo/pg/:id",
  UPLOADPHOTOPG_STR: "/admissions/upload-photo/pg/",
  PREVIEWPG: "/admissions/preview/pg/:id",
  PREVIEWPG_STR: "/admissions/preview/pg/",
  PREVIEWUG: "/admissions/preview/ug/:id",
  PREVIEWUG_STR: "/admissions/preview/ug/",
  RAISEQUERY: "/raise-query",
  CHANGEPASSWORD: "/change-password",
  REGISTRATIONCOMPLETE: "/registration-complete",
  FORGETPASSWORD: "/forget-password",
  APPLICATIONDETAILDIPLOMA: "/admissions/application-detail-form/diploma/:id",
  APPLICATIONDETAILDIPLOMA_STR: "/admissions/application-detail-form/diploma/",
  EDUCATIONDIPLOMA: "/admissions/education-detail-form/diploma/:id",
  EDUCATIONDIPLOMA_STR: "/admissions/education-detail-form/diploma/",
  SOCIODIPLOMA: "/admissions/social-economic-background/diploma/:id",
  SOCIODIPLOMA_STR: "/admissions/social-economic-background/diploma/",
  UPLOADPHOTODIPLOMA: "/admissions/upload-photo/diploma/:id",
  UPLOADPHOTODIPLOMA_STR: "/admissions/upload-photo/diploma/",
  PREVIEWDIPLOMA: "/admissions/preview/diploma/:id",
  PREVIEWDIPLOMA_STR: "/admissions/preview/diploma/",
  FAQS: "/faqs",
  SHARE: "/share",
  RESETEMAIL: "/reset-email",
  PAYMENTDETAILSUG: "/admissions/payment-details/:id",
  PAYMENTDETAILSUG_STR: "/admissions/payment-details/",
  SIGNINREDIRECT: "/signin-redirect",
  LISTFAQS: "/listfaqs",

};

export const API_PATHS = {
  SIGNUP_API: `${API_BASE_PATH}/auth/signup`,
  SIGNIN_API: `${API_BASE_PATH}/auth/login`,
  USER_REGISTRATION_API: `${API_BASE_PATH}/registration`,
  FORGOT_API: `${API_BASE_PATH}/auth/forgotPassword`,
  SETPASSWORD_API: `${API_BASE_PATH}/auth/setPassword`,
  DASHBOARD_API: `${API_BASE_PATH}/getDashboardDetails`, //dashboard
  NOTIFICATION_API: `${API_BASE_PATH}/getNotifications`,
  GETSOCIALECO_API: `${API_BASE_PATH}/ugSocioEconomicDetails`,
  APPLICATIONDETAIL_API: `${API_BASE_PATH}/postApplicationDetials`,
  EDUCATIONDETAIL_API: `${API_BASE_PATH}/postUgEducationDetails`,
  SOCIOECODETAIL_API: `${API_BASE_PATH}/postUgSocioEconomicDetails`,
  UPLOAD_API: `${API_BASE_PATH}/postDocumentDetails`,
  PREVIEW_UG_API: `${API_BASE_PATH}/getPreview`,
  GETAPPLICATIONDETAILUG_API: `${API_BASE_PATH}/getApplicationDetails`,
  GETEDUCATIONDETAILUG_API: `${API_BASE_PATH}/getUgEducationDetails`,
  GETSOCIOECODETAILUG_API: `${API_BASE_PATH}/getugSocioEconomicDetails`,
  GET_PG_EDUCATIONDETAIL_API: `${API_BASE_PATH}/getPgEducationDetails`,
  PG_EDUCATIONDETAIL_API: `${API_BASE_PATH}/postPgEducationDetails`,
  PICKLIST_API: `${API_BASE_PATH}/getPickListValues`,
  GET_ALL_TICKETS: `${API_BASE_PATH}/getAllTickets`,
  GET_CASE_TICKETS: `${API_BASE_PATH}/getPostTicket`,
  TICKET_CREATE: `${API_BASE_PATH}/createCase`,
  PREFERENCE_PICKLIST: `${API_BASE_PATH}/getPreferencesPickListValues`,
  NOTES_API: `${API_BASE_PATH}/getNotes`,
  DOC_GET_API: `${API_BASE_PATH}/getUgDocumentDetails`,
  NEW_TICKETS_POST: `${API_BASE_PATH}/addNewPostComment`,
  EXISTING_TICKETS_POST: `${API_BASE_PATH}/addCommentInExisting`,
  CREATE_DIPLOMA_API: `${API_BASE_PATH}/postDiplomaApplicationDetails`,
  CREATE_DIPLOMAEDUCATION_API: `${API_BASE_PATH}/postDiplomaEducationDetails`,
  FAQ_API: `${API_BASE_PATH}/getFaq`,
  CREATE_ORDER: `${API_BASE_PATH}/create-order`,
  PAYMENT_ORDER: `${API_BASE_PATH}/payment-response`,
  GET_PAYMENT_API: `${API_BASE_PATH}/getPayment`,
  PASSWORDCHANGED_API: `${API_BASE_PATH}/auth/getLastPasswordChange`,
  GETREGISTRATION_API: `${API_BASE_PATH}/getDiplomaRegistration`,
  LOGOUT_API: `${API_BASE_PATH}/auth/logout`,
  DELETE_WORK_EXP_API: `${API_BASE_PATH}/deleteWorkExp`,
};