import React, { useState } from "react";
import Modal from "react-modal";
import Loader from "./Loader";
import { useDispatch } from "react-redux";
import { logoutAction } from "../redux/actions/Api";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        maxWidth: "350px",
        width: "100%",
        padding: "30px",
        fontSize: "20px",
    },
};


export const TimeoutWarningModal = ({ isOpen, onRequestClose, message, warningMessage}) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    
    const onLogOffCall = () => {
      localStorage.clear();
      window.location.assign("/")
    };
   
    return (
        <div>
          
            <Modal isOpen={isOpen} style={customStyles} contentLabel="APU">
           
                <h2>{warningMessage}</h2>
                <div>
                  
                    <p>{message}</p>
                </div>
                <br />
                <div className="">
                    <button className='submit' onClick={onLogOffCall}>Sign In</button>
                   </div>

            </Modal>
        </div>
    );
};