// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `span {
    color: black;
}

li {
    color: #294799;
    font-weight: 700;
    font-size: 15px;
    line-height: 1.4;
}

a {
    color: red;
    font-weight: 400;
    font-size: 13px;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/Notes.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,UAAU;IACV,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":["span {\r\n    color: black;\r\n}\r\n\r\nli {\r\n    color: #294799;\r\n    font-weight: 700;\r\n    font-size: 15px;\r\n    line-height: 1.4;\r\n}\r\n\r\na {\r\n    color: red;\r\n    font-weight: 400;\r\n    font-size: 13px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
