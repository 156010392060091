import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import { PATHS } from "../../utils";

const Faqs = () => {

    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { faqData, headingName, faqDataList } = state || []; // <-- access state.jobs
    const { getFAQAllData } = useSelector((state) => state.collections);
   

    useEffect(() => {
        window.history.forward();
    }, [])

    return (
        <div>
            <Header />
            <div class="main_part">
                <div class="container_body flex">
                    <div className="w66 faq">
                        <div class="form_styl">
                            <div class="inner_part">
                                <h3>Articles</h3>
                            </div>
                        </div>
                        {faqData?.map((item, ind) => (
                            <div class="form_styl faqlist">
                                <div class="inner_part w100">
                                    <h4 onClick={() => {

                                        navigate(PATHS.FAQS,
                                            { state: { faqData: item, headingName: item } }
                                        )
                                    }}
                                    >{item?.Title}</h4>
                                    <p>{item?.ArticleTotalViewCount} Views • {item?.CreatedDate} • Article</p>
                                </div>
                            </div>

                        ))}

                        {/* <div class="form_styl faqlist">
                            <div class="inner_part w100">
                                <h4>What do you mean by karnataka domicile?</h4>
                                <p>22 Views • Aug 2 2024 • Article</p>
                            </div>
                        </div>
                        <div class="form_styl faqlist">
                            <div class="inner_part w100">
                                <h4>For programmes where there is the test, Can i have the option to write the
                                    online test from home?</h4>
                                <p>22 Views • Aug 2 2024 • Article</p>
                            </div>
                        </div>
                        <div class="form_styl faqlist">
                            <div class="inner_part w100">
                                <h4>What do you mean by karnataka domicile?</h4>
                                <p>22 Views • Aug 2 2024 • Article</p>
                            </div>
                        </div>
                        <div class="form_styl faqlist">
                            <div class="inner_part w100">
                                <h4>For programmes where there is the test, Can i have the option to write the
                                    online test from home?</h4>
                                <p>22 Views • Aug 2 2024 • Article</p>
                            </div>
                        </div>
                        <div class="form_styl">
                            <div class="inner_part w100">
                                <button type="submit" class="submit">
                                    Load more
                                </button>
                            </div>
                        </div> */}

                    </div>
                    {/* <div className="w33">
                        <div className="form_styl1">
                            <div className="w100 faq">
                                <a href="#">Undergraduate Program</a>
                                <hr />
                                <a href="#">Postgraduate Program</a>
                            </div> 
                        </div>
                        <div className="form_styl1">
                            <div className="w100 distance_learn">
                              
                                <a>{faqData?.Title}</a>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>

        </div>
    )
}

export default Faqs;