import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from '../Loader'
import { getFaqData } from "../../redux/actions/Api";
import { PATHS } from "../../utils";
import { useNavigate } from "react-router-dom";


const FaqSideView = () => {
    const { getFAQAllData, error } = useSelector((state) => state.collections);
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const fileInputRef = useRef(null);

    const [faqData, setFAQData] = useState();
    let formRef = useRef(null)
    useEffect(() => {
        if (getFAQAllData) {
            setLoader(false)
        }
    }, [getFAQAllData?.data])

    useEffect(() => {
        // setLoader(true)
        // dispatch(getFaqData());
        setLoader(true)
        let formData = {
            contactId: localStorage.getItem("contactId"),
          };
        dispatch(getFaqData(formData));

    }, [])
    return (
        <div>
            <Loader show={loader} />
            <div className="form_styl1">
                <div className="w100 faq">
                    <h4 className="m-0">Frequently Asked Questions</h4>

                </div>
            </div>
            <div className="form_styl1">
                <div className="w100 distance_learn">
                    {getFAQAllData &&
                        <>
                            {
                                Object.keys(getFAQAllData?.data?.faqArticle).map((item, ind) => (
                                    <div>
                                        <div>
                                            <p onClick={() => {
                                                navigate(PATHS.LISTFAQS,
                                                    { state: { faqData: getFAQAllData?.data?.faqArticle[item], headingName: item } }
                                                )
                                            }}>{item}</p>
                                        </div>

                                    </div>

                                ))
                            }
                        </>
                    }


                </div>
            </div>

            <div className="form_styl1 mt-3">
                <div className="w100 faq">
                    <h4 className="m-0">Topic Articles</h4>
                </div>
            </div>
            <div className="form_styl1">
                <div className="w100 topic_art">
                    {getFAQAllData &&
                        <>
                            {
                                Object.keys(getFAQAllData?.data?.topicArticle).map((item, ind) => (


                                    <div>
                                        <p><strong>{item} </strong> <i class="fa-solid fa-arrow-up-right-from-square"></i></p>
                                        {getFAQAllData?.data?.topicArticle[item]?.map((item1, index) => (
                                            <div className="sub_topic">

                                                <p onClick={() => {

                                                    navigate(PATHS.FAQS,
                                                        { state: { faqData: item1, headingName: item } }
                                                    )
                                                }}
                                                >{item1?.Title}</p>

                                            </div>
                                        ))}

                                    </div>

                                ))
                            }
                        </>
                    }
                </div>
            </div>

        </div>
    )
}

export default FaqSideView