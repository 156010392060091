export const AppCommon = Object.freeze({
  Dropdown_Default_Option: "--Select Options--",
  Dropdown_NA_Option: "NA",
  Dropdown_India_Option: "India",
  Dropdown_Others_Option: "Others",
});

export const HomePageDashboard = Object.freeze({
  Application_Name: "Application Name",
  Application_Number: "Application Number",
  Form_Completion: "Form Completion",
  Application_Status: "Application Status",
});

export const RegistrationPage = Object.freeze({
  Alert_Header: "APU Alert !!",
  Alert_Message:
    "The Undergraduate / Postgraduate admissions are not yet OPEN - Please SIGN OUT for now",
  Alert_Button: "SIGN OUT",
  Application_Registration: "Application Registration",
  Admission_Cycle: "Admission Cycle",
  Program_Group: "Program Group",
  Personal_Details: "Personal Details",
  First_Name: "First Name",
  Middle_Name: "Middle Name",
  Last_Name: "Last Name",
  DOB: "Date of Birth",
  Mobile_Number_SMS: "Mobile Number(for SMS notifications):",
  Alternative_Contact: "Alternative Contact",
  Alternate_Message:
    "Please make sure the email and mobile entered here is regularly checked for updates.",
  Relationship: "Relationship",
  Name: "Name",
  Mobile_No: "Mobile No.",
  Email: "Email",
  Communication_Address: "Communication Address",
  Permanent_Address: "Permanent Address",
  House_No: "House No/Building:",
  Area_Road: "Area/Road/Street/P.O:",
  Country: "Country:",
  State_Union: "State/Union Territory:",
  District: "District:",
  City: "City:",
  Pin: "PIN:",
  Permanent_Address_Same_Message: "Check if same as Communication Address",
  SOP: "Statement of Purpose (SOP)",
  DOB_Message: "What is your date of birth?",
  Challenge_Message:
    "What challenges do you think you will face, being older than other students in your class?",
  Interim_Message:
    "If you completed your 12th grade in 2023 or earlier, please tell us what you have been doing in the interim?",
  Years_Schooling_Message:
    "Have you taken more than twelve years to finish your schooling?",
  Years_Schooling_Response_Message: "If yes, please tell us why?",
  Hear_About_APU: "How did you get to know about Azim Premji University?",
  Please_Specify: "Please Specify:",
  Submit: "Submit",
});

export const ApplicationViewPage = Object.freeze({
  Help_Tooltip_Text: "Click to Raise Query",
  Draft_Button_Message: "Save as Draft",
  Save_And_Proceed_Button_Message: "Save and Proceed",
  Proceed_Button_Message: "Proceed",
  Preview: "Preview",
  Certificate: "Certificate",
  Diploma: "Diploma",
  Preview_Edit_Message:
    "If you would like to edit the details, please go to the respective sections and edit",
});

export const DiplomaApplicationDetails = Object.freeze({
  Application_Details: "Application Details",
  Diploma_Specification: "Diploma Specification",
  Programme_Applying: "Programme applying for",
  Background_Details: "Background Details",
  Nationality: "Nationality",
  Attended_Before: "Have you attended our University Programmes?",
  Attended_Year: "Programs attended and Year",
  Gender: "Gender",
  Domicile: "Domicile",
  SOP: "Statement of Purpose (SOP)",
  Nature_Of_Work: "Describe the nature of work that you do?",
  Joining_Reason: "Why do you want to join the Programme?",
  Apply_Learnings_Plan:
    "How do you plan to apply the learnings from the Programme?",
  Recommender_Details: "Recommender Details",
  Name: "Name",
  Organization_Name: "Organization Name",
  Job_Title: "Job Title",
  Email: "Email",
  Contact_Number: "Contact Number",
});

export const UGApplicationDetails = Object.freeze({
  Undergraduate_Programme: "Undergraduate programme",
  Application_Details: "Application Details",
  Eligibility_Criteria_Message:
    "Click here to check the eligibility criteria for Undergraduate Programmes at Azim Premji University.",
  Background_Details: "Background Details",
  Nationality: "Nationality",
  Gender: "Gender",
  Applied_Before: "Have you applied to us previously?",
  Applied_Year: "Applied Year",
  Domicile: "Domicile",
  Language_Details: "Language Details",
  Language: "Language",
  Spoken: "Spoken",
  Writing: "Writing",
  Reading: "Reading",
  Programme_Applying: "Programme applying for",
  Programme_Preference_1: "Programme Preference 1",
  Campus: "Campus",
  Programme_Preference_2: "Programme Preference 2",
  Programme_Preference_2_Tooltip_Message:
    "The second preference for a candidate will be considered only if all seats for a major has not been filled by the first preference list.",
  Center_Preference: "Center Preference",
  Preferred_Test_Center: "Preferred Test Center",
  Preferred_Interview_Center: "Preferred Interview Center",
});

export const PGApplicationDetails = Object.freeze({
  Postgraduate_Programme: "Postgraduate programme",
  Application_Details: "Application Details",
  Eligibility_Criteria_Message:
    "Click here to check the eligibility criteria for Postgraduate Programmes at Azim Premji University.",
  Background_Details: "Background Details",
  Nationality: "Nationality",
  Gender: "Gender",
  Applied_Before: "Have you applied to us previously?",
  Applied_Year: "Applied Year",
  Domicile: "Domicile",
  Language_Details: "Language Details",
  Language: "Language",
  Spoken: "Spoken",
  Writing: "Writing",
  Reading: "Reading",
  Programme_Preferences: "Programme Preferences",
  Programme_Applying: "Programme applying for",
  Campus: "Campus",
  Subject_Area_Preference_1: "Subject Area Preference 1",
  Subject_Area_Preference_2: "Subject Area Preference 2",
  Preference_Question: " Would you be open to be considered for admission in any campus?",
  Center_Preference: "Center Preference",
  Preferred_Test_Center: "Preferred Test Center",
  Preferred_Interview_Center: "Preferred Interview Center",
  Programme_Preference_2_Tooltip_Message:
    "Second preference option will be available only when you choose MA Development(MAD), MA Education(MAE), Master of Public health (MPH) or MA Early Child hood  CareCand Education (MAECCE) as first preference.",
  
});

export const DiplomaEducationDetails = Object.freeze({
  Education_Details: "Education Details",
  Bachelor_Details: "Bachelor’s Details",
  Qualification_Obtained: "Qualification obtained",
  Year_Of_Completion: "Year of completion",
  Class_10_Details: "Class 10 Details",
  Category_Of_City_10: "Category of city/town (10th)",
  Country_10: "Country (10th)",
  Please_Specify: "Please Specify",
  Medium_Of_Instruction_10: "Medium of Instruction(10th)",
  State_Union_10: "State/Union Territory of Residence(10th)",
  District_10: "District / City(10th)",
  Undergraduate_Details: "Undergraduate Details",
  Category_Of_City_UG: "Category of city/town where studied",
  Mode_Of_Graduation: "Mode of graduation",
  Stream_UG: "Stream/Specialization",
  University_Name_UG: "Name of the University",
  College_Name_UG: "Name of the College",
  Country_UG: "Country",
  State_Union_Residence_UG: "State/Union Territory of Residence",
  District_UG: "District / City",
  Medium_Of_Instruction_UG: "Medium of Instruction",
  PG_Degree_Question: "Do you have any Postgraduate degree?",
  PG_Degree_Name: "Name of the Postgraduate degree",
  Any_Other_Qualifications_Question:
    "Do you have any other educational qualification/certification?",
  Specialization_Other: "Specialization",
  University_Other: "University/Institution",
  Year_Of_Completion_Tooltip_Message: "Please enter value like 2021.",
  Work_Experience: "Work Experience",
  Program_Commencement_Month: "Program Commencement Month:",
  Total_Experience_After_Graduation:
    "Total Experience after Graduation (in months)",
  Relevant_Experience_After_Graduation:
    "Relevant work experience after Graduation (in months)",
  Type_Of_Organization_Experience: "Type of Organization",
  Organization_Additional_Info: "Organization Additional Info",
  Organization_Additional_Info_Tooltip_Message:
    "Select what best fits your Organization",
  Work_Experience_More_Details: "More Details on Work Experience",
  Record_Remove_Text: "Remove",
  Start_Date_More_Experience: "Start Date",
  End_Date_Error_Message: "End date cannot be earlier than start date",
  End_Date_More_Experience: "End Date",
  Organization_More_Experience: "Organization",
  Organization_More_Experience_Tooltip_Message:
    "Enter Name of the organization",
  Job_Title_More_Experience: "Job Title",
  Thematic_Areas_More_Experience: "Thematic areas of work",
  Roles_Responsibility_More_Experience: "Roles and Responsibility",
  Town_More_Experience: "Town",
  District_More_Experience: "District",
  Add_Record_More_Experience: "Add New Record",
  No_Of_People_Reporting: "No.of people reporting to you?",
});

export const UGEducationDetails = Object.freeze({
  Undergraduate_Programme: "Undergraduate programme",
  Education_Details: "Education Details",
  Class_10_Details: "Class 10 Details",
  Class_12_Details: "Class 12 Details",
  School_Name: "Name of the School / College",
  School_Address: "Address of the School",
  School_Country: "Country",
  Please_Specify: "Please Specify",
  State_Union_Residence: "State/Union Territory of Residence",
  State_Union_Residence_Tooltip_Message:
    "If you were not in India at the time of study, please pick a state that you most closely identify with",
  District: "District/ City",
  School_Board: "School / College Board",
  Major_Subjects: "Major Subjects",
  Grade: "Percentage / Grade / CGPA",
  Grade_Overall: "Overall Percentage / Grade / CGPA",
  Passing_Year: "Year of passing",
  Passing_Year_Tooltip_Message:
    "Please mention the passing year in 4 digits and Do not enter any special character",
  School_Type: "Type of School / College Board",
  Medium_Of_Instruction: "Medium of Instruction",
  Region_Of_Residence: "Region of Residence",
  Region_Of_Residence_Tooltip_Message:
    "If you were not in India at time of Study, please pick a region that you most closely identify with",
  School_Group_12: "School group",
  School_Group_12_Tooltip_Message:
    "If you are not a part of the group of schools, please select none",
});

export const PGEducationDetails = Object.freeze({
  Postgraduate_Programme: "Postgraduate programme",
  Education_Details: "Education Details",
  Bachelor_Details: "Bachelor’s Details",
  Bachelor_Degree_Completion_Status: `Undergraduate Degree completion status`,
  Qualification_Obtained: "Qualification obtained",
  Year_Of_Completion: "Year of completion",
  Duration_of_Undergraduate_Programme:"Duration of your Undergraduate Programme in years",
  Year_Of_Completion_Tooltip_Message:
    "Please mention the passing year in 4 digits and Do not enter any special character",
  UG_Economics_Passed_Question: "Have you passed UG Degree in Economics?",
  Class_10_Details: "Class 10 Details",
  Category_Of_City_10: "Category of city/town (10th)",
  Country_10: "Country (10th)",
  Please_Specify: "Please Specify",
  Medium_Of_Instruction_10: "Medium of Instruction(10th)",
  State_Union_10: "State/Union Territory of Residence(10th)",
  District_10: "District / City(10th)",
  Undergraduate_Details: "Undergraduate Details",
  Category_Of_City_UG: "Category of city/town where studied",
  Mode_Of_Graduation: "Mode of graduation",
  Stream_UG: "Stream/Specialization",
  University_Name_UG: "Name of the University",
  College_Name_UG: "Name of the College",
  Country_UG: "Country",
  State_Union_Residence_UG: "State/Union Territory of Residence",
  District_UG: "District / City",
  Medium_Of_Instruction_UG: "Medium of Instruction",
  PG_Degree_Question: "Do you have any Postgraduate degree?",
  PG_Degree_Name: "Name of the Postgraduate degree",
  Work_Experience: "Work Experience",
  Experience_After_Graduation_Question: "Work experience after graduation?",
  Currently_Employed_Question: "Are you currently employed?",
  Experience_As_On: "Work experience in months as on",
  Last_Organisation_Name: "Name of organization working/last worked",
  Last_Organisation_Type: "Type of organization",
  Last_Gross_Annual_Salary: "Annual gross salary (Rs)",
});

export const DiplomaSocialEcoDetails = Object.freeze({
  Socio_Economic_Background: "Socio-Economic Background",
  Organization_Sponsor_Fee_Question:
    "Will your organization sponsor the programme fee?",
  Caste: "Caste",
  Religion: "Religion",
  Family_Income_Excluding_Self: "Family income excluding your income",
  Exact_Annual_Income: "Exact Annual Income",
  Disability_Question: "Are you a person with disability?",
  Disability_Nature: "Nature of Disability",
  Disability_Details:
    "Mention more details about your disability and any specific support when you join the programme.",
});

export const UGSocialEcoDetails = Object.freeze({
  Undergraduate_Programme: "Undergraduate programme",
  Socio_Economic_Background: "Socio-Economic Background",
  Education_Details_Father: `Father's Education Details`,
  Education_Details_Father_Tooltip_Message: `Please enter your Father's education details`,
  Education_Details_Mother: `Mother's Education Details`,
  Education_Details_Mother_Tooltip_Message: `Please enter your Mother's education details`,
  Religion: "Religion",
  Caste: "Caste",
  Family_Occupation_Main: "Main Occupation of family",
  Family_Occupation_Main_Tooltip_Message:
    "Please go over the options carefully and pick the closest applicable to your family/household.",
  Family_Income_Annual: "Family income (Per Year)",
  Exact_Annual_Income: "Exact Annual Income",
  Financial_Assistance_Question:
    "Do you wish to avail of Financial Assistance for your studies at Azim Premji University?",
  Disability_Question: "Are you a person with disability?",
  Disability_Nature: "Nature of Disability",
  Disability_Details:
    "Mention more details about your disability and any specific support when you join the programme.",
  Please_Specify: "Please Specify",
  Scribe_Requirement_Question: "Is a scribe needed for writing the test?",
  Test_Support_Question:
    "Are you looking for any specific support to write the test?",
});

export const PGSocialEcoDetails = Object.freeze({
  Postgraduate_Programme: "Postgraduate programme",
  Socio_Economic_Background: "Socio-Economic Background",
  Education_Details_Father: `Father's Education Details`,
  Education_Details_Father_Tooltip_Message: `Please enter your Father's education details`,
  Education_Details_Mother: `Mother's Education Details`,
  Education_Details_Mother_Tooltip_Message: `Please enter your Mother's education details`,
  Religion: "Religion",
  Caste: "Caste",
  Family_Occupation_Main: "Main Occupation of family",
  Family_Occupation_Main_Tooltip_Message:
    "Please go over the options carefully and pick the closest applicable to your family/household.",
  Family_Income_Excluding_Self: "Annual Family income excluding yours",
  Family_Income_Excluding_Self_Tooltip_Message:
    "Excluding your income(Per Year)",
  Annual_Income: "Annual Income (Rs.)",
  Financial_Assistance_Question:
    "Do you wish to avail of Financial Assistance for your studies at Azim Premji University?",
  Disability_Question: "Are you a person with disability?",
  Disability_Nature: "Nature of Disability",
  Disability_Details:
    "Mention more details about your disability and any specific support when you join the programme.",
  Please_Specify: "Please Specify",
  Scribe_Requirement_Question: "Is a scribe needed for writing the test?",
  Need_Support_Question:
    "Do you need any specific support to write the test?",
  Test_Support_Question:
    "Select specific support required",
  Describe_Support_Question:
    "Describe any other support required to write the test",
});

export const DiplomaUploadDocuments = Object.freeze({
  Upload_Photo_Documents: "Upload photograph and documents",
  Upload_Photograph: "Upload Photograph:",
  Upload_File: "Upload File:",
  Upload_Photo_Note_1:
    "1. Upload a recent passport-size photograph taken against a plain background.",
  Upload_Photo_Note_2:
    "2. Photograph should be in JPEG format only. (size should be greater than 50kb and less than 100kb)",
  Upload_Photo_Note_3: `3. To upload the photograph, click on{" "} Upload File`,
  Upload_Photo_Note_4: `<strong>Note:</strong> If you want to change the image uploaded, Please repeat steps 1-3`,
});

export const UGUploadDocuments = Object.freeze({
  CUET_Language_Question: "Language in which the CUET test is taken?",
  Upload_Exam_Scorecard: "Upload your CUET/SAT/JEE Main score card:",
  Upload_Doc_File: "Upload Doc File",
  Exam_Year: "CUET/SAT/JEE Main Year",
  Upload_Photo_Documents: "Upload photograph and documents",
  Upload_Photograph: "Upload Photograph:",
  Upload_File: "Upload File:",
  Upload_Photo_Note_1:
    "1. Upload a recent passport-size photograph taken against a plain background.",
  Upload_Photo_Note_2:
    "2. Photograph should be in JPEG format only. (size should be greater than 50kb and less than 100kb)",
  Upload_Photo_Note_3: `3. To upload the photograph, click on{" "} 'Upload File'`,
  Upload_Photo_Note_4: `Note: If you want to change the image uploaded, Please repeat steps 1-3`,
});

export const PGUploadDocuments = Object.freeze({
  CUET_Language_Question: "Language in which the CUET test is taken?",
  Upload_Exam_Scorecard: "Upload your CUET/SAT/JEE Main score card:",
  Upload_DISABILITY: "Please upload your disability certificate/UDID Card/Or Self Declaration on the type of disability/Medical Records:",
  Upload_Doc_File: "Upload Doc File",
  Exam_Year: "CUET/SAT/JEE Main Year",
  Upload_Photo_Documents: "Upload photograph and documents",
  Upload_Photograph: "Upload Photograph:",
  Upload_File: "Upload File:",
  Upload_Photo_Note_1:
    "1. Upload a recent passport-size photograph taken against a plain background.",
  Upload_Photo_Note_2:
    "2. Photograph should be in JPEG format only. (size should be greater than 50kb and less than 100kb)",
  Upload_Photo_Note_3: `3. To upload the photograph, click on{" "}'Upload File'`,
  Upload_Photo_Note_4: `Note:If you want to change the image uploaded, Please repeat steps 1-3`,
});

export const PAYMENT_KEY = Object.freeze({
  API_KEY: "rzp_test_HG5xepza3cV5fI",
  KEY_SECRET: "roQzJYDAS68ZZpqDy4KKIL5L",
});

export const DIPLOMA_ADMISSION_CYCLE = Object.freeze({
  ADMISSION_CYCLE:
    "The PG Diploma programme in Educational Assessment comprises of 4 certificate programmes (12 weeks duration, 6 credits each).Out of the 4 certificates, the first one on Perspectives of Assessment is mandatory to complete as a pre-requisite for all subsequent certificates Click Here to know more about the programme",
});
