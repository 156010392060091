export const PROGRAM = [
    {label: "Postgraduate", value: "postgraduate"},
    {label: "Undergraduate", value: "undergraduate"}
];

export const ADMISSION = [
    {label: "Regular Admission", value: "regular"},
    {label: "Early Admission", value: "early"}
];

export const RELATION = [
    {label: "Mother", value: "mother"},
    {label: "Guardian", value: "guardian"},
    {label: "Brother", value: "brother"},
    {label: "Sister", value: "sister"},
    {label: "Teacher/Professor", value: "teacher"},
    {label: "Friend", value: "friend"},
    {label: "Relative", value: "relative"},
    {label: "Spouse", value: "spouse"},
]

export const KNOWABOUT = [
        {label: "Magazines", value: "magazines"},
        {label: "Information session/Public Lecture", value: "informarion"},
        {label: "Azim Premji Foundation/University", value: "azfu"},
        {label: "Azim Premji University Students", value: "azus"},
        {label: "Google Search", value: "google"},
        {label: "Newspaper Advertisement", value: "newspaper"},
        {label: "Social Media", value: "socialMedia"},
        {label: "Friend/Family/Colleague", value: "friend"},
        {label: "Through my School/College/Professor/Teachers", value: "school"},
        {label: "Alumini Azim Premji University", value: "alumini"},
        {label: "Foundation Members Information Sessions at your School", value: "foundation"},
        {label: "College Family", value: "college"},
        {label: "Friends School Teachers", value: "teacher"},
        {label: "College Professors", value: "professor"},
        {label: "Education Counsellors Google Search, Social Media Newspaper", value: "education"},
        {label: "Partner Organization", value: "partner"},
]

export const NATIONALITY = [
    {label:"Indian", value: "indian"},
    {label:"Others", value: "others"}
]
export const DOMICILE = [
    {label:"Karnataka", value: "karnataka"},
    {label:"Madhya Pradesh", value: "madhya Pradesh"},
    {label:"Others", value: "others"}
]
export const GENDER = [
    {label:"Female", value: "female"},
    {label:"Male", value: "male"},
    {label:"Others", value: "other"}
]
export const LANGUAGE = [
    {label:"Bengali", value: "bengali"},
    {label:"Bhojpuri", value: "bhojpuri"},
    {label:"Gujrati", value: "gujrati"},
    {label:"Hindi", value: "hindi"},
    {label:"Kannada", value: "kannada"},
    {label:"Malyalam", value: "malyalam"},
    {label:"Marathi", value: "marathi"},
    {label:"Nepali", value: "nepali"},
    {label:"Punjabi", value: "punjabi"},
    {label:"Sindhi", value: "sindhi"},
    {label:"Tamil", value: "tamil"},
    {label:"Telugu", value: "telugu"},
]
export const APPLYINGPROGRAM = [
    {label:"BSc BEd(Biology and Education)", value: "BSc BEd(Biology and Education)"},
    {label:"BSc BEd(Mathematics and Education)", value: "BSc BEd(Mathematics and Education)"},
    {label:"No Second Preference", value: ""},
]
export const PREFERPROGRAM = [
    {label:"", value: ""}
]
export const CAMPUS = [
    {label:"Banglore", value: "banglore"},
    {label:"Bhopal", value: "bhopal"}
]
export const TESTCENTER = [
    {label:"Bhubaneshwar", value: "bhubaneshwar"},
    {label:"Calicut", value: "calicut"},
    {label:"Chandigarh", value: "chandigarh"},
    {label:"Delhi", value: "delhi"},
    {label:"Delhi New Venue", value: "delhinew"},
    {label:"Delhi-PG", value: "delhipg"},
    {label:"Dharwad", value: "dharwad"},
    {label:"Indore", value: "indore"},
    {label:"Lucknow", value: "lucknow"},
    {label:"Madurai", value: "madurai"},
    {label:"Manglore", value: "manglore"},
    {label:"Nagpur", value: "nagpur"},
    {label:"Patna", value: "patna"},
    {label:"Pune_Test Venue", value: "punevenue"},
    {label:"Ranchi", value: "ranchi"},
    {label:"Siliguri", value: "siliguri"},
    {label:"Sirsi", value: "sirsi"},
    {label:"Thiruvananthapuram", value: "thiruvananthapuram"},
    {label:"varanasi", value: "varanasi"},
]
export const INTERVIEWCENTER = [
    {label:"Ahmedabad", value: "ahmedabad"},
    {label:"Bengaluru(In person)", value: "bengaluru"},
    {label:"Delhi", value: "delhi"},
    {label:"Offline", value: "offline"},
    {label:"Online", value: "online"},
    {label:"Online", value: "online"},
    {label:"Pune_Interview Venue", value: "puneinterview"},
    {label:"Raipur", value: "raipur"},
]

export const HAVEYOUTAKENMOREYEARS = [
    {Id: true, value: "Yes"},
    {Id: false, value: "No"},
]

export const HAVEPGEDUCATION = [
    {Id: "Yes", value: "Yes"},
    {Id: "No", value: "No"},
]

export const FAMINCOME = [
    { Id: "Less Than 2.5 Lakhs", value: "Less Than 2.5 Lakhs" },
    { Id: "2.5 Lakhs to 5 Lakhs",  value: "2.5 Lakhs to 5 Lakhs" },
    { Id: "5 Lakhs to 7.5 Lakhs", value: "5 Lakhs to 7.5 Lakhs" },
    { Id: "7.5 Lakhs to 10 Lakhs",  value: "7.5 Lakhs to 10 Lakhs" },
    { Id: "10 Lakhs to 12 Lakhs", value: "10 Lakhs to 12 Lakhs" },
    { Id: "12 Lakhs to 15 Lakhs", value: "12 Lakhs to 15 Lakhs" },
    { Id: "15 Lakhs to 20 Lakhs", value: "15 Lakhs to 20 Lakhs" },
    { Id: "20 Lakhs to 40 Lakhs", value: "20 Lakhs to 40 Lakhs" },
    { Id: "40 Lakhs & above", value: "40 Lakhs & above" }
]
