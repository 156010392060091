import { bachelorsComp } from "../actions";
import {
  USERSIGNIN,
  USERSIGNUP,
  GET_COUNTRIES,
  GET_DISTRICT,
  GET_STATE,
  REGISTRATION,
  REGISTRATION_DETAIL,
  APPLICATION_DETAIL_UG,
  CREATEAPPLICATION_DETAIL_UG,
  EDUCATION_DETAIL_UG,
  SOCIECO_DETAIL_UG,
  CREATEEDUCATION_DETAIL_UG,
  CREATESOCIOECO_DETAIL_UG,
  UPLOAD_DOC_UG,
  RESET_PASSWORD,
  DASHBOARD_DETAIL,
  THANK_YOU,
  ALL_NOTIFICATION,
  PREVIEW_UG,
  LOGINRESPONSE_FAILURE,
  FORGOT_PASSWORD,
  PICKLIST_DETAILS,
  SIGNUPRESPONSE_FAILURE,
  RESPONSE_FAILURE,
  UGAPPLICATION_DETAIL,
  UGEDUCATION_DETAIL,
  UGSOCIECO_DETAIL,
  TICKET_DETAILS,
  CREATE_TICKET,
  CREATEEDUCATION_DETAIL_PG,
  EDUCATION_DETAIL_PG,
  PREFERENCE_PICKLIST_VAL,
  ALL_NOTES,
  CREATE_DIPLOMA,
  CREATE_DIPLOMA_EDUCATION,
  DOC_DATA,
  GET_POST_CASE,
  POST_TICKET,
  POST_EXISTING_TICKET,
  FAQ_DATA,
  PAYMENT_DETAIL,
  CREATE_ORDER,
  SEND_PAYMENT_DETAIL,
  UNSAVED,
  PASSWORDCHANGED,
  REGISTRATION_DATA,
  DELETE_EXPERIENCE,
  BACHELROS
} from "../actions/Types";

const initialState = {
  loading: false,
  error: null,
};

const collections = (state = initialState, action) => {
  switch (action.type) {
    case USERSIGNIN:
      return { ...state, signinresponse: action.payload };
    case LOGINRESPONSE_FAILURE:
      return { ...state, loginResponseError: action.payload };
    case USERSIGNUP:
      return { ...state, signupresponse: action.payload };
    case SIGNUPRESPONSE_FAILURE:
      return { ...state, signupResponseError: action.payload };
    case FORGOT_PASSWORD:
      return { ...state, forgotresponse: action.payload };
    case GET_COUNTRIES:
      return { ...state, allcountries: action.payload };
    case GET_STATE:
      return { ...state, allstate: action.payload };
    case GET_DISTRICT:
      return { ...state, alldistrict: action.payload };
    case REGISTRATION:
      return { ...state, registrationresponse: action.payload };
    case REGISTRATION_DETAIL:
      return { ...state, registdetailresponse: action.payload };
    case APPLICATION_DETAIL_UG:
      return { ...state, applicationdetailres: action.payload };
    case CREATEAPPLICATION_DETAIL_UG:
      return { ...state, createapplicationres: action.payload };
    case EDUCATION_DETAIL_UG:
      return { ...state, educationdetailres: action.payload };
    case EDUCATION_DETAIL_PG:
      return { ...state, pgEducationdetailres: action.payload };
    case CREATEEDUCATION_DETAIL_UG:
      return { ...state, createeducationres: action.payload };
    case SOCIECO_DETAIL_UG:
      return { ...state, socioecores: action.payload };
    case CREATESOCIOECO_DETAIL_UG:
      return { ...state, createsociores: action.payload };
    case UPLOAD_DOC_UG:
      return { ...state, uploaddocres: action.payload };
    case RESET_PASSWORD:
      return { ...state, setpassrespone: action.payload };
    case DASHBOARD_DETAIL:
      return { ...state, dashboardresponse: action.payload };
    case THANK_YOU:
      return { ...state, thanksresponse: action.payload };
    case ALL_NOTIFICATION:
      return { ...state, notificationrespone: action.payload };
    case PREVIEW_UG:
      return { ...state, previewugresponse: action.payload };
    case PICKLIST_DETAILS:
      return { ...state, picklistdata: action.payload };
    case RESPONSE_FAILURE:
      return { ...state, error: action.payload };
    case UGAPPLICATION_DETAIL:
      return { ...state, ugappdata: action.payload };
    case UGEDUCATION_DETAIL:
      return { ...state, ugedudata: action.payload };
    case UGSOCIECO_DETAIL:
      return { ...state, ugsociodata: action.payload };
    case TICKET_DETAILS:
      return { ...state, ticketResponse: action.payload };
    case CREATE_TICKET:
      return { ...state, createTicketRes: action.payload };
    case CREATEEDUCATION_DETAIL_PG:
      return { ...state, pgedudata: action.payload };
    case PREFERENCE_PICKLIST_VAL:
      return { ...state, preferencepicklistvalue: action.payload };
    case ALL_NOTES:
      return { ...state, allNotes: action.payload };
    case DOC_DATA:
      return { ...state, docData: action.payload };
    case CREATE_DIPLOMA:
      return { ...state, appdiplomaresponse: action.payload };
    case CREATE_DIPLOMA_EDUCATION:
      return { ...state, edudiplomaresponse: action.payload };
    case GET_POST_CASE:
      return { ...state, getPostCase: action.payload };
    case POST_TICKET:
      return { ...state, postNewTicket: action.payload };
    case POST_EXISTING_TICKET:
      return { ...state, postExistingTicket: action.payload };
    case FAQ_DATA:
      return { ...state, getFAQAllData: action.payload };
    case PAYMENT_DETAIL:
      return { ...state, getPaymentData: action.payload };
    case CREATE_ORDER:
      return { ...state, getPaymentOrder: action.payload };
    case SEND_PAYMENT_DETAIL:
      return { ...state, sendPaymentDetailsSFDC: action.payload };
    case UNSAVED:
      return { ...state, unsavedValues: action.payload };
    case PASSWORDCHANGED:
      return { ...state, getPasswordChanged: action.payload };
    case REGISTRATION_DATA:
      return { ...state, registrationDetail: action.payload };
    case DELETE_EXPERIENCE:
      return { ...state, deleteExpRes: action.payload };
    case BACHELROS:
      return { ...state, bachelorsCompletionStatus: action.payload };
    default:
      return { ...state };
  }
};

export default collections;
