import React, { useState, useEffect } from "react";
import Home from "../Home";
import { Tooltip } from "react-tooltip";
import { getCapitalized } from "../../utils/Helper";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { HELP_TEXT, PATHS } from "../../utils";
import { useForm } from "react-hook-form";
import {
  EMAIL_REGEX,
  MOBILE_REGEX,
  NUM_REGEX,
  REGEXSHORT,
} from "../../utils/Regex";
import { useDispatch, useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";

import {
  createApplDiploma,
  getPreferencePicklist,
  getugApplicationDetail,
} from "../../redux/actions/Api";
import { resetForms, unsavedchanges } from "../../redux/actions";
import Error from "../notification/Error";
import { toast } from "react-toastify";
import Success from "../notification/Success";
import { CREATE_DIPLOMA } from "../../redux/actions/Types";
import { Notes } from "../common/Notes";
import Loader from "../Loader";
import {
  AppCommon,
  ApplicationViewPage,
  DiplomaApplicationDetails,
} from "../constantLabelFile";

let DraftBtn = "";
let specificationSize = "";
let obj = "";

const ApplicationDetailDiploma = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();

  const [year, setYear] = useState("");
  const [loader, setLoader] = useState(true);
  const [selectedValue, setSelectedValue] = useState("");
  const [pickListVal, setPickListVal] = useState("");
  const [preferencePick, setPreferencePick] = useState("");
  const [applicationData, setApplicationData] = useState("");
  const [programApply, setProgramApply] = useState("");
  const [programApplyValue, setprogramApplyValue] = useState("");
  const [programDisplayVal, setProgramDisplayVal] = useState("");
  const [nation, setNation] = useState("");
  const [readOnly, setReadOnly] = useState(false);
  const [showMsg, setShowMsg] = useState("");
  const [applicantId, setApplicantId] = useState("");

  const {
    picklistdata,
    dashboardresponse,
    preferencepicklistvalue,
    appdiplomaresponse,
    error,
    ugappdata,
  } = useSelector((state) => state.collections);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    clearErrors,
    watch,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onTouched" });

  useEffect(() => {
    // let appID = localStorage.getItem("userData");
    // if (appID) {
    let pasrseData = JSON.parse(localStorage.getItem("userData"));
    setApplicantId(pasrseData);
    // }
  }, []);

  useEffect(() => {
    if (applicantId) {
      // let pasrseData = JSON.parse(applicantId)
      let formdata = {
        applicationCycleId: applicantId?.appCycleId,
      };
      if (formdata && applicantId) {
        dispatch(getPreferencePicklist(formdata));
      }
    }
  }, [applicantId]);

  useEffect(() => {
    setPickListVal(picklistdata?.data);
    setPreferencePick(preferencepicklistvalue?.data);
  }, [picklistdata, preferencepicklistvalue]);

  useEffect(() => {
    if (applicantId) {
      let formData = {
        contactId: localStorage.getItem("contactId"),
        applicationId: applicantId?.appId,
      };
      if (formData) {
        dispatch(getugApplicationDetail(formData));
      }
    }
  }, [applicantId, appdiplomaresponse]);

  useEffect(() => {
    if (
      (ugappdata == undefined && preferencePick == undefined) ||
      preferencePick == undefined
    ) {
      setLoader(true);
    } else {
      setTimeout(() => {
        setLoader(false);
      }, 4000);
    }
  }, [ugappdata, preferencePick]);

  useEffect(() => {
    if (ugappdata) {
      setApplicationData(ugappdata?.data?.records[0]);
    }
  }, [ugappdata]);

  useEffect(() => {
    if (
      error?.message === "Unauthorized: Invalid or expired token." ||
      error?.validToken === false
    ) {
      setLoader(false);
    }
  }, [error]);

  useEffect(() => {
    if (error) {
      setLoader(false);
      toast.error(<Error error={error} />, {
        position: "top-center",
        autoClose: 3000,
        className: "Toastify__toast-theme--colored.Toastify__toast--warning",
      });
      setTimeout(() => {
        dispatch(resetForms());
      }, 3000);
    }
  }, [error]);

  let draftMsg = {
    message: "Details Saved Successfully.",
  };

  useEffect(() => {
    if (appdiplomaresponse?.success) {
      dispatch(unsavedchanges());
      toast.success(
        <Success response={!DraftBtn ? appdiplomaresponse : draftMsg} />,
        {
          position: "top-center",
          autoClose: 2000,
        }
      );

      setLoader(false);
      if (!DraftBtn) {
        setTimeout(() => {
          dispatch({ type: CREATE_DIPLOMA, payload: undefined });
          navigate(PATHS.EDUCATIONDIPLOMA_STR + applicantId?.appId);
        }, 500);
      } else if (DraftBtn) {
        setTimeout(() => {
          draftMsg.message = "";
          dispatch({ type: CREATE_DIPLOMA, payload: undefined });
        }, 500);
      }
    }
  }, [appdiplomaresponse]);

  useEffect(() => {
    if (applicationData) {
      setValue("nationality", applicationData?.hed__Country_of_Origin__c);
      setValue("domicile", applicationData?.Domicile__c);
      setValue("gender", applicationData?.hed__Gender__c);
      setValue(
        "prevApplied",
        applicationData?.hed__Applications_Applicant__r?.records[0]
          ?.Applied_for_earlier_cycles__c
      );
      if (
        applicationData?.hed__Applications_Applicant__r?.records[0]
          ?.APU_Diploma_Flexibility__c
      ) {
        setValue(
          "apuDiplomaFlexibility",
          applicationData?.hed__Applications_Applicant__r?.records[0]
            ?.APU_Diploma_Flexibility__c
        );
        setSelectedValue(
          applicationData?.hed__Applications_Applicant__r?.records[0]
            ?.APU_Diploma_Flexibility__c
        );
      }

      setValue(
        "programApplying",
        applicationData?.hed__Applications_Applicant__r?.records[0]
          ?.First_Preference__c
      );
      setprogramApplyValue(
        applicationData?.hed__Applications_Applicant__r?.records[0]
          ?.First_Preference__c
      );
      setValue(
        "haveYouAttendUniversityProgram",
        applicationData?.hed__Applications_Applicant__r?.records[0]
          ?.APU_Haveyouattended_university_Program__c
      );
      setValue(
        "apuYear",
        applicationData?.hed__Applications_Applicant__r?.records[0]?.APU_Year__c
      );
      setYear(
        applicationData?.hed__Applications_Applicant__r?.records[0]
          ?.APU_Haveyouattended_university_Program__c
      );
      setValue(
        "apuDescribeNatureOfWork",
        applicationData?.hed__Applications_Applicant__r?.records[0]
          ?.APU_Describe_nature_of_work_that_you_do__c
      );
      setValue(
        "apuWhyDoYouWantToJoinProgramme",
        applicationData?.hed__Applications_Applicant__r?.records[0]
          ?.APU_Why_do_you_want_to_join_programme__c
      );
      setValue(
        "apuHowYouPlanLearningFromProgramme",
        applicationData?.hed__Applications_Applicant__r?.records[0]
          ?.APU_How_you_plan_learning_from_programme__c
      );
      setValue(
        "apuRecommenderName",
        applicationData?.hed__Applications_Applicant__r?.records[0]
          ?.APU_Recommender_Name__c
      );
      setValue(
        "apuRecommenderOrgName",
        applicationData?.hed__Applications_Applicant__r?.records[0]
          ?.APU_Recommender_Organization_Name__c
      );
      setValue(
        "apuRecommenderJobTitle",
        applicationData?.hed__Applications_Applicant__r?.records[0]
          ?.APU_Recommender_Job_Title__c
      );
      setValue(
        "apuRecommenderEmail",
        applicationData?.hed__Applications_Applicant__r?.records[0]
          ?.APU_Recommender_Email__c
      );
      setValue(
        "apuRecommenderJobMobile",
        applicationData?.hed__Applications_Applicant__r?.records[0]
          ?.APU_Recommender_Contact_Number__c
      );
    }
  }, [applicationData, ugappdata]);

  const _onSubmit = (values) => {
    setLoader(true);
    let formData = {
      userId: localStorage.getItem("userId"),
      contactId: localStorage.getItem("contactId"),
      applicationId: applicantId?.appId,
      nationality: values.nationality,
      domicile: values.domicile,
      gender: values.gender,
      // prevApplied: values.prevApplied,
      haveYouAttendUniversityProgram: values.haveYouAttendUniversityProgram,
      // yearApplied: values.yearApplied,

      apuDiplomaFlexibility: values.apuDiplomaFlexibility,
      programApplying: values.programApplying,

      apuYear:
        values.haveYouAttendUniversityProgram === "Yes" ? values.apuYear : "",
      apuDescribeNatureOfWork: values.apuDescribeNatureOfWork,
      apuWhyDoYouWantToJoinProgramme: values.apuWhyDoYouWantToJoinProgramme,
      apuHowYouPlanLearningFromProgramme:
        values.apuHowYouPlanLearningFromProgramme,
      apuRecommenderName: values.apuRecommenderName,
      apuRecommenderOrgName: values.apuRecommenderOrgName,
      apuRecommenderJobTitle: values.apuRecommenderJobTitle,
      apuRecommenderEmail: values.apuRecommenderEmail,
      apuRecommenderJobMobile: values.apuRecommenderJobMobile,
      // Campus_Preferences_1__c:
    };

    if (formData) {
      dispatch(createApplDiploma(formData));
    }
  };

  useEffect(() => {
    if (preferencePick) {
      obj = Object.keys(preferencePick?.diplomaCoursePreference)?.length;
    }
  }, [preferencePick, preferencepicklistvalue, obj]);

  useEffect(() => {
    if (
      obj > 0 &&
      applicationData &&
      applicationData?.hed__Applications_Applicant__r?.records[0]
        ?.First_Preference__c &&
      preferencePick
    ) {
      let radiName =
        applicationData?.hed__Applications_Applicant__r?.records[0]
          ?.APU_Diploma_Flexibility__c;
      if (radiName) {
        const diplomaFlexi = preferencePick?.diplomaCoursePreference[radiName];
        setProgramApply(diplomaFlexi);
        let val =
          diplomaFlexi &&
          diplomaFlexi.find(
            (pro) =>
              pro.value ===
              applicationData?.hed__Applications_Applicant__r?.records[0]
                ?.First_Preference__c
          );
        setProgramDisplayVal(val);
      } else {
        const diplomaFlexi =
          preferencePick?.diplomaCoursePreference?.Certificate ||
          preferencePick?.diplomaCoursePreference?.Diploma;
        setProgramApply(diplomaFlexi);
        let val =
          diplomaFlexi &&
          diplomaFlexi.find(
            (pro) =>
              pro.value ===
              applicationData?.hed__Applications_Applicant__r?.records[0]
                ?.First_Preference__c
          );
        setProgramDisplayVal(val);
      }
    } else if (obj == 1) {
      const diplomaFlexi =
        preferencePick?.diplomaCoursePreference?.Certificate ||
        preferencePick?.diplomaCoursePreference?.Diploma;
      setProgramApply(diplomaFlexi);
      setProgramDisplayVal(diplomaFlexi);
    }
  }, [ugappdata, applicationData, obj, preferencePick]);

  const handleDiplomaFlexi = (e) => {
    setProgramDisplayVal("");
    setprogramApplyValue("");
    setSelectedValue(e.target.value);
    const diplomaFlexi =
      preferencePick && preferencePick?.diplomaCoursePreference[e.target.value];
    setProgramApply(diplomaFlexi);
    dispatch(unsavedchanges(e.target.value));
    clearErrors("apuDiplomaFlexibility");
  };

  const handleprogramvalue = (e) => {
    setprogramApplyValue(e.target.value);
    let val = programApply.find((pro) => pro.value === e.target.value);
    setProgramDisplayVal(val);
    dispatch(unsavedchanges(e.target.value));
    clearErrors("programApplying");
  };

  const currentDate = new Date();
  const year1 = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDate = `${year1}-${month}-${day}`;

  useEffect(() => {
    if (
      applicationData?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Started" ||
      applicationData?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Draft" ||
      applicationData?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Payment Failed" ||
      applicationData?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Payment Pending" ||
      applicationData?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Payment Submitted"
    ) {
      if (
        formattedDate >
        applicationData?.hed__Applications_Applicant__r?.records[0]
          ?.Admission_Cycle__r?.Application_Submission_Date__c
      ) {
        setReadOnly(true);
        // setShowMsg("We regret to inform you that the Application Submission for the current Admission cycle has been closed");
        setShowMsg(<Notes name="ADM_Cycle_Validation_App" />);
      } else {
        setReadOnly(false);
        setShowMsg("");
      }
    } else {
      if (
        formattedDate <
        applicationData?.hed__Applications_Applicant__r?.records[0]
          ?.Admission_Cycle__r?.Application_Submission_Date__c
      ) {
        setReadOnly(true);
        setShowMsg("");
      } else {
        setReadOnly(true);
        // setShowMsg("We regret to inform you that the Application Submission for the current Admission cycle has been closed");
        setShowMsg(<Notes name="ADM_Cycle_Validation_App" />);
      }
    }
  }, [applicationData, ugappdata]);

  return (
    <Home>
      <div class="right_panel">
        <div class="w100">
          <p className="top_head_styl">{showMsg}</p>
          <h4 className="top_head">
            {getCapitalized(applicantId?.programName)}
          </h4>
          <span className="help_link">
            <p>
              <NavLink to={PATHS.RAISEQUERY}>{HELP_TEXT}</NavLink>{" "}
              <Tooltip id="helpTooltip">
                {/* <p>Click to Raise Query</p> */}
                <p>{ApplicationViewPage.Tooltip_Text}</p>
              </Tooltip>
              <i
                data-tooltip-id="helpTooltip"
                class="fa-solid fa-circle-info"
              ></i>
            </p>
          </span>
        </div>
        <div className="w100 scroll_styl">
          <div class="heading_styl head_font">
            {/* <h4>{getCapitalized("Application Details")}</h4> */}
            <h4>
              {getCapitalized(DiplomaApplicationDetails.Application_Details)}
            </h4>
          </div>

          <Loader show={loader} />

          <form
            onSubmit={handleSubmit(_onSubmit)}
            onFocus={(e) => e.target.setAttribute("autoComplete", "none")}
            disabled={readOnly}
          >
            <div class="form_sec form_part">
              <div class="form_styl">
                {obj > 1 && (
                  <div class="inner_part">
                    <label>
                      {/* Diploma Specification <span className="redstyl">*</span> */}
                      {DiplomaApplicationDetails.Diploma_Specification}{" "}
                      <span className="redstyl">*</span>
                    </label>
                    <span class="flex">
                      {pickListVal &&
                        pickListVal?.apuDiplomaFlexibility?.map((dip, ind) => (
                          <span class="flex">
                            <input
                              type="radio"
                              className={`${
                                errors.apuDiplomaFlexibility ? "error_msg" : ""
                              }`}
                              {...register("apuDiplomaFlexibility", {
                                required: {
                                  value: true,
                                  message: "Please select value",
                                },
                              })}
                              value={dip.value}
                              checked={selectedValue === dip.value}
                              onChange={handleDiplomaFlexi}
                              disabled={readOnly}
                            />
                            <label>{dip.value}</label>
                          </span>
                        ))}
                    </span>

                    {errors.apuDiplomaFlexibility && (
                      <div className="error_shown">
                        {errors.apuDiplomaFlexibility?.message}
                      </div>
                    )}
                  </div>
                )}
                <div class="inner_part">
                  <label>
                    {/* Programme applying for <span className="redstyl">*</span> */}
                    {DiplomaApplicationDetails.Programme_Applying}{" "}
                    <span className="redstyl">*</span>
                  </label>
                  <select
                    {...register("programApplying", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    disabled={readOnly}
                    value={programApplyValue}
                    className={`${errors.programApplying ? "error_msg" : ""}`}
                    onChange={handleprogramvalue}
                  >
                    <option value="" disabled selected>
                      {/* --Select Options-- */}
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {programApply &&
                      programApply.map((opt, ind) => (
                        <option key={ind} value={opt.value}>
                          {opt.label}
                        </option>
                      ))}
                  </select>
                  {programDisplayVal && (
                    <p>{ReactHtmlParser(programDisplayVal?.displayNote)}</p>
                  )}

                  {errors.programApplying && (
                    <div className="error_shown">
                      {errors.programApplying?.message}
                    </div>
                  )}
                </div>
              </div>

              <div class="heading_styl">
                {/* <h4>Background Details</h4> */}
                <h4>{DiplomaApplicationDetails.Background_Details}</h4>
              </div>

              <div className="form_styl">
                <div class="inner_part">
                  <label>
                    {/* Nationality <span class="redstyl">* </span> */}
                    {DiplomaApplicationDetails.Nationality}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <select
                    {...register("nationality", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    disabled={readOnly}
                    className={`${errors.nationality ? "error_msg" : ""}`}
                    onChange={(e) => {
                      setNation(e.target.value);
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("nationality");
                    }}
                  >
                    <option value="" disabled selected>
                      {/* --Select Options-- */}
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.nationality.map((opt, ind) => (
                        <option key={ind} value={opt.value}>
                          {opt.value}
                        </option>
                      ))}
                  </select>
                  {errors.nationality && (
                    <div className="error_shown">
                      {errors.nationality?.message}
                    </div>
                  )}
                </div>
                <div class="inner_part">
                  <label>
                    {/* Have you attended our University Programmes?{" "}
                    <span className="redstyl"> *</span> */}
                    {DiplomaApplicationDetails.Attended_Before}{" "}
                    <span className="redstyl"> *</span>
                  </label>
                  <select
                    {...register("haveYouAttendUniversityProgram", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    disabled={readOnly}
                    value={year}
                    className={`${
                      errors.haveYouAttendUniversityProgram ? "error_msg" : ""
                    }`}
                    onChange={(e) => {
                      setYear(e.target.value);
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("haveYouAttendUniversityProgram");
                    }}
                  >
                    <option value="" disabled selected>
                      {/* --Select Options--{" "} */}
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.appliedEarlier.map((app, ind) => (
                        <option value={app.value}>{app.value}</option>
                      ))}
                  </select>
                  {errors.haveYouAttendUniversityProgram && (
                    <div className="error_shown">
                      {errors.haveYouAttendUniversityProgram?.message}
                    </div>
                  )}
                </div>
              </div>

              {year === "Yes" && (
                <div className="form_styl">
                  <div class="inner_part">
                    <label>
                      {/* Programs attended and Year{" "}
                      <span className="redstyl">*</span> */}
                      {DiplomaApplicationDetails.Attended_Year}{" "}
                      <span className="redstyl">*</span>
                    </label>
                    <textarea
                      {...register("apuYear", {
                        required: {
                          value: true,
                          message: "Please enter value",
                        },
                      })}
                      rows="5"
                      maxLength={255}
                      className={`${errors.apuYear ? "error_msg" : ""}`}
                      disabled={readOnly}
                      onChange={(e) => {
                        dispatch(unsavedchanges(e.target.value));
                        clearErrors("apuYear");
                      }}
                      autoComplete="off"
                    />
                    {errors.apuYear && (
                      <div className="error_shown">
                        {errors.apuYear?.message}
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {/* Gender <span class="redstyl">* </span> */}
                    {DiplomaApplicationDetails.Gender}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <select
                    {...register("gender", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    disabled={readOnly}
                    className={`${errors.gender ? "error_msg" : ""}`}
                    onChange={(e) => {
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("gender");
                    }}
                  >
                    <option value="" disabled selected>
                      {/* --Select Options-- */}
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.gender?.map((opt, ind) => (
                        <option key={ind} value={opt.value}>
                          {opt.value}
                        </option>
                      ))}
                  </select>
                  {errors.gender && (
                    <div className="error_shown">{errors.gender?.message}</div>
                  )}
                </div>
                <div class="inner_part">
                  <label>
                    {/* Domicile <span className="redstyl">*</span> */}
                    {DiplomaApplicationDetails.Domicile}{" "}
                    <span className="redstyl">*</span>
                    <Tooltip id="domicileTooltip">
                      <Notes name="Eligibility_Criteria_Note" />
                    </Tooltip>
                    <i
                      data-tooltip-id="domicileTooltip"
                      class="fa-solid fa-circle-info"
                      style={{ color: "gray" }}
                    ></i>
                  </label>
                  <select
                    {...register("domicile", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    disabled={readOnly}
                    className={`${errors.domicile ? "error_msg" : ""}`}
                    onChange={(e) => {
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("domicile");
                    }}
                  >
                    <option value="" disabled selected>
                      {/* --Select Options-- */}
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.domicile?.map((opt, ind) => (
                        <option key={opt.ind} value={opt.value}>
                          {opt.value}
                        </option>
                      ))}
                  </select>
                  {errors.domicile && (
                    <div className="error_shown">{errors.domicile.message}</div>
                  )}
                </div>
              </div>

              {(programDisplayVal?.shortName?.includes("ECE") ||
                programDisplayVal?.shortName?.includes("IE") ||
                programDisplayVal?.shortName?.includes("LD") ||
                programDisplayVal?.shortName?.includes("EA")) && (
                <>
                  <div class="heading_styl">
                    {/* <h4>Statement of Purpose (SOP)</h4> */}
                    <h4>{DiplomaApplicationDetails.SOP}</h4>
                  </div>
                  <div class="form_styl">
                    <div class="inner_part textAreaminhgt">
                      <label>
                        {/* Describe the nature of work that you do?{" "}
                        <span className="redstyl">*</span> */}
                        {DiplomaApplicationDetails.Nature_Of_Work}{" "}
                        <span className="redstyl">*</span>
                      </label>
                      <textarea
                        {...register("apuDescribeNatureOfWork", {
                          required: {
                            value: true,
                            message: "Please enter value",
                          },
                        })}
                        className={`${
                          errors.apuDescribeNatureOfWork ? "error_msg" : ""
                        }`}
                        onChange={(e) => {
                          dispatch(unsavedchanges(e.target.value));
                          clearErrors("apuDescribeNatureOfWork");
                        }}
                        disabled={readOnly}
                        placeholder=" "
                        rows="3"
                        maxLength="32768"
                      />
                      {errors.apuDescribeNatureOfWork && (
                        <div className="error_shown">
                          {errors.apuDescribeNatureOfWork?.message}
                        </div>
                      )}
                    </div>
                    <div class="inner_part textAreaminhgt">
                      <label>
                        {/* Why do you want to join the Programme?{" "}
                        <span className="redstyl">*</span> */}
                        {DiplomaApplicationDetails.Joining_Reason}{" "}
                        <span className="redstyl">*</span>
                      </label>
                      <textarea
                        {...register("apuWhyDoYouWantToJoinProgramme", {
                          required: {
                            value: true,
                            message: "Please enter value",
                          },
                        })}
                        className={`${
                          errors.apuWhyDoYouWantToJoinProgramme
                            ? "error_msg"
                            : ""
                        }`}
                        onChange={(e) => {
                          dispatch(unsavedchanges(e.target.value));
                          clearErrors("apuWhyDoYouWantToJoinProgramme");
                        }}
                        disabled={readOnly}
                        placeholder=" "
                        rows="3"
                        maxLength="32768"
                      />
                      {errors.apuWhyDoYouWantToJoinProgramme && (
                        <div className="error_shown">
                          {errors.apuWhyDoYouWantToJoinProgramme?.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div class="form_styl">
                    <div class="inner_part textAreaminhgt">
                      <label>
                        {/* How do you plan to apply the learnings from the
                        Programme? <span className="redstyl">*</span> */}
                        {DiplomaApplicationDetails.Apply_Learnings_Plan}{" "}
                        <span className="redstyl">*</span>
                      </label>
                      <textarea
                        {...register("apuHowYouPlanLearningFromProgramme", {
                          required: {
                            value: true,
                            message: "Please enter value",
                          },
                        })}
                        className={`${
                          errors.apuHowYouPlanLearningFromProgramme
                            ? "error_msg"
                            : ""
                        }`}
                        onChange={(e) => {
                          dispatch(unsavedchanges(e.target.value));
                          clearErrors("apuHowYouPlanLearningFromProgramme");
                        }}
                        disabled={readOnly}
                        placeholder=" "
                        rows="3"
                        maxLength="32768"
                      />
                      {errors.apuHowYouPlanLearningFromProgramme && (
                        <div className="error_shown">
                          {errors.apuHowYouPlanLearningFromProgramme?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}

              {programDisplayVal?.evolutionMode1?.includes("Letter") && (
                <>
                  <div class="heading_styl">
                    {/* <h4>Recommender Details </h4> */}
                    <h4>{DiplomaApplicationDetails.Recommender_Details}</h4>
                  </div>
                  <div class="form_styl">
                    <div class="w100">
                      <Notes name="Diploma_Recommender" />
                    </div>
                  </div>
                  <div class="form_styl">
                    <div class="inner_part">
                      <label>
                        {/* Name <span className="redstyl">*</span> */}
                        {DiplomaApplicationDetails.Name}{" "}
                        <span className="redstyl">*</span>
                      </label>
                      <input
                        type="text"
                        className={`${
                          errors.apuRecommenderName ? "error_msg" : ""
                        }`}
                        {...register("apuRecommenderName", {
                          required: {
                            value: true,
                            message: "Please enter value",
                          },
                        })}
                        onChange={(e) => {
                          dispatch(unsavedchanges(e.target.value));
                          clearErrors("apuRecommenderName");
                        }}
                        disabled={readOnly}
                      />

                      {errors?.apuRecommenderName && (
                        <div className="error_shown">
                          {errors?.apuRecommenderName?.message}
                        </div>
                      )}
                    </div>
                    <div class="inner_part">
                      <label>
                        {/* Organization Name <span className="redstyl">*</span> */}
                        {DiplomaApplicationDetails.Organization_Name}{" "}
                        <span className="redstyl">*</span>
                      </label>
                      <input
                        type="text"
                        className={`${
                          errors.apuRecommenderOrgName ? "error_msg" : ""
                        }`}
                        {...register("apuRecommenderOrgName", {
                          required: {
                            value: true,
                            message: "Please enter value",
                          },
                        })}
                        onChange={(e) => {
                          dispatch(unsavedchanges(e.target.value));
                          clearErrors("apuRecommenderOrgName");
                        }}
                        disabled={readOnly}
                      />
                      {errors?.apuRecommenderOrgName && (
                        <div className="error_shown">
                          {errors?.apuRecommenderOrgName?.message}
                        </div>
                      )}
                    </div>
                  </div>

                  <div class="form_styl">
                    <div class="inner_part">
                      <label>
                        {/* Job Title <span className="redstyl">*</span> */}
                        {DiplomaApplicationDetails.Job_Title}{" "}
                        <span className="redstyl">*</span>
                      </label>
                      <input
                        type="text"
                        className={`${
                          errors.apuRecommenderJobTitle ? "error_msg" : ""
                        }`}
                        {...register("apuRecommenderJobTitle", {
                          required: {
                            value: true,
                            message: "Please enter value",
                          },
                        })}
                        onChange={(e) => {
                          dispatch(unsavedchanges(e.target.value));
                          clearErrors("apuRecommenderJobTitle");
                        }}
                        disabled={readOnly}
                      />
                      {errors?.apuRecommenderJobTitle && (
                        <div className="error_shown">
                          {errors?.apuRecommenderJobTitle?.message}
                        </div>
                      )}
                    </div>
                    <div class="inner_part">
                      <label>
                        {/* Email <span className="redstyl">*</span> */}
                        {DiplomaApplicationDetails.Email}{" "}
                        <span className="redstyl">*</span>
                      </label>
                      <input
                        type="text"
                        className={`${
                          errors.apuRecommenderEmail ? "error_msg" : ""
                        }`}
                        {...register("apuRecommenderEmail", {
                          required: {
                            value: true,
                            message: "Please enter value",
                          },
                          pattern: {
                            value: EMAIL_REGEX,
                            message: "Please enter valid email",
                          },
                          validate: (val) => {
                            if (applicationData?.Email === val || applicationData?.Father_Email__c === val) {
                              return "Recommender email can not be same as applicant email.";
                            }
                          },
                        })}
                        autocomplete="off"
                        onChange={(e) => {
                          dispatch(unsavedchanges(e.target.value));
                          clearErrors("apuRecommenderEmail");
                        }}
                        disabled={readOnly}
                      />
                      {errors?.apuRecommenderEmail && (
                        <div className="error_shown">
                          {errors?.apuRecommenderEmail?.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div class="form_styl">
                    <div class="inner_part">
                      <label>
                        {/* Contact Number <span className="redstyl">*</span> */}
                        {DiplomaApplicationDetails.Contact_Number}{" "}
                        <span className="redstyl">*</span>
                      </label>
                      <input
                        className={`${
                          errors.apuRecommenderJobMobile ? "error_msg" : ""
                        }`}
                        {...register("apuRecommenderJobMobile", {
                          required: {
                            value: true,
                            message: "Please enter value",
                          },
                          pattern: {
                            value: MOBILE_REGEX,
                            message:
                              "Please enter valid 10 digit mobile number.",
                          },
                          validate: (val) => {
                            if (applicationData?.MobilePhone === val || applicationData?.Father_Phone__c === val) {
                              return "Recommender contact number can not be same as alternative contact number.";
                            }
                          },
                        })}
                        onChange={(e) => {
                          dispatch(unsavedchanges(e.target.value));
                          clearErrors("apuRecommenderJobMobile");
                        }}
                        disabled={readOnly}
                        autocomplete="off"
                        maxLength={"10"}
                        minLength={"10"}
                      />

                      {errors.apuRecommenderJobMobile && (
                        <div className="error_shown">
                          {errors.apuRecommenderJobMobile.message}
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}

              <div class="form_styl">
                <div class="w100">
                  <button
                    onClick={(e) => (DraftBtn = e.target.value)}
                    value="draft"
                    type="submit"
                    class={readOnly ? "submit ml_5 disabled" : "submit ml_5"}
                    disabled={readOnly}
                  >
                    {/* Save as Draft */}
                    {ApplicationViewPage.Draft_Button_Message}
                  </button>
                  <button
                    onClick={(e) => (DraftBtn = e.target.value)}
                    value=""
                    type="submit"
                    class={readOnly ? "submit ml_5 disabled" : "submit ml_5"}
                    disabled={readOnly}
                  >
                    {/* Save and Proceed */}
                    {ApplicationViewPage.Save_And_Proceed_Button_Message}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Home>
  );
};
export default ApplicationDetailDiploma;
