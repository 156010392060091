import React, { useState, useEffect } from "react";
import Header from "../header/Header";
import { PATHS } from "../../utils";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader";
import * as images from "../../resources/images";
import { StorageService } from "../../services/StorageService";
import { Accordion } from 'rsuite';
import 'rsuite/Accordion/styles/index.css';
import {
    getCaseResponse,
    postCommentTicketRequest,
    postTicketRequest,
} from "../../redux/actions/Api";
import Success from "../notification/Success";
import { convertBase64 } from "../../utils/Helper";
import { POST_EXISTING_TICKET } from "../../redux/actions/Types";
import moment, { fn } from "moment";
import $ from 'jquery';


let doc1;
let doc2;
let imagePath;
const regex = /(<([^>]+)>)/gi;
const Share = () => {
    let location = useLocation();
    const [loader, setLoader] = useState(false);
    const [postFirstComment, setFirstPostTicket] = useState("");
    const [image, setImage] = React.useState("");
    const imageRef = React.useRef(null);
    const [rslt, setResult] = React.useState("");
    const [postComment, setPostTicket] = useState("");
    const dispatch = useDispatch();
    const { ticketResponse } = location.state;
    const [fileData, setFileData] = React.useState("");
    const [selectedImage, setSelectedImage] = useState(null);
    const [base64Image, setBase64Image] = useState("");
    const [fileName, setFileName] = useState("");
    const { getPostCase, postNewTicket, postExistingTicket } = useSelector(
        (state) => state.collections
    );

    function changeTxt(event) {
        setPostTicket(event.target.value);

    }
    /**photo upload */

    const handleImageChange = (e, index) => {
     
        // let imgtag = document.getElementById("img101" + index).files;

        const file = e.target.files[0];
      
        // const getData = document.getElementById("sr1" + index);
       
        // imgtag.name = file.name;
        // getData.src = url;

        if (file) {
            setFileName(file.name);
            const reader = new FileReader();
            reader.onloadend = () => {
                setBase64Image(reader.result);
            };
            reader.readAsDataURL(e.target.files[0]);
            setSelectedImage(URL.createObjectURL(file));
        }
    };

    useEffect(() => {
        getTickets();
    }, []);

    useEffect(() => {
        if (getPostCase?.success) {
            setPostTicket("");
            setLoader(false);
        }
    }, [getPostCase]);

    useEffect(() => {
        if (postNewTicket?.success) {

            getTickets();
        }
    }, [postNewTicket]);

    useEffect(() => {
        if (postExistingTicket?.success) {
            setPostTicket("");
            setLoader(false);
            setSelectedImage("");
            setFileName("");
            getTickets();

            // setLoader(false);
            dispatch({ type: POST_EXISTING_TICKET, payload: undefined });
        }
    }, [postExistingTicket]);

    const getTickets = () => {

        setLoader(true);
        let data = {
            caseId: ticketResponse.Id,
        };
        setPostTicket("");
        dispatch(getCaseResponse(data));
    };

    const postTicket = () => {
        setLoader(true);
        let data = {
            caseId: ticketResponse.Id,
            body: postFirstComment,
            type: "TextPost",
        };
        dispatch(postTicketRequest(data));
        document.getElementById('txtShare').value = "";
       
        //
    };

    const postExistingTicketRequest = async (item, index) => {
        const fileBase64 = base64Image.split("data:image/jpeg;base64,");
        if (postComment) {
            setLoader(true);
            const data = {
                body: postComment,
                feedItemId: item?.feedItemId,
                feedCommentId: null,
                // fileBase64: base64Image,
                files: {
                    Title: fileName,
                    VersionData: fileBase64[1],
                },
            };
            document.getElementById("txt101" + index).value = '';
            document.getElementById('img101').value = "";
            dispatch(postCommentTicketRequest(data));
        } else {
            alert("Please enter value ");

            // return false;
        }
    };

    function getCommentBody(item) {

        if (item?.feedCommentList && item?.feedCommentList?.length > 0) {
            return (
                <div>
                    {item?.feedCommentList?.map((item1, index) => (
                        <>

                            <div class="sharechat">
                                <div>
                                    <i class="fa-solid fa-user"></i>
                                </div>
                                <div className="commentParrts">

                                    <p
                                        className="rcorners1"
                                        dangerouslySetInnerHTML={{
                                            __html: item1.commentCreatedByName + item1.commentUserType,
                                        }}
                                    />
                                    <p className="datetimecomment">{moment(item1.commentCreatedDate).format('MM/DD/YYYY, hh:mm a')}</p>
                                    <p
                                        className="rcorners2"
                                        dangerouslySetInnerHTML={{
                                            __html: item1.commentBody,
                                        }}
                                    />
                                    {item1?.feedCommentFileLink?.map((imgItem, imgindex) => (
                                        <>
                                            {imgItem &&
                                                <div class="w100 Addchat_image flex flex-wrap">
                                                    <img src={imgItem} style={{ width: '50%' }} />
                                                </div>
                                            }

                                        </>

                                    ))}

                                    <div>
                                    </div>

                                </div>

                            </div>
                            <div className="comentpart">

                            </div>
                        </>
                    ))}
                </div>
            );
        } else {
            return null;
        }
    }

    return (
        <div>
            <Header />
            <div class="main_part">
                <Loader show={loader} />
                <div class="container_body flex">
                    <div className="w66">
                        <div class="form_styl flex">
                            <div class="w100 sharebox">
                                <h4>Share Post</h4>
                                <textarea
                                    placeholder="Write your feedback"
                                    rows="10"
                                    id="txtShare"
                                    onChange={(e) => {
                                        setFirstPostTicket(e.target.value);
                                    }}
                                ></textarea>

                                <diV>
                                    <input
                                        type="button"
                                        value="Share"
                                        className="submit"
                                        onClick={postTicket}
                                    />
                                </diV>
                            </div>
                        </div>

                        {/* Comment Section  below */}
                        {getPostCase?.data?.feedItemList[0]?.feedBody && (
                            <div className="sharecomment">
                                {getPostCase?.data?.feedItemList?.map((item, index) => (
                                    <div class="replycommnt">
                                        {item.feedBody && (
                                            <>
                                                <div class="sharechat" style={{}}>
                                                    <div>
                                                        <i
                                                            class="fa-solid fa-user"
                                                        ></i>
                                                    </div>
                                                    <div className="commentParrts">
                                                        <p
                                                            className="rcorners1"
                                                            dangerouslySetInnerHTML={{
                                                                __html: item.createdByName + item.userType,
                                                            }}
                                                        />


                                                        <p className="datetimecomment">{moment(item.createdDate).format('MM/DD/YYYY, hh:mm a')}</p>
                                                        <p
                                                            className="rcorners2"
                                                            dangerouslySetInnerHTML={{
                                                                __html: item.feedBody,
                                                            }}
                                                        />
                                                        {item?.feedItemFileLink &&
                                                            <div class="w100 Addchat_image flex flex-wrap">

                                                                <img src={item?.feedItemFileLink} style={{ width: '50%' }} />
                                                            </div>

                                                        }

                                                    </div>
                                                </div>
                                                <div className="comentpart ">
                                                    {/* <br /> */}
                                                    <div class="w100 subchatbox">
                                                        {getCommentBody(item)}

                                                    </div>
                                                    <div className="Commentbox">
                                                        <ul>
                                                            <li><i class="fa-solid fa-comment"></i>Comment</li>
                                                        </ul>
                                                        <div className="userCommentinput">
                                                            <div className="userProicon">
                                                                <img src={images.usericon} alt="" />
                                                            </div>
                                                            <label>
                                                                <textarea style={{ resize: 'vertical' }}
                                                                    rows="3"
                                                                    placeholder="Write your comment..."
                                                                    // onChange={changeTxt}
                                                                    onChange={(e) => {
                                                                        setPostTicket(e.target.value);
                                                                    }}
                                                                    id={"txt101" + index}
                                                                ></textarea>
                                                            </label>

                                                        </div>
                                                        <div>
                                                            {/* <input
                                                                id={"img101" + index}
                                                                type="file"
                                                                accept="image/jpeg"
                                                                onChange={(e) => {
                                                                    handleImageChange(e, index)
                                                                }}
                                                            /> */}
                                                            <input
                                                                type="button"
                                                                value="Reply"
                                                                className="submit"
                                                                onClick={() =>
                                                                    postExistingTicketRequest(item, index)
                                                                }
                                                            />
                                                        </div>
                                                        {/* {selectedImage && (
                                                            <div>
                                                                <h4>Selected File:</h4>
                                                                <img
                                                                    id={"sr1" + index}
                                                                    src={selectedImage}
                                                                    alt="Selected"
                                                                    className="myImage"
                                                                />
                                                            </div>
                                                        )} */}
                                                    </div>

                                                </div>
                                            </>
                                        )}

                                    </div>

                                ))}
                                <div>
                                    <input
                                        id={"img101"}
                                        type="file"
                                        accept="image/jpeg"
                                        onChange={handleImageChange}
                                    />
                                    {selectedImage && (
                                        <div>
                                            <h4>Selected File:</h4>
                                            <img
                                                id={"sr1"}
                                                src={selectedImage}
                                                alt="Selected"
                                                className="myImage"
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}

                    </div>

                    {/* Case Details */}

                    <div className="w33 faq casesedtl">
                        {/* <h4>
                            <i class="fa-solid fa-chevron-down"></i> Case Details
                        </h4> */}
                        <Accordion>
                            <Accordion.Panel header="Case Details" defaultExpanded>
                                <div>
                                    <div class="form_styl">
                                        <div class="inner_part">
                                            <label>
                                                Ticket Number <span>*</span>
                                            </label>

                                            <input
                                                type="text"
                                                value={ticketResponse.Ticket_No__c}
                                                disabled={true}
                                            />
                                        </div>
                                        <div class="inner_part">
                                            <label>
                                                Status <span>*</span>
                                            </label>

                                            <input
                                                type="text"
                                                value={ticketResponse.Status}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div class="form_styl">
                                        <div class="inner_part">
                                            <label>
                                                Contact Name <span>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                value={ticketResponse.Contact.Name}
                                                disabled={true}
                                            />
                                        </div>
                                        <div class="inner_part">
                                            <label>
                                                Priority <span>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                value={ticketResponse.Priority}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div class="form_styl">
                                        <div class="inner_part">
                                            <label>
                                                Topic <span>*</span>
                                            </label>
                                            <input type="text" value="Applications" disabled={true} />
                                        </div>
                                        <div class="inner_part">
                                            <label>
                                                Sub Topic<span>*</span>
                                            </label>
                                            {/* <input
                                    type="text"
                                    value={ticketResponse.Description}
                                    disabled={true}
                                /> */}
                                            <div className="raisequerydesc">
                                                <p>{ticketResponse.Sub_Topic__c}</p>

                                            </div>
                                        </div>

                                    </div>
                                    <div class="form_styl">
                                        <div class="inner_part">
                                            <label>
                                                Subject <span>*</span>
                                            </label>

                                            <div className="raisequerydesc">
                                                <p>{ticketResponse.Subject}</p>
                                            </div>
                                        </div>
                                        <div class="inner_part">
                                            <label>
                                                Description <span>*</span>
                                            </label>

                                            <div className="raisequerydesc">
                                                <p>{ticketResponse.Description}</p>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="form_styl">
                                        <div class="inner_part">
                                            <label>
                                                Program Group <span>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                value={ticketResponse.Program_Group__c}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Panel>
                        </Accordion>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Share;