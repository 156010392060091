import React, {useEffect} from "react";
import Header from "./header/Header";
import Sidebar from "./detailForms/Sidebar";
import Notification from "./footer/Notification";
import {Success} from "./notification/Success";
import {Error} from "./notification/Error";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Home = ({children}, props) => {

    // useEffect(() => {
    //     if (error) {
    //       if (error === "Request failed with status code 403") {
    //         localStorage.removeItem("access_token");
    //         window.location.assign(PATHS.LANDINGPAGE);
    //         dispatch(logoutAction());
    //       } else if (error === "Unauthorized Access") {
    //         toast.error(<Error error={error} />, {
    //           position: "top-center",
    //           autoClose: TOAST_TIMER,
    //         });
    //         dispatch(resetResponse()).then(() => {
    //           dispatch(isLoading(false)).then(() => {
    //             // window.location.assign(PATHS.ACCESS_DENIED);
    //           });
    //         });
    //       } else {
    //         toast.error(<Error error={error} />, {
    //           position: "top-center",
    //           autoClose: TOAST_TIMER,
    //         });
    //         dispatch(resetResponse());
    //         dispatch(isLoading(false));
    //       }
    //     }
    //   }, [error]);
    
    //   useEffect(() => {
    //     if (response && response?.success) {
    //       toast.info(<Success response={response} />, {});
    //       dispatch(resetResponse());
    //     }
    //   }, [response]);

    return(
        <div>
            <Header/>
            <div className="container_body flex">
            <Sidebar/>
            {children && children}
            </div>
            {/* <Notification/> */}
        </div>
    )
}
export default Home;