import React, { useState, useEffect } from "react";
import Home from "../Home";
import { getCapitalized } from "../../utils/Helper";
import { HELP_TEXT, PATHS } from "../../utils";
import { NavLink, useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { CHAR_REGEX, NUM_REGEX } from "../../utils/Regex";
import {
  createEduDiploma,
  deleteWorkExperience,
  getpgEducationDetail,
} from "../../redux/actions/Api";
import Success from "../notification/Success";
import { toast } from "react-toastify";
import { CREATE_DIPLOMA_EDUCATION } from "../../redux/actions/Types";
import Loader from "../Loader";
import DatePicker from "react-date-picker";
import moment from "moment";
import { resetForms, unsavedchanges } from "../../redux/actions";
import Error from "../notification/Error";
import { Notes } from "../common/Notes";
import "react-datepicker/dist/react-datepicker.css";
import { HAVEPGEDUCATION } from "../../utils/DataObjects";
import {
  AppCommon,
  ApplicationViewPage,
  DiplomaEducationDetails,
  PGEducationDetails,
} from "../constantLabelFile";

let DraftBtn = "";
const ids = new Set();

const EducationDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    picklistdata,
    error,
    pgedudata,
    ugedudata,
    pgEducationdetailres,
    edudiplomaresponse,
    deleteExpRes,
  } = useSelector((state) => state.collections);

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    unregister,
    control,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    shouldUnregister: true,
  });

  const [experience, setExprience] = useState("");
  const [eduQual, setEduQual] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectednewCountry, setSelectednewCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [district, setDistrict] = useState([]);
  const [selectedState1, setSelectedState1] = useState("");
  const [selectedDistrict1, setSelectedDistrict1] = useState("");
  const [district1, setDistrict1] = useState([]);
  const [loader, setLoader] = useState(true);
  const [pickListVal, setPickListVal] = useState("");
  const [medInsti, setMedInsti] = useState("");
  const [mediumInsti, setMediumInsti] = useState("");
  const [pgDegree, setPgdegree] = useState("");
  const [allEduresponsedata, setAllEduresponsedata] = useState("");

  const [records, setRecords] = useState([]);
  const [endDateMsg, setEndDateMsg] = useState("");
  const [readOnly, setReadOnly] = useState(false);

  const [showMsg, setShowMsg] = useState("");
  const [appIds, setAppID] = useState("");

  // const [ids, setIds] = useState(new Set([]));
  const [elementData, seElementData] = useState();
  const [prgLabelname, setPrglabelname] = useState();

  const [eduQualError, setEduQualError] = useState("");

  useEffect(() => {
    const appID = localStorage.getItem("userData");
    const pasrseData = JSON.parse(appID);
    setAppID(pasrseData?.appId);
    setPrglabelname(pasrseData);
  }, [localStorage.getItem("userData")]);

  useEffect(() => {
    if (pgEducationdetailres == undefined) {
      setLoader(true);
    } else {
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  }, [pgEducationdetailres]);

  useEffect(() => {
    if (
      error?.message === "Unauthorized: Invalid or expired token." ||
      error?.validToken === false
    ) {
      setLoader(false);
    }
  }, [error]);

  useEffect(() => {
    if (error) {
      setLoader(false);
      toast.error(<Error error={error} />, {
        position: "top-center",
        autoClose: 1000,
        className: "Toastify__toast-theme--colored.Toastify__toast--warning",
      });
      setTimeout(() => {
        dispatch(resetForms());
      }, 1000);
    }
  }, [error]);

  useEffect(() => {
    setAllEduresponsedata(pgEducationdetailres?.data?.records[0]);
  }, [pgEducationdetailres]);

  useEffect(() => {
    ids.clear();
    if (
      allEduresponsedata?.Work_Experiences__r &&
      allEduresponsedata?.Work_Experiences__r?.records.length > 0
    ) {
      setRecords(allEduresponsedata?.Work_Experiences__r?.records);

      let workExpRecords = allEduresponsedata?.Work_Experiences__r?.records;
      for (let i = 0; i < workExpRecords.length; i++) {
        setValue(
          `recorddetails.${i}.organization`,
          workExpRecords[i]?.APU_Organization__c
        );
        setValue(
          `recorddetails.${i}.startDate`,
          workExpRecords[i]?.APU_Start_Date__c
        );
        setValue(
          `recorddetails.${i}.endDate`,
          workExpRecords[i]?.APU_End_Date__c
        );
        setValue(
          `recorddetails.${i}.title`,
          workExpRecords[i]?.APU_Job_Title__c
        );
        setValue(
          `recorddetails.${i}.thematicArea`,
          workExpRecords[i]?.APU_Thematic_areas_of_work__c
        );
        setValue(
          `recorddetails.${i}.rolesAndResponsibilities`,
          workExpRecords[i]?.APU_Roles_and_Responsibilities__c
        );
        setValue(`recorddetails.${i}.town`, workExpRecords[i]?.APU_Town__c);
        setValue(
          `recorddetails.${i}.district`,
          workExpRecords[i]?.APU_District__c
        );

        ids.add(workExpRecords[i].Id);
        // ids.push(workExpRecords[i].Id);
      }
    } else {
      setRecords([
        {
          organization: "",
          startDate: "",
          endDate: "",
          title: "",
          thematicArea: "",
          rolesAndResponsibilities: "",
          town: "",
          district: "",
          Id: "",
        },
      ]);
    }
  }, [allEduresponsedata, pgEducationdetailres]);

  useEffect(() => {
    if (
      allEduresponsedata?.hed__Education_History__r &&
      allEduresponsedata?.hed__Applications_Applicant__r &&
      picklistdata &&
      pickListVal
    ) {
      setValue(
        "qualificationsObtained",
        allEduresponsedata?.hed__Education_History__r?.records[1]
          ?.Qualification_obtained__c
      );
      setValue(
        "completionYear",
        allEduresponsedata?.hed__Education_History__r?.records[1]
          ?.Year_of_completion__c
      );
      setValue("durationUndergraduateProg", allEduresponsedata?.hed__Education_History__r?.records[1]?.APU_Duration_of_your_UG__c)

      setValue(
        "townCategoryTenth",
        allEduresponsedata?.hed__Education_History__r?.records[0]
          ?.Category_of_city_town_where_studied__c
      );
      setValue(
        "countryTenth",
        allEduresponsedata?.hed__Education_History__r?.records[0]?.Country__c
      );
      setSelectedCountry(
        allEduresponsedata?.hed__Education_History__r?.records[0]?.Country__c
      );
      setValue(
        "otherCountrySpecifyTenth",
        allEduresponsedata?.hed__Education_History__r?.records[0]
          ?.Please_Specify_Country__c
      );
      setValue(
        "mediumInstTenth",
        allEduresponsedata?.hed__Education_History__r?.records[0]
          ?.Medium_of_instruction__c
      );
      setMedInsti(
        allEduresponsedata?.hed__Education_History__r?.records[0]
          ?.Medium_of_instruction__c
      );
      setValue(
        "specifyMediumTenth",
        allEduresponsedata?.hed__Education_History__r?.records[0]
          ?.Please_Specify_Medium_of_Instruction__c
      );
      setValue(
        "stateTenth",
        allEduresponsedata?.hed__Education_History__r?.records[0]?.State__c
      );
      setSelectedState(
        allEduresponsedata?.hed__Education_History__r?.records[0]?.State__c
      );
      setValue(
        "districtTenth",
        allEduresponsedata?.hed__Education_History__r?.records[0]?.District__c
      );

      if (
        pickListVal &&
        allEduresponsedata?.hed__Education_History__r?.records[0]
          ?.Country__c === "India"
      ) {
        const stateObject =
          pickListVal?.districts?.India[
            allEduresponsedata?.hed__Education_History__r?.records[0]?.State__c
          ];
        const dis = Object?.values(stateObject);
        setDistrict(dis);
      }
      if (
        pickListVal &&
        allEduresponsedata?.hed__Education_History__r?.records[1]
          ?.Country__c === "India"
      ) {
        const stateObject1 =
          pickListVal?.districts?.India[
            allEduresponsedata?.hed__Education_History__r?.records[1]?.State__c
          ];
        const dis1 = Object?.values(stateObject1);
        setDistrict1(dis1);
      }
      setSelectedDistrict(
        allEduresponsedata?.hed__Education_History__r?.records[0]?.District__c
      );

      setValue(
        "townCategoryGrad",
        allEduresponsedata?.hed__Education_History__r?.records[1]
          ?.Category_of_city_town_where_studied__c
      );
      setValue(
        "gradMode",
        allEduresponsedata?.hed__Education_History__r?.records[1]
          ?.Mode_of_graduation__c
      );
      setValue(
        "streamSpecialization",
        allEduresponsedata?.hed__Education_History__r?.records[1]
          ?.Stream_Specialization__c
      );
      setValue(
        "universityName",
        allEduresponsedata?.hed__Education_History__r?.records[1]
          ?.Name_of_the_University__c
      );
      setValue(
        "schoolNameGrad",
        allEduresponsedata?.hed__Education_History__r?.records[1]
          ?.hed__Educational_Institution_Name__c
      );
      setValue(
        "countryGrad",
        allEduresponsedata?.hed__Education_History__r?.records[1]?.Country__c
      );
      setSelectednewCountry(
        allEduresponsedata?.hed__Education_History__r?.records[1]?.Country__c
      );
      setValue(
        "otherCountrySpecifyGrad",
        allEduresponsedata?.hed__Education_History__r?.records[1]
          ?.Please_Specify_Country__c
      );
      setValue(
        "stateGrad",
        allEduresponsedata?.hed__Education_History__r?.records[1]?.State__c
      );
      setSelectedState1(
        allEduresponsedata?.hed__Education_History__r?.records[1]?.State__c
      );

      setValue(
        "districtGrad",
        allEduresponsedata?.hed__Education_History__r?.records[1]?.District__c
      );
      setSelectedDistrict1(
        allEduresponsedata?.hed__Education_History__r?.records[1]?.District__c
      );

      setValue(
        "mediumInstGrad",
        allEduresponsedata?.hed__Education_History__r?.records[1]
          ?.Medium_of_instruction__c
      );
      setMediumInsti(
        allEduresponsedata?.hed__Education_History__r?.records[1]
          ?.Medium_of_instruction__c
      );

      setValue(
        "specifyMediumGrad",
        allEduresponsedata?.hed__Education_History__r?.records[1]
          ?.Please_Specify_Medium_of_Instruction__c
      );
      setValue(
        "havePGDegree",
        allEduresponsedata?.hed__Education_History__r?.records[1]
          ?.Do_you_have_any_Postgraduate_degree__c
      );
      setPgdegree(
        allEduresponsedata?.hed__Education_History__r?.records[1]
          ?.Do_you_have_any_Postgraduate_degree__c
      );

      setValue(
        "qualificationName",
        allEduresponsedata?.hed__Education_History__r?.records[1]
          ?.Name_on_Qualification__c
      );
      setEduQual(
        allEduresponsedata?.hed__Education_History__r?.records[1]
          ?.APU_Have_any_qualification_certification__c
      );
      setValue(
        "haveQualification",
        allEduresponsedata?.hed__Education_History__r?.records[1]
          ?.APU_Have_any_qualification_certification__c
      );
      setValue(
        "diplomaSpecialization",
        allEduresponsedata?.hed__Education_History__r?.records[1]
          ?.APU_Diploma_Specialization__c
      );
      setValue(
        "diplomaUniversityInstitution",
        allEduresponsedata?.hed__Education_History__r?.records[1]
          ?.APU_Diploma_University_Institution__c
      );
      setValue(
        "diplomaYearOfCompletion",
        allEduresponsedata?.hed__Education_History__r?.records[1]
          ?.APU_Year_of_completion_diploma__c
      );

      setValue(
        "workExperience",
        allEduresponsedata?.Work_experience_after_gr__c
      );
      setExprience(allEduresponsedata?.Work_experience_after_gr__c);

      setValue(
        "workExperienceInMonths",
        allEduresponsedata?.hed__Applications_Applicant__r?.records[0]
          ?.APU_Total_Experience_in_Months__c
      );

      setValue(
        "relevantWorkExpAfterGrad",
        allEduresponsedata?.hed__Applications_Applicant__r?.records[0]
          ?.APU_Relevant_experience_after_Graduation__c
      );
      setValue(
        "typeOfOrg",
        allEduresponsedata?.hed__Applications_Applicant__r?.records[0]
          ?.APU_Type_of_Organization__c
      );
      setValue(
        "orgAdditionalInfo",
        allEduresponsedata?.hed__Applications_Applicant__r?.records[0]
          ?.APU_Organization_Additional_info__c
      );
      setValue(
        "orgName",
        allEduresponsedata?.hed__Applications_Applicant__r?.records[0]
          ?.APU_Name_of_the_organization__c
      );
      setValue(
        "noOfPeopleReporting",
        allEduresponsedata?.hed__Applications_Applicant__r?.records[0]
          ?.APU_No_people_reporting_to_you__c
      );
    }
  }, [allEduresponsedata, picklistdata, pickListVal, pgEducationdetailres]);

  useEffect(() => {
    if (selectedCountry !== "India") {
      setSelectedState("");
      setSelectedDistrict("");
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectednewCountry !== "India") {
      setSelectedState1("");
      setSelectedDistrict1("");
    }
  }, [selectednewCountry]);

  useEffect(() => {
    if (appIds) {
      let formData = {
        contactId: localStorage.getItem("contactId"),
        applicationId: appIds,
      };
      if (formData && appIds) {
        dispatch(getpgEducationDetail(formData));
        dispatch(deleteWorkExperience());
      }
    }
  }, [appIds, deleteExpRes, edudiplomaresponse]);

  const handleExprience = (e) => {
    setExprience(e.target.value);
    dispatch(unsavedchanges(e.target.value));
  };

  const handleState = (e) => {
    setSelectedState(e.target.value);
    const stateObject = pickListVal?.districts?.India[e.target.value];
    const dis = Object?.values(stateObject);
    setDistrict(dis);
    setSelectedDistrict("");

    dispatch(unsavedchanges(e.target.value));
    clearErrors("stateTenth");
  };

  const handlenewState = (e) => {
    setSelectedState1(e.target.value);
    const stateObject = pickListVal?.districts?.India[e.target.value];
    const dis = Object?.values(stateObject);
    setDistrict1(dis);
    setSelectedDistrict1("");
    dispatch(unsavedchanges(e.target.value));
    clearErrors("stateGrad");
  };

  useEffect(() => {
    setPickListVal(picklistdata?.data);
  }, [picklistdata]);

  useEffect(() => {
    if (deleteExpRes) {
      toast.success(<Success response={deleteExpRes} />, {
        position: "top-center",
        autoClose: 2000,
      });
    }
  }, [deleteExpRes, elementData, ids]);

  let draftMsg = {
    message: "Details Saved Successfully.",
  };

  useEffect(() => {
    if (edudiplomaresponse) {
      dispatch(unsavedchanges());
      toast.success(
        <Success response={!DraftBtn ? edudiplomaresponse : draftMsg} />,
        {
          position: "top-center",
          autoClose: 2000,
        }
      );
      setLoader(false);
      // ids = [];
      if (!DraftBtn) {
        setTimeout(() => {
          dispatch({ type: CREATE_DIPLOMA_EDUCATION, payload: undefined });
          navigate(PATHS.SOCIODIPLOMA_STR + appIds);
        }, 500);
      } else if (DraftBtn) {
        setTimeout(() => {
          draftMsg.message = "";
          dispatch({ type: CREATE_DIPLOMA_EDUCATION, payload: undefined });
        }, 1000);
      }
    }
  }, [edudiplomaresponse]);

  const _onSubmit = (values) => {
    setLoader(true);
    const array = Array.from(ids);

    if (
      endDateMsg &&
      endDateMsg?.some(
        (msg) => msg === "End date cannot be earlier than start date"
      )
    ) {
      return false;
    }

    let workExperienceList = [];

    values?.recorddetails?.map((val, key) => {
      workExperienceList.push({
        organization: val.organization,
        startDate: moment(val.startDate).format("YYYY-MM-DD"),
        endDate:
          val.endDate == null ? null : moment(val.endDate).format("YYYY-MM-DD"),
        title: val.title,
        thematicArea: val.thematicArea,
        rolesAndResponsibilities: val.rolesAndResponsibilities,
        town: val.town,
        district: val.district,
        workExpId: array.length > 0 ? (array[key] ? array[key] : null) : null, //keep blank in case of post, fill in case of patch
      });
    });

    let formData = {
      userId: localStorage.getItem("userId"),
      contactId: localStorage.getItem("contactId"),
      applicationId: appIds,
      educationId10: allEduresponsedata?.hed__Education_History__r?.records[0]
        ?.Id
        ? allEduresponsedata?.hed__Education_History__r?.records[0]?.Id
        : null,
      //keep blank in case of post, fill in case of patch
      bachelorsCompletedByAug: "Already completed",
      qualificationsObtained: values.qualificationsObtained,
      completionYear: values.completionYear,
      durationUndergraduateProg: values.durationUndergraduateProg,
      townCategoryTenth: values.townCategoryTenth,
      countryTenth: values.countryTenth ? values.countryTenth : "",
      otherCountrySpecifyTenth: values.otherCountrySpecifyTenth
        ? values.otherCountrySpecifyTenth
        : "",
      mediumInstTenth: values.mediumInstTenth ? values.mediumInstTenth : "",
      specifyMediumTenth: values.specifyMediumTenth
        ? values.specifyMediumTenth
        : "",
      stateTenth:
        values.countryTenth === "India"
          ? values.stateTenth
            ? values.stateTenth
            : ""
          : "",
      districtTenth:
        values.countryTenth === "India"
          ? values.districtTenth
            ? values.districtTenth
            : null
          : null,
      educationIdGrad: allEduresponsedata?.hed__Education_History__r?.records[1]
        ?.Id
        ? allEduresponsedata?.hed__Education_History__r?.records[1]?.Id
        : null,
      //keep blank in case of post, fill in case of patch

      townCategoryGrad: values.townCategoryGrad,
      gradMode: values.gradMode,
      streamSpecialization: values.streamSpecialization,
      universityName: values.universityName,
      schoolNameGrad: values.schoolNameGrad,
      countryGrad: values.countryGrad ? values.countryGrad : "",
      otherCountrySpecifyGrad: values.otherCountrySpecifyGrad
        ? values.otherCountrySpecifyGrad
        : "",
      stateGrad:
        values.countryGrad === "India"
          ? values.stateGrad
            ? values.stateGrad
            : ""
          : "",
      districtGrad:
        values.countryGrad === "India"
          ? values.districtGrad
            ? values.districtGrad
            : null
          : null,
      mediumInstGrad: values.mediumInstGrad,
      specifyMediumGrad: values.specifyMediumGrad
        ? values.specifyMediumGrad
        : "",
      havePGDegree: values.havePGDegree,
      qualificationName:
        values.havePGDegree == "Yes" ? values.qualificationName : "",
      qualificationCertification: eduQual,
      diplomaSpecialization:
        eduQual == "Yes" ? values.diplomaSpecialization : "",
      diplomaUniversityInstitution:
        eduQual == "Yes" ? values.diplomaUniversityInstitution : "",
      diplomaYearOfCompletion:
        eduQual == "Yes" ? values.diplomaYearOfCompletion : "",
      workExperience: "Yes",
      workExperienceInMonths: parseInt(values.workExperienceInMonths),
      relevantWorkExpAfterGrad: parseInt(values.relevantWorkExpAfterGrad),
      typeOfOrg: values.typeOfOrg,
      orgAdditionalInfo: values.orgAdditionalInfo,
      orgName: values.orgName,
      noOfPeopleReporting: parseInt(values.noOfPeopleReporting),

      HaveUGEconomicsPasse: "",
      allPGDetails: "",
      workExperienceList,
    };

    if (formData) {
      dispatch(createEduDiploma(formData));
    }
  };

  const handleNewRecords = () => {
    const results = records.filter(
      (element) => Object.keys(element).length !== 0
    );

    setRecords([
      ...results,
      {
        organization: "",
        startDate: "",
        endDate: "",
        title: "",
        thematicArea: "",
        rolesAndResponsibilities: "",
        town: "",
        district: "",
        Id: "",
      },
    ]);
  };

  const handleRemoverecords = (element, index) => {
    seElementData(element);

    let formData = {
      workExpId: element?.Id,
    };

    if (formData && element?.Id) {
      dispatch(deleteWorkExperience(formData));
      // setIds(prevIds => {
      //   const newIds = new Set(prevIds);
      //   newIds.delete(element?.Id);
      //   return newIds;
      // });
    }

    const newFormValues = records.filter((el, ind) => ind !== index);

    // const results = records.filter((element) => {
    //   if (Object.keys(element).length !== 0) {
    //     return true;
    //   }
    // });
    // let newFormValues = [...results];

    // newFormValues.map((vl, ky) => {
    //   if (ky === index) {
    //     delete newFormValues[ky];
    unregister(`recorddetails.${index}.organization`);
    unregister(`recorddetails.${index}.startDate`);
    unregister(`recorddetails.${index}.endDate`);
    unregister(`recorddetails.${index}.title`);
    unregister(`recorddetails.${index}.thematicArea`);
    unregister(`recorddetails.${index}.rolesAndResponsibilities`);
    unregister(`recorddetails.${index}.town`);
    unregister(`recorddetails.${index}.district`);

    setRecords(newFormValues);
  };

  const currentDate = new Date();
  const year1 = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDate = `${year1}-${month}-${day}`;

  useEffect(() => {
    if (
      allEduresponsedata?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Started" ||
      allEduresponsedata?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Draft" ||
      allEduresponsedata?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Payment Failed" ||
      allEduresponsedata?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Payment Pending" ||
      allEduresponsedata?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Payment Submitted"
    ) {
      if (
        formattedDate >
        allEduresponsedata?.hed__Applications_Applicant__r?.records[0]
          ?.Admission_Cycle__r?.Application_Submission_Date__c
      ) {
        setReadOnly(true);
        // setShowMsg( "We regret to inform you that the Application Submission for the current Admission cycle has been closed");
        setShowMsg(<Notes name="ADM_Cycle_Validation_App" />);
      } else {
        setReadOnly(false);
        setShowMsg("");
      }
    } else {
      if (
        formattedDate <
        allEduresponsedata?.hed__Applications_Applicant__r?.records[0]
          ?.Admission_Cycle__r?.Application_Submission_Date__c
      ) {
        setReadOnly(true);
        setShowMsg("");
      } else {
        setReadOnly(true);
        // setShowMsg( "We regret to inform you that the Application Submission for the current Admission cycle has been closed");
        setShowMsg(<Notes name="ADM_Cycle_Validation_App" />);
      }
    }
  }, [allEduresponsedata]);

  function prefrenceView() {
    if (
      allEduresponsedata?.hed__Applications_Applicant__r?.records[0]
        ?.First_Preference__r?.APU_Short_Name__c
    ) {
      if (
        allEduresponsedata?.hed__Applications_Applicant__r?.records[0]?.First_Preference__r?.APU_Short_Name__c.includes(
          "DL"
        )
      ) {
        return (
          <div class="form_styl">
            <div class="inner_part">
              <label>
                {/* No.of people reporting to you?{" "}
                <span className="redstyl">*</span> */}
                {DiplomaEducationDetails.No_Of_People_Reporting}{" "}
                <span className="redstyl">*</span>
              </label>
              <input
                type="text"
                {...register("noOfPeopleReporting", {
                  required: {
                    value: true,
                    message: "Please enter value",
                  },
                  pattern: {
                    value: NUM_REGEX,
                    message: "Please enter number",
                  },
                })}
                className={`${errors.noOfPeopleReporting ? "error_msg" : ""}`}
                onChange={(e) => {
                  dispatch(unsavedchanges(e.target.value));
                  clearErrors("noOfPeopleReporting");
                }}
                disabled={readOnly}
              />
              {errors.noOfPeopleReporting && (
                <div className="error_shown">
                  {errors.noOfPeopleReporting?.message}
                </div>
              )}
            </div>
          </div>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  return (
    <Home>
      <div class="right_panel">
        <div class="w100">
          <p className="top_head_styl">{showMsg}</p>
          <h4 className="top_head">
            {getCapitalized(prgLabelname?.programName)}
          </h4>
          <span className="help_link">
            <p>
              <NavLink to={PATHS.RAISEQUERY}>{HELP_TEXT}</NavLink>{" "}
              <Tooltip id="helpTooltip">
                {/* <p>Click to Raise Query</p> */}
                <p>{ApplicationViewPage.Help_Tooltip_Text}</p>
              </Tooltip>
              <i
                data-tooltip-id="helpTooltip"
                class="fa-solid fa-circle-info"
              ></i>
            </p>
          </span>
        </div>
        <div className="w100 scroll_styl">
          <div class="heading_styl head_font">
            {/* <h4>{getCapitalized("Education Details")}</h4> */}
            <h4>{getCapitalized(DiplomaEducationDetails.Education_Details)}</h4>
          </div>
          <Loader show={loader} />

          {/* <Loader backdrop content="loading..." vertical /> */}

          <form
            onSubmit={handleSubmit(_onSubmit)}
            onFocus={(e) => e.target.setAttribute("autoComplete", "none")}
            disabled={readOnly}
          >
            <div class="form_sec form_part">
              {/* <div class="heading_styl">
                <h4>Bachelor’s Details</h4>
                <h4>{DiplomaEducationDetails.Bachelor_Details}</h4>
              </div> */}
              

              <div class="heading_styl">
                {/* <h4>Class 10 Details</h4> */}
                <h4>{DiplomaEducationDetails.Class_10_Details}</h4>
              </div>

              <div class="form_styl">
                <div class="w100 blc_txt">
                  <p>
                    Category A:{" "}
                    <i>
                      Bangalore, Chennai, Delhi, Kolkata, Mumbai, Pune,
                      Hyderabad and Chandigarh;
                    </i>
                  </p>
                  <p>
                    Category B:{" "}
                    <i>
                      Other state capital cities and cities with population
                      greater than 10 lakhs;
                    </i>
                  </p>
                  <p>
                    Category C: <i>Cities with population less than 10 Lakhs</i>
                  </p>
                  <p>
                    Category D: <i>Rural</i>
                  </p>
                </div>
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {/* Category of city/town (10th){" "}
                    <span className="redstyl">*</span> */}
                    {DiplomaEducationDetails.Category_Of_City_10}{" "}
                    <span className="redstyl">*</span>
                  </label>
                  <select
                    {...register("townCategoryTenth", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    onChange={(e) => {
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("townCategoryTenth");
                    }}
                    disabled={readOnly}
                    className={`${errors.townCategoryTenth ? "error_msg" : ""}`}
                  >
                    <option disabled selected value="">
                      {/* --Select Options-- */}
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.regionOfResidence?.map((soci, ind) => (
                        <option value={soci.key}>{soci.value}</option>
                      ))}
                  </select>
                  {errors.townCategoryTenth && (
                    <div className="error_shown">
                      {errors.townCategoryTenth?.message}
                    </div>
                  )}
                </div>
                <div class="inner_part">
                  <label>
                    {/* Country (10th) <span className="redstyl">*</span> */}
                    {DiplomaEducationDetails.Country_10}{" "}
                    <span className="redstyl">*</span>
                  </label>
                  <select
                    {...register("countryTenth", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    disabled={readOnly}
                    value={selectedCountry}
                    onChange={(e) => {
                      setSelectedCountry(e.target.value);
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("countryTenth");
                    }}
                    className={`${errors.countryTenth ? "error_msg" : ""}`}
                  >
                    <option disabled selected value="">
                      {/* --Select Options-- */}
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.country?.map((soci, ind) => (
                        <option value={soci.key}>{soci.value}</option>
                      ))}
                  </select>
                  {errors.countryTenth && (
                    <div className="error_shown">
                      {errors.countryTenth?.message}
                    </div>
                  )}
                </div>
              </div>
              <div class="form_styl">
                {selectedCountry === "Others" && (
                  <div class="inner_part">
                    <label>
                      {/* Please Specify <span class="redstyl">* </span> */}
                      {DiplomaEducationDetails.Please_Specify}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input
                      {...register("otherCountrySpecifyTenth", {
                        required: {
                          value: true,
                          message: "Please select value",
                        },
                      })}
                      onChange={(e) => {
                        dispatch(unsavedchanges(e.target.value));
                        clearErrors("otherCountrySpecifyTenth");
                      }}
                      disabled={readOnly}
                      className={`${
                        errors.otherCountrySpecifyTenth ? "error_msg" : ""
                      }`}
                    />
                    {errors.otherCountrySpecifyTenth && (
                      <div className="error_shown">
                        {errors.otherCountrySpecifyTenth?.message}
                      </div>
                    )}
                  </div>
                )}
                <div class="inner_part">
                  <label>
                    {/* Medium of Instruction(10th) <span class="redstyl">* </span> */}
                    {DiplomaEducationDetails.Medium_Of_Instruction_10}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <select
                    {...register("mediumInstTenth", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    disabled={readOnly}
                    className={`${errors.mediumInstTenth ? "error_msg" : ""}`}
                    value={medInsti}
                    onChange={(e) => {
                      setMedInsti(e.target.value);
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("mediumInstTenth");
                    }}
                  >
                    <option disabled selected value="">
                      {/* --Select Options-- */}
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.mediumOfInstruction?.map((soci, ind) => (
                        <option value={soci.key}>{soci.value}</option>
                      ))}
                  </select>
                  {errors.mediumInstTenth && (
                    <div className="error_shown">
                      {errors.mediumInstTenth?.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="form_styl">
                {medInsti === "Other" && (
                  <div class="inner_part">
                    <label>
                      {/* Please Specify <span class="redstyl">* </span> */}
                      {DiplomaEducationDetails.Please_Specify}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input
                      {...register("specifyMediumTenth", {
                        required: {
                          value: true,
                          message: "Please select value",
                        },
                      })}
                      onChange={(e) => {
                        dispatch(unsavedchanges(e.target.value));
                        clearErrors("specifyMediumTenth");
                      }}
                      disabled={readOnly}
                      className={`${
                        errors.specifyMediumTenth ? "error_msg" : ""
                      }`}
                    />
                    {errors.specifyMediumTenth && (
                      <div className="error_shown">
                        {errors.specifyMediumTenth?.message}
                      </div>
                    )}
                  </div>
                )}

                <div class="inner_part">
                  <label>
                    {/* State/Union Territory of Residence(10th){" "}
                    <span class="redstyl">* </span> */}
                    {DiplomaEducationDetails.State_Union_10}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <select
                    {...register("stateTenth", {
                      required: {
                        value: selectedCountry === "Others" ? false : true,
                        message: "Please select value",
                      },
                    })}
                    className={`${errors.stateTenth ? "error_msg" : ""}`}
                    value={selectedState}
                    onChange={handleState}
                    disabled={
                      !selectedCountry ||
                      selectedCountry === "Others" ||
                      readOnly
                    }
                  >
                    {selectedCountry === "Others" ? (
                      <option disabled selected value="">
                        {/* NA */}
                        {AppCommon.Dropdown_NA_Option}
                      </option>
                    ) : (
                      <option disabled value="">
                        {/* --Select Options-- */}
                        {AppCommon.Dropdown_Default_Option}
                      </option>
                    )}
                    {selectedCountry !== "Others" &&
                      pickListVal &&
                      pickListVal?.states?.map((soci, ind) => (
                        <option value={soci.Id}>{soci.value}</option>
                      ))}
                  </select>
                  {errors.stateTenth && (
                    <div className="error_shown">
                      {errors.stateTenth?.message}
                    </div>
                  )}
                </div>
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {/* District / City(10th) <span class="redstyl">* </span> */}
                    {DiplomaEducationDetails.District_10}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <select
                    {...register("districtTenth", {
                      required: {
                        value: selectedCountry === "Others" ? false : true,
                        message: "Please select value",
                      },
                    })}
                    className={`${errors.districtTenth ? "error_msg" : ""}`}
                    value={selectedDistrict}
                    onChange={(e) => {
                      setSelectedDistrict(e.target.value);
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("districtTenth");
                    }}
                    disabled={selectedCountry === "Others" || readOnly}
                  >
                    {selectedCountry === "Others" ? (
                      <option disabled selected value="">
                        {/* NA */}
                        {AppCommon.Dropdown_NA_Option}
                      </option>
                    ) : (
                      <option disabled value=""></option>
                    )}
                    {selectedCountry !== "Others" &&
                      district &&
                      district?.map((distObj, ind) => (
                        <option key={ind} value={distObj.Id}>
                          {distObj.Name}
                        </option>
                      ))}
                  </select>
                  {errors.districtTenth && (
                    <div className="error_shown">
                      {errors.districtTenth?.message}
                    </div>
                  )}
                </div>
              </div>

              <div class="heading_styl">
                {/* <h4>Undergraduate Details</h4> */}
                <h4>{DiplomaEducationDetails.Undergraduate_Details}</h4>
              </div>

              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {/* Qualification obtained <span className="redstyl">*</span> */}
                    {DiplomaEducationDetails.Qualification_Obtained}{" "}
                    <span className="redstyl">*</span>
                  </label>
                  <select
                    {...register("qualificationsObtained", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    onChange={(e) => {
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("qualificationsObtained");
                    }}
                    disabled={readOnly}
                    className={`${
                      errors.qualificationsObtained ? "error_msg" : ""
                    }`}
                  >
                    <option disabled selected value="">
                      {/* --Select Options-- */}
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.qualificationObtained?.map((soci, ind) => (
                        <option value={soci.key}>{soci.value}</option>
                      ))}
                  </select>
                  {errors.qualificationsObtained && (
                    <div className="error_shown">
                      {errors.qualificationsObtained?.message}
                    </div>
                  )}
                </div>
                <div class="inner_part">
                  <label>
                    {/* Year of completion <span className="redstyl">*</span>{" "} */}
                    {DiplomaEducationDetails.Year_Of_Completion}{" "}
                    <span className="redstyl">*</span>{" "}
                    <i
                      data-tooltip-id="yearcompletion"
                      class="fa-solid fa-circle-info"
                    ></i>
                    <Tooltip id="yearcompletion">
                      {/* <p>
                        Please mention the passing year in 4 digits and Do not
                        enter any special character.{" "}
                      </p> */}
                      <Notes name="Year_of_completion_Diploma" />
                    </Tooltip>
                  </label>
                  <input
                    {...register("completionYear", {
                      required: {
                        value: true,
                        message: "Please enter value",
                      },
                      pattern: {
                        value: NUM_REGEX,
                        message:
                          "Your entry does not match the allowed pattern.",
                      },
                      maxLength: {
                        value: 4,
                        message: "Please enter valid year",
                      },
                      minLength: {
                        value: 4,
                        message: "Please enter valid year",
                      },
                    })}
                    onChange={(e) => {
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("completionYear");
                    }}
                    disabled={readOnly}
                    maxLength="4"
                    className={`${errors.completionYear ? "error_msg" : ""}`}
                    onFocus={(e) =>
                      e.target.setAttribute("autoComplete", "none")
                    }
                  />
                  {errors.completionYear && (
                    <div className="error_shown">
                      {errors.completionYear?.message}
                    </div>
                  )}
                </div>
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>                    
                    {PGEducationDetails.Duration_of_Undergraduate_Programme}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <select
                    {...register("durationUndergraduateProg", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    onChange={(e) => {
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("durationUndergraduateProg");
                    }}
                    disabled={
                      readOnly &&
                      formattedDate >
                        allEduresponsedata?.hed__Applications_Applicant__r
                          ?.records[0]?.Admission_Cycle__r
                          ?.Preference_End_Date__c
                    }
                    className={`${
                      errors.durationUndergraduateProg ? "error_msg" : ""
                    }`}
                  >
                    <option disabled selected value="">
                      {/* --Select Options-- */}
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.durationUG?.map((soci, ind) => (
                        <option value={soci.key}>{soci.value}</option>
                      ))}
                  </select>
                  {errors.durationUndergraduateProg && (
                    <div className="error_shown">
                      {errors.durationUndergraduateProg?.message}
                    </div>
                  )}
                </div>
                <div class="inner_part"></div>
                </div>
              <div class="form_styl">
                <div class="w100 blc_txt">
                  <p>
                    Category A:{" "}
                    <i>
                      Bangalore, Chennai, Delhi, Kolkata, Mumbai, Pune,
                      Hyderabad and Chandigarh;
                    </i>
                  </p>
                  <p>
                    Category B:{" "}
                    <i>
                      Other state capital cities and cities with population
                      greater than 10 lakhs;
                    </i>
                  </p>
                  <p>
                    Category C: <i>Cities with population less than 10 Lakhs</i>
                  </p>
                  <p>
                    Category D: <i>Rural</i>
                  </p>
                </div>
              </div>
              <div class="form_styl">
                <div class="form_styl">
                  <div class="inner_part">
                    <label>
                      {/* Category of city/town where studied{" "}
                      <span class="redstyl">* </span> */}
                      {DiplomaEducationDetails.Category_Of_City_UG}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <select
                      {...register("townCategoryGrad", {
                        required: {
                          value: true,
                          message: "Please select value",
                        },
                      })}
                      onChange={(e) => {
                        dispatch(unsavedchanges(e.target.value));
                        clearErrors("townCategoryGrad");
                      }}
                      disabled={readOnly}
                      className={`${
                        errors.townCategoryGrad ? "error_msg" : ""
                      }`}
                    >
                      <option disabled selected value="">
                        {/* --Select Options-- */}
                        {AppCommon.Dropdown_Default_Option}
                      </option>
                      {pickListVal &&
                        pickListVal?.regionOfResidence?.map((soci, ind) => (
                          <option value={soci.Id}>{soci.value}</option>
                        ))}
                    </select>
                    {errors.townCategoryGrad && (
                      <div className="error_shown">
                        {errors.townCategoryGrad?.message}
                      </div>
                    )}
                  </div>
                  <div class="inner_part">
                    <label>
                      {/* Mode of graduation <span class="redstyl">* </span> */}
                      {DiplomaEducationDetails.Mode_Of_Graduation}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <select
                      {...register("gradMode", {
                        required: {
                          value: true,
                          message: "Please select value",
                        },
                      })}
                      onChange={(e) => {
                        dispatch(unsavedchanges(e.target.value));
                        clearErrors("gradMode");
                      }}
                      disabled={readOnly}
                      className={`${errors.gradMode ? "error_msg" : ""}`}
                    >
                      <option disabled selected value="">
                        {/* --Select Options-- */}
                        {AppCommon.Dropdown_Default_Option}
                      </option>
                      {pickListVal &&
                        pickListVal?.modeOfGraduation?.map((soci, ind) => (
                          <option value={soci.Id}>{soci.value}</option>
                        ))}
                    </select>
                    {errors.gradMode && (
                      <div className="error_shown">
                        {errors.gradMode?.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {/* Stream/Specialization <span class="redstyl">* </span> */}
                    {DiplomaEducationDetails.Stream_UG}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <input
                    type="text"
                    {...register("streamSpecialization", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    onChange={(e) => {
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("streamSpecialization");
                    }}
                    disabled={readOnly}
                    className={`${
                      errors.streamSpecialization ? "error_msg" : ""
                    }`}
                  />
                  {errors.streamSpecialization && (
                    <div className="error_shown">
                      {errors.streamSpecialization?.message}
                    </div>
                  )}
                </div>
                <div class="inner_part">
                  <label>
                    {/* Name of the University <span class="redstyl">* </span> */}
                    {DiplomaEducationDetails.University_Name_UG}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <input
                    type="text"
                    {...register("universityName", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    onChange={(e) => {
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("universityName");
                    }}
                    disabled={readOnly}
                    className={`${errors.universityName ? "error_msg" : ""}`}
                  />
                  {errors.universityName && (
                    <div className="error_shown">
                      {errors.universityName?.message}
                    </div>
                  )}
                </div>
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {/* Name of the College <span class="redstyl">* </span> */}
                    {DiplomaEducationDetails.College_Name_UG}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <input
                    type="text"
                    {...register("schoolNameGrad", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    onChange={(e) => {
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("schoolNameGrad");
                    }}
                    disabled={readOnly}
                    className={`${errors.schoolNameGrad ? "error_msg" : ""}`}
                  />
                  {errors.schoolNameGrad && (
                    <div className="error_shown">
                      {errors.schoolNameGrad?.message}
                    </div>
                  )}
                </div>
                <div class="inner_part">
                  <label>
                    {/* Country <span class="redstyl">* </span> */}
                    {DiplomaEducationDetails.Country_UG}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <select
                    {...register("countryGrad", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    disabled={readOnly}
                    className={`${errors.countryGrad ? "error_msg" : ""}`}
                    value={selectednewCountry}
                    onChange={(e) => {
                      setSelectednewCountry(e.target.value);
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("countryGrad");
                    }}
                  >
                    <option disabled selected value="">
                      {/* --Select Options-- */}
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.country?.map((soci, ind) => (
                        <option value={soci.Id}>{soci.value}</option>
                      ))}
                  </select>
                  {errors.countryGrad && (
                    <div className="error_shown">
                      {errors.countryGrad?.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="form_styl">
                {selectednewCountry === "Others" && (
                  <div class="inner_part">
                    <label>
                      {/* Please Specify <span class="redstyl">* </span> */}
                      {DiplomaEducationDetails.Please_Specify}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input
                      {...register("otherCountrySpecifyGrad", {
                        required: {
                          value: true,
                          message: "Please select value",
                        },
                      })}
                      onChange={(e) => {
                        dispatch(unsavedchanges(e.target.value));
                        clearErrors("otherCountrySpecifyGrad");
                      }}
                      disabled={readOnly}
                      className={`${
                        errors.otherCountrySpecifyGrad ? "error_msg" : ""
                      }`}
                    />
                    {errors.otherCountrySpecifyGrad && (
                      <div className="error_shown">
                        {errors.otherCountrySpecifyGrad?.message}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {/* State/Union Territory of Residence{" "}
                    <span class="redstyl">* </span> */}
                    {DiplomaEducationDetails.State_Union_Residence_UG}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <select
                    {...register("stateGrad", {
                      required: {
                        value: selectednewCountry === "Others" ? false : true,
                        message: "Please select value",
                      },
                    })}
                    className={`${errors.stateGrad ? "error_msg" : ""}`}
                    value={selectedState1}
                    onChange={handlenewState}
                    disabled={
                      !selectednewCountry ||
                      selectednewCountry === "Others" ||
                      readOnly
                    }
                  >
                    {selectednewCountry === "Others" ? (
                      <option selected disabled value="">
                        {/* NA */}
                        {AppCommon.Dropdown_NA_Option}
                      </option>
                    ) : (
                      <option disabled value="">
                        {/* --Select Options-- */}
                        {AppCommon.Dropdown_Default_Option}
                      </option>
                    )}
                    {selectednewCountry !== "Others" &&
                      pickListVal?.states?.map((stateobj, ind) => (
                        <option value={stateobj.Id} key={ind}>
                          {stateobj.value}
                        </option>
                      ))}
                  </select>
                  {errors.stateGrad && (
                    <div className="error_shown">
                      {errors.stateGrad?.message}
                    </div>
                  )}
                </div>

                <div class="inner_part">
                  <label>
                    {/* District / City <span class="redstyl">* </span> */}
                    {DiplomaEducationDetails.District_UG}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <select
                    type="text"
                    {...register("districtGrad", {
                      required: {
                        value: selectednewCountry === "Others" ? false : true,
                        message: "Please select value",
                      },
                    })}
                    className={`${errors.districtGrad ? "error_msg" : ""}`}
                    value={selectedDistrict1}
                    onChange={(e) => {
                      setSelectedDistrict1(e.target.value);
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("districtGrad");
                    }}
                    disabled={selectednewCountry === "Others" || readOnly}
                  >
                    {selectednewCountry === "Others" && (
                      <option selected disabled value="">
                        {/* NA */}
                        {AppCommon.Dropdown_NA_Option}
                      </option>
                    )}
                    {selectednewCountry !== "Others" && (
                      <option disabled value=""></option>
                    )}
                    {selectednewCountry !== "Others" &&
                      district1 &&
                      district1?.map((distObj, ind) => (
                        <option key={ind} value={distObj.Id}>
                          {distObj.Name}
                        </option>
                      ))}
                  </select>
                  {errors.districtGrad && (
                    <div className="error_shown">
                      {errors.districtGrad?.message}
                    </div>
                  )}
                </div>
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {/* Medium of Instruction <span class="redstyl">* </span> */}
                    {DiplomaEducationDetails.Medium_Of_Instruction_UG}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <select
                    {...register("mediumInstGrad", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    disabled={readOnly}
                    className={`${errors.mediumInstGrad ? "error_msg" : ""}`}
                    value={mediumInsti}
                    onChange={(e) => {
                      setMediumInsti(e.target.value);
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("mediumInstGrad");
                    }}
                  >
                    <option disabled selected value="">
                      {/* --Select Options-- */}
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.mediumOfInstruction?.map((soci, ind) => (
                        <option value={soci.Id}>{soci.value}</option>
                      ))}
                  </select>
                  {errors.mediumInstGrad && (
                    <div className="error_shown">
                      {errors.mediumInstGrad?.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="form_styl">
                {mediumInsti === "Other" && (
                  <div class="inner_part">
                    <label>
                      {/* Please Specify <span class="redstyl">* </span> */}
                      {DiplomaEducationDetails.Please_Specify}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input
                      {...register("specifyMediumGrad", {
                        required: {
                          value: true,
                          message: "Please select value",
                        },
                      })}
                      onChange={(e) => {
                        dispatch(unsavedchanges(e.target.value));
                        clearErrors("specifyMediumGrad");
                      }}
                      disabled={readOnly}
                      className={`${
                        errors.specifyMediumGrad ? "error_msg" : ""
                      }`}
                    />
                    {errors.specifyMediumGrad && (
                      <div className="error_shown">
                        {errors.specifyMediumGrad?.message}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="form_styl">
                <div class="inner_part">
                  <label>
                    {/* Do you have any Postgraduate degree?{" "}
                    <span class="redstyl">* </span> */}
                    {DiplomaEducationDetails.PG_Degree_Question}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <select
                    {...register("havePGDegree", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    disabled={readOnly}
                    className={`${errors.havePGDegree ? "error_msg" : ""}`}
                    value={pgDegree}
                    onChange={(e) => {
                      setPgdegree(e.target.value);
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("havePGDegree");
                    }}
                  >
                    <option disabled selected value="">
                      {/* --Select Options-- */}
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.doYouHavePostGradDegree?.map((soci, ind) => (
                        <option value={soci.Id}>{soci.value}</option>
                      ))}
                  </select>
                  {errors.havePGDegree && (
                    <div className="error_shown">
                      {errors.havePGDegree?.message}
                    </div>
                  )}
                </div>
              </div>

              {pgDegree == "Yes" && (
                <div class="form_styl">
                  <div class="inner_part">
                    <label>
                      {/* Name of the Postgraduate degree{" "}
                      <span class="redstyl">* </span> */}
                      {DiplomaEducationDetails.PG_Degree_Name}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input
                      type="text"
                      {...register("qualificationName", {
                        required: {
                          value: true,
                          message: "Please select value",
                        },
                      })}
                      onChange={(e) => {
                        dispatch(unsavedchanges(e.target.value));
                        clearErrors("qualificationName");
                      }}
                      disabled={readOnly}
                      className={`${
                        errors.qualificationName ? "error_msg" : ""
                      }`}
                    />
                    {errors.qualificationName && (
                      <div className="error_shown">
                        {errors.qualificationName?.message}
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {/* Do you have any other educational qualification
                    /certification? <span className="redstyl">*</span> */}
                    {DiplomaEducationDetails.Any_Other_Qualifications_Question}{" "}
                    <span className="redstyl">*</span>
                  </label>
                  {/* {...register("qualificationCertification")} */}
                  <span className="flex">
                    {HAVEPGEDUCATION &&
                      HAVEPGEDUCATION.map((dip, ind) => (
                        <span class="flex">
                          <input
                            type="radio"
                            className={`${
                              errors.haveQualification ? "error_msg" : ""
                            }`}
                            {...register("haveQualification", {
                              required: {
                                value: true,
                                message: "Please select value",
                              },
                            })}
                            value={dip.value}
                            // checked={eduQual === dip.value}
                            // onChange={handleDiplomaFlexi}
                            disabled={readOnly}
                            onChange={(e) => {
                              setEduQual(e.target.value);
                              dispatch(unsavedchanges(e.target.value));
                              clearErrors("haveQualification");
                            }}
                          />
                          <label>{dip.value}</label>
                        </span>
                      ))}
                  </span>
                  {errors.haveQualification && (
                    <div className="error_shown">
                      {errors.haveQualification?.message}
                    </div>
                  )}
                </div>

                {eduQual === "Yes" && (
                  <div class="inner_part">
                    <label>
                      {/* Specialization <span className="redstyl">*</span> */}
                      {DiplomaEducationDetails.Specialization_Other}{" "}
                      <span className="redstyl">*</span>
                    </label>
                    <input
                      type="text"
                      className={`${
                        errors.diplomaSpecialization ? "error_msg" : ""
                      }`}
                      {...register("diplomaSpecialization", {
                        required: {
                          value: true,
                          message: "Please enter value",
                        },
                      })}
                      onChange={(e) => {
                        dispatch(unsavedchanges(e.target.value));
                        clearErrors("diplomaSpecialization");
                      }}
                      disabled={readOnly}
                    />
                    <div className="error_shown">
                      {errors.diplomaSpecialization?.message}
                    </div>
                  </div>
                )}
              </div>
              {eduQual === "Yes" && (
                <>
                  {" "}
                  <div class="form_styl">
                    <div class="inner_part">
                      <label>
                        {/* University/Institution{" "}
                        <span className="redstyl">*</span> */}
                        {DiplomaEducationDetails.University_Other}{" "}
                        <span className="redstyl">*</span>
                      </label>
                      <input
                        type="text"
                        className={`${
                          errors.diplomaUniversityInstitution ? "error_msg" : ""
                        }`}
                        {...register("diplomaUniversityInstitution", {
                          required: {
                            value: true,
                            message: "Please enter value",
                          },
                        })}
                        onChange={(e) => {
                          dispatch(unsavedchanges(e.target.value));
                          clearErrors("diplomaUniversityInstitution");
                        }}
                        disabled={readOnly}
                      />
                      <div className="error_shown">
                        {errors.diplomaUniversityInstitution?.message}
                      </div>
                    </div>
                    <div class="inner_part">
                      <label>
                        {/* Year of completion <span className="redstyl">*</span> */}
                        {DiplomaEducationDetails.Year_Of_Completion}{" "}
                        <span className="redstyl">*</span>
                        <i
                          data-tooltip-id="yearcompletionEdu"
                          class="fa-solid fa-circle-info"
                        ></i>
                        <Tooltip id="yearcompletionEdu">
                          {/* <p>Please enter value like 2021. </p> */}
                          <p>
                            {
                              DiplomaEducationDetails.Year_Of_Completion_Tooltip_Message
                            }
                          </p>
                        </Tooltip>
                      </label>
                      <input
                        {...register("diplomaYearOfCompletion", {
                          required: {
                            value: true,
                            message: "Please enter value",
                          },
                          pattern: {
                            value: NUM_REGEX,
                            message:
                              "Your entry does not match the allowed pattern.",
                          },
                          maxLength: {
                            value: 4,
                            message: "Please enter valid year",
                          },
                          minLength: {
                            value: 4,
                            message: "Please enter valid year",
                          },
                        })}
                        maxLength="4"
                        className={`${
                          errors.diplomaYearOfCompletion ? "error_msg" : ""
                        }`}
                        onChange={(e) => {
                          dispatch(unsavedchanges(e.target.value));
                          clearErrors("diplomaYearOfCompletion");
                        }}
                        disabled={readOnly}
                      />
                      {errors.diplomaYearOfCompletion && (
                        <div className="error_shown">
                          {errors.diplomaYearOfCompletion?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}

              <div class="heading_styl">
                {/* <h4>Work Experience</h4> */}
                <h4>{DiplomaEducationDetails.Work_Experience}</h4>
              </div>
              <div class="form_styl">
                <div class="inner_part w100">
                  {allEduresponsedata?.hed__Applications_Applicant__r
                    ?.records[0] && (
                    <div className="inner_part">
                      <p>
                        {/* Program Commencement Month :{" "} */}
                        {
                          DiplomaEducationDetails.Program_Commencement_Month
                        }{" "}
                        {
                          allEduresponsedata?.hed__Applications_Applicant__r
                            ?.records[0]?.First_Preference__r
                            ?.APU_Commencement_Month__c
                        }
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="form_styl Work_experience">
                <div class="inner_part">
                  <label>
                    {/* Total Experience after Graduation (in months){" "}
                    <span className="redstyl">*</span> */}
                    {DiplomaEducationDetails.Total_Experience_After_Graduation}{" "}
                    <span className="redstyl">*</span>
                    <>
                      <Tooltip id="exphelpTooltip">
                        {/* <p>
                          Enter the total experience in months at the time
                          of Program Commencement Month
                        </p> */}
                        <Notes name="Total_Experience_after_Graduation_Diploma" />
                      </Tooltip>
                      <i
                        data-tooltip-id="exphelpTooltip"
                        class="fa-solid fa-circle-info"
                      ></i>
                    </>
                  </label>
                  <input
                    type="text"
                    {...register("workExperienceInMonths", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                      pattern: {
                        value: NUM_REGEX,
                        message: "Please enter number",
                      },
                      maxLength: {
                        value: 3,
                        message: "Please enter valid months",
                      },
                      validate: (val) => {
                        if (
                          allEduresponsedata?.hed__Applications_Applicant__r
                            ?.records[0]?.First_Preference__r
                            ?.APU_Minimum_Experience__c > val
                        ) {
                          return `Total experience is less than the minimum ${allEduresponsedata?.hed__Applications_Applicant__r?.records[0]?.First_Preference__r?.APU_Minimum_Experience__c} months required for this program`;
                        }
                      },
                    })}
                    maxLength="3"
                    onChange={(e) => {
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("workExperienceInMonths");
                    }}
                    disabled={readOnly}
                    className={`${
                      errors.workExperienceInMonths ? "error_msg" : ""
                    }`}
                  />
                  {errors.workExperienceInMonths && (
                    <div className="error_shown">
                      {errors.workExperienceInMonths?.message}
                    </div>
                  )}
                </div>
                <div class="inner_part">
                  <label>
                    {/* Relevant work experience after Graduation (in months){" "}
                    <span className="redstyl">*</span> */}
                    {
                      DiplomaEducationDetails.Relevant_Experience_After_Graduation
                    }{" "}
                    <span className="redstyl">*</span>
                    <>
                      <Tooltip id="relevantwork">
                        {/* <p>
                          Experience in the broad fields of Education/Development/Health etc will be considered as relevant
                        </p> */}
                        <Notes name="Relevant_work_experience_after_Graduation_Diploma" />
                      </Tooltip>
                      <i
                        data-tooltip-id="relevantwork"
                        class="fa-solid fa-circle-info"
                      ></i>
                    </>
                  </label>
                  <input
                    {...register("relevantWorkExpAfterGrad", {
                      required: {
                        value: true,
                        message: "Please enter value",
                      },
                      pattern: {
                        value: NUM_REGEX,
                        message: "Please enter number",
                      },
                      maxLength: {
                        value: 3,
                        message: "Please enter valid months",
                      },
                    })}
                    maxLength="3"
                    onChange={(e) => {
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("relevantWorkExpAfterGrad");
                    }}
                    disabled={readOnly}
                    type="text"
                    className={`${
                      errors.relevantWorkExpAfterGrad ? "error_msg" : ""
                    }`}
                  />
                  {errors.relevantWorkExpAfterGrad && (
                    <div className="error_shown">
                      {errors.relevantWorkExpAfterGrad?.message}
                    </div>
                  )}
                </div>
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {/* Type of Organization <span className="redstyl">*</span> */}
                    {DiplomaEducationDetails.Type_Of_Organization_Experience}{" "}
                    <span className="redstyl">*</span>
                    <>
                      <Tooltip id="typeoforg">
                        {/* <p>Choose the most relevant type based on your current / latest organization</p> */}
                        <Notes name="Type_of_Organization_Diploma" />
                      </Tooltip>
                      <i
                        data-tooltip-id="typeoforg"
                        class="fa-solid fa-circle-info"
                      ></i>
                    </>
                  </label>
                  <select
                    {...register("typeOfOrg", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    onChange={(e) => {
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("typeOfOrg");
                    }}
                    disabled={readOnly}
                    className={`${errors.typeOfOrg ? "error_msg" : ""}`}
                  >
                    <option disabled selected value="">
                      {/* --Select Options-- */}
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.apuOrgType?.map((soci, ind) => (
                        <option value={soci.Id}>{soci.value}</option>
                      ))}                     
                  </select>
                  {errors.typeOfOrg && (
                    <div className="error_shown">
                      {errors.typeOfOrg?.message}
                    </div>
                  )}
                </div>
                <div class="inner_part">
                  <label>
                    {/* Organization Additional Info{" "}
                    <span className="redstyl">*</span> */}
                    {DiplomaEducationDetails.Organization_Additional_Info}{" "}
                    <span className="redstyl">*</span>
                    <Tooltip id="orghelpTooltip">
                      {/* <p>Select what best fits your Organization</p> */}
                      <p>
                        {
                          DiplomaEducationDetails.Organization_Additional_Info_Tooltip_Message
                        }
                      </p>
                    </Tooltip>
                    <i
                      data-tooltip-id="orghelpTooltip"
                      class="fa-solid fa-circle-info"
                    ></i>
                  </label>
                  <select
                    {...register("orgAdditionalInfo", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    className={`${errors.orgAdditionalInfo ? "error_msg" : ""}`}
                    disabled={readOnly}
                  >
                    <option disabled selected value="">
                      {/* --Select Options-- */}
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.apuAdditionOrgInfo?.map((soci, ind) => (
                        <option value={soci.Id}>{soci.value}</option>
                      ))}
                  </select>
                  {errors.orgAdditionalInfo && (
                    <div className="error_shown">
                      {errors.orgAdditionalInfo?.message}
                    </div>
                  )}
                </div>
              </div>

              {prefrenceView()}

              <div class="heading_styl">
                {/* <h4>More Details on Work Experience</h4> */}
                <h4>{DiplomaEducationDetails.Work_Experience_More_Details}</h4>
              </div>

              <div class="form_styl">
                <div class="w100">
                  {/* <p>
                    Note: Enter work experience details starting with latest
                    experience{" "}
                  </p> */}
                  <Notes name="More_Details_on_Work_Experience_Diploma" />
                </div>
              </div>

              {records?.map((element, index) => (
                <React.Fragment key={index}>
                  {!readOnly && index > 0 ? (
                    <div class="w100 removerecord flex">
                      <span onClick={() => handleRemoverecords(element, index)}>
                        {/* <i className="fa-solid fa-trash-can"></i> Remove */}
                        <i className="fa-solid fa-trash-can"></i>{" "}
                        {DiplomaEducationDetails.Record_Remove_Text}
                      </span>
                    </div>
                  ) : (
                    <span className="deleteitem pr-3">&nbsp;</span>
                  )}

                  <div class="form_styl">
                    <div class="inner_part">
                      <label>
                        {/* Start Date <span class="redstyl">* </span> */}
                        {
                          DiplomaEducationDetails.Start_Date_More_Experience
                        }{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <Controller
                        className={`${
                          errors?.recorddetails &&
                          errors?.recorddetails[index]?.startDate
                            ? "error_msg"
                            : ""
                        }`}
                        {...register(`recorddetails.${index}.startDate`, {
                          required: {
                            value: true,
                            message: "Please enter value",
                          },
                        })}
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          onChange = (event) => {
                            const endDate = getValues(
                              `recorddetails.${index}.endDate`
                            );
                            if (new Date(event) <= new Date(endDate)) {
                              setValue(
                                `recorddetails.${index}.startDate`,
                                event
                              );
                              clearErrors(`recorddetails.${index}.startDate`);
                              setEndDateMsg((prevMsgs) => {
                                const newMsgs = [...prevMsgs];
                                newMsgs[index] = "";
                                return newMsgs;
                              });
                            } else {
                              setValue(
                                `recorddetails.${index}.startDate`,
                                event
                              );
                              clearErrors(`recorddetails.${index}.startDate`);
                              if (endDate) {
                                setEndDateMsg((prevMsgs) => {
                                  const newMsgs = [...prevMsgs];
                                  newMsgs[index] = [
                                    <div className="error_shown">
                                      {/* End date cannot be earlier than start date */}
                                      {
                                        DiplomaEducationDetails.End_Date_Error_Message
                                      }
                                    </div>,
                                  ];
                                  return newMsgs;
                                });
                              }
                            }
                          };
                          return (
                            <DatePicker
                              className={`${
                                errors?.recorddetails &&
                                errors?.recorddetails[index]?.startDate
                                  ? "error_msg"
                                  : ""
                              }`}
                              clearIcon={null}
                              onTap
                              onChange={onChange}
                              onKeyPress={false}
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                              value={value}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              // format="MM/dd/yyyy"
                              format="MMM dd yy"
                              placement={"topEnd"}
                              maxDate={new Date()}
                              disabled={readOnly}
                            />
                          );
                        }}
                      />
                      {errors?.recorddetails &&
                        errors?.recorddetails[index]?.startDate && (
                          <div className="error_shown">
                            {errors?.recorddetails[index].startDate?.message}
                          </div>
                        )}
                    </div>

                    <div class="inner_part">
                      {/* <label>End Date</label> */}
                      <label>
                        {DiplomaEducationDetails.End_Date_More_Experience}
                      </label>
                      <Controller
                        className={`${
                          endDateMsg[index] ||
                          (errors?.recorddetails &&
                            errors.recorddetails[index]?.endDate)
                            ? "error_msg"
                            : ""
                        }`}
                        {...register(`recorddetails.${index}.endDate`)}
                        disabled={readOnly}
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          onChange = (event) => {
                            if (event === null) {
                              // Clear the date
                              setValue(`recorddetails.${index}.endDate`, null);
                              clearErrors(`recorddetails.${index}.endDate`);
                              setEndDateMsg((prevMsgs) => {
                                const newMsgs = [...prevMsgs];
                                newMsgs[index] = "";
                                return newMsgs;
                              });
                            } else {
                              const startDate = getValues(
                                `recorddetails.${index}.startDate`
                              );

                              if (new Date(event) >= new Date(startDate)) {
                                setValue(
                                  `recorddetails.${index}.endDate`,
                                  event
                                );
                                clearErrors(`recorddetails.${index}.endDate`);
                                setEndDateMsg((prevMsgs) => {
                                  const newMsgs = [...prevMsgs];
                                  newMsgs[index] = "";
                                  return newMsgs;
                                });
                              } else {
                                setValue(
                                  `recorddetails.${index}.endDate`,
                                  event
                                );
                                setEndDateMsg((prevMsgs) => {
                                  const newMsgs = [...prevMsgs];
                                  newMsgs[index] = [
                                    <div className="error_shown">
                                      {/* End date cannot be earlier than start date */}
                                      {
                                        DiplomaEducationDetails.End_Date_Error_Message
                                      }
                                    </div>,
                                  ];
                                  return newMsgs;
                                });
                              }
                            }
                          };
                          return (
                            <DatePicker
                              className={`${
                                endDateMsg[index] ||
                                (errors?.recorddetails &&
                                  errors?.recorddetails[index]?.endDate)
                                  ? "error_msg"
                                  : ""
                              }`}
                              clearIcon
                              isClearable
                              onTap
                              onChange={onChange}
                              onKeyPress={false}
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                              value={value}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              // format="MM/dd/yyyy"
                              format="MMM dd yy"
                              placement={"topEnd"}
                              maxDate={new Date()}
                              disabled={readOnly}
                            />
                          );
                        }}
                      />

                      {endDateMsg[index] ||
                        (errors?.recorddetails &&
                          errors?.recorddetails[index]?.endDate && (
                            <div className="error_shown">
                              <span className="error_shown">
                                {endDateMsg[index]
                                  ? endDateMsg[index]
                                  : errors?.recorddetails[index]?.endDate
                                      ?.message}
                              </span>
                            </div>
                          ))}
                    </div>
                  </div>
                  <div class="form_styl">
                    <div class="inner_part">
                      <label>
                        {/* Organization <span className="redstyl">*</span> */}
                        {
                          DiplomaEducationDetails.Organization_More_Experience
                        }{" "}
                        <span className="redstyl">*</span>
                        <Tooltip id="expoforg">
                          {/* <p>Enter Name of the organization</p> */}
                          <p>
                            {
                              DiplomaEducationDetails.Organization_More_Experience_Tooltip_Message
                            }
                          </p>
                        </Tooltip>
                        <i
                          data-tooltip-id="expoforg"
                          class="fa-solid fa-circle-info"
                        ></i>
                      </label>
                      <input
                        type="text"
                        className={`${
                          errors?.recorddetails &&
                          errors?.recorddetails[index]?.organization
                            ? "error_msg"
                            : ""
                        }`}
                        {...register(`recorddetails.${index}.organization`, {
                          required: {
                            value: true,
                            message: "Please enter value",
                          },
                        })}
                        disabled={readOnly}
                      />
                      {errors?.recorddetails &&
                        errors?.recorddetails[index]?.organization && (
                          <div className="error_shown">
                            {errors?.recorddetails[index].organization?.message}
                          </div>
                        )}
                    </div>

                    <div class="inner_part">
                      <label>
                        {/* Job Title <span className="redstyl"> *</span> */}
                        {DiplomaEducationDetails.Job_Title_More_Experience}{" "}
                        <span className="redstyl"> *</span>
                      </label>
                      <input
                        className={`${
                          errors?.recorddetails &&
                          errors?.recorddetails[index]?.title
                            ? "error_msg"
                            : ""
                        }`}
                        {...register(`recorddetails.${index}.title`, {
                          required: {
                            value: true,
                            message: "Please enter value",
                          },
                        })}
                        disabled={readOnly}
                      />
                      {errors?.recorddetails &&
                        errors?.recorddetails[index]?.title && (
                          <div className="error_shown">
                            {errors?.recorddetails[index].title?.message}
                          </div>
                        )}
                    </div>
                  </div>
                  <div class="form_styl">
                    <div class="inner_part">
                      <label>
                        {/* Thematic areas of work{" "}
                        <span className="redstyl"> *</span> */}
                        {DiplomaEducationDetails.Thematic_Areas_More_Experience}{" "}
                        <span className="redstyl"> *</span>
                        <Tooltip id="areaTooltip">
                          {/* <p>
                            Please write brief areas of your work (eg - Climate
                            change, women empowerment etc)
                          </p> */}
                          <Notes name="Diploma_Thematic_Areas" />
                        </Tooltip>
                        <i
                          data-tooltip-id="areaTooltip"
                          class="fa-solid fa-circle-info"
                        ></i>
                      </label>
                      <textarea
                        className={`${
                          errors?.recorddetails &&
                          errors?.recorddetails[index]?.thematicArea
                            ? "error_msg"
                            : ""
                        }`}
                        {...register(`recorddetails.${index}.thematicArea`, {
                          required: {
                            value: true,
                            message: "Please enter value",
                          },
                        })}
                        disabled={readOnly}
                        rows="4"
                        maxLength={300}
                      ></textarea>
                      {errors?.recorddetails &&
                        errors?.recorddetails[index]?.thematicArea && (
                          <div className="error_shown">
                            {errors?.recorddetails[index].thematicArea?.message}
                          </div>
                        )}
                    </div>
                    <div class="inner_part">
                      <label>
                        {/* Roles and Responsibility{" "}
                        <span className="redstyl"> *</span> */}
                        {
                          DiplomaEducationDetails.Roles_Responsibility_More_Experience
                        }{" "}
                        <span className="redstyl"> *</span>
                      </label>
                      <textarea
                        {...register(
                          `recorddetails.${index}.rolesAndResponsibilities`,
                          {
                            required: {
                              value: true,
                              message: "Please enter value",
                            },
                          }
                        )}
                        className={`${
                          errors?.recorddetails &&
                          errors?.recorddetails[index]?.rolesAndResponsibilities
                            ? "error_msg"
                            : ""
                        }`}
                        disabled={readOnly}
                        rows="4"
                        maxLength={1100}
                      ></textarea>
                      {errors?.recorddetails &&
                        errors?.recorddetails[index]
                          ?.rolesAndResponsibilities && (
                          <div className="error_shown">
                            {
                              errors?.recorddetails[index]
                                .rolesAndResponsibilities?.message
                            }
                          </div>
                        )}
                    </div>
                  </div>
                  <div class="form_styl">
                    <div class="inner_part">
                      <label>
                        {/* Town <span className="redstyl"> *</span> */}
                        {DiplomaEducationDetails.Town_More_Experience}{" "}
                        <span className="redstyl"> *</span>
                      </label>
                      <input
                        type="text"
                        className={`${
                          errors?.recorddetails &&
                          errors?.recorddetails[index]?.town
                            ? "error_msg"
                            : ""
                        }`}
                        {...register(`recorddetails.${index}.town`, {
                          required: {
                            value: true,
                            message: "Please enter value",
                          },
                        })}
                        disabled={readOnly}
                      />
                      {errors?.recorddetails &&
                        errors?.recorddetails[index]?.town && (
                          <div className="error_shown">
                            {errors?.recorddetails[index].town?.message}
                          </div>
                        )}
                    </div>
                    <div class="inner_part">
                      <label>
                        {/* District <span className="redstyl"> *</span> */}
                        {DiplomaEducationDetails.District_More_Experience}{" "}
                        <span className="redstyl"> *</span>
                      </label>
                      <input
                        type="text"
                        className={`${
                          errors?.recorddetails &&
                          errors?.recorddetails[index]?.district
                            ? "error_msg"
                            : ""
                        }`}
                        {...register(`recorddetails.${index}.district`, {
                          required: {
                            value: true,
                            message: "Please enter value",
                          },
                        })}
                        disabled={readOnly}
                      />
                      {errors?.recorddetails &&
                        errors?.recorddetails[index]?.district && (
                          <div className="error_shown">
                            {errors?.recorddetails[index].district?.message}
                          </div>
                        )}
                    </div>
                  </div>
                  <hr></hr>
                </React.Fragment>
              ))}

              {!readOnly && (
                <div class="form_styl">
                  <div class="w100 addnewrecord flex">
                    <span onClick={handleNewRecords}>
                      {/* <i class="fa-solid fa-circle-plus"></i> Add New Record */}
                      <i class="fa-solid fa-circle-plus"></i>{" "}
                      {DiplomaEducationDetails.Add_Record_More_Experience}
                    </span>
                  </div>
                </div>
              )}

              <div class="form_styl">
                <div class="w100">
                  <button
                    type="submit"
                    onClick={(e) => (DraftBtn = e.target.value)}
                    value="draft"
                    class={readOnly ? "submit ml_5 disabled" : "submit ml_5"}
                    disabled={readOnly}
                  >
                    {/* Save as Draft */}
                    {ApplicationViewPage.Draft_Button_Message}
                  </button>
                  <button
                    onClick={(e) => (DraftBtn = e.target.value)}
                    type="submit"
                    value=""
                    class={readOnly ? "submit ml_5 disabled" : "submit ml_5"}
                    disabled={readOnly}
                  >
                    {/* Save and Proceed */}
                    {ApplicationViewPage.Save_And_Proceed_Button_Message}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Home>
  );
};

export default EducationDetail;
