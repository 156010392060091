import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../utils";

const NotFound = () => {
  const navigate = useNavigate();


  return (
    <div>
      <div class="redirect_container">
        <div class="container_body signinredrct">
          <div class="redirectnotes">
            <h2>404. Error</h2>
            <h4 class="pb1">
              Page not found.
            </h4>
            <p className="notfound">
              The requested URL was not found on this server.
            </p>

          </div>
          <div class="form_styl">
            <div class="w100">
              <button
                value=""
                type="submit"
                class="submit ml_5"
                onClick={() => navigate(PATHS.DASHBOARD)}>
                Reload Page
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};



export default NotFound;