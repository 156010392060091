import React, { useEffect, useRef, useState } from "react";
import Header from "../header/Header";
import { PATHS } from "../../utils";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { StorageService } from "../../services/StorageService";
import {
  createTicket,
  getAllTickets,
  getFaqData,
} from "../../redux/actions/Api";
import Loader from "../Loader";
import Success from "../notification/Success";
import { toast } from "react-toastify";
import { CREATE_TICKET } from "../../redux/actions/Types";
import Error from "../notification/Error";
import { resetForms } from "../../redux/actions";
import FaqSideView from "./FaqSideView";

const RaiseQuery = () => {
  const {
    picklistdata,
    ticketResponse,
    createTicketRes,
    getFAQAllData,
    error,
  } = useSelector((state) => state.collections);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const [faqData, setFAQData] = useState();
  let formRef = useRef(null);

  useEffect(() => {
    if (error) {
      setLoader(false);
      toast.error(<Error error={error} />, {
        position: "top-center",
        autoClose: 3000,
        className: "Toastify__toast-theme--colored.Toastify__toast--warning",
      });
      setTimeout(() => {
        dispatch(resetForms());
      }, 3000);
    }
  }, [error]);

  useEffect(() => {
    if (getFAQAllData) {
      setLoader(false);
    }
  }, [getFAQAllData?.data]);

  useEffect(() => {
    let formData = {
      contactId: localStorage.getItem("contactId"),
    };
   
    if (formData) { 
      dispatch(getFaqData(formData));
    }
  }, []);

  useEffect(() => {
    if (!ticketResponse) {
      getTickets();
    } else {
      if (ticketResponse.success) {
        setLoader(false);
        dispatch({ type: CREATE_TICKET, payload: undefined });
      }
    }
  }, [ticketResponse]);

  useEffect(() => {
    if (createTicketRes?.success) {
      toast.success(<Success response={createTicketRes} />, {
        position: "top-center",
        autoClose: 500,
      });

      getTickets();
      dispatch({ type: CREATE_TICKET, payload: undefined });
    }
    setLoader(false);
  }, [createTicketRes]);

  const getTickets = () => {
    setLoader(true);
    let data = {
      contactId: StorageService.getContactId(),
    };

    dispatch(getAllTickets(data));
  };

  const [topic, setTopic] = useState("");
  const [program, setProgram] = useState("");
  const [subTopicData, setSubTopicData] = useState([]);
  const [subTopic, setSubTopic] = useState("");
  const [fileData, setFileData] = React.useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [base64Image, setBase64Image] = useState("");
  const [fileName, setFileName] = useState("");

  const handleTopic = (e) => {
    setTopic(e.target.value);
    setSubTopic("");
    const data = picklistdata.data.subTopic[e.target.value];
    setSubTopicData(data);
  };
  const handelSubTopic = (e) => {
    setSubTopic(e.target.value);
  };
  const handleProgram = (e) => {
    setProgram(e.target.value);
  };
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();
  //** doc upload */
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      const reader = new FileReader();
      reader.onloadend = () => {
        setBase64Image(reader.result);
      };
      reader.readAsDataURL(file);
      setSelectedImage(URL.createObjectURL(file));
    }
  };

  const _onSubmit = (values) => {
    document.getElementById("txtArea").value = "";
    document.getElementById("inpText").value = "";
    const fileBase64 = base64Image.split("data:image/jpeg;base64,");

    let data = {
      type: values.topic,
      programGroup: values.programGroup,
      subject: values.subject,
      description: values.description,
      subTopic: values.subTopic,
      contactId: StorageService.getContactId(),
      documents: [
        {
          fileName: fileName,
          base64: fileBase64[1] ? fileBase64[1] : null,
        },
      ],
    };

    setLoader(true);
    dispatch(createTicket(data));
    setTopic("");
    setSubTopicData("");
    setProgram("");
    setSelectedImage("");
    setFileName("");
    // reset()
  };

  const handleButtonClick = () => {
    fileInputRef?.current?.click();
  };

  return (
    <div>
      <Header />
      <Loader show={loader} />
      <div class="main_part">
        <div class="container_body flex">
          <div className="w30">
            <div class="form_styl1">
              <div class="w100">
                <h4>How can we help you?</h4>
              </div>
            </div>

            <form
              ref={(ref) => (formRef = ref)}
              onSubmit={handleSubmit(_onSubmit)}
            >
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    Topic <span className="redstyl"> *</span>
                  </label>
                  <select
                    {...register("topic", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    value={topic}
                    onChange={handleTopic}
                    name="topic"
                    className={`${errors.topic ? "error_msg" : ""}`}
                  >
                    <option value="">None</option>
                    {picklistdata?.data.topic.map((pickValue, index) => (
                      <option key={index} value={pickValue.value}>
                        {pickValue.key}
                      </option>
                    ))}
                  </select>
                  <div className="error_shown">{errors.topic?.message}</div>
                </div>
                <div class="inner_part">
                  <label>
                    Program Group <span className="redstyl"> *</span>
                  </label>
                  <select
                    {...register("programGroup", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    value={program}
                    onChange={handleProgram}
                    name="programGroup"
                    className={`${errors.programGroup ? "error_msg" : ""}`}
                  >
                    <option value="">None</option>
                    {picklistdata?.data.programmGroup.map(
                      (pickValue, index) => (
                        <option key={index} value={pickValue.Id}>
                          {pickValue.value}
                        </option>
                      )
                    )}
                  </select>
                  <div className="error_shown">
                    {errors.programGroup?.message}
                  </div>
                </div>
              </div>
              <div class="form_styl">
                <div class="inner_part w100">
                  <label>
                    Sub Topic <span className="redstyl"> *</span>
                  </label>
                  <select
                    {...register("subTopic", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    value={subTopic}
                    name="subTopic"
                    onChange={handelSubTopic}
                    className={`${errors.subTopic ? "error_msg" : ""}`}
                  >
                    <option value="">None</option>
                    {subTopicData.length > 0 &&
                      subTopicData.map((val) => (
                        <option value={val}>{val}</option>
                      ))}
                  </select>
                  <div className="error_shown">{errors.subTopic?.message}</div>
                </div>
              </div>
              <div class="form_styl">
                <div class="inner_part w100">
                  <label>
                    Subject <span className="redstyl"> *</span>
                  </label>
                  <textarea
                    id="inpText"
                    // type="text"
                    rows="4"
                    cols="50"
                    name="subject"
                    className={`${errors.subject ? "error_msg" : ""}`}
                    {...register("subject", {
                      required: {
                        value: true,
                        message: "Please enter value",
                      },
                    })}
                    maxLength={"255"}

                    // style={{resize: "vertical"}}
                  />
                  <div className="error_shown">{errors.subject?.message}</div>
                </div>
              </div>
              <div class="form_styl">
                <div class="inner_part w100">
                  <label>
                    Description <span className="redstyl"> *</span>
                  </label>
                  <textarea
                    type="text"
                    className={`${errors.description ? "error_msg" : ""}`}
                    {...register("description", {
                      required: {
                        value: true,
                        message: "Please enter value",
                      },
                    })}
                    name="description"
                    rows="4"
                    cols="50"
                    id="txtArea"
                  ></textarea>
                  <div className="error_shown">
                    {errors.description?.message}
                  </div>
                </div>
              </div>
              <div class="form_styl">
                <div class="inner_part uploadfile">
                  <div className="flex uploaddoc">
                    <span className="uploadfile" onClick={handleButtonClick}>
                      <i class="fa-solid fa-paperclip"></i> Upload File{" "}
                    </span>
                    <span>{fileName ? fileName : " "}</span>
                  </div>
                  <input
                    ref={fileInputRef}
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                  />
                </div>
              </div>
              <div class="form_styl">
                <div class="inner_part w100">
                  <button type="submit" class="submit">
                    Submit
                  </button>
                </div>
              </div>
              {selectedImage && (
                <div>
                  <h4>Selected File:</h4>
                  <img src={selectedImage} alt="Selected" className="myImage" />
                </div>
              )}
            </form>
          </div>

          <div className="w40">
            {ticketResponse?.data?.records?.length > 0 && (
              <div className="form_styl1">
                <div className="w100">
                  <h4 className="m-0">My Tickets</h4>
                  <table width="100%">
                    <thead>
                      <tr>
                        <th>Ticket Number</th>
                        <th>Status</th>
                        <th>Subject</th>
                        <th>Topic</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ticketResponse?.data?.records.map((data, index) => (
                        <tr>
                          <td key={index}>
                            <NavLink
                              to={PATHS.SHARE}
                              state={{ ticketResponse: data }}
                            >
                              {data?.Ticket_No__c}
                            </NavLink>
                          </td>
                          <td>{data.Status}</td>
                          <td>{data.Subject}</td>
                          <td>{data.Type}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
          <div className="w30">
            <FaqSideView />
            {/* <div className="form_styl1">
                            <div className="w100 faq">
                                <h4 className="m-0">Frequently Asked Questions</h4>

                            </div>
                        </div>
                        <div className="form_styl1">
                            <div className="w100 distance_learn">
                                {getFAQAllData &&
                                    <>
                                        {
                                            Object.keys(getFAQAllData?.data?.faqArticle).map((item, ind) => (
                                                <div>
                                                    <div>
                                                        <p onClick={() => {
                                                            navigate(PATHS.LISTFAQS,
                                                                { state: { faqData: getFAQAllData?.data?.faqArticle[item], headingName: item } }
                                                            )
                                                        }}>{item}</p>
                                                    </div>

                                                </div>

                                            ))
                                        }
                                    </>
                                }


                            </div>
                        </div>

                        <div className="form_styl1 mt-3">
                            <div className="w100 faq">
                                <h4 className="m-0">Topic Articles</h4>
                            </div>
                        </div>
                        <div className="form_styl1">
                            <div className="w100 topic_art">
                                {getFAQAllData &&
                                    <>
                                        {
                                            Object.keys(getFAQAllData?.data?.topicArticle).map((item, ind) => (


                                                <div>
                                                    <p><strong>{item} </strong> <i class="fa-solid fa-arrow-up-right-from-square"></i></p>
                                                    {getFAQAllData?.data?.topicArticle[item]?.map((item1, index) => (
                                                        <div className="sub_topic">

                                                            <p onClick={() => {

                                                                navigate(PATHS.FAQS,
                                                                    { state: { faqData: item1, headingName: item } }
                                                                )
                                                            }}
                                                            >{item1?.Title}</p>

                                                        </div>
                                                    ))}

                                                </div>

                                            ))
                                        }
                                    </>
                                }
                            </div>
                        </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RaiseQuery;
