

import React from "react";
import { Circles } from "react-loader-spinner";
const Loader = ({ show }) => {
    if (!show) return null;

    return (
        <div
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 2,
                backdropFilter: 'blur(8px)',
            }}
        >
            <div
                style={{
                    margin: "auto",
                    padding: "2%",
                    borderRadius: "10px",
                    boxShadow: "2px solid black",
                }}
            >
                <div
                    className="loaderstyl"></div>
            </div>
        </div >
    );
};

export default Loader;
