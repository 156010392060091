import React from "react";

const Success = ({response}) => {
    return(
        <div>
            <p>{response?.message}</p>
        </div>
    )
}

export default Success;