import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import { PATHS } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, } from "react-router-dom";
import { getCapital } from "../../utils/Helper";
import { getdashboardData, getPreferencePicklist, getugApplicationDetail } from "../../redux/actions/Api";
import { StorageService } from "../../services/StorageService";
import PreventBackNavigation from "../common/PreventBackNavigation";
import $ from 'jquery';
import { resetForms } from "../../redux/actions";


const RegistrationComplete = () => {
    const location = useLocation();
    const { dashboardresponse, registrationresponse } = useSelector((state) => state.collections);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [userDetail, setUserDetail] = useState("")
    const getRegisterData = JSON.parse(localStorage.getItem('registrationData'));

    // localStorage.removeItem('userData')

    useEffect(() => {
        // dispatch(getPreferencePicklist())
        // dispatch(getugApplicationDetail())
        let id = {
            contactId: localStorage.getItem("contactId")
        }
        dispatch(getdashboardData(id))
    }, [])

    useEffect(() => {
        if (dashboardresponse) {
            let data = dashboardresponse?.data?.allDataOnDashboard
            let data1 = data[data.length - 1]
            setUserDetail(data1)
            localStorage.setItem('userData', JSON.stringify(data1))
        } else {
            setUserDetail("")
        }
    }, [dashboardresponse])

    const programType = localStorage.getItem("application_type");
    const display_name = localStorage.getItem("display_name");

    const username = localStorage.getItem("applicant_name");
    const appNumber = localStorage.getItem("applicationNumber");
    const useremail = localStorage.getItem("application_email");
    const mobile = localStorage.getItem("application_mobile");
    const year = localStorage.getItem("applicationYear");

    useEffect(() => {
        function DisableBackButton() {
            window.history.forward()
        }
        DisableBackButton();
        window.onload = DisableBackButton;
        window.onpageshow = function (evt) { if (evt.persisted) DisableBackButton() }
        window.onload = function () { void (0) }
    }, [])

    const _onSubmit = () => {
        dispatch(resetForms());
        StorageService.APP_USERDATA = "RegistrationComplete"
        if (programType === 'Postgraduate Program') {
            navigate(PATHS.APPLICATIONDETAILPG_STR + userDetail?.appId, { state: { programName: programType, pageType: 'RegComplete' } })
        } else if (programType === "Undergraduate Program") {
            navigate(PATHS.APPLICATIONDETAILUG_STR + userDetail?.appId, { state: { programName: programType, pageType: 'RegComplete' } })
        } else {
            navigate(PATHS.APPLICATIONDETAILDIPLOMA_STR + userDetail?.appId, { state: { programName: programType, pageType: 'RegComplete' } })
        }
    }

    return (

        <div>
            {/* <Header /> */}
            <div class="main_part">
                <div class="container_body">
                    <div class="heading_styl">
                        <h4>Initial Registration Completed</h4>
                    </div>
                    <div className="w100">

                        <div class="reg_complete">
                            <div class="inner_part">
                                <p>Dear {username} {','}</p>
                                <p>Thank you for registering with Azim Premji University for {display_name} {" "}
                                    - {year}</p>
                                <p><strong>Application Number - {appNumber}</strong></p>
                                <p>An automated email and SMS has been sent to your registration email id
                                    <strong> {useremail}</strong> and mobile number
                                    <strong> {mobile}</strong>. Request you
                                    to make a note of this for future reference and use this registered email ID
                                    for logging into the portal and for future communication.
                                </p>
                                <p><strong>Wish you all the best</strong></p>
                                <p>Team Admissions</p>
                            </div>
                        </div>
                        <div className="text-center">
                            <button type="submit" className="Continue"
                                onClick={_onSubmit}>Continue</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>


    )
}

export default RegistrationComplete;
