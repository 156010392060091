import {
  PREVIEW_UG,
  DASHBOARD_DETAIL,
  ALL_NOTIFICATION,
  CREATEAPPLICATION_DETAIL_UG,
  SOCIECO_DETAIL_UG,
  FORGOT_PASSWORD,
  REGISTRATION,
  RESET_PASSWORD,
  USERSIGNIN,
  USERSIGNUP,
  CREATEEDUCATION_DETAIL_UG,
  CREATESOCIOECO_DETAIL_UG,
  UPLOAD_DOC_UG,
  LOGOUT,
  EDUCATION_DETAIL_PG,
  CREATEEDUCATION_DETAIL_PG,
  PICKLIST_DETAILS,
  UGAPPLICATION_DETAIL,
  UGEDUCATION_DETAIL,
  UGSOCIECO_DETAIL,
  TICKET_DETAILS,
  CREATE_TICKET,
  PREFERENCE_PICKLIST_VAL,
  ALL_NOTES,
  CREATE_DIPLOMA,
  CREATE_DIPLOMA_EDUCATION,
  DOC_DATA,
  GET_POST_CASE,
  POST_TICKET,
  POST_EXISTING_TICKET,
  FAQ_DATA,
  PAYMENT_DETAIL,
  CREATE_ORDER,
  SEND_PAYMENT_DETAIL,
  PASSWORDCHANGED,
  REGISTRATION_DATA,
  DELETE_EXPERIENCE,
} from "./Types";
import { getUnauthedAxios, getAxios } from "../../utils/Helper";
import { API_PATHS, PATHS } from "../../utils";
import { loginResponseFailure, responseFailure, signupResponseFailure } from ".";
import { StorageService } from "../../services/StorageService";

// signup api
export const userSignup = (userData) => async (dispatch) => {
  try {
    const instance = getUnauthedAxios();
    const { data } = await instance.post(`${API_PATHS.SIGNUP_API}`, userData);

    localStorage.setItem("email", data.email)    

    dispatch({ type: USERSIGNUP, payload: data });

    return data;
  } catch (error) {
    dispatch(signupResponseFailure(error))
  }
};

// signin api
export const userSignin = (formData) => async (dispatch) => {
  try {
    const instance = getUnauthedAxios();
    const { data } = await instance.post(`${API_PATHS.SIGNIN_API}`, formData);

    if (data) {
      StorageService.setUserDetail(data);
      localStorage.setItem("access_token", data.token);
      localStorage.setItem("contactId", data.data?.contact_id);
      localStorage.setItem("userId", data.data?.user_id);
      localStorage.setItem("first_name", data.data?.first_name);
      localStorage.setItem("last_name", data.data?.last_name);
      localStorage.setItem('email', data.data?.email);
      localStorage.setItem('selection_Status', data.data?.selectionStatus);
    }

    dispatch({ type: USERSIGNIN, payload: data })

    dispatch(getdashboardData({ contactId: data.data?.contact_id }))

    return data;
  } catch (error) {
    dispatch(loginResponseFailure(error));
  }
};

// LOGOUT API
export const logoutAction = () => async (dispatch) => {
  try {
    const instance = getAxios();
    const { data } = await instance.post(
      `${API_PATHS.LOGOUT_API}`,
    );
    dispatch({ type: LOGOUT, payload: data });
    if (data) {
      localStorage.clear();
      window.location.assign("/")
    }
  } catch (error) {
    dispatch(responseFailure(error))
  }
};

//FORGOT_API;
export const forgotPassword = (formData) => async (dispatch) => {
  try {
    if (formData) {
      const instance = getUnauthedAxios();
      const { data } = await instance.post(`${API_PATHS.FORGOT_API}`, formData);
      dispatch({ type: FORGOT_PASSWORD, payload: data });
    }
  } catch (error) {
    dispatch(responseFailure(error))
  }
};

// reset password
export const resetPassword = (formData, token) => async (dispatch) => {
  try {
    const instance = getAxios();
    if (formData && token) {
      const { data } = await instance.post(
        `${API_PATHS.SETPASSWORD_API}?token=${token}`,
        formData
      );

      dispatch({ type: RESET_PASSWORD, payload: data });
    }
  } catch (error) {
    dispatch(responseFailure(error))
  }
}

/********DASHBOARD*************** */
export const getdashboardData = (formData) => async (dispatch) => {
  try {
    if (formData) {
      const instance = getAxios();
      const { data } = await instance.post(
        `${API_PATHS.DASHBOARD_API}`,
        formData
      );

      localStorage.setItem("applicationId", data.data?.allDataOnDashboard[0]?.appId);

      dispatch({ type: DASHBOARD_DETAIL, payload: data });
    }
  } catch (error) {
    dispatch(responseFailure(error))
  }
};

/********NOTIFICATION*************** */
export const getNotifications = () => async (dispatch) => {
  try {
    const instance = getUnauthedAxios();
    const { data } = await instance.get(
      `${API_PATHS.NOTIFICATION_API}`);
    dispatch({ type: ALL_NOTIFICATION, payload: data });

  } catch (error) {
    dispatch(responseFailure(error))
  }
};

/********CREATE REGISTRATION*************** */
export const createRegistration = (formData) => async (dispatch) => {

  try {
    if (formData) {
      const instance = getAxios();
      const { data } = await instance.post(
        `${API_PATHS.USER_REGISTRATION_API}`,
        formData
      );
      dispatch({ type: REGISTRATION, payload: data });

    }
  } catch (error) {
    dispatch(responseFailure(error))
  }
};

/********APPLICATION DETAIL FORM*************** */
export const createApplication = (formData) => async (dispatch) => {
  try {
    if (formData) {
      const instance = getAxios();
      const { data } = await instance.post(
        `${API_PATHS.APPLICATIONDETAIL_API}`,
        formData
      );
      dispatch({ type: CREATEAPPLICATION_DETAIL_UG, payload: data });
    }
    else {
      dispatch({ type: CREATEAPPLICATION_DETAIL_UG, payload: undefined });
    }
  } catch (error) {
    dispatch(responseFailure(error))
  }
};

export const createTicket = (formData) => async (dispatch) => {
  try {
    if (formData) {
      const instance = getAxios();
      const { data } = await instance.post(
        `${API_PATHS.TICKET_CREATE}`,
        formData
      );
      dispatch({ type: CREATE_TICKET, payload: data });
    } else {
      dispatch({ type: CREATE_TICKET, payload: undefined });
    }
  } catch (error) {
    dispatch(responseFailure(error))
  }
};

// CREATE EDUCATION
export const createEducation = (formData) => async (dispatch) => {
  try {
    if (formData) {
      const instance = getAxios();
      const { data } = await instance.post(
        `${API_PATHS.EDUCATIONDETAIL_API}`,
        formData
      );
      dispatch({ type: CREATEEDUCATION_DETAIL_UG, payload: data });
    }
    else {
      dispatch({ type: CREATEEDUCATION_DETAIL_UG, payload: undefined });
    }
  } catch (error) {
    dispatch(responseFailure(error))
  }
};

// get socio-economic detail
export const getSocioEcoDetail = () => async (dispatch) => {
  try {
    const instance = getAxios();
    const { data } = await instance.get(
      `${API_PATHS.GETSOCIALECO_API}`
    );
    dispatch({ type: SOCIECO_DETAIL_UG, payload: data });
  } catch (error) {
    dispatch(responseFailure(error))
  }
};

// CREATE SOCIOECO
export const createSocioeco = (formData) => async (dispatch) => {
  try {
    if (formData) {
      const instance = getAxios();
      const { data } = await instance.post(
        `${API_PATHS.SOCIOECODETAIL_API}`,
        formData
      );
      dispatch({ type: CREATESOCIOECO_DETAIL_UG, payload: data });
    }
    else {
      dispatch({ type: CREATESOCIOECO_DETAIL_UG, payload: undefined });
    }
  } catch (error) {
    dispatch(responseFailure(error))
  }
};

// UPLOAD DOCUMENT
export const uploadDocument = (formData) => async (dispatch) => {
  try {
    if (formData) {
      const instance = getAxios();
      const { data } = await instance.post(
        `${API_PATHS.UPLOAD_API}`,
        formData
      );
      dispatch({ type: UPLOAD_DOC_UG, payload: data });
    }
    else {
      dispatch({ type: UPLOAD_DOC_UG, payload: undefined });
    }
  } catch (error) {
    dispatch(responseFailure(error))
  }
};

/********PREVIEW*************** */
export const getUgPreview = (formData) => async (dispatch) => {
  try {
    if (formData) {
      const instance = getAxios();
      const { data } = await instance.post(
        `${API_PATHS.PREVIEW_UG_API}`,
        formData
      );
      dispatch({ type: PREVIEW_UG, payload: data });
    }
  } catch (error) {
    dispatch(responseFailure(error))
  }
};

// Notes Data
export const getNotesData = () => async (dispatch) => {
  try {
    const instance = getUnauthedAxios();
    const { data } = await instance.get(
      `${API_PATHS.NOTES_API}`);
    dispatch({ type: ALL_NOTES, payload: data });

  } catch (error) {
    dispatch(responseFailure(error))
  }
};

// faq api

export const getFaqData = (formData) => async (dispatch) => {
  try {
    if (formData) {
    const instance = getAxios();
    const { data } = await instance.get(`${API_PATHS.FAQ_API}`, formData)
    dispatch({ type: FAQ_DATA, payload: data });
    }

  } catch (error) {
    dispatch(responseFailure(error))
  }
};

//get Doc Data
export const getDocData = (formData) => async (dispatch) => {
  try {
    if (formData) {
    const instance = getAxios();
    const { data } = await instance.post(
      `${API_PATHS.DOC_GET_API}`, formData);
    
    dispatch({ type: DOC_DATA, payload: data });
    }
  } catch (error) {
    dispatch(responseFailure(error))
  }
};

// CREATE EDUCATION pg
export const createEducationpg = (formData) => async (dispatch) => {
  try {
    if (formData) {
      const instance = getAxios();
      const { data } = await instance.post(
        `${API_PATHS.PG_EDUCATIONDETAIL_API}`,
        formData
      );
      dispatch({ type: CREATEEDUCATION_DETAIL_PG, payload: data });
    }
    else {
      dispatch({ type: CREATEEDUCATION_DETAIL_PG, payload: undefined });
    }
  } catch (error) {
    dispatch(responseFailure(error))
  }
};

/********PICKLIST*************** */
export const getPicklistdetails = () => async (dispatch) => {
  try {
    const instance = getUnauthedAxios();
    const { data } = await instance.get(
      `${API_PATHS.PICKLIST_API}`);
    dispatch({ type: PICKLIST_DETAILS, payload: data });

  } catch (error) {
    dispatch(responseFailure(error))
  }
};

//raise a query
export const getAllTickets = (formData) => async (dispatch) => {
  try {
    const instance = getAxios();
    const { data } = await instance.post(
      `${API_PATHS.GET_ALL_TICKETS}`, formData);
    dispatch({ type: TICKET_DETAILS, payload: data });

  } catch (error) {
    dispatch(responseFailure(error))
  }
};

export const getCaseResponse = (formData) => async (dispatch) => {
  try {
    const instance = getAxios();
    const { data } = await instance.post(
      `${API_PATHS.GET_CASE_TICKETS}`, formData);
    dispatch({ type: GET_POST_CASE, payload: data });

  } catch (error) {
    dispatch(responseFailure(error))
  }
};

export const postTicketRequest = (formData) => async (dispatch) => {
  try {
    const instance = getAxios();
    const { data } = await instance.post(
      `${API_PATHS.NEW_TICKETS_POST}`, formData);
    dispatch({ type: POST_TICKET, payload: data });

  } catch (error) {
    dispatch(responseFailure(error))
  }
};

export const postCommentTicketRequest = (formData) => async (dispatch) => {
  try {
    const instance = getAxios();
    const { data } = await instance.post(
      `${API_PATHS.EXISTING_TICKETS_POST}`, formData);
    dispatch({ type: POST_EXISTING_TICKET, payload: data });

  } catch (error) {
    dispatch(responseFailure(error))
  }
};

export const createOrder = (orderData) => async (dispatch) => {
  try {
    const instance = getAxios();
    const { data } = await instance.post(
      `${API_PATHS.CREATE_ORDER}`, orderData);
    dispatch({ type: CREATE_ORDER, payload: data });
    return data;

  } catch (error) {
    dispatch(responseFailure(error))
    Promise.reject(error);
  }
}


// GET API FOR UNDERGRADUATE
export const getugApplicationDetail = (formData) => async (dispatch) => {
  try {
    if (formData) {
      const instance = getAxios();
      const { data } = await instance.post(
        `${API_PATHS.GETAPPLICATIONDETAILUG_API}`,
        formData
      );
      dispatch({ type: UGAPPLICATION_DETAIL, payload: data });
    }
    else {
      dispatch({ type: UGAPPLICATION_DETAIL, payload: undefined });
    }
  } catch (error) {
    dispatch(responseFailure(error))
  }
};

export const getugEducationDetail = (formData) => async (dispatch) => {
  try {
    if (formData) {
      const instance = getAxios();
      const { data } = await instance.post(
        `${API_PATHS.GETEDUCATIONDETAILUG_API}`,
        formData
      );
      dispatch({ type: UGEDUCATION_DETAIL, payload: data });
    }
    else {
      dispatch({ type: UGEDUCATION_DETAIL, payload: undefined });
    }
  } catch (error) {
    dispatch(responseFailure(error))
  }
};

export const getpgEducationDetail = (formData) => async (dispatch) => {
  try {
    if (formData) {
      const instance = getAxios();
      const { data } = await instance.post(
        `${API_PATHS.GET_PG_EDUCATIONDETAIL_API}`,
        formData
      );
      dispatch({ type: EDUCATION_DETAIL_PG, payload: data });
    }
    else {
      dispatch({ type: EDUCATION_DETAIL_PG, payload: undefined });
    }
  } catch (error) {
    dispatch(responseFailure(error))
  }
};

export const getugSocioEcoDetail = (formData) => async (dispatch) => {
  try {
    if (formData) {
      const instance = getAxios();
      const { data } = await instance.post(
        `${API_PATHS.GETSOCIOECODETAILUG_API}`,
        formData
      );
      dispatch({ type: UGSOCIECO_DETAIL, payload: data });
    }
    else {
      dispatch({ type: UGSOCIECO_DETAIL, payload: undefined });
    }
  } catch (error) {
    dispatch(responseFailure(error))
  }
};

export const getPreferencePicklist = (formData) => async (dispatch) => {
  try {
    if (formData) {
      const instance = getAxios();
      const { data } = await instance.post(
        `${API_PATHS.PREFERENCE_PICKLIST}`,
        formData
      );
      dispatch({ type: PREFERENCE_PICKLIST_VAL, payload: data });
    }
    else {
      dispatch({ type: PREFERENCE_PICKLIST_VAL, payload: "" });
    }
  } catch (error) {
    dispatch(responseFailure(error))
  }
};

/********CREATE APP DIPLOMA*************** */
export const createApplDiploma = (formData) => async (dispatch) => {

  try {
    if (formData) {
      const instance = getAxios();
      const { data } = await instance.post(
        `${API_PATHS.CREATE_DIPLOMA_API}`,
        formData
      );
      dispatch({ type: CREATE_DIPLOMA, payload: data });
    }
  } catch (error) {
    dispatch(responseFailure(error))
  }
};

/********CREATE EDU DIPLOMA*************** */
export const createEduDiploma = (formData) => async (dispatch) => {
  try {
    if (formData) {
      const instance = getAxios();
      const { data } = await instance.post(
        `${API_PATHS.CREATE_DIPLOMAEDUCATION_API}`,
        formData
      );
      dispatch({ type: CREATE_DIPLOMA_EDUCATION, payload: data });
    }
  } catch (error) {
    dispatch(responseFailure(error))
  }
};


/** create payment */

export const sendPaymentData = (formData) => async (dispatch) => {
  try {
    if (formData) {
      const instance = getAxios();
      const { data } = await instance.post(
        `${API_PATHS.PAYMENT_ORDER}`,
        formData
      );
      dispatch({ type: SEND_PAYMENT_DETAIL, payload: data });
    }
    else {
      dispatch({ type: SEND_PAYMENT_DETAIL, payload: undefined });
    }
  } catch (error) {
    dispatch(responseFailure(error))
  }
};

//** get Payment */
export const getPaymentsDetails = (formData) => async (dispatch) => {
  try {
    if (formData) {
      const instance = getAxios();
      const { data } = await instance.post(
        `${API_PATHS.GET_PAYMENT_API}`,
        formData
      );
      dispatch({ type: PAYMENT_DETAIL, payload: data });
    }
    else {
      dispatch({ type: PAYMENT_DETAIL, payload: undefined });
    }
  } catch (error) {
    dispatch(responseFailure(error))
  }
};

// getLastPassword change
export const getLastPasswordDetails = (token) => async (dispatch) => {
  try {
    const instance = getAxios();
    if (token) {
      const { data } = await instance.get(
        `${API_PATHS.PASSWORDCHANGED_API}?token=${token}`
      ); 
      dispatch({ type: PASSWORDCHANGED, payload: data });
    }
  } catch (error) {
    dispatch(responseFailure(error))
  }
}

// get registration data
export const getRegistrationData = (formData) => async (dispatch) => {
  try {
    if (formData) {
      const instance = getAxios();
      const { data } = await instance.post(
        `${API_PATHS.GETREGISTRATION_API}`, formData
      );
      dispatch({ type: REGISTRATION_DATA, payload: data });
    }
  } catch (error) {
    dispatch(responseFailure(error))
  }
};

/********DELETE WORK EXPERIENCE*************** */
export const deleteWorkExperience = (formData) => async (dispatch) => {
  try {
    if (formData) {
      const instance = getAxios();
      const { data } = await instance.post(
        `${API_PATHS.DELETE_WORK_EXP_API}`, formData
      );
      dispatch({ type: DELETE_EXPERIENCE, payload: data });
    } else {
      dispatch({ type: DELETE_EXPERIENCE, payload: "" });
    }
  } catch (error) {
    dispatch(responseFailure(error))
  }
};