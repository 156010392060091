import React, { useEffect, useState } from "react";
import Home from "../Home";
import { Controller, useForm } from "react-hook-form";
import { Tooltip } from "react-tooltip";
import { HELP_TEXT, PATHS } from "../../utils";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Error from "../notification/Error";
import { resetForms, unsavedchanges } from "../../redux/actions";
import Success from "../notification/Success";
import { createSocioeco, getugSocioEcoDetail } from "../../redux/actions/Api";
import { PERCENT } from "../../utils/Regex";
import { Notes } from "../common/Notes";
import $ from "jquery";
import { getCapital, getCapitalized, numberToWords } from "../../utils/Helper";
import { FAMINCOME } from "../../utils/DataObjects";
import Loader from "../Loader";
import {
  AppCommon,
  ApplicationViewPage,
  PGSocialEcoDetails,
} from "../constantLabelFile";
import Select from "react-select";

let DraftBtn = "";
let numToWrd = "";
let natureDis = [];

const SocialEco = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    createsociores,
    picklistdata,
    ugsociodata,
    error,
    dashboardresponse,
  } = useSelector((state) => state.collections);
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    control,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
  });

  const [disability, setDisability] = useState("");
  const [loader, setLoader] = useState(true);
  const [pickListVal, setPickListVal] = useState("");

  const [fedu, setFedu] = useState("");
  const [medu, setMedu] = useState("");
  const [religionState, setReligionState] = useState("");
  const [casteState, setCasteState] = useState("");
  const [famoccuState, setFamoccuStateState] = useState("");
  const [famlIncomeState, setFamlIncomeState] = useState("");
  const [disaNature, setDisaNature] = useState("");
  const [socioecoData, setSocioecoData] = useState("");
  const [readOnly, setReadOnly] = useState(false);
  const [showMsg, setShowMsg] = useState("");
  const [numToWords, setNumToWords] = useState("");
  const [skills, setSkills] = useState([]);
  const [needSupport, setNeedSupport] = useState("");
  const [selectedOption, setSelectedOption] = useState(null); // state for selected value

  useEffect(() => {
    if (ugsociodata == undefined) {
      setLoader(true);
    } else {
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  }, [ugsociodata]);

  useEffect(() => {
    if (error) {
      setLoader(false);
      toast.error(<Error error={error} />, {
        position: "top-center",
        autoClose: 5000,
        className: "Toastify__toast-theme--colored.Toastify__toast--warning",
      });
      setTimeout(() => {
        dispatch(resetForms());
      }, 5000);
    }
  }, [error]);

  useEffect(() => {
    let formData = {
      contactId: localStorage.getItem("contactId"),
      applicationId: localStorage.getItem("applicationId"),
    };
    if (formData) {
      dispatch(getugSocioEcoDetail(formData));
    }
  }, []);

  useEffect(() => {
    if (ugsociodata) {
      setSocioecoData(ugsociodata.data?.records[0]);
    }
  }, [ugsociodata]);

  useEffect(() => {
    if (socioecoData?.Are_you_a_person_with_disability__c !== null) {
      setValue("fatherEdu", socioecoData?.Father_Education_Details__c);
      setFedu(socioecoData?.Father_Education_Details__c);
      setValue("motherEdu", socioecoData?.Mother_Education_Details__c);
      setMedu(socioecoData?.Mother_Education_Details__c);
      setValue("caste", socioecoData?.Caste__c);
      setCasteState(socioecoData?.Caste__c);
      setValue("religion", socioecoData?.hed__Religion__c);
      setReligionState(socioecoData?.hed__Religion__c);
      setValue("famlOccupation", socioecoData?.Main_Occupation_of_family__c);
      setFamoccuStateState(socioecoData?.Main_Occupation_of_family__c);
      setValue("famlIncome", socioecoData?.Family_income_per_year__c);
      setFamlIncomeState(socioecoData?.Family_income_per_year__c);
      setValue("annualIncome", socioecoData?.Exact_Annual_income__c);
      setNumToWords(numberToWords(parseInt(socioecoData?.Exact_Annual_income__c)));
      setValue("financialAssis", socioecoData?.Do_you_need_Financial_Assistance__c);
      setSelectedOption(socioecoData?.Do_you_need_Financial_Assistance__c);
      setValue("disability", socioecoData?.Are_you_a_person_with_disability__c);
      setDisability(socioecoData?.Are_you_a_person_with_disability__c);
      setValue("disabilityNature", socioecoData?.Nature_of_Disability__c);
      setDisaNature(socioecoData?.Nature_of_Disability__c);
      setValue("specifyNature", socioecoData?.Please_Specify_Disability__c);
      setValue(
        "scribe",
        socioecoData?.Is_scribe_needed_for_the_writing_test__c);
      setValue(
        "needSpecificSupport",
        socioecoData?.APU_need_any_specific_support__c);
      setSkills(socioecoData?.APU_need_any_specific_support__c);
      setValue(
        "needSupportWrite",
        socioecoData?.APU_need_any_support_to_write_test__c);
      setNeedSupport(socioecoData?.APU_need_any_support_to_write_test__c);
      setValue(
        "supportExpecting",
        socioecoData?.What_kind_of_support_u_r_expecting__c);
      setValue(
        "moreDetails",
        socioecoData?.Do_you_need_any_help_from_Admission_Team__c );
    }
  }, [socioecoData]);

  useEffect(() => {
    setPickListVal(picklistdata?.data);
  }, [picklistdata]);

  const handleDisability = (e) => {
    setDisability(e.target.value);
    if (e.target.value === "No") {
      setValue("disabilityNature", "");
      setValue("specifyNature", "");
      setValue("scribe", "");
      setValue("specificSupport", "");
      setValue("needSpecificSupport", "");
      setValue("needSupportWrite", "");
      setValue("supportExpecting", "");
      setValue("moreDetails", "");
    }
    dispatch(unsavedchanges(e.target.value));
    clearErrors("disability");
  };

  const handleSupport = (e) => {
    setNeedSupport(e.target.value);
    if (e.target.value === "No") {
      setValue("needSpecificSupport", "");
      setValue("supportExpecting", "");
    }
    dispatch(unsavedchanges(e.target.value));
    clearErrors("needSupportWrite");
  };

  const _onSubmit = (values) => {   
    let formData = {
      userId: localStorage.getItem("userId"),
      contactId: localStorage.getItem("contactId"),
      applicationId: localStorage.getItem("applicationId"),
      fatherEdu: values.fatherEdu,
      motherEdu: values.motherEdu,
      caste: values.caste,
      religion: values.religion,
      famlOccupation: values.famlOccupation,
      famlIncome: values.famlIncome,
      annualIncome: parseInt(values.annualIncome),
      financialAssis: selectedOption,
      disability: values.disability,
      disabilityNature: disability === "No" ? "" : values.disabilityNature,
      specifyNature:
        disability === "No"
          ? ""
          : values.disabilityNature === "Others"
          ? values.specifyNature
          : "",
      needSupportWrite: disability === "No" ? "" : values.needSupportWrite,
      needSpecificSupport:
        values.needSupportWrite === "No" ? "" : values.needSpecificSupport,
      supportExpecting:
        values.needSupportWrite === "No" ? "" : values.supportExpecting,
      moreDetails: disability === "No" ? "" : values.moreDetails,
      sponsorProgrammeFee: "",
      finalInterviewResult: values.financialAssis === "No" ? "NFA" : "",
    };

    if (formData) {
      setLoader(true);
      dispatch(createSocioeco(formData));
    }
  };

  let draftMsg = {
    message: "Details Saved Successfully.",
  };

  useEffect(() => {
    if (createsociores) {
      dispatch(unsavedchanges());
      toast.success(
        <Success response={!DraftBtn ? createsociores : draftMsg} />,
        {
          position: "top-center",
          autoClose: 2000,
        }
      );
      setLoader(false);
      if (!DraftBtn) {
        setTimeout(() => {
          navigate(
            PATHS.UPLOADPHOTOPG_STR +
              dashboardresponse?.data?.allDataOnDashboard[0]?.appId
          );
        }, 500);
      } else if (DraftBtn) {
        setTimeout(() => {
          draftMsg.message = "";
        }, 500);
      }
    }
  }, [createsociores]);

  const currentDate = new Date();
  const year1 = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDate = `${year1}-${month}-${day}`;

  useEffect(() => {
    if (
      socioecoData?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Started" ||
      socioecoData?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Draft" ||
      socioecoData?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Payment Failed" ||
      socioecoData?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Payment Pending" ||
      socioecoData?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Payment Submitted"
    ) {
      if (
        formattedDate >
        socioecoData?.hed__Applications_Applicant__r?.records[0]
          ?.Admission_Cycle__r?.Application_Submission_Date__c
      ) {
        setReadOnly(true);
        setShowMsg(<Notes name="ADM_Cycle_Validation_App" />);
      } else {
        setReadOnly(false);
        setShowMsg("");
      }
    } else {
      if (
        formattedDate <
        socioecoData?.hed__Applications_Applicant__r?.records[0]
          ?.Admission_Cycle__r?.Application_Submission_Date__c
      ) {
        setReadOnly(true);
        setShowMsg("");
      } else {
        setReadOnly(true);
        setShowMsg(<Notes name="ADM_Cycle_Validation_App" />);
      }
    }
  }, [socioecoData]);

  const handleBlur = (nbmr) => {
    setNumToWords(numberToWords(nbmr));
  };

  useEffect(()=>{
    if(pickListVal){   
    natureDis =  pickListVal?.disabilityNature;
    natureDis?.forEach(function(v, i) {
      if (v.Id == 'Others') {
        natureDis?.push(natureDis[i]);
        natureDis?.splice(i, 1);
      }
    });
  }},[natureDis, pickListVal])

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
    dispatch(unsavedchanges(event.target.value));
    clearErrors("financialAssis");
  };

  return (
    <Home>
      <div class="right_panel">
        <div class="w100">
          <p className="top_head_styl">{showMsg}</p>
          <h4 className="top_head">
            {getCapitalized(PGSocialEcoDetails.Postgraduate_Programme)}
          </h4>
          <span className="help_link">
            <p>
              <NavLink to={PATHS.RAISEQUERY}>{HELP_TEXT}</NavLink>{" "}
              <Tooltip id="helpTooltip">
                <p>{ApplicationViewPage.Help_Tooltip_Text}</p>
              </Tooltip>
              <i
                data-tooltip-id="helpTooltip"
                class="fa-solid fa-circle-info"
              ></i>
            </p>
          </span>
        </div>

        <div className="w100 scroll_styl">
          <div class="heading_styl head_font">
            <h4>{PGSocialEcoDetails.Socio_Economic_Background}</h4>
          </div>
          <Loader show={loader} />
          <form
            onSubmit={handleSubmit(_onSubmit)}
          >
            <div class="form_sec form_part">
              <div class="form_styl">
                <div class="w100">
                  <p>
                    <Notes name="SED" />
                  </p>
                </div>
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {PGSocialEcoDetails.Education_Details_Father}{" "}
                    <span class="redstyl">* </span>{" "}
                    <Tooltip id="fedu">
                      <p>
                        {
                          PGSocialEcoDetails.Education_Details_Father_Tooltip_Message
                        }
                      </p>
                    </Tooltip>
                    <i
                      data-tooltip-id="fedu"
                      class="fa-solid fa-circle-info"
                    ></i>
                  </label>
                  <select
                    {...register("fatherEdu", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    disabled={readOnly}
                    className={`${errors.fatherEdu ? "error_msg" : ""}`}
                    value={fedu}
                    onChange={(e) => {
                      setFedu(e.target.value);
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("fatherEdu");
                    }}
                  >
                    <option selected value="">
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.fatherEduDetails?.map((soci, ind) => (
                        <option value={soci.value}>{soci.value}</option>
                      ))}
                  </select>
                  {errors.fatherEdu && (
                    <div className="error_shown">
                      {errors.fatherEdu?.message}
                    </div>
                  )}
                </div>
                <div class="inner_part">
                  <label>
                    {PGSocialEcoDetails.Education_Details_Mother}{" "}
                    <span class="redstyl">* </span>{" "}
                    <Tooltip id="medu">
                      <p>
                        {
                          PGSocialEcoDetails.Education_Details_Mother_Tooltip_Message
                        }
                      </p>
                    </Tooltip>
                    <i
                      data-tooltip-id="medu"
                      class="fa-solid fa-circle-info"
                    ></i>
                  </label>
                  <select
                    {...register("motherEdu", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    disabled={readOnly}
                    className={`${errors.motherEdu ? "error_msg" : ""}`}
                    value={medu}
                    onChange={(e) => {
                      setMedu(e.target.value);
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("motherEdu");
                    }}
                  >
                    <option selected value="">
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.motherEduDetails?.map((soci, ind) => (
                        <option value={soci.value}>{soci.value}</option>
                      ))}
                  </select>
                  {errors.motherEdu && (
                    <div className="error_shown">
                      {errors.motherEdu?.message}
                    </div>
                  )}
                </div>
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {PGSocialEcoDetails.Caste} <span class="redstyl">* </span>
                  </label>
                  <select
                    {...register("caste", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    disabled={readOnly}
                    className={`${errors.caste ? "error_msg" : ""}`}
                    value={casteState}
                    onChange={(e) => {
                      setCasteState(e.target.value);
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("caste");
                    }}
                  >
                    <option selected value="">
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.caste?.map((soci, ind) => (
                        <option value={soci.value}>{soci.value}</option>
                      ))}
                  </select>
                  {errors.caste && (
                    <div className="error_shown">{errors.caste?.message}</div>
                  )}
                </div>
                <div class="inner_part">
                  <label>
                    {PGSocialEcoDetails.Religion}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <select
                    {...register("religion", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    disabled={readOnly}
                    className={`${errors.religion ? "error_msg" : ""}`}
                    value={religionState}
                    onChange={(e) => {
                      setReligionState(e.target.value);
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("religion");
                    }}
                  >
                    <option selected value="">
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.religion?.map((soci, ind) => (
                        <option value={soci.value}>{soci.value}</option>
                      ))}
                  </select>
                  {errors.religion && (
                    <div className="error_shown">
                      {errors.religion?.message}
                    </div>
                  )}
                </div>
              </div>

              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {PGSocialEcoDetails.Family_Occupation_Main}{" "}
                    <span class="redstyl">* </span>{" "}
                    <Tooltip id="famocc">
                      <p>
                        {
                          PGSocialEcoDetails.Family_Occupation_Main_Tooltip_Message
                        }
                      </p>
                    </Tooltip>
                    <i
                      data-tooltip-id="famocc"
                      class="fa-solid fa-circle-info"
                    ></i>
                  </label>
                  <select
                    {...register("famlOccupation", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    disabled={readOnly}
                    className={`${errors.famlOccupation ? "error_msg" : ""}`}
                    value={famoccuState}
                    onChange={(e) => {
                      setFamoccuStateState(e.target.value);
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("famlOccupation");
                    }}
                  >
                    <option selected value="">
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.familyOccupation?.map((soci, ind) => (
                        <option value={soci.Id}>{soci.value}</option>
                      ))}
                  </select>
                  {errors.famlOccupation && (
                    <div className="error_shown">
                      {errors.famlOccupation?.message}
                    </div>
                  )}
                </div>
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {PGSocialEcoDetails.Family_Income_Excluding_Self}{" "}
                    <span class="redstyl">* </span>{" "}
                    <Tooltip id="famin">
                      <p>
                        {
                          PGSocialEcoDetails.Family_Income_Excluding_Self_Tooltip_Message
                        }
                      </p>
                    </Tooltip>
                    <i
                      data-tooltip-id="famin"
                      class="fa-solid fa-circle-info"
                    ></i>
                  </label>
                  <select
                    {...register("famlIncome", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    className={`${errors.famlIncome ? "error_msg" : ""}`}
                    disabled={readOnly}
                    value={famlIncomeState}
                    onChange={(e) => {
                      setFamlIncomeState(e.target.value);
                      dispatch(unsavedchanges(e.target.value));
                      clearErrors("famlIncome");
                    }}
                  >
                    <option selected value="">
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {FAMINCOME &&
                      FAMINCOME?.map((soci, ind) => (
                        <option value={soci.value}>{soci.value}</option>
                      ))}
                  </select>
                  {errors.famlIncome && (
                    <div className="error_shown">
                      {errors.famlIncome?.message}
                    </div>
                  )}
                </div>
                <div class="inner_part">
                  <p>
                    <Notes
                      name="Family_Income_Per_Year_Help"
                      size={"0.73rem"}
                    />
                  </p>
                </div>
              </div>
              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {PGSocialEcoDetails.Annual_Income}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <input
                    {...register("annualIncome", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                      pattern: {
                        value: PERCENT,
                        message: "Please enter number only",
                      },
                    })}
                    autoComplete="off"
                    onChange={(e) => {
                      clearErrors("annualIncome");
                      dispatch(unsavedchanges(e.target.value));
                      numToWrd = e.target.value;
                      handleBlur(numToWrd);
                    }}
                    className={`${errors.annualIncome ? "error_msg" : ""}`}                  
                    maxLength={"11"}
                    disabled={readOnly}
                  />

                  {errors.annualIncome && (
                    <div className="error_shown">
                      {errors.annualIncome?.message}
                    </div>
                  )}
                  <p>{getCapital(numToWords)}</p>
                </div>
              </div>

              <div class="form_styl mt-3">
                <div class="inner_part">
                  <label className="display_inblock">
                    {PGSocialEcoDetails.Financial_Assistance_Question}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  {pickListVal &&
                    pickListVal?.needFinancialAssistance.map((fin, ind) => (
                      <span class="flex">
                        <label key={ind}>
                          <input
                            type="radio"
                            {...register("financialAssis", {
                              required: {
                                value: true,
                                message: "Please select value",
                              },
                            })}
                            value={fin.value}
                            onChange={(e) => {
                              handleRadioChange(e);
                            }}
                            disabled={readOnly}
                            className={`${
                              errors.financialAssis ? "error_msg" : ""
                            }`}
                          />
                          {fin.value}
                        </label>
                      </span>
                    ))}
                  {errors.financialAssis && (
                    <div className="error_shown">
                      {errors.financialAssis?.message}
                    </div>
                  )}
                </div>
                <div class="inner_part">
                  <Notes name="Financial_Assistance_PG1" size={"0.73rem"} />
                  <Notes name="Financial_Assistance_PG" size={"0.73rem"} />
                </div>
              </div>

              <div class="form_styl">
                <div class="inner_part">
                  <label>
                    {PGSocialEcoDetails.Disability_Question}{" "}
                    <span class="redstyl">* </span>
                  </label>
                  <select
                    {...register("disability", {
                      required: {
                        value: true,
                        message: "Please select value",
                      },
                    })}
                    disabled={readOnly}
                    className={`${errors.disability ? "error_msg" : ""}`}
                    value={disability}
                    onChange={(e) => handleDisability(e)}
                  >
                    <option value="">
                      {AppCommon.Dropdown_Default_Option}
                    </option>
                    {pickListVal &&
                      pickListVal?.isDisable.map((soci, ind) => (
                        <option value={soci.value}>{soci.value}</option>
                      ))}
                  </select>
                  {errors.disability && (
                    <div className="error_shown">
                      {errors.disability?.message}
                    </div>
                  )}

                  {disability === "Yes" && (
                    <>
                      <label>
                        {PGSocialEcoDetails.Disability_Nature}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <select
                        {...register("disabilityNature", {
                          required: {
                            value: true,
                            message: "Please select value",
                          },
                        })}
                        disabled={readOnly}
                        className={`${
                          errors.disabilityNature ? "error_msg" : ""
                        }`}
                        value={disaNature}
                        onChange={(e) => {
                          setDisaNature(e.target.value);
                          dispatch(unsavedchanges(e.target.value));
                          clearErrors("disabilityNature");
                        }}
                      >
                        <option disabled selected value="">
                          {AppCommon.Dropdown_Default_Option}
                        </option>
                        {natureDis &&
                          natureDis?.map((soci, ind) => (
                            <option value={soci.value}>{soci.value}</option>
                          ))}
                      </select>
                      {errors.disabilityNature && (
                        <div className="error_shown">
                          {errors.disabilityNature?.message}
                        </div>
                      )}

                      {disaNature === "Others" && (
                        <>
                          <label>
                            {PGSocialEcoDetails.Please_Specify}{" "}
                            <span class="redstyl">* </span>
                          </label>
                          <input
                            {...register("specifyNature", {
                              required: {
                                value: true,
                                message: "Please select value",
                              },
                            })}
                            autoComplete="off"
                            disabled={readOnly}
                            className={`${
                              errors.specifyNature ? "error_msg" : ""
                            }`}
                          />
                          {errors.specifyNature && (
                            <div className="error_shown">
                              {errors.specifyNature?.message}
                            </div>
                          )}
                        </>
                      )}

                      <label>
                        {PGSocialEcoDetails.Need_Support_Question}{" "}
                        <span class="redstyl">* </span>
                      </label>
                      <select
                        {...register("needSupportWrite", {
                          required: {
                            value: true,
                            message: "Please select value",
                          },
                        })}
                        disabled={readOnly}
                        className={`${
                          errors.needSupportWrite ? "error_msg" : ""
                        }`}
                        value={needSupport}
                        onChange={(e) => {
                          handleSupport(e);
                        }}
                      >
                        <option disabled selected value="">
                          {AppCommon.Dropdown_Default_Option}
                        </option>
                        {pickListVal &&
                          pickListVal?.needAnyWriteSupport?.map((soci, ind) => (
                            <option value={soci.value}>{soci.value}</option>
                          ))}
                      </select>
                      {errors.needSupportWrite && (
                        <div className="error_shown">
                          {errors.needSupportWrite?.message}
                        </div>
                      )}
                    
                      {needSupport === "Yes" &&
                      <>
                        <br/>
                      <div>
                        <Notes name="Disability_specific_support" />
                      </div>
                      </>
}

                      {needSupport === "Yes" && (
                        <>
                          <label>
                            {PGSocialEcoDetails.Test_Support_Question}{" "}
                            <span class="redstyl">* </span>
                          </label>

                          <Controller
                            className={`${
                              errors.needSpecificSupport ? "error_msg" : ""
                            }`}
                            {...register("needSpecificSupport", {
                              required: {
                                value: true,
                                message: "Please select a value",
                              },
                            })}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              const supportValue = value
                                ? value.split(";").map((val) => ({
                                    value: val.trim(),
                                    label: val.trim(),
                                  }))
                                : [];
                              return (
                                <Select menuShouldScrollIntoView ={false}
                                  options={pickListVal?.needAnySpecificSupport}
                                  onChange={(e) => {
                                    const selectedValues = e
                                      .map((opt) => opt.value)
                                      .join(";");
                                    onChange(selectedValues);
                                    setSkills(e || []);
                                    setValue(
                                      "needSpecificSupport",
                                      selectedValues
                                    );
                                    dispatch(unsavedchanges(selectedValues));
                                    clearErrors("needSpecificSupport");
                                  }}
                                  value={supportValue}
                                  isMulti
                                />
                              );
                            }}
                          />
                          {errors.needSpecificSupport && (
                            <div className="error_shown">
                              {errors.needSpecificSupport?.message}
                            </div>
                          )}

                          <label>
                            {PGSocialEcoDetails.Describe_Support_Question}
                          </label>

                          <input
                            type="text"
                            placeholder=""
                            {...register("supportExpecting")}
                            autoComplete="off"
                            onChange={(e) => {
                              dispatch(unsavedchanges(e.target.value));
                              clearErrors("supportExpecting");
                            }}
                            disabled={readOnly}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
                <div class="inner_part">
                  <Notes name="Disability" />
                  <Notes name="Disability_privacy" />
                </div>
              </div>

              {disability === "Yes" && (
                <div class="form_styl">
                  <div class="inner_part">
                    <label className="display_inblock">
                      {PGSocialEcoDetails.Disability_Details}{" "}
                      <span class="redstyl">* </span>
                    </label>
                    <input
                      type="text"
                      placeholder=""
                      {...register("moreDetails", {
                        required: {
                          value: true,
                          message: "Please select value",
                        },
                      })}
                      autoComplete="off"
                      onChange={(e) => {
                        dispatch(unsavedchanges(e.target.value));
                        clearErrors("moreDetails");
                      }}
                      disabled={readOnly}
                      className={`${errors.moreDetails ? "error_msg" : ""}`}
                    />
                    {errors.moreDetails && (
                      <div className="error_shown">
                        {errors.moreDetails?.message}
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div class="form_styl">
                <div class="w100">
                  <button
                    onClick={(e) => (DraftBtn = e.target.value)}
                    value="draft"
                    type="submit"
                    class={readOnly ? "submit ml_5 disabled" : "submit ml_5"}
                    disabled={readOnly}
                  >
                    {ApplicationViewPage.Draft_Button_Message}
                  </button>
                  <button
                    onClick={(e) => (DraftBtn = e.target.value)}
                    value=""
                    type="submit"
                    class={readOnly ? "submit ml_5 disabled" : "submit ml_5"}
                    disabled={readOnly}
                  >
                    {ApplicationViewPage.Save_And_Proceed_Button_Message}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Home>
  );
};

export default SocialEco;
