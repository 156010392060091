/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState } from "react";
import Home from "../Home";
import { convertBase64, getCapitalized, convertPDFBase64 } from "../../utils/Helper";
import { getDocData, uploadDocument } from "../../redux/actions/Api";
import { useDispatch, useSelector } from "react-redux";
import { HELP_TEXT, PATHS } from "../../utils";
import { NavLink, useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { toast } from "react-toastify";
import Success from "../notification/Success";
import Loader from "../Loader";
import { UPLOAD_DOC_UG } from "../../redux/actions/Types";
import { unsavedchanges } from "../../redux/actions";
import { Notes } from "../common/Notes";
import {
  ApplicationViewPage,
  DiplomaUploadDocuments,
  PGUploadDocuments,
} from "../constantLabelFile";

let doc1;
let doc2;
let filterPDF;
let pdfPath;
let imagePath;
let setImage ;
let setCuetImage;
let setDisabilityImage;
let setFileName;
let setPdfFileName;
let setPdfFileNameDisability;
let pdfLink;
let pdfDisability;
const UploadPhotoDiploma = () => {
  const fileInputRef = useRef(null);
  const fileDisabilityInputRef = useRef(null);

  const navigate = useNavigate();
  const [cuetImage, setCuetImage] = useState();
  const [image, setImage] = React.useState("");
  const imageRef = React.useRef(null);
  const [rslt, setResult] = React.useState("");
  const [loader, setLoader] = useState(true);
  const [cuetYear, setCuetYear] = useState("");
  const [cuetLang, setCuetLang] = useState("");
  const [doc, setDocData] = React.useState("");
  const [readOnly, setReadOnly] = useState(false);
  const [showMsg, setShowMsg] = useState("");
  const [appIds, setAppID] = useState("");
  const [appNumber, setAppNumber] = useState("");

  const [prgLabelname, setPrglabelname] = useState();
  const [disability, setDisability] = useState();

  const [photoGraphData, setPhotoGraphData] = useState('');
  const [disabilityData, setDisabilityData] = useState('');


  const dispatch = useDispatch();

  const { uploaddocres, docData, error } = useSelector(
    (state) => state.collections
  );

  useEffect(() => {
    if (
      error?.message === "Unauthorized: Invalid or expired token." ||
      error?.validToken === false
    ) {
      setLoader(false);
    }
  }, [error]);

  useEffect(() => {
    const appID = localStorage.getItem("userData");
    const pasrseData = JSON.parse(appID);
    setAppNumber(pasrseData?.appNumber);

    setAppID(pasrseData?.appId);
    setPrglabelname(pasrseData);
  }, [localStorage.getItem("userData")]);

  useEffect(() => {
    if (docData == undefined) {
      setLoader(true);
    } else {
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  }, [docData]);

  useEffect(() => {
    if (docData) {
      setPhotoGraphData(docData?.data?.photoGraphFile)
      setDisabilityData(docData?.data?.disabiltyFile)



      doc1 =  docData?.data?.photoGraphFile;
      doc2 = docData?.data?.disabiltyFile;
      setDisability(docData?.data?.IsDisable);
      setDocData(docData);
    }
  }, [docData]);
  useEffect(()=>{
    if(photoGraphData){
      setImage = photoGraphData?.VersionData;
      setFileName =photoGraphData?.PathOnClient;
     
    }
  },[photoGraphData])

  useEffect(()=>{
    if(disabilityData){
      setDisabilityImage = disabilityData?.VersionData;
      setPdfFileNameDisability = disabilityData?.PathOnClient ;
      pdfDisability = disabilityData?.link;
    }
  },[disabilityData])

  useEffect(() => {
    if (uploaddocres?.success) {
      dispatch(unsavedchanges());
      setLoader(false);
      toast.success(<Success response={uploaddocres} />, {
        position: "top-center",
        autoClose: 2000,
      });
      setTimeout(() => {
        setLoader(false);
        dispatch({ type: UPLOAD_DOC_UG, payload: undefined });
        navigate(PATHS.PREVIEWDIPLOMA_STR + appIds);
      }, 1500);
    }
  }, [uploaddocres]);

  function downloadPDF(pdf) {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = "file.pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  useEffect(() => {
    if (appIds) {
      let id = {
        applicationId: appIds,
      };
      dispatch(getDocData(id));
    }
  }, [appIds]);

  const uploadPhoto = async (e) => {
    dispatch(unsavedchanges(e.target.value));
    const isValid = validateImageType(e.target.files[0]?.name);
    const fileSize = Math.round(e.target.files[0]?.size / 1024);
    if (isValid) {
      if (fileSize > 50 && fileSize < 100) {
        doc1 = e.target.files[0];
        setImage(await convertBase64(doc1));
        imagePath = e.target.files[0].name;
        setFileName= e.target.files[0].name;

        uploader(e);
      } else {
        alert(
          "Please upload the photo, ensuring size should be greater than 50kb and less than 100kb"
        );
      }
    }
  };

  function validateImageType(fileName) {
    if (fileName) {
      const fileExtension = fileName.split(".").at(-1);
      const allowedFileTypes = ["jpg", "jpeg", "JPEG", "JPG"];
      if (!allowedFileTypes.includes(fileExtension)) {
        window.alert(
          `File does not support. Files type must be ${allowedFileTypes.join(
            ", "
          )}`
        );
        return false;
      } else {
        return true;
      }
    }
  }

  const saveData = async () => {
    if (doc1) {
      const data = {
        userId: localStorage.getItem("userId"),
        contactId: localStorage.getItem("contactId"),
        applicationId: appIds,
        cuetYear: cuetYear,
        cuetLang: cuetLang,
        records: [
          {
            Title: doc1?.name ? doc1?.name : doc1?.Title,
            PathOnClient: doc1?.name ? "/" + doc1?.name : "/" + doc1?.Title,
            VersionData: checkBase64(doc1?.VersionData)
              ? doc1?.VersionData
              : await convertBase64(doc1),
            FirstPublishLocationId: appIds,
            Description: "Photograph upload",
            IsMajorVersion: true,
          },
        ],
      };
      if (docData?.data?.IsDisable) {
      
        if (!doc2) {
        alert("Please upload Disability");
        return false;
        }
        else {
          {
            data.Title = doc2?.name ? doc2?.name : doc2?.Title;
            data.PathOnClient = setPdfFileNameDisability
              ? "/" + setPdfFileNameDisability
              : "/" + doc2?.Title;
            // data.VersionData = filterPDF?.split('data:application/pdf;base64,')[1];
            data.VersionData = checkBase64(doc2?.VersionData)
              ? doc2?.VersionData
              : filterPDF?.split("data:application/pdf;base64,")[1];
            data.FirstPublishLocationId = appIds;
            data.Description = "Disablity upload";
            data.IsMajorVersion = true;

          
          }

          data.records?.push({
            Title: data.Title,
            PathOnClient: data.PathOnClient,
            VersionData: data.VersionData,
            FirstPublishLocationId: data.FirstPublishLocationId,
            Description: data.Description,
            IsMajorVersion: data.IsMajorVersion,
          });
        }
      }
      setLoader(true);
      dispatch(uploadDocument(data));
    } else {
      alert("Please Choose file");
    }
  };

  function useDisplayImage() {
    async function uploader(e) {
      const imageFile = e.target.files[0];
      const reader = new FileReader();
      reader.addEventListener("load", (e) => {
        setResult(e.target.result);
      });
      reader.readAsDataURL(imageFile);
    }
    return { rslt, uploader };
  }
  const checkBase64 = (data) => {
    const base64regex =
      /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    return base64regex.test(data);
  };

  const { result, uploader } = useDisplayImage();

  const currentDate = new Date();
  const year1 = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDate = `${year1}-${month}-${day}`;

  useEffect(() => {
    if (
      docData?.data?.applicationStatus === "Started" ||
      docData?.data?.applicationStatus === "Draft" ||
      docData?.data?.applicationStatus === "Payment Failed" ||
      docData?.data?.applicationStatus === "Payment Pending" ||
      docData?.data?.applicationStatus === "Payment Submitted"
    ) {
      if (formattedDate > docData?.data?.applicationCycleEndDate) {
        setReadOnly(true);
        // setShowMsg("We regret to inform you that the Application Submission for the current Admission cycle has been closed");
        setShowMsg(<Notes name="ADM_Cycle_Validation_App" />);
      } else {
        setReadOnly(false);
        setShowMsg("");
      }
    } else {
      if (formattedDate < docData?.data?.applicationCycleEndDate) {
        setReadOnly(true);
        setShowMsg("");
      } else {
        setReadOnly(true);
        // setShowMsg("We regret to inform you that the Application Submission for the current Admission cycle has been closed");
        setShowMsg(<Notes name="ADM_Cycle_Validation_App" />);
      }
    }
  }, [docData]);

  const handleButtonClick = () => {
    fileInputRef?.current?.click();
  };
  const handleDisabilityClick = () => {
    fileDisabilityInputRef?.current.click();
  };
  function validatePDFDisability(pdfFileNameDisability) {
    if (pdfFileNameDisability) {
      const fileExtension = pdfFileNameDisability.split(".").at(-1);
      const allowedFileTypes = ["pdf"];
      if (!allowedFileTypes.includes(fileExtension)) {
        window.alert(
          `File does not support. Files type must be ${allowedFileTypes.join(
            ", "
          )}`
        );
        return false;
      } else {
        return true;
      }
    }
  }
   /** upload disdability */
   const uploadDisability = async (e) => {
    dispatch(unsavedchanges(e.target.value));
    const isValid = validatePDFDisability(e.target.files[0]?.name);
    const fileSize = Math.round(e.target.files[0]?.size / 1024);
    if (isValid) {
      doc2 = e.target.files[0];
      filterPDF = await convertPDFBase64(doc2);
      pdfPath = e.target.files[0].name;
      setPdfFileNameDisability = e.target.files[0].name;
      setDisabilityImage=filterPDF.split("data:application/pdf;base64")[1];
      const url = URL.createObjectURL(doc2);
      // setPdfDisability(url);
      uploader(e);
    }
  };
  function displayDisability() {
    if (docData?.data?.IsDisable) {
    return (
          <tr>
            <td>
              {PGUploadDocuments.Upload_DISABILITY}{" "}
              <span class="redstyl">* </span>{" "}
            </td>

            <td>
              <div className="flex uploadpic">
                <span
                  onClick={handleDisabilityClick}
                  className="uploadfile_styl"
                >
                  {PGUploadDocuments.Upload_Doc_File}{" "}
                </span>
                <span>{setPdfFileNameDisability ? setPdfFileNameDisability : "No file choosen"}</span>
              </div>
              <input
                ref={fileDisabilityInputRef}
                type="file"
                id="myfile"
                name="myfile"
                accept="application/pdf"
                onChange={uploadDisability}
                disabled={readOnly}
                style={{ display: "none" }}
              />{" "}
            </td>
            <td>
              {setDisabilityImage && (
                <a 
                href={pdfDisability}
                target="_blank" style={{ cursor: "pointer" }}>
                   {'Disability- '}{appIds}
                </a>
              )}
            </td>
          </tr>

    );
  }else{
    return false;
  }
  }

  return (
    <Home>
      <Loader show={loader}></Loader>
      <div class="right_panel">
        <div class="w100">
          <p className="top_head_styl">{showMsg}</p>
          <h4 className="top_head">
            {getCapitalized(prgLabelname?.programName)}
          </h4>
          <span className="help_link">
            <p>
              <NavLink to={PATHS.RAISEQUERY}>{HELP_TEXT}</NavLink>{" "}
              <Tooltip id="helpTooltip">
                {/* <p>Click to Raise Query</p> */}
                <p>{ApplicationViewPage.Help_Tooltip_Text}</p>
              </Tooltip>
              <i
                data-tooltip-id="helpTooltip"
                class="fa-solid fa-circle-info"
              ></i>
            </p>
          </span>
        </div>
        <div className="w100 scroll_styl">
          <div class="heading_styl head_font">
            {/* <h4>{getCapitalized("upload photograph and documents")}</h4> */}
            <h4>
              {getCapitalized(DiplomaUploadDocuments.Upload_Photo_Documents)}
            </h4>
          </div>
          <div class="form_sec form_part">
            <div class="form_styl">
              <div class="w100 blctext">
                  <Notes name = 'FileUploadInstructions' />
              </div>
            </div>

            <div class="form_styl">
              <table>
                <tr>
                  <td>
                    {/* Upload Photograph : <span class="redstyl">* </span>{" "} */}
                    {DiplomaUploadDocuments.Upload_Photograph}{" "}
                    <span class="redstyl">* </span>{" "}
                  </td>

                  <td>
                    <div className="flex uploadpic">
                      <span
                        onClick={handleButtonClick}
                        className="uploadfile_styl"
                      >
                        {/* Upload File{" "} */}
                        {DiplomaUploadDocuments.Upload_File}{" "}
                      </span>
                      <span>{setFileName ? setFileName : "No file choosen"}</span>
                    </div>
                    <input
                      ref={fileInputRef}
                      type="file"
                      id="myfile"
                      name="myfile"
                      accept="image/jpeg"
                      onChange={uploadPhoto}
                      disabled={readOnly}
                      style={{ display: "none" }}
                    />{" "}
                  </td>
                  <td>
                    {" "}
                    <img
                      ref={imageRef}
                      src={`data:image/jpeg;base64,${image}`}
                      alt=""
                      className="myImage"
                      disabled={readOnly}
                    />
                    {/* )} */}
                  </td>
                </tr>
                {displayDisability()}
              </table>
            </div>

            <div class="form_styl">
              <div class="w100">
                <input
                  onClick={() => {
                    saveData();
                  }}
                  type="button"
                  // value="Save and Proceed "
                  value={ApplicationViewPage.Save_And_Proceed_Button_Message}
                  class="submit"
                  disabled={readOnly}
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Home>
  );
};

export default UploadPhotoDiploma;
